import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
// import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { withStyles} from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";


const containedButton = {
    color: "white",
    backgroundColor: "#46596A",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "10px !important"
};

const outlinedButton = {
    borderColor: "#46596A",
    // background-color: #d7dadd,
    color: "#46596A",
    // width: '100%',
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "10px !important",
};
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <i className="material-icons-outlined">close</i>
            </IconButton>
        </MuiDialogTitle>
    );
});



const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function DuplicateConfirmationDialog(props) {
    let {
        open,row
    }=props.props;
    return (
        <>
        <Dialog
          aria-labelledby="Calculation Types"
          open={open}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle onClick={()=>{props.props.handleDuplicateConfirmDialog(row,"close",false)}}>Confirm </DialogTitle>
          <MuiDialogContent dividers>
            <Typography>
              There is already <b>Duplicate row</b> with current selection.Are you sure want to proceed?
            </Typography>
          </MuiDialogContent>
          <DialogActions>
            <Button style={outlinedButton} onClick={()=>{props.props.handleDuplicateConfirmDialog(row,"close",false)}}>
              No
            </Button>
            <Button
              autoFocus
              style={containedButton}
              onClick={() => {
                props.props.handleDuplicateConfirmDialog(row,"close",true)
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        
        </>
    );
}