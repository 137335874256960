const constants = {
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  CHANGE_THEME: "CHANGE_THEME",
  TOGGLE_SIDENAV: "TOGGLE_SIDENAV",
  UPDATE_CNC_DATAS :"UPDATE_CNC_DATAS",
  UPDATE_ALERT_CNC: "UPDATE_ALERT_CNC",
  UPDATE_CNC_INPUTS :"UPDATE_CNC_INPUTS",
  UPDATE_FLAGS :"UPDATE_FLAGS",
  UPDATE_WHOLE_CNC_INPUTS:"UPDATE_WHOLE_CNC_INPUTS",
  UPDATE_SCREEN:"UPDATE_SCREEN",  
  UPDATE_ADMIN_INPUTS:"UPDATE_ADMIN_INPUTS",
  UPDATE_WHOLE_ADMIN_INPUT:"UPDATE_WHOLE_ADMIN_INPUT",
};

export default constants;
