/*
Created on June 2022
Confidential and Proprietary of Reckitt
Project: CNC
Component name : Override Table
Component Description : Whenever Scenario Flag is enabled ,This Table Component  is shown
                        to override weights and price forecast
@author: Tailwyndz
*/


import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from "@material-ui/core/TableContainer";
import { updateCNCInputs, updateAlertCNC, updatewholeCNCInputs ,updateCNCDatas} from '../../../../redux/actions';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles, makeStyles } from '@material-ui/core/styles';





  function GetTableData(props) {

    let {updateCNCInputs,cncData} = props;
    const CNCTableCell = withStyles((theme) => ({
        head: {
          backgroundColor: "#e5e5e5",
          color: "#212529",
          fontSize: 12,
          height: 10, textAlign: 'left'
        },
        body: {
          fontSize: 12, textAlign: 'left', padding: 6
        },
      }))(TableCell);

      const cncTableStyles = makeStyles({
        table: {
          // width:"100%"
          // minHeight: 400,
          // overflowY: "auto"
        },
        container: {
          maxHeight: 350
        }
      });
      const classes = cncTableStyles();
      const handleOverride=(e,id)=>{
        let override_forecast_header=[...cncData.override_forecast_header];
        const row=override_forecast_header[id]
    
        row.value=parseInt(e.target.value);
        override_forecast_header[id]=row;
        updateCNCInputs("override_forecast_header",override_forecast_header);
    
        
      } 
    
    return (
      <TableContainer className={classes.container}>
        <Table stickyHeader className={classes.table}>
          <TableHead className="cnc-table-headers">
            <TableRow>
              {cncData.override_forecast_header.map((item, idx) => (
                <CNCTableCell>{item.label}</CNCTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="cnc-table-body">
            <TableRow >
              {cncData.override_forecast_header.map((item, id) => (
                <CNCTableCell >
                  {
                    cncData.mode==="view"?
                    <input type="number" id={id} value={item.value} disabled={true} autoFocus={id === cncData.override_active} onChange={(e)=>{updateCNCInputs("override_active",id); handleOverride(e,id)}} style={{ width: "75% !important",textAlign: "right" }} className='table-input' ></input>:
                    <input type="number" id={id} value={item.value} autoFocus={id === cncData.override_active} onChange={(e)=>{updateCNCInputs("override_active",id); handleOverride(e,id)}} style={{ width: "75% !important",textAlign: "right" }} className='table-input' ></input>
                  } 
                </CNCTableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }


  GetTableData.propTypes = {
    user: PropTypes.object,
    userData: PropTypes.object,
    cncData: PropTypes.object,
    updateCNCInputs: PropTypes.func,
    updatewholeCNCInputs: PropTypes.func,
    updateAlertCNC: PropTypes.func,
    updateCNCDatas:PropTypes.func,
  };
  
  const mapStateToProps = (state) => ({
    user: state.user,
    cncData: state.cncinputs,
    allData:state.data
  });
  
  const mapDispatchToProps = {
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateCNCDatas,
  };
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GetTableData));