const user = {
  isAuth: false,
  theme: "light",
  username: "",
  preferred_username: "",
  token: "",
  error: null,
  role:null,
  groups:[],
  group_add_info:[],
  errorCode: null,
  alertInfo: {
    message: "",
    open: false,
    severity: "success",
    closeFlag:true
  },
  screen: {
    isSidenavBarExpanded: true,
  },
  sidebarItems:[]
};
const cncinputs={
  loading:false,
  mode:"",
  run_id:"",
  tableHeader:[],
  cncDataGraph:[],
  futures_graph1:[],
  futures_graph2:[],
  futures_graph3:[],
  futures_graph4:[],
  weight_graph1:20,
  weight_graph2:20,
  weight_graph3:20,
  weight_graph4:20,
  weight_override:0,
  weight_default_total : 100,
  weight_graphs:[],
  weight_graphs_copy:[],
  weight_min:0,
  weight_max:100,
  importance_type:"",
  override_values:[],
  demand_in_mt:[],
  override_forecast_header:[],
  demand_header:[],
  demand_treeData:[],
  override_active:"",
  commodityOptions:[],
  commodity_selection:"",
  commodity_selection_value:"",
  month_selection:"",
  month_selection_value:"",
  month_options:[],
  description:"",
  isIndexVisible:false,
  isCountryVisible:false,
  isDemandEditable:false,
  scenarioFlag:false,
  scenario_flag_checkbox:false,
  isExpandable:false,
  indexOptions:[],
  index_commodity_id:"",
  region_selection:[],
  regionOptions:[],
  regionOptionsAll:[],
  region_selection_value:[],
  countryOptions:[],
  countryOptionsAll:[],
  country_selection:"",
  country_selection_value:[],
  factoryOptions:[],
  factoryOptionsAll:[],
  factory_selection:[],
  use_for_final_hedging:false,
  index_source_weights:[],
  demandDataEdited:false,
  report_url:"",
  demand_difference_flag:false,
}
const data ={
  saved_commodity:null,
  shared_commodity:null,
  starred_commodity:null,
  commodity_runs:null,
  schedule_runs:null,
  notification_data:null,
  activeCommodity:null,
  activeCommodityRun:null,
  defaultCommodity:null,
  unSavedChangesDialogData:{
      open:false,
      savefunction:()=>{},
      cancelfunction:()=>{},
  }
}
const flags={
  disable:false,
  reloadCommodities:false,
  reloadConfigs:false,
  reloadRuns:false,
  reloadNotification:true,
  unSavedChanges:false
}
const admin_data={
  type_selection_value : "",
  userSelectedData : [],
  selected_user : '',
  users : [],
  userSelectedData : [],
  users_firestore:[],
  userGroupOptions:[],
  userList:[],
  group_label:"",
  group_value:"",
  groupData:[],
  admin_options:[],
  demand_flag:false,
  activeSelections:{
    indexSelection:[],
    commoditySelection:[],
    regionSelection:[],
    countrySelection:[],
    factorySelection:[],
  },
  planner_info:{},
  activeData:[],
  commodityOptions:[],
  indexOptions:[],
  regionOptions:[],
  countryOptions:[],
  factoryOptions:[],
  reload_flag:false,
}
export const stateData = {
  user,
  cncinputs,
  data,
  flags,
  admin_data
};
