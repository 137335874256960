/*
Created on June 2022
Confidential and Proprietary of Reckitt
Project: CNC
Component name : BottomSelection
Component Description : This Component Displays the Bottom Panel (Price Forecast Graph &Demand Tree) 
@author: Tailwyndz
*/

import React, { useEffect, useState } from "react";
import {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
  updateCNCDatas,
} from "../../../../redux/actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { config } from '../../../../config/config';
import { useHistory } from "react-router";
import { Grid } from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Buttons from '../../../../components/Button/Buttons'
import "../addcommodity.scss";
import Loader from '../../../../components/Loader/Loader';
import CNCDashboard from '../components/cncDashboard/CNCDashboard';
import TreeTableComponent from "../../../../components/TreeTable/TreeTable"
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import nocommodity from '../../../../../assets/images/undraw_empty.svg'
import GetTableData from './OverrideTable';
import Button from "@material-ui/core/Button";

function BottomSelection(props) {
  let { user,cncData,updateCNCInputs, updatewholeCNCInputs, updateAlertCNC} = props;
  const data = [
    {
      month: 'April',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      month: 'May',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      month: 'June',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      month: 'July',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      month: 'August',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      month: 'September',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      month: 'October',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    }, {
      month: 'November',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
  ];
  let api = config.api.api_url;
  const history = useHistory();
  let username = user.preferred_username;
  let usermail = user.email
  let cncpanels = config.cnc_panel.slice(0, 2);
  const [disable, setDisable] = useState(false)
  const paperuseStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      '& > *': {
        margin: theme.spacing(1),
        // width: theme.spacing(40),
        height: theme.spacing(30),
      },
    },
  }));
  const containedCNCButton = {
    color: "white",
    backgroundColor: "#46596A",
    fontFamily: "Hind Siliguri",
    fontSize: "11px",
    fontWeight: "bold",
    marginRight: "10px !important",
    height: "20px",
  }
  const paper = paperuseStyles();
  const [value, setValue] = useState(0);
  const [actionflag, setActionflag] = useState(false)
  const outlinedCNCButton = {
    borderColor: '#46596A',
    // background-color: #d7dadd,
    color: '#46596A',
    // width: '100%',
    fontFamily: "Hind Siliguri",
    fontSize: '12px',
    fontWeight: "bold",
    marginRight: "5px",
  }
  const containedButton = {
    color: "white",
    backgroundColor: "#46596A",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    // marginLeft: "10px !important",
    marginRight: "5px",
};
const outlinedButton = {
    borderColor: "#46596A",
    // background-color: #d7dadd,
    color: "#46596A",
    // width: '100%',
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "10px !important",
};
  let today = new Date();
  let currentUTC =
    today.getUTCFullYear() +
    "-" +
    (today.getUTCMonth() < 9
      ? "0" + (today.getUTCMonth() + 1)
      : today.getUTCMonth() + 1) +
    "-" +
    (today.getUTCDate() < 10 ? "0" + today.getUTCDate() : today.getUTCDate());
  let currentDate =
    today.getFullYear() +
    "-" +
    (today.getMonth() < 9
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

  const formatXAxis = (tickItem) => {
    return new Date(tickItem).toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
  }

  useEffect(() => {
    updateCNCInputs("demand_difference_flag", false);
  }, [])

  function initializeState() {

    let initialState = [];
    initialState.loading = false;
    initialState.mode = "";
    initialState.cncDataGraph = [];
    initialState.tableHeader = [];
    initialState.futures_graph1 = [];
    initialState.futures_graph2 = [];
    initialState.futures_graph3 = [];
    initialState.futures_graph4 = [];
    initialState.weight_graph1 = 20;
    initialState.weight_graph2 = 20;
    initialState.weight_graph3 = 20;
    initialState.weight_graph4 = 20;
    initialState.weight_override = 0;
    initialState.weight_default_total = 100;
    initialState.weight_graphs = [];
    initialState.weight_graphs_copy = [];
    initialState.weight_min = 0;
    initialState.weight_max = 100;
    initialState.importance_type = "";
    initialState.override_values = [];
    initialState.demand_in_mt = [];
    initialState.override_forecast_header = [];
    initialState.demand_header = [];
    initialState.demand_treeData = [];
    initialState.commodityOptions = [];
    initialState.commodity_selection = "";
    initialState.commodity_selection_value = "";
    initialState.month_selection = "";
    initialState.month_selection_value = "";
    initialState.description = "";
    initialState.month_options = [];
    initialState.region_selection = [];
    initialState.regionOptions = [];
    initialState.regionOptionsAll = [];
    initialState.region_selection_value = [{
      label: "Global",
      value: "Global",
      geography_level: "region",
      parent_id: "null",
    }];
    initialState.isIndexVisible = false;
    initialState.isCountryVisible = false;
    initialState.isDemandEditable = false;
    initialState.scenarioFlag = false;
    initialState.scenario_flag_checkbox = false;
    initialState.isExpandable = false;
    initialState.indexOptions = [];
    initialState.index_commodity_id = "";
    initialState.countryOptions = [];
    initialState.countryOptionsAll = [];
    initialState.country_selection = "";
    initialState.country_selection_value = [];
    initialState.factoryOptions = [];
    initialState.factoryOptionsAll = [];
    initialState.factory_selection = [];
    initialState.use_for_final_hedging = false;
    initialState.index_source_weights = [];
    initialState.demandDataEdited = false;
    initialState.demand_difference_flag = false;
    initialState.graph_difference_flag = false;
    initialState.expand = true;
    updatewholeCNCInputs(initialState);
  }

  function onCancel() {
    initializeState();
    history.push({
      pathname: `/home/myCommodityHedging`,
    });
  }

  function handleTabs(value) {
    setValue(value)
  }

  function weightChange(id, e, forecast_source) {
    if (e.target.value < 0 || e.target.value > 100) {
      updateAlertCNC({ open: true, message: config.messages.weight_individual_validation, severity: "info", closeFlag: true })
    }
    else {
      if (id !== 'override') {
        let graphWeights = cncData.weight_graphs
        graphWeights[forecast_source] = parseInt(e.target.value)

        updateCNCInputs("weight_graphs", graphWeights)
      }
      else {
        updateCNCInputs("weight_override", parseInt(e.target.value))
      }
    }
  }

  const handleDiffrenceChange = (event) => {
    if (event.target.name === "Demand_Difference") {
      if (event.target.checked) {
        updateAlertCNC({ open: true, message: config.messages.show_demand_flag_enabled, severity: "info" })
      }

      else {
        // updateAlertCNC({ open: true, message: config.messages.show_demand_flag_disabled, severity: "info" })
      }
      updateCNCInputs("demand_difference_flag", event.target.checked);
      handleTabs(1);
    }
  }

  //Handle checkbox toggle for the Scenario Checkbox
  const handleScenarioChange = (event) => {
    //   setScenarioFlagState(event.target.checked)

    if (event.target.checked) {
      updateAlertCNC({ open: true, message: config.messages.scenario_flag_enabled, severity: "info" })
    }

    else {
      updateAlertCNC({ open: true, message: config.messages.scenario_flag_disabled, severity: "info" })
      let override_forecast_header = cncData.override_forecast_header;

      for (let i = 0; i < override_forecast_header.length; i++) {
        override_forecast_header[i].value = ""
      }
      updateCNCInputs("override_forecast_header", override_forecast_header)
    }
    if (event.target.name === "Scenario_Flag") {
      updateCNCInputs("scenario_flag_checkbox", event.target.checked);
      handleTabs(0);//if Flag is enabled user has to fill the Override weight and values
    }
  }


  //cncData.override_forecast_header has the Price Forecast override Values
  //If any of Input value is empty or Nan Then the function returns false
  //return true only when all the override values are entered
  const overrideWeightsEmptyCheck = () => {
    if (cncData.override_forecast_header !== undefined) {
      for (let i = 0; i < cncData.override_forecast_header.length; i++) {
        if (cncData.override_forecast_header[i].value === '' || (Number.isNaN(cncData.override_forecast_header[i].value))) {
          updateAlertCNC({
            open: true,
            message: config.messages.override_weight_empty_check,
            severity: "info",
          });
          return false;
        }
      }
      return true;
    }

  }

  const weightSumValidation = () => {

    if (cncData.scenario_flag_checkbox) {
      //The Override Weight value cannot be 0 or NaN ,throw alert if the case occurs
      if (cncData.weight_override === 0 || Number.isNaN(cncData.weight_override)) {
        updateAlertCNC({
          open: true,
          message: config.messages.override_weight_validation,
          severity: "info",
        });
        return false;
      }
      //The price forecast override values cannot be left empty 
      let override_empty_check = overrideWeightsEmptyCheck();
      if (!override_empty_check) {
        return false;
      }


      else {
        let weight_sum = 0;
        for (let i = 0; i < cncData.unique_values.length; i++) {
          weight_sum += cncData.weight_graphs[cncData.unique_values[i]];
        }
        if (cncData.scenario_flag_checkbox) {
          weight_sum = weight_sum + cncData.weight_override;
        }
       

        if (weight_sum > 100) {
          updateAlertCNC({
            open: true,
            message: config.messages.weight_validation,
            severity: "info",
          });
          updateCNCInputs("weight_graphs", cncData.weight_graphs_copy);
          // updateCNCInputs("weight_override",0);
          return false;
        }

        else {
          return true;
        }
      }

    }

    else {
      return true;
    }



  }

  //function to handle next tab movement 
  const handleNext = () => {
    //if scenarioFlag is enabled user can edit weights 
    //so validating sum of weights =100 before moving to next tab
    //if sum of weights exceeds 100 throw alert to the users
    if (cncData.scenarioFlag) {

      if (weightSumValidation()) { handleTabs(1); }
    } else {
      //The users will be navigated to Demand tab if scenarioFlag is disabled for commodity
      handleTabs(1);
    }

  }

  function formattedDate(date) {
    let d = new Date(date)
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${year}-${month}-${day}`;
  }

  //Function to Generate Demand Tree Data when commodity is Saved/Save &Run
  /*
    1)cncData.ancestorData.data contains all the Rowmodels currently available in demand tree structure in a list 
    2)This function Generates the tree the tree structure again from this data
 
  */
  const generateTreeData = () => {
    let ancs = cncData.ancestorData.data;
    let regionData = []
    let countryTreeData = [];
    let Factorydata = []
    //Generate the TreeData for Factory Level
    if (cncData.factory_selection !== undefined && cncData.factory_selection.length > 0) {
      for (let i = 0; i < ancs.length; i++) {
        let rowdata = {};
        if (ancs[i].data.level === "factory") {
          rowdata = {
            data: ancs[i].data,
            height: 37,
            children: [],
          };
          Factorydata.push(rowdata);
        }

      }
      updateCNCInputs("demand_treeData", Factorydata);
      return Factorydata;
    }
    //Generate the TreeData for Country Level
    else if (cncData.country_selection_value !== undefined && cncData.country_selection_value.length > 0) {
      //Genarate the country level structure for the tree
      for (let i = 0; i < ancs.length; i++) {
        let rowdata = {};
        if (ancs[i].data.level === "country") {
          rowdata = {
            data: ancs[i].data,
            height: 37,
            children: [],
          };
          countryTreeData.push(rowdata)
        }

      }
      //insert factory into country Tree data

      for (let i = 0; i < ancs.length; i++) {
        let rowdata = {};
        if (ancs[i].data.level === "factory") {
          rowdata = {
            data: ancs[i].data,
            height: 37,
            children: [],
          };
          Factorydata.push(rowdata);
        }

      }



      for (let i = 0; i < countryTreeData.length; i++) {
        for (let j = 0; j < Factorydata.length; j++) {
          if (Factorydata[j].data.parent === countryTreeData[i].data.name) {
            countryTreeData[i].children.push(Factorydata[j])
          }
        }
      }
      updateCNCInputs("demand_treeData", countryTreeData);
      return countryTreeData;
    }
    //Generate the TreeData for Region Level
    else if (cncData.region_selection_value !== undefined && cncData.region_selection_value.length > 0) {
      //generate country data
      for (let i = 0; i < ancs.length; i++) {
        let rowdata = {};
        if (ancs[i].data.level === "country") {
          rowdata = {
            data: ancs[i].data,
            height: 37,
            children: [],
          };
          countryTreeData.push(rowdata)
        }

      }
      //insert factory into country data

      for (let i = 0; i < ancs.length; i++) {
        let rowdata = {};
        if (ancs[i].data.level === "factory") {
          rowdata = {
            data: ancs[i].data,
            height: 37,
            children: [],
          };
          Factorydata.push(rowdata);
        }

      }



      for (let i = 0; i < countryTreeData.length; i++) {
        for (let j = 0; j < Factorydata.length; j++) {
          if (Factorydata[j].data.parent === countryTreeData[i].data.name) {
            countryTreeData[i].children.push(Factorydata[j])
          }
        }
      }

      for (let i = 0; i < ancs.length; i++) {
        let rowdata = {};
        if (ancs[i].data.level === "region") {
          rowdata = {
            data: ancs[i].data,
            height: 37,
            children: [],
          };
          regionData.push(rowdata);
        }

      }



      for (let i = 0; i < regionData.length; i++) {
        for (let j = 0; j < countryTreeData.length; j++) {
          if (countryTreeData[j].data.parent === regionData[i].data.name) {
            regionData[i].children.push(countryTreeData[j])
          }
        }
      }

      //If the Region Selection has Global selected then insert all the region level data into Global
      if (cncData.region_selection_value[0].value === "Global") {

        let GlobalData = [];
        for (let i = 0; i < ancs.length; i++) {
          let rowdata = {};
          if (ancs[i].data.name === "Global") {
            rowdata = {
              data: ancs[i].data,
              height: 37,
              children: [],
            };
            GlobalData.push(rowdata);
            break;
          }

        }


        for (let i = 0; i < GlobalData.length; i++) {
          for (let j = 0; j < regionData.length; j++) {
            if (regionData[j].data.parent === GlobalData[i].data.name) {
              GlobalData[i].children.push(regionData[j])
            }
          }
        }
        updateCNCInputs("demand_treeData", GlobalData);
        return GlobalData;
      }

      else {
        updateCNCInputs("demand_treeData", regionData);
        return regionData;
      }
    }



  }

  //Function to save the Commodity Hedging
  const Save = () => {
    if(cncData.cncDataGraph.length===0 ||cncData.demand_treeData.length===0){
      updateAlertCNC({ open: true, message: config.messages.forecast_demand_empty_check, severity: "info" })
      return;
    }
    setActionflag(true)
    if (cncData.commodity_selection_value !== '' && cncData.month_selection_value !== '') {
      let factory_selection_list = [];
      let country_selection_list = [];
      let region_selection_list = [];
      let demand_treeData = generateTreeData()
      let user_commodity_name = cncData.commodity_selection + "-" + cncData.month_selection_value;
      //If the Scenario flag checkbox is enabled  then Commodity name must prefix with 'Scenario'
      if (cncData.scenario_flag_checkbox === true) {
        user_commodity_name = 'Scenario ' + user_commodity_name
      }
      if (cncData.region_selection_value.length > 0) {
        for (let i = 0; i < cncData.region_selection_value.length; i++) {
          region_selection_list.push(cncData.region_selection_value[i]["value"])
          user_commodity_name = user_commodity_name + "-" + cncData.region_selection_value[i]["value"]
        }
      }
      if (cncData.country_selection_value.length > 0) {
        for (let i = 0; i < cncData.country_selection_value.length; i++) {
          country_selection_list.push(cncData.country_selection_value[i]["value"])
          user_commodity_name = user_commodity_name + "-" + cncData.country_selection_value[i]["value"]
        }
      }

      if (cncData.factory_selection.length > 0) {
        for (let i = 0; i < cncData.factory_selection.length; i++) {
          factory_selection_list.push(cncData.factory_selection[i]["value"])
          user_commodity_name = user_commodity_name + "-" + cncData.factory_selection[i]["value"]
        }
      }

      let triggered_time =
        currentUTC +
        " " +
        (today.getUTCHours() < 10
          ? "0" + today.getUTCHours()
          : today.getUTCHours()) +
        ":" +
        (today.getUTCMinutes() < 10
          ? "0" + today.getUTCMinutes()
          : today.getUTCMinutes()) +
        ":" +
        (today.getUTCSeconds() < 10
          ? "0" + today.getUTCSeconds()
          : today.getUTCSeconds());


      if (cncData.scenario_flag_checkbox) {
        let graphWeights = cncData.weight_graphs
        graphWeights[username] = cncData.weight_override

        updateCNCInputs("weight_graphs", graphWeights)
      }
      let reqOptions = {};
      if (cncData.mode === "create") {

        const input = {
          deleted: false,
          cnc_id: "",
          run_id: "",
          user: username,
          commodityOptions: cncData.commodityOptions,
          regionOptions: cncData.regionOptions,
          regionOptionsAll: cncData.regionOptionsAll,
          countryOptions: cncData.countryOptions,
          countryOptionsAll: cncData.countryOptionsAll,
          factoryOptions: cncData.factoryOptions,
          factoryOptionsAll: cncData.factoryOptionsAll,
          indexOptions: cncData.indexOptions,
          demand_header: cncData.demand_header,
          override_forecast_header: cncData.override_forecast_header,
          commodity_selection: cncData.commodity_selection,
          commodity_selection_value: cncData.commodity_selection_value,
          user_commodity_name: user_commodity_name,
          use_for_final_hedging: cncData.use_for_final_hedging,
          commodity_created_dt: currentDate,
          month_selection_value: cncData.month_selection_value,
          decision_month: formattedDate(cncData.month_selection_value),
          month_options: cncData.month_options,
          description: cncData.description,
          region_selection: region_selection_list,
          isIndexVisible: cncData.isIndexVisible,
          isCountryVisible: cncData.isCountryVisible,
          isDemandEditable: cncData.isDemandEditable,
          scenarioFlag: cncData.scenarioFlag,
          scenario_flag_checkbox: cncData.scenario_flag_checkbox,
          isExpandable: cncData.isExpandable,
          index_commodity_id: cncData.index_commodity_id,
          index_source_weights: cncData.index_source_weights,
          country_selection: country_selection_list,
          factory_selection: factory_selection_list,
          weight_default_total: cncData.weight_default_total,
          weight_graph1: cncData.weight_graph1,
          weight_graph2: cncData.weight_graph2,
          weight_graph3: cncData.weight_graph3,
          weight_graph4: cncData.weight_graph4,
          weight_graphs: cncData.weight_graphs,
          weight_max: cncData.weight_max,
          weight_min: cncData.weight_min,
          weight_override: cncData.weight_override,
          override_values: cncData.override_values,
          demand_in_mt: cncData.demand_in_mt,
          demand_treeData: demand_treeData,
          cncDataGraph: cncData.cncDataGraph,
          shared: 0,
          shared_with: [],
          starred: 0,
          total_runs: 0,
          timestamp: triggered_time,
          best_run: false,
          unique_values: cncData.unique_values,
          ancestorData: cncData.ancestorData,
          demandDataEdited: cncData.demandDataEdited,
        };
        reqOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(input),
        };
      }

      else {
        const input = {
          deleted: false,
          cnc_id: cncData.cnc_id,
          run_id: cncData.run_id,
          user: cncData.user,
          commodity_selection: cncData.commodity_selection,
          commodity_selection_value: cncData.commodity_selection_value,
          user_commodity_name: cncData.user_commodity_name,
          use_for_final_hedging: cncData.use_for_final_hedging,
          commodity_created_dt: currentDate,
          month_selection_value: cncData.month_selection_value,
          decision_month: formattedDate(cncData.month_selection_value),
          month_options: cncData.month_options,
          description: cncData.description,
          region_selection: region_selection_list,
          isIndexVisible: cncData.isIndexVisible,
          isCountryVisible: cncData.isCountryVisible,
          isDemandEditable: cncData.isDemandEditable,
          scenarioFlag: cncData.scenarioFlag,
          scenario_flag_checkbox: cncData.scenario_flag_checkbox,
          isExpandable: cncData.isExpandable,
          index_commodity_id: cncData.index_commodity_id,
          index_source_weights: cncData.index_source_weights,
          demand_header: cncData.demand_header,
          override_forecast_header: cncData.override_forecast_header,
          country_selection: country_selection_list,
          factory_selection: factory_selection_list,
          weight_default_total: cncData.weight_default_total,
          weight_graph1: cncData.weight_graph1,
          weight_graph2: cncData.weight_graph2,
          weight_graph3: cncData.weight_graph3,
          weight_graph4: cncData.weight_graph4,
          weight_graphs: cncData.weight_graphs,
          weight_max: cncData.weight_max,
          weight_min: cncData.weight_min,
          weight_override: cncData.weight_override,
          override_values: cncData.override_values,
          demand_in_mt: cncData.demand_in_mt,
          demand_treeData: demand_treeData,
          shared: cncData.shared,
          shared_with: cncData.shared_with,
          starred: cncData.starred,
          total_runs: cncData.total_runs,
          cncDataGraph: cncData.cncDataGraph,
          timestamp: triggered_time,
          best_run: false,
          unique_values: cncData.unique_values,
          ancestorData: cncData.ancestorData,
          demandDataEdited: cncData.demandDataEdited,
        };
        reqOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(input),
        };

      }

      fetch(api + "/userCommodities", reqOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.status.statusCode === "cnc-200") {
            updateAlertCNC({
              open: true,
              message: cncData.mode === "create" ? config.messages.commodity_save_success : config.messages.commodity_update_success,
              severity: "info",
              closeFlag: false
            });
            reqOptions.body = JSON.stringify(response.data)
            // initializeState();
            setActionflag(false)
            history.push({
              pathname: `/home/myCommodityHedging`,
            });
            setTimeout(() => {
              reqOptions.method = "POST"
              fetch(api + "/db/hedging_forecast", reqOptions)
                .then((response) => response.json())
                .then((response) => { 
                if(response.status.statusCode==="cnc-200"){
                  if(cncData.demandDataEdited){
                    fetch(api+"/sync_bronze_to_silver?commodity_selection_value="+cncData.commodity_selection_value)
                    .then((res)=>(res.json()))
                    .then((res)=>{})
                    .catch((error) => {
                      // updateAlertCNC({
                      //   open: true,
                      //   message: config.messages.unexpected_error,
                      //   severity: "info",
                      //   closeFlag:false
                      // });
                    });  
                  }
                }

                else if(response.status.statusCode==="cnc-400"){
                  updateAlertCNC({
                    open: true,
                    message: response.status.statusMessage,
                    severity: "info",
                    closeFlag:false
                  });
                }

                else{
                  updateAlertCNC({
                    open: true,
                    message: config.messages.unexpected_error,
                    severity: "info",
                    closeFlag:false
                  });
                }
                 
                })
                .catch((error) => {
                  updateAlertCNC({
                    open: true,
                    message: config.messages.unexpected_error,
                    severity: "info",
                    closeFlag:false
                  });
                });  
             
            }, 1000);
          }
          else {
            updateAlertCNC({
              open: true,
              message:
                response.status.statusMessage,
              severity: "info",
              closeFlag: true
            });
          }
        })
        .catch((error) => {
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
        });  
    }
    else {
      updateAlertCNC({ open: true, message: config.messages.fill_mandatory, severity: "info" })
    }
  }

  //Function to save and Run the Commodity Hedging
  const SaveRun = () => {
    if(cncData.cncDataGraph.length===0 ||cncData.demand_treeData.length===0){
      updateAlertCNC({ open: true, message: config.messages.forecast_demand_empty_check, severity: "info" })
      return;
    }
    setActionflag(true)
    if (cncData.commodity_selection_value !== '' && cncData.month_selection_value !== '') {
      let factory_selection_list = [];
      let country_selection_list = [];
      let region_selection_list = [];
      let demand_treeData = generateTreeData()
      let user_commodity_name = cncData.commodity_selection + "-" + cncData.month_selection_value;
      if (cncData.scenario_flag_checkbox === true) {
        user_commodity_name = 'Scenario ' + user_commodity_name
      }
      if (cncData.region_selection_value.length > 0) {
        for (let i = 0; i < cncData.region_selection_value.length; i++) {
          region_selection_list.push(cncData.region_selection_value[i]["value"])
          user_commodity_name = user_commodity_name + "-" + cncData.region_selection_value[i]["value"]
        }
      }
      if (cncData.country_selection_value.length > 0) {
        for (let i = 0; i < cncData.country_selection_value.length; i++) {
          country_selection_list.push(cncData.country_selection_value[i]["value"])
          user_commodity_name = user_commodity_name + "-" + cncData.country_selection_value[i]["value"]
        }
      }

      if (cncData.factory_selection.length > 0) {
        for (let i = 0; i < cncData.factory_selection.length; i++) {
          factory_selection_list.push(cncData.factory_selection[i]["value"])
          user_commodity_name = user_commodity_name + "-" + cncData.factory_selection[i]["value"]
        }
      }

      let triggered_time =
        currentUTC +
        " " +
        (today.getUTCHours() < 10
          ? "0" + today.getUTCHours()
          : today.getUTCHours()) +
        ":" +
        (today.getUTCMinutes() < 10
          ? "0" + today.getUTCMinutes()
          : today.getUTCMinutes()) +
        ":" +
        (today.getUTCSeconds() < 10
          ? "0" + today.getUTCSeconds()
          : today.getUTCSeconds());


      if (cncData.scenario_flag_checkbox) {
        let graphWeights = cncData.weight_graphs
        graphWeights[username] = cncData.weight_override

        updateCNCInputs("weight_graphs", graphWeights)
      }
      let reqOptions = {};
      if (cncData.mode === "create") {
        const cnc_commodities = {
          deleted: false,
          cnc_id: "",
          run_id: "",
          user: username,
          commodityOptions: cncData.commodityOptions,
          regionOptions: cncData.regionOptions,
          regionOptionsAll: cncData.regionOptionsAll,
          countryOptions: cncData.countryOptions,
          countryOptionsAll: cncData.countryOptionsAll,
          factoryOptions: cncData.factoryOptions,
          factoryOptionsAll: cncData.factoryOptionsAll,
          indexOptions: cncData.indexOptions,
          demand_header: cncData.demand_header,
          override_forecast_header: cncData.override_forecast_header,
          commodity_selection: cncData.commodity_selection,
          commodity_selection_value: cncData.commodity_selection_value,
          user_commodity_name: user_commodity_name,
          use_for_final_hedging: cncData.use_for_final_hedging,
          commodity_created_dt: currentDate,
          month_selection_value: cncData.month_selection_value,
          decision_month: formattedDate(cncData.month_selection_value),
          month_options: cncData.month_options,
          description: cncData.description,
          region_selection: region_selection_list,
          isIndexVisible: cncData.isIndexVisible,
          isCountryVisible: cncData.isCountryVisible,
          isDemandEditable: cncData.isDemandEditable,
          scenarioFlag: cncData.scenarioFlag,
          scenario_flag_checkbox: cncData.scenario_flag_checkbox,
          isExpandable: cncData.isExpandable,
          index_commodity_id: cncData.index_commodity_id,
          index_source_weights: cncData.index_source_weights,
          country_selection: country_selection_list,
          factory_selection: factory_selection_list,
          weight_default_total: cncData.weight_default_total,
          weight_graph1: cncData.weight_graph1,
          weight_graph2: cncData.weight_graph2,
          weight_graph3: cncData.weight_graph3,
          weight_graph4: cncData.weight_graph4,
          weight_graphs: cncData.weight_graphs,
          weight_max: cncData.weight_max,
          weight_min: cncData.weight_min,
          weight_override: cncData.weight_override,
          override_values: cncData.override_values,
          demand_in_mt: cncData.demand_in_mt,
          demand_treeData: demand_treeData,
          cncDataGraph: cncData.cncDataGraph,
          shared: 0,
          shared_with: [],
          starred: 0,
          total_runs: 0,
          timestamp: triggered_time,
          best_run: false,
          unique_values: cncData.unique_values,
          ancestorData: cncData.ancestorData,
          demandDataEdited: cncData.demandDataEdited,
        };
        const run = {
          run_id: cncData.cnc_id,
          cnc_id: cncData.cnc_id,
          user_commodity_name: user_commodity_name,
          use_for_final_hedging: cncData.use_for_final_hedging,
          created_by: username,
          user: username,
          created_on: currentDate,
          triggered_on: triggered_time,
          duration: "",
          flag: null,
          status: "",
          message: "",
          email: usermail,
          description: cncData.description,
          run_start_time: "",
          isDataLoaded: false,
        };

        const notification = {
          notification_id: "",
          username: user.preferred_username,
          triggered_on: triggered_time,
          cnc_id: data.cnc_id,
          user_commodity_name: user_commodity_name,
          notification_type: "",
          status: "Unread",
          message: "",
        };
        const input = {
          cnc_commodities: cnc_commodities,
          runs: run,
          notification: notification,
        };
        reqOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(input),
        };
      }

      else {
        const cnc_commodities = {
          deleted: false,
          cnc_id: cncData.cnc_id,
          run_id: cncData.run_id,
          user: cncData.user,
          commodityOptions: cncData.commodityOptions,
          regionOptions: cncData.regionOptions,
          regionOptionsAll: cncData.regionOptionsAll,
          countryOptions: cncData.countryOptions,
          countryOptionsAll: cncData.countryOptionsAll,
          factoryOptions: cncData.factoryOptions,
          factoryOptionsAll: cncData.factoryOptionsAll,
          indexOptions: cncData.indexOptions,
          demand_header: cncData.demand_header,
          override_forecast_header: cncData.override_forecast_header,
          commodity_selection: cncData.commodity_selection,
          commodity_selection_value: cncData.commodity_selection_value,
          user_commodity_name: cncData.user_commodity_name,
          use_for_final_hedging: cncData.use_for_final_hedging,
          commodity_created_dt: cncData.commodity_created_dt,
          month_selection_value: cncData.month_selection_value,
          decision_month: formattedDate(cncData.month_selection_value),
          month_options: cncData.month_options,
          description: cncData.description,
          region_selection: region_selection_list,
          isIndexVisible: cncData.isIndexVisible,
          isCountryVisible: cncData.isCountryVisible,
          isDemandEditable: cncData.isDemandEditable,
          scenarioFlag: cncData.scenarioFlag,
          scenario_flag_checkbox: cncData.scenario_flag_checkbox,
          isExpandable: cncData.isExpandable,
          index_commodity_id: cncData.index_commodity_id,
          index_source_weights: cncData.index_source_weights,
          country_selection: country_selection_list,
          factory_selection: factory_selection_list,
          weight_default_total: cncData.weight_default_total,
          weight_graph1: cncData.weight_graph1,
          weight_graph2: cncData.weight_graph2,
          weight_graph3: cncData.weight_graph3,
          weight_graph4: cncData.weight_graph4,
          weight_graphs: cncData.weight_graphs,
          weight_max: cncData.weight_max,
          weight_min: cncData.weight_min,
          weight_override: cncData.weight_override,
          override_values: cncData.override_values,
          demand_in_mt: cncData.demand_in_mt,
          demand_treeData: demand_treeData,
          shared: cncData.shared,
          shared_with: cncData.shared_with,
          starred: cncData.starred,
          total_runs: cncData.total_runs,
          cncDataGraph: cncData.cncDataGraph,
          timestamp: triggered_time,
          best_run: false,
          unique_values: cncData.unique_values,
          ancestorData: cncData.ancestorData,
          demandDataEdited: cncData.demandDataEdited,
        };

        const run = {
          run_id: cncData.cnc_id,
          cnc_id: cncData.cnc_id,
          user_commodity_name: cncData.user_commodity_name,
          use_for_final_hedging: cncData.use_for_final_hedging,
          created_by: username,
          user: cncData.user,
          created_on: currentDate,
          triggered_on: triggered_time,
          duration: "",
          flag: null,
          status: "",
          message: "",
          email: usermail,
          description: cncData.description,
          run_start_time: "",
          isDataLoaded: false,
        };

        const notification = {
          notification_id: "",
          username: user.preferred_username,
          triggered_on: triggered_time,
          cnc_id: data.cnc_id,
          user_commodity_name: cncData.user_commodity_name,
          notification_type: "",
          status: "Unread",
          message: "",
        };
        const input = {
          cnc_commodities: cnc_commodities,
          runs: run,
          notification: notification,
        };
        reqOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(input),
        };

      }



      fetch(api + "/save_run", reqOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.status.statusCode === "cnc-200") {
            updateAlertCNC({
              open: true,
              message: config.messages.commodity_save_run_success,
              severity: "info",
              closeFlag: false
            });
            setActionflag(false)
            reqOptions.body = JSON.stringify(response.commodity_data)
            // let run_id=response.data.run_id
            let run_data = response.run_data
            let cnc_commodities = response.commodity_data
            let notification = response.notification;
            // initializeState();
            let input = {
              runs: run_data,
              cnc_commodities: cnc_commodities,
              notification: notification,
            }
            let req = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(input),
            };
            fetch(api + "/trigger_job", req)
              .then((res) => res.json())
              .then((res) => { })
              .catch((error) => {
                updateAlertCNC({
                  open: true,
                  message: config.messages.unexpected_error,
                  severity: "info",
                  closeFlag:false
                });
              });  
              history.push({
                pathname: `/home/myCommodityHedging`,
              });  
            setTimeout(() => {
              reqOptions.method = "POST"
              fetch(api + "/db/hedging_forecast", reqOptions)
                .then((response) => response.json())
                .then((response) => { 
                if(response.status.statusCode==="cnc-200"){
                  if(cncData.demandDataEdited){
                    fetch(api+"/sync_bronze_to_silver?commodity_selection_value="+cncData.commodity_selection_value)
                    .then((res)=>(res.json()))
                    .then((res)=>{})
                    .catch((error) => {
                      // updateAlertCNC({
                      //   open: true,
                      //   message: config.messages.unexpected_error,
                      //   severity: "info",
                      //   closeFlag:false
                      // });
                    });  
                  }
                }

                else if(response.status.statusCode==="cnc-400"){
                  updateAlertCNC({
                    open: true,
                    message: response.status.statusMessage,
                    severity: "info",
                    closeFlag:false
                  });
                }

                else{
                  updateAlertCNC({
                    open: true,
                    message: config.messages.unexpected_error,
                    severity: "info",
                    closeFlag:false
                  });
                }
                 
                })
                .catch((error) => {
                  updateAlertCNC({
                    open: true,
                    message: config.messages.unexpected_error,
                    severity: "info",
                    closeFlag:false
                  });
                }); 
             
            }, 1000);
          }
          else {
            updateAlertCNC({
              open: true,
              message:
                response.status.statusMessage,
              severity: "info",
              closeFlag: true
            });
          }
        })
        .catch((error) => {
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
        });  
    }
    else {
      updateAlertCNC({ open: true, message: config.messages.fill_mandatory, severity: "info" })
    }
  }

  //This reducer function replaces %s with the values in demand_difference_url_params and return the report url
  var _r = function (p, c) { return p.replace(/%s/, c) };
  const getReportURL = () => {
    let url = ''
    let report_params = []; //used to store the values from report_parameters
    cncData.commodityOptions.filter((obj) => {
      if (obj.value === cncData.commodity_selection_value && obj.isIndexVisible) {
        url = obj.demand_difference_url;
        report_params = obj.demand_difference_url_params.split(',');
      }
    })
    let params = [];
    for (let i = 0; i < report_params.length; i++) {
      params.push(eval(report_params[i]))
    }
    return params.reduce(_r, url);
  }



  return (
    <>
      <div className="cnc-models-menu">
        {cncpanels.map((item, index) => (
          <div
            key={item}
            className="models-item"
            onClick={() => {
              {
                index === 1 ? handleNext() : handleTabs(index);
              }
            }}
          >
            <div
              className={`models-item-div ${index === value ? "active" : ""}`}
            >
              <p>{item}</p>
            </div>
          </div>
        ))}
        {cncData.scenarioFlag && cncData.mode !== "view" && (
          <div className="cnc-scenario-flag">
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      size="medium"
                      checked={cncData.scenario_flag_checkbox}
                      disabled={cncData.mode === "create" ? false : true}
                      onChange={handleScenarioChange}
                      name="Scenario_Flag"
                    />
                  }
                  label="Scenario"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </div>
        )}
        {
          (cncData.mode === "create" || cncData.mode === "edit") &&
          <div className="cnc-scenario-flag">
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      size="medium"
                      checked={cncData.demand_difference_flag}
                      disabled={(cncData.demand_treeData.length===0||cncData.loading)?true:false}
                      onChange={handleDiffrenceChange}
                      name="Demand_Difference"
                    />
                  }
                  label="Demand Difference"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </div>
        }

      </div>
      {cncData.mode !== "view" && (
        <>
          {value === 0 && (
            <>
              <div
                style={{
                  padding: "0px",
                  maxHeight: cncData.expand ? "47vh" : "500px",
                  overflowY: "scroll",
                }}
              >
                <Card
                  variant="outlined"
                  // style={{ padding: 10, marginTop: 10 }}
                >
                  <div className="cnc-card-header">
                    <label style={{marginLeft:"3px"}}>Price Forecast</label>
                  </div>
                  <div
                    style={{
                      // padding: 10,
                      // marginTop: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CardContent className="cnc-card-content">
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "5px" }}
                      >
                        <div className={paper.root}>
                          <>
                            {cncData.forecastloading ? (
                              <center>
                                <Loader />
                                <div>
                                  <p>Fetching price forecast data... </p>
                                </div>
                              </center>
                            ) : (
                              <>
                                {cncData.cncDataGraph.length > 0 ? (
                                  cncData.cncDataGraph.map((item, id) => (
                                    <Paper elevation={3}>
                                      <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                      >
                                        <Grid item xs={10} sm={10}>
                                          <div className="cnc-card-content-title">
                                            <label
                                              style={{
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              {item.forecast_source}
                                            </label>
                                          </div>
                                          <ResponsiveContainer
                                            width="100%"
                                            height={200}
                                          >
                                            <LineChart data={item.data}>
                                              <CartesianGrid strokeDasharray="3 3" />
                                              <XAxis
                                                dataKey={"forecast_month"}
                                                tickFormatter={formatXAxis}
                                              />
                                              <YAxis
                                                type="number"
                                                domain={[
                                                  (dataMin) =>
                                                  Number.isInteger(dataMin)?dataMin:dataMin.toFixed(2),
                                                  (dataMax) =>
                                                  Number.isInteger(dataMax)?dataMax:dataMax.toFixed(2),
                                                ]}
                                              />
                                              <Tooltip
                                                labelFormatter={formatXAxis}
                                              />
                                              <Legend />
                                              <Line
                                                type="monotone"
                                                dataKey={"price_forecast"}
                                                stroke="#8884d8"
                                              />
                                            </LineChart>
                                          </ResponsiveContainer>
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                          <div className="">
                                            <label>Weight</label>
                                          </div>
                                          <div>
                                            <input
                                              type="number"
                                              style={{
                                                width: "50%",
                                                textAlign: "right",
                                              }}
                                              className="form-control-cnc"
                                              id={id}
                                              value={
                                                cncData.weight_graphs[
                                                item.forecast_source
                                                ]
                                              }
                                              disabled={
                                                cncData.mode === "create" ||
                                                  cncData.mode === "edit"
                                                  ? !cncData.scenario_flag_checkbox
                                                  : true
                                              }
                                              min={0}
                                              max={100}
                                              placeholder="Enter a value"
                                              defaultValue={20}
                                              name="startdate"
                                              onChange={(event) => {
                                                updateCNCInputs(
                                                  "override_active",
                                                  ""
                                                );
                                                weightChange(
                                                  id,
                                                  event,
                                                  item.forecast_source
                                                );
                                              }}
                                            ></input>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  ))
                                ) : (
                                  <div className="no-commodity cnc-no-img-height">
                                    <img
                                      src={nocommodity}
                                      alt=""
                                      className="cnc-img"
                                    />
                                    {
                                        cncData.forecast_fetch_status?(
                                          <p style={{ marginLeft: "25px" }}>
                                        No data available
                                        </p>
                                        ):(
                                          <p style={{ marginLeft: "25px",width:"120%" }}>
                                         {cncData.forecast_errorMessage}
                                          </p>
                                        )
                                      } 
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        </div>
                      </Grid>
                    </CardContent>
                  </div>
                </Card>
                {
                  <>
                    {cncData.scenario_flag_checkbox && (
                      <Card
                        variant="outlined"
                        style={{ padding: 10, marginTop: 10 }}
                      >
                        <div className="cnc-card-header">
                          <label>Override Forecast</label>
                        </div>
                        <CardContent>
                          <div className="">
                            <label
                              style={{
                                textAlign: "right",
                                float: "left",
                                clear: "both",
                                marginRight: 15,
                              }}
                            >
                              Weight :{" "}
                            </label>
                            <input
                              type="number"
                              className="form-control-cnc"
                              id="override"
                              min={0}
                              max={100}
                              placeholder="Enter a value"
                              // defaultValue={0}
                              value={cncData.weight_override}
                              disabled={
                                cncData.mode === "create" ||
                                  cncData.mode === "edit"
                                  ? false
                                  : true
                              }
                              name="startdate"
                              onChange={(event) => {
                                updateCNCInputs("override_active", "");
                                weightChange("override", event);
                              }}
                              style={{
                                width: 50,
                                height: 10,
                                textAlign: "right",
                              }}
                            ></input>
                          </div>
                          <Box sx={{ minWidth: 275, padding: 10 }}>
                            <Card
                              variant="outlined"
                              style={{
                                padding: 10,
                                border: "solid 0px",
                              }}
                            >
                              <GetTableData />
                            </Card>
                          </Box>
                        </CardContent>
                      </Card>
                    )}
                  </>
                }
              </div>
              <div
                style={{ margin: "3px !important" }}
                className="cnc-button-footer"
              >
                <Buttons
                  button={[
                    {
                      name: "Next",
                      callback: handleNext,
                      flag: disable,
                      style: "contained",
                    },
                  ]}
                />
              </div>
            </>
          )}

          {value === 1 && (
            <>
              {/* <div
                  style={{
                    padding: "3px",
                    maxHeight: cncData.expand ? "300px" : "700px",
                    marginBottom: "23px",
                  }}
                > */}
              <>
                {/* <Box sx={{ minWidth: 275, padding: 10}} style={{marginTop:cncData.demand_difference_flag &&"-18px"}}> */}
                {
                  cncData.demand_difference_flag &&
                  <div
                    className="button-new"
                    style={{ marginTop: "-25px", marginBottom: "3px" }}
                  >
                    <Button
                      className="button-align"
                      disableRipple
                      variant={"contained"}
                      onClick={() => { }}
                      style={containedCNCButton}
                    >

                      <a
                        style={{
                          color: "white",
                          textDecoration: "none",
                          display: "flex",
                        }}
                        target="_blank"
                        rel="noreferrer"
                        href={getReportURL()}
                      >
                        <i className="material-icons-outlined search-icon" style={{ marginTop: "8px" }}>exit_to_app</i>
                        <p>View in Dashboard</p>
                      </a>
                    </Button>
                  </div>
                }
                <Card
                  variant="outlined"
                  style={{
                    padding: 0,
                    maxHeight: (!cncData.loading && !cncData.expand) ? "100vh" : "44vh",
                    height:
                      cncData.loading
                         &&
                      "44vh",
                  }}
                >
                  {cncData.loading ? (
                    <center>
                      <Loader />
                      <div>
                        <p>Fetching demand data...</p>
                      </div>
                    </center>
                  ) : (
                    <>
                      {cncData.demand_treeData.length > 0 ? (
                        <TreeTableComponent
                          data={cncData.demand_treeData}
                          month_options={cncData.demand_header}
                          demandFlag={
                            cncData.isDemandEditable ? false : true
                          }
                        />
                      ) : (
                        <div className="no-commodity">
                          <img
                            src={nocommodity}
                            alt=""
                            className="cnc-img"
                          />
                           {cncData.fetch_status?
                                  <p
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  No data available
                                </p>
                                :
                                <p
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {cncData.errorMessage}
                                </p>

                              }
                        </div>
                      )}
                    </>
                  )}
                </Card>
                {/* </Box> */}
              </>
              {/* </div> */}
              {(cncData.mode === "create"||cncData.mode === "edit") && (
                <div className="cnc-button-footer">
                  <div className="button-new">
                  <Button
                    autoFocus
                    variant="outlined"
                    style={outlinedCNCButton}
                    onClick={onCancel}
                    disabled={false}
                  >
                    Cancel
                </Button>
                  {/* <Buttons
                    button={[
                      {
                        name: "Cancel",
                        callback: onCancel,
                        flag: disable,
                        style: "outlined",
                      },
                    ]}
                  /> */}
                   <Button
                    autoFocus
                    style={actionflag ? outlinedButton:containedButton}
                    onClick={SaveRun}
                    disabled={actionflag}
                  >
                    {cncData.mode === "create"?"Save & Run":"Update & Run"}
                </Button>
                  {/* <Buttons
                    button={[
                      {
                        name: cncData.mode === "create"?"Save & Run":"Update & Run",
                        callback: SaveRun,
                        flag: false,
                        style: "outlined",
                      },
                    ]}
                  /> */}
                  <Button
                    autoFocus
                    style={actionflag ? outlinedButton:containedButton}
                    onClick={Save}
                    disabled={actionflag}
                  >
                    {cncData.mode === "create"?"Save":"Update"}
                </Button>
                  {/* <Buttons
                    button={[
                      {
                        name: cncData.mode === "create"?"Save":"Update",
                        callback: Save,
                        flag: !actionflag,
                        style: "contained",
                      },
                    ]}
                  /> */}
                  </div>
                </div>
              )}

              {/* {cncData.mode === "edit" && (
                <div className="cnc-button-footer">
                  <Buttons
                    button={[
                      {
                        name: "Cancel",
                        callback: onCancel,
                        flag: disable,
                        style: "outlined",
                      },
                    ]}
                  />
                  <Buttons
                    button={[
                      {
                        name: "Update & Run",
                        callback: SaveRun,
                        flag: disable,
                        style: "outlined",
                      },
                    ]}
                  />
                  <Buttons
                    button={[
                      {
                        name: "Update",
                        callback: Save,
                        flag: disable,
                        style: "contained",
                      },
                    ]}
                  />
                </div>
              )} */}
            </>
          )}
        </>
      )}

{cncData.mode === "view" && (
          <>
            {value === 0 && (
              <>
                <div
                  style={{
                    padding: "10px 0px 10px 0px",
                    maxHeight: !cncData.expand ? "90%" : "45%",
                    overflow: "auto",
                  }}
                >
                  <Card
                    variant="outlined"
                    style={{ padding: 10, marginTop: 10 }}
                  >
                    <div className="cnc-card-header">
                      <label>Forecast Weight</label>
                    </div>
                    {cncData.forecastloading ? (
                                <center>
                                  <Loader />
                                  <div>
                                    <p>Fetching  Forecast Weight... </p>
                                  </div>
                                </center>
                              ):(
                                <CardContent className="cnc-card-content">
                                <Grid
                                  container
                                  spacing={1}
                                  style={{ marginTop: "5px", textAlign: "center" }}
                                >
                                  {/* <div className={paper.root}> */}
                                  <Grid item xs={3} sm={3} direction="row"></Grid>
                                  {cncData.cncDataGraph !== undefined &&
                                  cncData.cncDataGraph.length > 0 ? (
                                    <>
                                      {cncData.cncDataGraph.map((item, id) => (
                                        <>
                                          <Grid item xs={1} sm={1} direction="row">
                                            <div className="">
                                              <span>{item.forecast_source}</span>
                                            </div>
                                          </Grid>
                                          <Grid item xs={1} sm={1} direction="row">
                                            <input
                                              type="number"
                                              style={{
                                                width: "70%",
                                                textAlign: "right",
                                              }}
                                              className="form-control-cnc"
                                              id="weight"
                                              value={
                                                item.forecast_source_weight
                                              }
                                              min={0}
                                              max={100}
                                              disabled={true}
                                              placeholder="Enter a value"
                                              defaultValue={20}
                                              name="startdate"
                                              onChange={(event) => {
                                                weightChange(id, event);
                                              }}
                                            ></input>
                                          </Grid>
                                        </>
                                      ))}
          
                                      {cncData.scenario_flag_checkbox && (
                                        <>
                                          <Grid item xs={1} sm={1} direction="row">
                                            <div className="">
                                              <label
                                                style={{
                                                  textAlign: "right",
                                                  float: "left",
                                                  clear: "both",
                                                  marginRight: 15,
                                                }}
                                              >
                                                Override Forecast
                                              </label>
                                            </div>
                                          </Grid>
                                          <Grid item xs={1} sm={1} direction="row">
                                            <input
                                              type="number"
                                              className="form-control-cnc"
                                              id="override"
                                              min={0}
                                              max={100}
                                              placeholder="Enter a value"
                                              defaultValue={20}
                                              value={cncData.weight_override}
                                              disabled={true}
                                              name="startdate"
                                              onChange={(event) => {
                                                weightChange("override", event);
                                              }}
                                              style={{
                                                width: 50,
                                                height: 10,
                                                textAlign: "right",
                                              }}
                                            ></input>
                                          </Grid>
                                        </>
                                      )}
                                      <Grid item xs={3} sm={3} direction="row"></Grid>
                                    </>
                                  ) : (
                                    <div className="no-commodity">
                                      <img
                                        src={nocommodity}
                                        alt=""
                                        className="cnc-img"
                                      />
                                      {
                                        cncData.forecast_fetch_status?(
                                          <p style={{ marginLeft: "25px" }}>
                                        No data available
                                        </p>
                                        ):(
                                          <p style={{ marginLeft: "25px" }}>
                                         {cncData.forecast_errorMessage}
                                          </p>
                                        )
                                      }                 
                                    </div>
                                  )}
                                  {/* </div> */}
                                </Grid>
                              </CardContent>
                              )
                    }                 
                    {cncData.scenario_flag_checkbox && (
                      <>
                        <div className="cnc-card-header">
                          <label>Override Forecast</label>
                        </div>
                        <CardContent>
                          <Box sx={{ minWidth: 275, padding: 10 }}>
                            <Card
                              variant="outlined"
                              style={{ padding: 10, border: "solid 0px" }}
                            >
                              {cncData.override_forecast_header.length > 0 && (
                                // getTableData()
                                <GetTableData />
                              )}
                            </Card>
                          </Box>
                        </CardContent>
                      </>
                    )}
                  </Card>

                  <CNCDashboard />
                </div>
              </>
            )}
            {value === 1 && (
              <>
                <div style={{ marginTop: "10px", padding: "10px" }}>
                  <>
                  <Card
                        variant="outlined"
                        style={{
                          padding: 0,
                          maxHeight: (!cncData.loading &&!cncData.expand) ?"65vh":"45vh",
                          height:
                            cncData.loading &&
                            "150px",
                        }}
                      >
                        {cncData.loading ? (
                          <center>
                            <Loader />
                            <div>
                              <p>Fetching demand data...</p>
                            </div>
                          </center>
                        ) : (
                          <>
                            {cncData.demand_treeData.length > 0 ? (
                              // <Box sx={{ minWidth: 275, padding: 10 }}>
                              // <Card variant="outlined" style={{ padding: 10 }}>
                                <TreeTableComponent
                                  data={cncData.demand_treeData}
                                  month_options={cncData.demand_header}
                                  demandFlag={false}
                                />
                            //   </Card>
                            // </Box>
                            ) : (
                              <div className="no-commodity">
                                <img
                                  src={nocommodity}
                                  alt=""
                                  className="cnc-img"
                                />
                                {cncData.fetch_status?
                                  <p
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  No data available
                                </p>
                                :
                                <p
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {cncData.errorMessage}
                                </p>

                                }
                                
                              </div>
                            )}
                          </>
                        )}

                      </Card>                 
                  </>
                </div>
                <CNCDashboard />
              </>
            )}
          </>
        )}
    </>
  );
}

BottomSelection.propTypes = {
  user: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  cncData: state.cncinputs,
  allData: state.data,
});

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BottomSelection)
);
