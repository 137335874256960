import * as React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from "@material-ui/core/TableContainer";
import Select from "react-select";
// import "./table.scss"
import TablePaginate from '../../../../components/PaginationTable/TablePagination';
import {updateAlertCNC} from "../../../../redux/actions";
import {config} from "../../../../config/config"
import Tablestyles from "./BudgetTable.module.css";
import { NumericFormat } from 'react-number-format';
const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#e5e5e5",
      color: "#212529",
    },
    body: {
      fontSize: 12,
    },
    root:{
      padding:"5px"
    }
  }))(TableCell);

const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    container: {},
  });

  const customStyles = { 
    menuPortal: (base) => ({
      ...base,
      maxHeight: "80%",
      fontSize: 12,
      fontFamily: "Hind Siliguri !important",
      zIndex: 9999,
    }),
  };  

  

 function BudgetTable(props) {
    let {cncData} = props;
    const classes = useStyles()
    // const [startDate, setStartDate] = useState(new Date());
    return (
      <>
        <TableContainer
          className={Tablestyles.budget_tb}
        >
          <Table stickyHeader className={classes.table}>
            <TableHead className="table-heading">
              <TableRow>
                {config.BudgetTableHeader.map((item, idx) => (
                  <StyledTableCell>{item}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="table_body_scenario">
              {props.filteredData
                .slice(
                  props.page * props.rowsPerPage,
                  props.page * props.rowsPerPage + props.rowsPerPage
                )
                .map((row, id) => (
                  <TableRow
                    key={id}
                    // title={row.model_id}
                    hover={true}
                  >
                   
                    <StyledTableCell style={{ width: "17%" }}>
                      <Select
                        options={cncData.budget_type_options}
                        menuPortalTarget={document.body}
                        value={cncData.budget_type_options && cncData.budget_type_options.filter((obj) => {
                          return obj.value === row.type;
                        })}
                        styles={customStyles}
                        isDisabled={row.existing_data?true:!row.edit_flag}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        placeholder={"Select Type"}
                        onChange={(event) => {
                          props.handleTypeChange(event, row.id);
                        }}
                      />
                    </StyledTableCell>

                    <StyledTableCell style={{ width: "17%" }}>
                    <Select
                        options={cncData.yearOptions}
                        menuPortalTarget={document.body}
                        value={cncData.yearOptions && cncData.yearOptions.filter((obj) => {
                          return obj.value === row.year;
                        })}
                        styles={customStyles}
                        isDisabled={row.existing_data?true:!row.edit_flag}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        placeholder={"Select Year"}
                        onChange={(event) => {
                          props.handleYearChange(event, row.id);
                        }}
                      />
                    </StyledTableCell>

                    <StyledTableCell style={{ width: "17%"}}>
                    <Select
                        options={props.monthOptions}
                        menuPortalTarget={document.body}
                        value={props.monthOptions && props.monthOptions.filter((obj) => {
                          return obj.value === row.month;
                        })}
                        styles={row.month_visible?customStyles:{control: (state) =>({display:"none"})}}
                        isDisabled={row.existing_data?true:!row.edit_flag}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        placeholder={"Select Month"}
                        onChange={(event) => {
                          props.handleMonthChange(event, row.id);
                        }}
                      />
                    </StyledTableCell>

                    <StyledTableCell style={{ width: "15%" }}>
                      {/* <input
                        type="number"
                        placeholder="Enter a value"
                        className="form-control"
                        id={row.id}
                        value={row.value}
                        min={0}
                        disabled={!row.edit_flag}
                        style={{ textAlign: "right" }}
                        name={"value"}
                        onChange={(event) => {
                          props.handleInputchange(event, row.id);
                        }}
                      ></input> */}

                        <NumericFormat
                             displayType={"number"}
                             value={row.value}
                            disabled={!row.edit_flag}
                            valueIsNumericString={true}
                            thousandSeparator=","
                            thousandsGroupStyle="thousand"
                            prefix={""}
                            min={0}
                            name={'value'}
                            onValueChange={(event,sourceInfo) => {
                              props.handleInputchange(event,row.id,'value')
                            }}
                            id={row.id}
                            style={{ textAlign: "right" }}
                            className="form-control"
                            />
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "9%" }}>
                      <i
                        aria-describedby={id}
                        id={row.cnc_id}
                        className="material-icons-outlined "
                        value={id}
                        onClick={() => {
                          props.handleDialog(
                            row.user_comments !== "null"
                              ? row.user_comments
                              : "",
                            row.id
                          );
                        }}
                      >
                        comments
                      </i>

                      {!row.edit_flag ? (
                        <i
                          aria-describedby={id}
                          id={row.id}
                          className="material-icons-outlined "
                          value={id}
                          onClick={() => {
                            props.handleRowEdit(row.id, "edit", id);
                          }}
                        >
                          edit
                        </i>
                      ) : (
                        <>
                          <i
                            aria-describedby={id}
                            id={row.cnc_id}
                            className="material-icons-outlined "
                            value={id}
                            style={{ color: "red" }}
                            onClick={() => {
                              props.handleRowEdit(row.id, "clear", id);
                            }}
                          >
                            close
                          </i>
                          <i
                            aria-describedby={id}
                            id={row.cnc_id}
                            className="material-icons-outlined "
                            value={id}
                            style={{ color: "green" }}
                            onClick={() => {
                              props.handleRowEdit(row.id, "save", id);
                            }}
                          >
                            done
                          </i>
                        </>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          
        </TableContainer>
        <TablePaginate data={props.page_props} />
      </>
    );
}


BudgetTable.propTypes = {
  updateAlertCNC: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user:state.user,
  cncData: state.cncinputs,
});

const mapDispatchToProps = {
  updateAlertCNC
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BudgetTable)
);