import * as React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from "@material-ui/core/TableContainer";
import "./table.scss"
import Tooltip from "@material-ui/core/Tooltip";
import PopoverComponent from '../Popover/PopoverComponent';
import TablePaginate from '../PaginationTable/TablePagination';
import {updateAlertCNC} from "../../redux/actions";



const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#e5e5e5",
      color: "#212529",
    },
    body: {
      fontSize: 12,
    },
  }))(TableCell);

const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    container: {},
  });

 function TableComponent(props) {
    let { updateAlertCNC} = props;
    const classes = useStyles()
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        //backgroundColor: "#f5f5f9",
        backgroundColor:"rgba(97, 97, 97, 0.9)",
        //color: "rgba(0, 0, 0, 0.87)",
        color:"#fff",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
        opacity: 1,
        transform: "none",
        transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        transform: "133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        // pointerEvents:"none",
        zIndex: 9999,
        // position:"absolute",
      }
    }))(Tooltip);
    return (
      <>
        { props.component ==="MyCommodity"||
          props.component ==="CommodityStarred"||
          props.component ==="MyCommodityRecent" ?(
            <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table}>
              <TableHead className="table-heading">
                <TableRow>
                  {props.header.map((item, idx) => (
                    <StyledTableCell>{item}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="table_body_scenario">
                {props.filteredData
                  .slice(
                    props.page * props.rowsPerpage,
                    props.page * props.rowsPerpage + props.rowsPerpage
                  )
                  .map((row, id) => (
                    <TableRow
                      key={id}
                      // title={row.model_id}
                      hover={true}
                    >
                      <StyledTableCell>
                        <Tooltip title={row.user_commodity_name} placement="center">
                          <div>
                            {row.user_commodity_name.length > 70
                              ? row.user_commodity_name.slice(0, 70) + "..."
                              : row.user_commodity_name}
                          </div>
                          </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>
                      {row.commodity_created_dt}
                        </StyledTableCell>
                      <StyledTableCell>
                       <Tooltip title={row.description} placement="center">
                          <div>
                            {row.description.length > 40
                              ? row.description.slice(0, 40) + "..."
                              : row.description}
                          </div>
                        </Tooltip>                     
                      </StyledTableCell>
                      <StyledTableCell>{row.total_runs}</StyledTableCell>
                      <StyledTableCell>
                         <i
                          aria-describedby={id}
                          id={row.cnc_id}
                          className="material-icons-outlined "
                          value={id}
                          onClick={props.handleClick}
                        >
                          more_vert
                        </i> 
                         <PopoverComponent
                          component={props.component}
                          handleClick={props.handleClick}
                          handleClose={props.handleClose}
                          anchorEl={props.anchorEl}
                          value={props.value}
                          id={id}
                          menulist={props.menulist}
                        /> 
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePaginate data={props.page_props} />
          </TableContainer>
          ):props.component ==="SharedCommodity"?(
            <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table}>
              <TableHead className="table-heading">
                <TableRow>
                  {props.header.map((item, idx) => (
                    <StyledTableCell>{item}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="table_body_scenario">
                {props.filteredData
                  .slice(
                    props.page * props.rowsPerpage,
                    props.page * props.rowsPerpage + props.rowsPerpage
                  )
                  .map((row, id) => (
                    <TableRow
                      key={id}
                      // title={row.model_id}
                      hover={true}
                    >
                      <StyledTableCell>
                              <Tooltip title={row.user_commodity_name} placement="center">
                          <div>
                            {row.user_commodity_name.length > 90
                              ? row.user_commodity_name.slice(0, 90) + "..."
                              : row.user_commodity_name}
                          </div>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>
                      {row.user}
                        </StyledTableCell>
                      <StyledTableCell>
                      {row.commodity_created_dt}
                        </StyledTableCell>
                      <StyledTableCell>
                      <Tooltip title={row.description} placement="center">
                          <div>
                            {row.description.length > 40
                              ? row.description.slice(0, 40) + "..."
                              : row.description}
                          </div>
                        </Tooltip>        
                      </StyledTableCell>
                      <StyledTableCell>{row.total_runs}</StyledTableCell>
                      <StyledTableCell>
                         <i
                          aria-describedby={id}
                          id={row.cnc_id}
                          className="material-icons-outlined "
                          value={id}
                          onClick={props.handleClick}
                        >
                          more_vert
                        </i> 
                        <PopoverComponent
                          component={props.component}
                          handleClick={props.handleClick}
                          handleClose={props.handleClose}
                          handleEdit={props.handleEdit}
                          handleView={props.handleView}
                          copyCommodity={props.copyCommodity}
                          runCommodity={props.runCommodity}
                          // scheduleDialog={props.scheduleDialog}
                          anchorEl={props.anchorEl}
                          value={props.value}
                          id={id}
                          access={props.access}
                        />
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePaginate data={props.page_props} />
          </TableContainer>
          ):(
            <></>
          )
         
       }
      </>
    );
}


TableComponent.propTypes = {
  updateAlertCNC: PropTypes.func,
};

const mapStateToProps = (state) => ({
  // data:state.dssStateData.data
});

const mapDispatchToProps = {
  updateAlertCNC
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableComponent)
);