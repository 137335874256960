import C from "../constants";

export default function data(state = {}, payload) {
    let { type, key, data } = payload;
    // console.log(payload)
    switch (type) {
      case C.UPDATE_CNC_DATAS:
    return { ...state, [key]:data };
      default:
        return state;
    }
  }