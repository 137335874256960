import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./UploadDemandTreeTable.scss";
import { TreeTable, TreeState } from 'cp-react-tree-table';
import { updateCNCInputs, updateAlertCNC, updatewholeCNCInputs ,updateCNCDatas,updateFlag} from '../../../../redux/actions';
import Buttons from '../../../../components/Button/Buttons'
import Dialogs from "../../../../components/Dialog/Dialog";
// const TREE_DATA = genData();
// const { TreeTable, TreeState } = cpReactTreeTable;

function UploadDemandTreeTable(props) {

    let{
        data,
        month_options,
        cncData,
        demandFlag,
        updateCNCInputs,
        updateAlertCNC,
        updateFlag,
    }=props;
    const TREE_DATA = cncData.demand_treeData;
    const treeTableRef = useRef(null);
    const[treeValue,setTreeValue]=useState(TREE_DATA)
    const [dialogData, setDialog] = useState([])
    useEffect(()=>{
      // if(cncData.demand_treeData.length>0){
        setTreeValue(TreeState.expandAll(TreeState.create(TREE_DATA)))
      // }
    },[TREE_DATA])

    
    useEffect(()=>{
      updateCNCInputs("ancestorData",treeValue)
    },[treeValue])
    const handleOnChange = (newValue) => {
        setTreeValue(newValue);
        updateCNCInputs("ancestorData",newValue)
      }     



      const renderIndexCell = (row) => {
        return (
          <div style={{ paddingLeft: (row.metadata.depth * 5) + 'px'}}
            className={row.metadata.hasChildren ? 'with-children' : 'without-children'}>
            
            {(row.metadata.hasChildren)
              ? (
                  <button className={`toggle-button ${!row.$state.isExpanded ? "collapsed" :""}`} onClick={row.toggleChildren}></button>
                )
              : ''
            }
            
            <span>{row.data!==undefined?row.data.name:""}</span>
          </div>
        );
      }
    
      /*RollUpValues is a recursive function which is used to rollup and sum the input values
      TreeState.expandAncestors(treeValue, rowModel)-->returns all the ancestor rowmodels for the given row
      */
      const RollUpValues=(row,depth,label,value)=>{
        let sum=0;
        const rowModel = treeValue.findRowModel(row);
        let ancestorData=TreeState.expandAncestors(treeValue, rowModel);
          ancestorData.data.filter((obj)=>{
            if(obj.data.parent===row.data.parent &&obj.data.grade!==row.data.grade&&obj.data.level===row.data.level){
              sum+=obj.data[label]
            }
          })
          sum+=value;
          if(depth===0)
          {return;}
          let parentRow;
          ancestorData.data.filter((obj)=>{
            if(obj.data.name ===row.data.parent){
              obj.data[label]=sum;
              parentRow=obj;
              return;
            }
          })
          RollUpValues(parentRow,depth-1,label,sum)
      }
      
      
      const renderEditableCell = (row,label) => {
        // console.log("label",label)
        let month_label=label.split(" ").join('');
         month_label=label+'_flag';
          //  if(cncData.mode==="view")
        //  label =label.split(' ').join('')
        let flag=true;
        if(row.data.level==="factory-grade"){
            flag=false;
        }
        let value;
        return (
          <>
              <input type="number" step="any" min="0" disabled={flag} 
              // value={(row.data[label]!==undefined&&row.data[label]!==null)?(flag? row.data[label].toFixed(2):row.data[label]):0}
              value={(row.data[label]!==undefined &&row.data[label]!=="")?row.data[label]:""}  
              onChange={(event) => {
                  value=parseFloat(event.target.value);
                  row.updateData({
                    ...row.data,
                    [label]: parseFloat(event.target.value),
                  })
                  
                  updateCNCInputs("demandDataEdited",true);            
                  updateFlag("unSavedChanges",true)                 
                  if(row.metadata.depth>0){
                    RollUpValues(row,row.metadata.depth,label,value);
                  }
                }}
                onClick={(event)=>{
                  if(row.data[label]===0){
                    value='';
                  row.updateData({
                    ...row.data,
                    [label]: '',
                  })
                  }
                }}
                style={{padding:'0px',textAlign:'right'}} 
                className='table-input'
                // title={(row.data[label]!==undefined&&row.data[label]!==null)?row.data[label]:''}
                />
           </>  
        );
      }

    const renderGradeCell = (row) => {
        return (
          row.data.level==="factory"?
          <span className="volume-cell">{row.data.grade}</span>:
          <input type="text" value={row.data.grade}  style={{padding:'0px'}} 
         onChange={(event)=>{
          row.updateData({
            ...row.data,
            "grade": event.target.value,
          })
         }} 
          className='table-input'  />
        );
      }

   

      const handleFactorySelection = (e) => {
        if(e!== null &&e.length>0){
        updateCNCInputs("factory_chosen", e);
        }
    
        else{
          updateCNCInputs("factory_chosen", []);
        }
      }

    //function to open dialog when multiple factories are chosen 
    const handleFactoryDialog = (item) => {
    
      setDialog({
        open: true,
        selection: true,
        close: dialogClose,
        createFunction: addMultipleRow,
        handleFactorySelection:handleFactorySelection,
        factoryOptions:cncData.factoryOptionsRowAddition,
        name: "",
        title:""
      });
      
    };

     //close dialog for factory selection
     const dialogClose = () => {
      setDialog({
        ...dialogData,
        open: false,
      });
    };  

    const addRow=()=>{

        updateFlag("unSavedChanges",true)
        let gradeLevel_Data=[];// filter grade level factory Demand Data
        let factoryLevel_Data=[];//filter factory level Data
        let newgradeData={}
        let rows=treeValue.data;
        let empty_flag=false;//to check for empty values in row
        //creating a new grade row structure
        
          let row={
            parent:"",
            level:"factory-grade",
            name:"",
            grade:"",
          }
          for(let i=0;i<cncData.demand_header.length;i++){
            row={
              ...row,
              [cncData.demand_header[i].label]:""
            }
          }
          newgradeData={
            data:row,
            children:[],
            height:37
          }

        for(let i=0;i<rows.length;i++){
          if(rows[i].data.level==="factory-grade"){
              if(rows[i].data.grade===""){
                empty_flag=true;
                break;
              }
              for(let j=0;j<cncData.demand_header.length;j++){
                if(rows[i].data[cncData.demand_header[j].label]===""||isNaN(rows[i].data[cncData.demand_header[j].label])){
                  empty_flag=true;
                  break;
                }
              }
              
                let data1={
                  data:rows[i].data,
                  children:[],
                  height:37
                  }
                gradeLevel_Data.push(data1)   
          }

          if(rows[i].data.level==="factory"){
            let data1={
              data:rows[i].data,
              children:[],
              height:37
            }
            factoryLevel_Data.push(data1);
            }
        }

        if(empty_flag){
          updateAlertCNC({
            open: true,
            message: "Cannot add new row when grade column/Demand values are empty",
            severity: "info",
            closeFlag:false
          });
        return;
        }

        if(cncData.factory_selection.length ===1 ||cncData.factoryOptionsRowAddition.length===1){
          //if no grade column is empty then create a new grade row for all factories  
          let newGradeDataWithParent=newgradeData 
          newGradeDataWithParent.data.parent=cncData.factory_selection[0].label
          gradeLevel_Data.push(newGradeDataWithParent)
          //push grade level Data into its corresponding factory level Data
            for(let i=0;i<factoryLevel_Data.length;i++){
              for(let j=0;j<gradeLevel_Data.length;j++){
              if(gradeLevel_Data[j].parent===factoryLevel_Data[i].name){
                factoryLevel_Data[i].children.push(gradeLevel_Data[j])
              }
            }
          }
       
        // setTreeValue(TreeState.expandAll(TreeState.create(factoryLevel_Data)));
        updateCNCInputs("demand_treeData",factoryLevel_Data)
        }

      else if(cncData.factoryOptionsRowAddition.length>1){
        updateCNCInputs("factory_chosen", []);
        handleFactoryDialog()
      }
    }  


    const addMultipleRow=(factoryselected)=>{
        updateFlag("unSavedChanges",true)
        dialogClose();
        let gradeLevel_Data=[];// filter grade level factory Demand Data
        let factoryLevel_Data=[];//filter factory level Data
        let rows=treeValue.data;
        
        
        
          

          //after a empty grade data is created push it inside only selected factories by the user

          let selectedFactories=[];
          for(let i=0;i<factoryselected.length;i++){
            selectedFactories.push(factoryselected[i].label)
          }
          
          for(let i=0;i<rows.length;i++){
            if(rows[i].data.level==="factory-grade"){
                
                  let data1={
                    data:rows[i].data,
                    children:[],
                    height:37
                    }
                  gradeLevel_Data.push(data1)   
            }
  
            if(rows[i].data.level==="factory"){
              let data1={
                data:rows[i].data,
                children:[],
                height:37
              }
              factoryLevel_Data.push(data1);
              }
          }

          //insert empty graderows in gradelevel

            for(let i=0;i<selectedFactories.length;i++){
              let newGradeDataWithParent={}
              //creating a new grade row structure
              let row={
                parent:selectedFactories[i],
                level:"factory-grade",
                name:"",
                grade:"",
              }
              for(let i=0;i<cncData.demand_header.length;i++){
                row={
                  ...row,
                  [cncData.demand_header[i].label]:""
                }
              }
              newGradeDataWithParent={
                data:row,
                children:[],
                height:37
              }
              gradeLevel_Data.push(newGradeDataWithParent)
            }

           

             //push grade level Data into its corresponding factory level Data
             for(let i=0;i<gradeLevel_Data.length;i++){
                for(let j=0;j<factoryLevel_Data.length;j++){
                  if(gradeLevel_Data[i].data.parent===factoryLevel_Data[j].data.name){
                    factoryLevel_Data[j].children.push(gradeLevel_Data[i])
                    break;
                  }
              }
            }
          // setTreeValue(TreeState.expandAll(TreeState.create(factoryLevel_Data)));
          updateCNCInputs("demand_treeData",factoryLevel_Data)


    }

    
    return (
      <>
      {
        // cncData.demand_treeData.length>0&&(
          <div 
          // -className={`${((cncData.max_decision_months >9||cncData.month_options.length>9) )?"treetable-recommendation-Demand":"treetable-recommendation"}`}
          className="treetable-recommendation"
          >
          
          <TreeTable
          value={treeValue}
          ref={treeTableRef}
          onChange={handleOnChange}>
        <TreeTable.Column basis="172px" grow="0"
          renderCell={renderIndexCell}
          renderHeaderCell={() => <span>Region</span>}
          // style={{margin:"5px"}}
          />
           <TreeTable.Column
                renderCell={renderGradeCell}
                renderHeaderCell={()=><span>Grade</span>}
                // style={{margin:"5px"}}
                />
         {
             month_options.map((item,id)=>(
                <TreeTable.Column
                renderCell={(row)=>renderEditableCell(row,item.label)}
                renderHeaderCell={()=><span>{item.label}</span>}
                // style={{margin:"5px"}}
                />   
                
             ))
         
        }

          {/* <TreeTable.Column
                renderCell={renderAddCell}
                renderHeaderCell={()=><span></span>}
                style={{width:"20%"}}
                /> */}
          </TreeTable>
                <Buttons
                      button={[
                        {
                          name: "Add Row",
                          callback: addRow,
                          flag: false,
                          style: "contained",
                        },
                      ]}
                    />
           </div>
           
        // ) 
      }
      {dialogData.open && <Dialogs props={dialogData}></Dialogs>}
      </>
    );

  
 
}




UploadDemandTreeTable.propTypes = {
    updateAlertCNC: PropTypes.func,
    cncData: PropTypes.object,
    updateFlag:PropTypes.func,
   
  };
  
  const mapStateToProps = (state) => ({
    // data:state.dssStateData.data
    cncData: state.cncinputs,
    flags:state.flags
  });
  
  const mapDispatchToProps = {
    updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
  updateFlag
  };
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UploadDemandTreeTable)
  );