import * as React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Buttons from "../../../components/Button/Buttons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Select from "react-select";
// import "./table.scss"
import TablePaginate from "../../../components/PaginationTable/TablePagination";
import { updateAlertCNC } from "../../../redux/actions";
import Tablestyles from "./components/ExecutionTable.module.css";
import { NumericFormat } from "react-number-format";
import { useState } from "react";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#e5e5e5",
    color: "#212529",
  },
  body: {
    fontSize: 12,
  },
  root: {
    padding: "5px",
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  container: {},
});

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    maxHeight: "80%",
    fontSize: 12,
    fontFamily: "Hind Siliguri !important",
    zIndex: 9999,
  }),
};

function SimpleDialog(props) {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { onClose, open, filteredData, onSave } = props;

  //function to set page for changing table row
  const pageChange = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let page_props = {
    total: props.filteredData ? props.filteredData.length : 0,
    page: page,
    changePage: pageChange,
    rowsPerPage: rowsPerPage,
    handleChangeRowsPerPage: handleChangeRowsPerPage,
    show_pageOptions: true,
  };

  const handleClose = () => {
    onClose();
  };
  const onSaving = () => {
    props.updateTableExecutedVolume();
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"xl"}>
      <DialogTitle>
        Totally {" '" + props.filteredData.length + "' "} rows have been
        modified
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <>
            <TableContainer className={Tablestyles.execution_tb}>
              <Table stickyHeader className={classes.table}>
                <TableHead className="table-heading">
                  <TableRow>
                    {props.header.map((item, idx) => (
                      <StyledTableCell>{item}</StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="table_body_scenario">
                  {props.filteredData &&
                    props.filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, id) => (
                        <TableRow
                          key={id}
                          // title={row.model_id}
                        >
                          <StyledTableCell style={{ width: "20%" }}>
                            <Select
                              menuPortalTarget={document.body}
                              value={props.factory_options.filter((obj) => {
                                return obj.value === row.geography_id;
                              })}
                              styles={customStyles}
                              isDisabled={true}
                              menuPlacement="auto"
                              menuPosition="fixed"
                              placeholder={"Select Factory"}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Select
                              options={
                                props.type_options ? props.type_options : []
                              }
                              menuPortalTarget={document.body}
                              value={props.type_options.filter((obj) => {
                                return obj.value === row.type;
                              })}
                              styles={customStyles}
                              isDisabled={true}
                              menuPlacement="auto"
                              menuPosition="fixed"
                              placeholder={"Enter type"}
                            />
                          </StyledTableCell>

                          <StyledTableCell>
                            <input
                              className="form-control"
                              type="month"
                              data-clear-btn="true"
                              id="eta_month"
                              name="eta_month"
                              disabled={true}
                              // min={row.decision_month}
                              value={props.formattedDate(row.eta_month)}
                            ></input>
                          </StyledTableCell>

                          <StyledTableCell>
                            <input
                              className="form-control"
                              type="month"
                              data-clear-btn="true"
                              id="contract_month"
                              name="contract_month"
                              disabled={true}
                              // min={row.decision_month}
                              value={props.formattedDate(row.contract_month)}
                            ></input>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Select
                              options={
                                props.status_options ? props.status_options : []
                              }
                              menuPortalTarget={document.body}
                              value={props.status_options.filter((obj) => {
                                return obj.value === row.status;
                              })}
                              styles={customStyles}
                              isDisabled={true}
                              menuPlacement="auto"
                              menuPosition="fixed"
                              placeholder={"Enter status"}
                            />
                          </StyledTableCell>

                          {props.isDairy && (
                            <StyledTableCell style={{ width: "10%" }}>
                              {/* <input
                      type="number"
                      placeholder="Enter a value"
                      className="form-control"
                      id={row.id}
                      value={row.executed_volume}
                      min={0}
                      disabled={!row.edit_flag}
                      style={{ textAlign: "right"}}
                      name={'executed_volume'}
                      onChange={(event) => {props.handleInputchange(event,row.id)}}
                    ></input> */}
                              <NumericFormat
                                displayType={"number"}
                                value={row.executed_volume}
                                disabled={true}
                                valueIsNumericString={true}
                                thousandSeparator=","
                                thousandsGroupStyle="thousand"
                                prefix={""}
                                name={"executed_volume"}
                                id={row.id}
                                style={{ textAlign: "right" }}
                                className="form-control"
                              />
                            </StyledTableCell>
                          )}
                          <StyledTableCell style={{ width: "9%" }}>
                            {/* <input
                        type="number"
                        placeholder="Enter a value"
                        className="form-control"
                        id={row.id}
                        value={row.executed_price}
                        min={0}
                        max={100}
                        disabled={!row.edit_flag}
                        style={{ textAlign: "right" }}
                        name={'executed_price'}
                        onChange={(event) => {props.handleInputchange(event,row.id)}}
                      ></input> */}
                            <NumericFormat
                              displayType={"number"}
                              value={row.executed_price}
                              disabled={true}
                              valueIsNumericString={true}
                              thousandSeparator=","
                              thousandsGroupStyle="thousand"
                              prefix={""}
                              min={0}
                              name={"executed_price"}
                              id={row.id}
                              style={{ textAlign: "right" }}
                              className="form-control"
                            />
                          </StyledTableCell>
                          {!true && (
                            <StyledTableCell style={{ width: "11%" }}>
                              {/* <input
                            type="number"
                            placeholder="Enter a value"
                            className="form-control"
                            id={row.id}
                            value={row.executed_price_fcy}
                            min={0}
                            max={100}
                            disabled={!row.edit_flag}
                            style={{ textAlign: "right" }}
                            name={'executed_price_fcy'}
                            onChange={(event) => {props.handleInputchange(event,row.id)}}
                          ></input> */}

                              <NumericFormat
                                displayType={"number"}
                                value={row.executed_price_fcy}
                                disabled={!row.edit_flag}
                                valueIsNumericString={true}
                                thousandSeparator=","
                                thousandsGroupStyle="thousand"
                                prefix={""}
                                name={"executed_price_fcy"}
                                id={row.id}
                                style={{ textAlign: "right" }}
                                className="form-control"
                              />
                            </StyledTableCell>
                          )}
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginate data={page_props} />
          </>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Buttons
          button={[
            {
              name: "Cancel",
              callback: handleClose,
              flag: false,
              style: "contained",
            },
          ]}
        />
        <Buttons
          button={[
            {
              name: "Confirm",
              callback: onSaving,
              flag: false,
              style: "contained",
            },
          ]}
        />
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function OnsaveDialog(props) {
  let { open, setOpen, updateTableExecutedVolume } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        setOpen={setOpen}
        filteredData={props.filteredData}
        formattedDate={props.formattedDate}
        type_options={props.type_options}
        status_options={props.status_options}
        factory_options={props.factory_options}
        header={props.header}
        isDairy={props.isDairy}
        updateTableExecutedVolume={updateTableExecutedVolume}
      />
    </div>
  );
}
