

//Import Libraries
import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from 'react';
import {config} from '../../../../../config/config';
import { withRouter } from "react-router";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import { updateCNCInputs, updateAlertCNC, updatewholeCNCInputs ,updateCNCDatas} from '../../../../../redux/actions';
// import forecast from "../../../assets/images/forecast.png";
//import styles
import "../../../AddCommodity/addcommodity.scss";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';


let api = config.api.api_url;
function CNCDashboard(props){

    let { cncData,allData, updateCNCInputs,  updateAlertCNC } = props;
    const [currentSelection, activeselection] = useState("status"); 
    const setselection = (value) => {
        activeselection(value);
      };

     //function to handle checkbox changes
    const [state, setState] = React.useState(false)  

    //Handle checkbox toggle
  const handleChange = (event) => {
    setState(event.target.checked)

    if(event.target.checked){
      updateAlertCNC({open:true,message:config.messages.run_selected_for_final_hedging,severity:"info"})
    }

    else{
      updateAlertCNC({open:true,message:config.messages.run_unselected_for_final_hedging,severity:"info"})
    }
    if(event.target.name ==="use_for_final_hedging"){
      updateCNCInputs("use_for_final_hedging",event.target.checked);      let run=allData.activeCommodityRun;
      run.use_for_final_hedging=event.target.checked;
      let input={
        run:run,
        commodity_selection_value:cncData.commodity_selection_value
      }
      let reqOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(input),
      };
      fetch(api+"/view_runs",reqOptions)
      .then((response)=>response.json())
      .then((response)=>{
        if (response.status.statusCode === "cnc-200") {
          reqOptions.body = JSON.stringify(response.data)
          fetch(api+"/db/hedging_update",reqOptions)
          .then((response) => response.json())
          .then((response) => {}) 
          .catch((error) => {
            updateAlertCNC({
              open: true,
              message: config.messages.unexpected_error,
              severity: "info",
              closeFlag:false
            });
          });  
        
        }

        else{
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
        }
        
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });

    }
  }

  //This reducer function replaces %s with the values in report_parameters and return the report url
  var _r= function(p,c){return p.replace(/%s/,c)};
  const getReportURL=()=>{
    let url = ''
    let report_params=[]; //used to store the values from report_parameters
    cncData.commodityOptions.filter((obj)=>{
      if(obj.value ===cncData.commodity_selection_value &&obj.isIndexVisible){
        url = obj.report_url;
        report_params=obj.report_params.split(',');
      }
    })
    let params=[];
    for(let i=0;i<report_params.length;i++){
      params.push(eval(report_params[i]))
    }
    return params.reduce(_r,url);
  }

  const getReportURLExecution=()=>{
    let url = ''
    let report_params=[]; //used to store the values from report_parameters
    cncData.commodityOptions.filter((obj)=>{
      if(obj.value ===cncData.commodity_selection_value &&obj.isIndexVisible){
        url = obj.report_url_execution;
        report_params=obj.report_params_execution.split(',');
      }
    })
    let params=[];
    for(let i=0;i<report_params.length;i++){
      params.push(eval(report_params[i]))
    }
    return params.reduce(_r,url);
  }
    return (
      <>
        <Card variant="outlined" style={{ padding: 10, marginTop: 10 }}>
          {/* <div clasName="model-view"> */}
          <div className="mb-tabs-container">
            <>
              <div className="sel">
                <div
                  className={`mb-tab-selection-div ${
                    currentSelection === "status" ? "active" : ""
                  }`}
                  onClick={() => {
                    setselection("status");
                  }}
                >
                  <p
                    className={`mb-tab-selection-title ${
                      currentSelection === "status" ? "mb-active" : ""
                    }`}
                  >
                    Status
                  </p>
                </div>

                {cncData.status==="Completed"&&<div
                  className={`mb-tab-selection-div ${
                    currentSelection === "Recommendation Dashboard" ? "mb-active" : ""
                  }`}
                  onClick={() => {
                    setselection("Recommendation Dashboard");
                  }}
                >
                  <p
                    className={`mb-tab-selection-title ${
                      currentSelection === "Recommendation Dashboard" ? "mb-active" : ""
                    }`}
                  >
                    {
                      <a
                        className={`mb-tab-selection-title ${
                          currentSelection === "Recommendation Dashboard" ? "mb-active" : ""
                        }`}
                        target="_blank"
                        rel="noreferrer"
                        //DEV - URL
                        href={getReportURL()}
                      >
                       Recommendation Dashboard
                      </a>
                    }
                  </p>
                </div>

                }

              {cncData.status==="Completed"&& cncData.show_execution_dashboard&&<div
                  className={`mb-tab-selection-div ${
                    currentSelection === "Execution Dashboard" ? "mb-active" : ""
                  }`}
                  style={{marginLeft: "-35px"}}
                  onClick={() => {
                    setselection("Execution Dashboard");
                  }}
                >
                  <p
                    className={`mb-tab-selection-title ${
                      currentSelection === "Execution Dashboard" ? "mb-active" : ""
                    }`}
                  >
                    {
                      <a
                        className={`mb-tab-selection-title ${
                          currentSelection === "Execution Dashboard" ? "mb-active" : ""
                        }`}
                        target="_blank"
                        rel="noreferrer"
                        //DEV - URL
                        href={getReportURLExecution()}
                      >
                        Execution Dashboard
                      </a>
                    }
                  </p>
                </div>

                }

                {cncData.status==="Completed"&&
                  <div className="mb-tab-selection-div">
                            <FormControl component="fieldset">
                              <FormGroup aria-label="position" row>
                                <FormControlLabel
                                  value="end"
                                  control={
                                    <Checkbox
                                      size="small"
                                      checked={cncData.use_for_final_hedging}
                                      onChange={handleChange}
                                      name="use_for_final_hedging"
                                    />
                                  }
                                  label="Select run for Final Hedging"
                                  labelPlacement="end"
                                />
                              </FormGroup>
                            </FormControl>
                          </div>
                }         
              </div>
            </>
          </div>

          <div
            style={{
              width: "100x",
              borderColor: "1px solid black",
              overflow: "hidden",
            }}
          >
            {currentSelection !== "status" ? (
              <div
                className="row"
                style={{ paddingTop: 10, height: "40px" }}
              ></div>
            ) : (
              <div className="modelrun-failed ">
                 {cncData.message !== "" &&
                    cncData.message !== undefined &&
                    cncData.message !== null ? (
                      <p>{cncData.message}</p>
                    ) : (
                      <p>{cncData.status}</p>
                    )}
                {/* <p>{allData.activeCommodityRun.message}</p> */}
              </div>
            )}
          </div>
          {/* </div> */}
        </Card>
      </>
    );

}


CNCDashboard.propTypes = {
    user: PropTypes.object,
    cncData: PropTypes.object,
    updateCNCInputs: PropTypes.func,
    updatewholeCNCInputs: PropTypes.func,
    updateAlertCNC: PropTypes.func,
    updateCNCDatas:PropTypes.func,
  };
  
  const mapStateToProps = (state) => ({
    user: state.user,
    cncData: state.cncinputs,
    allData:state.data
  });
  
  const mapDispatchToProps = {
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateCNCDatas,
  };
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CNCDashboard));



