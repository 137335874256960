
import React from 'react';
import { useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCNCInputs, updateAlertCNC, updatewholeCNCInputs ,updateCNCDatas} from '../../redux/actions';
import { withRouter } from "react-router";

 function Search(props){
  let {
    match,
    user,
    // userData,
    cncData,
    allData,
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    mode,
    updateCNCDatas,
    setFilteredData,
    setPage,
    searchData
  } = props;
  const [val, setVal] = useState("");
  const [textClearVisible, settextClearVisible] = useState(false);
  // search and filter x rows based on keyword
  function SearchVal(e, flag = false) {
    let value = flag ? e : e.target.value;
    updateCNCInputs("searchValue",value);
    setVal(value);
    if (value.length > 0) {
      settextClearVisible(true);
    }
    if (value == "" || value == "" || value.length == 0) {
      settextClearVisible(false);
    }
    let result = [];
    result = searchData.filter((data) => {
      return (
        data.user_commodity_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        (data.commodity_created_dt!==undefined&& data.commodity_created_dt.includes(value)) ||
        data.description.toString().toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredData(result);
    !flag && setPage(0);
  }

  //function to handle the text clear in search bar
  const handleClear = () => {
    setVal("");
    settextClearVisible(false);
    setFilteredData(searchData);
    setPage(0);
  };
  return (
    <>
      <div className="dss-filter-new">
        <div className="input-search">
          <i className="material-icons-outlined search-icon ">search</i>
          <input
            type="text"
            name="search"
            autoComplete="off"
            value={val}
            className="input-label"
            placeholder="Search"
            onChange={(e) => {
              SearchVal(e);
            }}
          ></input>
          {textClearVisible && (
            <i
              class="material-icons-outlined search-icon"
              onClick={handleClear}
            >
              clear
            </i>
          )}
        </div>
      </div>
    </>
  );
}


Search.propTypes = {
  user: PropTypes.object,
  // userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas:PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  // userData: state.user,
  cncData: state.cncinputs,
  allData:state.data
});

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));