/*
Created on November 2021
Confidential and Proprietary of Reckit
Project: Model Builder
Screen name : CNCRun
Screen Description : To view model details 
@author: tailwyndz
*/

//Import Component
import { useState,useEffect } from "react";
import {config} from "../../../config/config"
import AddCommodity from "../AddCommodity/AddCommodity";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateCNCInputs, updateAlertCNC, updatewholeCNCInputs,updateUserInfo } from '../../../redux/actions';
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";

function CNCCommodity(props){
  let {user,match,updateUserInfo}=props
  const [SidebarItems,setSidebarItems]=useState(config.sidenav);
  const [defaultRoute,setDefaultRoute]=useState('');
  const [loading,setLoading]=useState(false);
  useEffect(()=>{
    if(user.role==="admin"){
      setSidebarItems(config.sidenav_admin)
      setDefaultRoute(config.defaultRoute.demand_planner)
      updateUserInfo({
        ...user,
        sidebarItems:config.sidenav_admin,
        demand_planner:true,
        demand_planner_info:user.demand_planner_info
     })
    }
    else if(user.groups){
      if(user.groups.includes('demand_planner')){
        setSidebarItems(config.sidenav_demandManager)
        setDefaultRoute(config.defaultRoute.demand_planner)
            updateUserInfo({
              ...user,
              sidebarItems:config.sidenav_demandManager,
              demand_planner:true,
              demand_planner_info:user.demand_planner_info
           })  
            setLoading(false)
      }
      else if(user.groups.includes('commodity_manager')){
        setSidebarItems(config.sidenav_commodityManager) 
        setDefaultRoute(config.defaultRoute.commodity_manager)
        updateUserInfo({
          ...user,
          sidebarItems:config.sidenav_commodityManager,
          commodity_manager:true,
          demand_planner:false,
       })  
        setLoading(false)
      }  
      else{
        setSidebarItems(config.sidenav)
        setDefaultRoute(config.defaultRoute.CNC)
        updateUserInfo({
          ...user,
          sidebarItems:config.sidenav,
          demand_planner:false,
          commodity_manager:false,
       })  
        setLoading(false)
      }
    // })
  // }
    }
  },[user.groups])
    return(
      <LayoutTopSideBottom>
        <Breadcrumbs match={match}/>
        <AddCommodity mode={props.cncData.mode}/>
      </LayoutTopSideBottom>    
    )
}

CNCCommodity.propTypes={
  user: PropTypes.object,
  match:PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func
    
}

const mapStateToProps = (state) => ({
  user: state.user,
  cncData: state.cncinputs,
});

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateUserInfo
};
export default connect(mapStateToProps,mapDispatchToProps)(CNCCommodity);