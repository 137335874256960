// Import required libraries
import React from "react";
import { Link } from "react-router-dom";

// Import data/utils
import logo from "../../../assets/images/rb-logo.png";

function RbLogo(props) {
  let {defaultRoute} =props;
  const headerText = "CNC";
  const headerRedirectUrl = "/home/myCommodityHedging";

  return (
    <div className="top-left-nav">
      <Link to={defaultRoute} className="rb-logo-container">
        <img className="rb-logo" src={logo} alt="RB Logo" />
      </Link>
      <Link to={defaultRoute} className="rb-logo-text">
        {headerText}
      </Link>
    </div>
  );
}

export default RbLogo;
