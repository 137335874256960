// Import required libraries
import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";

// Import custom components

// Import styles
import "./UserInfo.scss";

// Import config
import { config } from "../../config/config";

function UserInfo(props) {
  let { user } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const logout = () => {
    window.location.href = config.api.signOutUrl;
    window.localStorage.removeItem(config.localStorageReduxStoreName);
  };

  return (
    <div className="user-info-container">
      <i
        className="material-icons-outlined header-menu-icon"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {" "}
        person{" "}
      </i>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: { width: "15%" },
        }}
      >
        <div className="user-info-name">
          <p className="user-info-text">{user.name}</p>
        </div>
        <div className="user-info-sign-out">
          <p
            className="user-info-sign-out-text"
            onClick={() => {
              logout();
            }}
          >
            Sign Out
          </p>
        </div>
      </Popover>
    </div>
  );
}

export default UserInfo;
