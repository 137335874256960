/*
Created on March 2022
Confidential and Proprietary of Reckitt
Project: CNC
Screen name : AddCommodity
Screen Description : 
@author: Tailwyndz
*/

//Import Libraries
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from "react";
import { config } from "../../../../config/config";
import { withRouter } from "react-router";
import { Grid } from "@material-ui/core";
import { components } from "react-select";
// import Button from '@material-ui/core/Button';
import { default as ReactSelect } from "react-select";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
  updateCNCDatas,
  toggleSidenav,
} from "../../../../redux/actions";
//import styles
import "../../AddCommodity/addcommodity.scss";

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    maxHeight: "90%",
    fontSize: 12,
    fontFamily: "Hind Siliguri !important",
    zIndex: 9999,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "auto",
    maxHeight: "7vh",
  }),

  option: (provided, state) => ({
    ...provided,
    fontSize: 12,
    fontFamily: "Hind Siliguri !important",
  }),
};
function RecommendMetaSelection(props) {
  let {
    user,
    cncData,
    updateCNCInputs,
    updateAlertCNC,
    toggleSidenav,
    setPage,
    convertToUTC,
  } = props;

  const [expand, setExpand] = useState(false);
  let api = config.api.api_url;
  //When Global is chosen as region country and factories should be hidden
  const [hideOnGlobalFlag, setHideOnGlobalFlag] = useState(false);
  const [inputDisableFlag, setInputDisableFlag] = useState(false);

  //function to populate data for decision Month dropdown &Headers for Override and Demand tables
  /*
    if getNext_N_MonthNamesWithYear() is called only with 1 parameter ie)maxmonth it generates dropdown
      values for Decision month (month options)

    if  getNext_N_MonthNamesWithYear() is called only with 2 parameter ie)Max month and date
      it generates values for override price forecast table and demand tree header
    */
  function getNext_N_MonthNamesWithYear(maxMonth, date = "") {
    var names = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var now = new Date();
    var month;
    var year;
    if (arguments.length === 1) {
      // month=now.getMonth()-4;
      let before_three_month = new Date(now.setMonth(now.getMonth() - 3));
      month = before_three_month.getMonth();
      year = before_three_month.getFullYear();
      maxMonth = 13;
    } else {
      let month_in_text = date.split(" ")[0];
      month = names.indexOf(month_in_text) + 1;
      year = date.split(" ")[1];
    }

    let month_options = [];
    let override_forecast_header = [];
    let demand_header = [];
    let execution_demand_month_options = [];
    for (var i = 0; i < maxMonth; ++i) {
      if (arguments.length === 1) {
        month_options[i] = {
          label: names[month] + " " + year,
          value: names[month] + " " + year,
        };
      } else if (arguments.length > 1) {
        override_forecast_header[i] = {
          label: names[month] + " " + year,
          value: "",
        };

        demand_header[i] = {
          label: names[month] + " " + year,
          value: "",
        };
        execution_demand_month_options[i] = {
          label: names[month] + " " + year,
          value: names[month] + " " + year,
        };
      }

      if (++month === 12) {
        month = 0;
        ++year;
      }
    }
    if (arguments.length === 1) {
      updateCNCInputs("month_options", month_options);
    }
    if (arguments.length > 1) {
      updateCNCInputs("override_forecast_header", override_forecast_header);
      updateCNCInputs("demand_header", demand_header);
      updateCNCInputs(
        "execution_demand_month_options",
        execution_demand_month_options
      );
    }
  }
  //handles the change for Commodity selection
  const commodityChange = (e) => {
    updateCNCInputs("month_selection_value", "");
    updateCNCInputs("month_options", []);
    updateCNCInputs("indexOptions", []);
    updateCNCInputs("countryOptions", []);
    updateCNCInputs("factoryOptions", []);
    updateCNCInputs("commodity_selection", e.label);
    updateCNCInputs("commodity_selection_value", e.value);
    updateCNCInputs("index_commodity_id", "");
    updateCNCInputs("region_selection_value", []);
    // getRegions();
    getNext_N_MonthNamesWithYear(e.decision_months);
    updateCNCInputs("isIndexVisible", e.isIndexVisible);
    setPage(0);
    if (e.isIndexVisible) {
      // getIndexOption(e.value);
      let indexOptions = [];
      for (let i = 0; i < e.indices.length; i++) {
        indexOptions[i] = {
          label: e.indices[i].index_name,
          value: e.indices[i].index_commodity_id,
          source_weights: e.indices[i].source_weight,
        };
      }
      if (indexOptions.length === 1) {
        updateCNCInputs("index_commodity_id", indexOptions[0].value);
        updateCNCInputs("index_source_weights", indexOptions[0].source_weights);
      }
      updateCNCInputs("indexOptions", indexOptions);
    }
    updateCNCInputs("isCountryVisible", e.isCountryVisible);
    updateCNCInputs("isDemandEditable", e.isDemandEditable);
    updateCNCInputs("scenarioFlag", e.scenarioFlag);
    updateCNCInputs("isExpandable", e.isExpandable);
    updateCNCInputs("report_url", e.report_url);
    updateCNCInputs("demand_difference_url", e.demand_difference_url);
    updateCNCInputs("isDairy", e.isDairy);
    updateCNCInputs("eta_futures_month_lag", e.eta_futures_month_lag);
    updateCNCInputs("execution_type", e.execution_type ? e.execution_type : []);
    updateCNCInputs(
      "execution_status",
      e.execution_status ? e.execution_status : []
    );

    let regionOptions = [];
    let region = e.region;
    // getTableFactories(e.region)//to get Factories for corresponding regions to show in Table
    for (let i = 0; i < region.length; i++) {
      regionOptions[i] = {
        value: region[i],
        label: region[i],
        geography_level: "region",
      };
    }
    if (regionOptions.length === 1) {
      updateCNCInputs("region_selection_value", [regionOptions[0]]);
    }
    updateCNCInputs("regionOptions", regionOptions);
    //set the factories to be shown in execution table
    let factoryOptions = [];
    let factory_id = e.factory_id;
    for (let i = 0; i < factory_id.length; i++) {
      factoryOptions[i] = {
        value: factory_id[i],
        label: factory_id[i],
        geography_level: "factory",
      };
    }

    updateCNCInputs("factoryOptionsTable", factoryOptions);
  };

  useEffect(() => {
    // getCommodity();
  }, []);

  useEffect(() => {
    // getIndexOption(cncData.commodity_selection_value)
    if (
      cncData.mode === "edit" ||
      cncData.mode === "view" ||
      cncData.mode === "view-shared"
    ) {
      let indexOptions = [];
      cncData.commodityOptions.filter((obj) => {
        if (
          obj.value === cncData.commodity_selection_value &&
          obj.isIndexVisible
        ) {
          for (let i = 0; i < obj.indices.length; i++) {
            indexOptions[i] = {
              label: obj.indices[i].index_name,
              value: obj.indices[i].index_commodity_id,
              source_weights: obj.indices[i].source_weight,
            };
          }
          updateCNCInputs("indexOptions", indexOptions);
          updateCNCInputs("isIndexVisible", obj.isIndexVisible);
          updateCNCInputs("isCountryVisible", obj.isCountryVisible);
          updateCNCInputs("isDemandEditable", obj.isDemandEditable);
          updateCNCInputs("scenarioFlag", obj.scenarioFlag);
          updateCNCInputs("report_url", obj.report_url);
        }
      });
    }
  }, [cncData.commodity_selection_value, cncData.commodityOptions]);

  // useEffect(()=>{
  //   if(cncData.mode=="edit"||cncData.mode=="view"){
  //     getNext_N_MonthNamesWithYear(cncData.month_options.length,cncData.month_selection_value);
  //   }
  // },[cncData.month_options,cncData.scenario_flag_checkbox])
  //handles the change for Commodity selection
  const decisionMonthChange = (e) => {
    // getRegions();
    updateCNCInputs("month_selection", e.label);
    updateCNCInputs("month_selection_value", e.value);
    updateCNCInputs("decision_month", formattedDate(e.value));
    // updateCNCInputs("index_commodity_id","");
    if (cncData.indexOptions.length === 1) {
      updateCNCInputs("index_commodity_id", cncData.indexOptions[0].value);
      updateCNCInputs(
        "index_source_weights",
        cncData.indexOptions[0].source_weights
      );
    }
    let month;
    cncData.commodityOptions.filter((obj) => {
      if (obj.label === cncData.commodity_selection) {
        month = obj.decision_months;
      }
    });
    updateCNCInputs("max_decision_months", month);
    getNext_N_MonthNamesWithYear(month, e.value);
  };

  //Function to get Regions
  const getRegions = () => {
    let regionOptions = [];

    //fetch call
    let input = {
      level: "region",
      parent_id: "",
    };
    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };

    fetch(api + "/geography", reqOptions)
      .then((respone) => respone.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          for (let i = 0; i < response.data.length; i++) {
            regionOptions[i] = {
              value: response.data[i].geography_id,
              label: response.data[i].geography,
              geography_level: response.data[i].geography_level,
              parent_id: response.data[i].parent_id,
            };
          }
          updateCNCInputs("regionOptions", regionOptions);
          // getTableFactories(["Global"])
        } else {
          updateCNCInputs("regionOptions", []);
        }
      })
      .catch((error) => {
        updateCNCInputs("regionOptions", []);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag: false,
        });
      });
  };

  //Function to getFactories
  const getFactories = (countries) => {
    let factoryOptions = [];

    let input = {
      level: "factory",
      parent_id: countries,
    };

    //fetch call
    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };

    fetch(api + "/geography", reqOptions)
      .then((respone) => respone.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          for (let i = 0; i < response.data.length; i++) {
            factoryOptions[i] = {
              value: response.data[i].geography_id,
              label: response.data[i].geography,
              geography_level: response.data[i].geography_level,
              parent_id: response.data[i].parent_id,
            };
          }
          updateCNCInputs("factoryOptions", factoryOptions);
        } else {
          updateCNCInputs("factoryOptions", []);
        }
      })
      .catch((error) => {
        updateCNCInputs("factoryOptions", []);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag: false,
        });
      });
  };

  // function for multiselect inputs
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            id="productDimension"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            // style={{display:"flex",flexWrap:"wrap"}}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleRegionChange = (e) => {
    let regionList = [];
    updateCNCInputs("region_selection_value", []);
    updateCNCInputs("factory_selection", []);
    // updateCNCInputs("factoryOptions",[]);
    // updateCNCInputs("countryOptions", []);
    updateCNCInputs("country_selection", "");
    updateCNCInputs("country_selection_value", []);
    // updateCNCInputs("region_selection_value", e);
    if (e !== null && e.length > 0) {
      setHideOnGlobalFlag(true);
      for (let i = 0; i < e.length; i++) {
        if (e[i].value !== "Global") regionList.push(e[i].value);
      }

      let option = e;

      if (option.length > 1) {
        option = option.filter((item) => item.label !== "Global");
      }
      updateCNCInputs("region_selection", e[0].label);
      updateCNCInputs("region_selection_value", option);
      // getCountries(regionList)
      // setTimeout(()=>{
      //   getTableFactories(regionList)
      // },2000)
    } else {
      setHideOnGlobalFlag(false);
      updateCNCInputs("region_selection", "");
      updateCNCInputs("region_selection_value", []);
      updateCNCInputs("factory_selection", []);
      updateCNCInputs("factoryOptions", []);
      updateCNCInputs("countryOptions", []);
      updateCNCInputs("country_selection", "");
      updateCNCInputs("country_selection_value", []);
      // getCountries(["Global"])
      // setTimeout(()=>{
      //   getTableFactories(["Global"])
      // },2000)
    }
  };

  const handleIndexChange = (e) => {
    updateCNCInputs("index_commodity_id", e.value);
    updateCNCInputs("index_source_weights", e.source_weights);
  };
  const handleCountryChange = (e) => {
    let countryList = [];
    if (e !== null && e.length > 0) {
      for (let i = 0; i < e.length; i++) {
        countryList.push(e[i].value);
      }
      getFactories(countryList);
      updateCNCInputs("country_selection_value", e);
    } else {
      updateCNCInputs("country_selection", "");
      updateCNCInputs("country_selection_value", []);
      updateCNCInputs("factoryOptions", []);
      updateCNCInputs("factory_selection", []);
    }
  };

  function formattedDate(date, previous = false) {
    let d = new Date(date);
    d = convertToUTC(d);
    let month = previous ? String(d.getMonth()) : String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (
      cncData.index_commodity_id !== "" &&
      cncData.month_selection_value !== "" &&
      cncData.region_selection_value !== undefined &&
      cncData.region_selection_value.length > 0
    ) {
      let geography_id = [];
      let demand_input;
      let factory_selection_list = [];
      let country_selection_list = [];
      let region_selection_list = [];
      /*  Generating the User Commodity name from selection  */
      let user_commodity_name =
        cncData.commodity_selection + "-" + cncData.month_selection_value;
      //If the Scenario flag checkbox is enabled  then Commodity name must prefix with 'Scenario'
      if (cncData.scenario_flag_checkbox === true) {
        user_commodity_name = "Scenario " + user_commodity_name;
      }
      if (cncData.region_selection_value.length > 0) {
        for (let i = 0; i < cncData.region_selection_value.length; i++) {
          region_selection_list.push(
            cncData.region_selection_value[i]["value"]
          );
          user_commodity_name =
            user_commodity_name +
            "-" +
            cncData.region_selection_value[i]["value"];
        }
      }
      if (cncData.country_selection_value.length > 0) {
        for (let i = 0; i < cncData.country_selection_value.length; i++) {
          country_selection_list.push(
            cncData.country_selection_value[i]["value"]
          );
          user_commodity_name =
            user_commodity_name +
            "-" +
            cncData.country_selection_value[i]["value"];
        }
      }

      if (cncData.factory_selection.length > 0) {
        for (let i = 0; i < cncData.factory_selection.length; i++) {
          factory_selection_list.push(cncData.factory_selection[i]["value"]);
          user_commodity_name =
            user_commodity_name + "-" + cncData.factory_selection[i]["value"];
        }
      }

      /*  Generating the User Commodity name from selection ends  */

      /*Creating the reqoptions for fetching the Execution data */

      if (cncData.factory_selection.length > 0) {
        for (let i = 0; i < cncData.factory_selection.length; i++) {
          geography_id.push(cncData.factory_selection[i].value);
        }
        demand_input = {
          isGlobal: false,
          isFactory: true,
          level: "factory",
          height: config.demand_treeData_height,
          user_commodity_name: user_commodity_name,
          input: {
            geography_id: geography_id.join(","),
            index_commodity_id: cncData.index_commodity_id,
            decision_month: formattedDate(cncData.month_selection_value),
            // run_id: "",
          },
          commodity_selection_value: cncData.commodity_selection_value,
        };
      } else if (cncData.country_selection_value.length > 0) {
        for (let i = 0; i < cncData.country_selection_value.length; i++) {
          geography_id.push(cncData.country_selection_value[i].value);
        }
        demand_input = {
          isGlobal: false,
          isFactory: false,
          level: "country",
          height: 37,
          user_commodity_name: user_commodity_name,
          input: {
            country: geography_id.join(","),
            index_commodity_id: cncData.index_commodity_id,
            decision_month: formattedDate(cncData.month_selection_value),
            // run_id: ""
          },
          commodity_selection_value: cncData.commodity_selection_value,
        };
      } else {
        for (let i = 0; i < cncData.region_selection_value.length; i++) {
          geography_id.push(cncData.region_selection_value[i].value);
        }
        if (cncData.region_selection_value[0].value === "Global") {
          demand_input = {
            isGlobal: true,
            isFactory: false,
            level: "region",
            height: 37,
            user_commodity_name: user_commodity_name,
            input: {
              region: "",
              index_commodity_id: cncData.index_commodity_id,
              decision_month: formattedDate(cncData.month_selection_value),
              // run_id: "",
            },
            commodity_selection_value: cncData.commodity_selection_value,
          };
        } else {
          demand_input = {
            isGlobal: false,
            isFactory: false,
            level: "region",
            height: 37,
            user_commodity_name: user_commodity_name,
            input: {
              region: geography_id.join(","),
              index_commodity_id: cncData.index_commodity_id,
              decision_month: formattedDate(cncData.month_selection_value),
              // run_id: "",
            },
            commodity_selection_value: cncData.commodity_selection_value,
          };
        }
      }
      //fetch call
      let reqOptionsDemand = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(demand_input),
      };
      updateCNCInputs("loading", true);
      setInputDisableFlag(true);
      fetch(api + "/db/recommendation", reqOptionsDemand)
        .then((res) => res.json())
        .then((res) => {
          setPage(0);
          if (user.screen.isSidenavBarExpanded) {
            toggleSidenav();
          }
          if (res.status.statusCode === "cnc-200") {
            //if commodity is diary then load the table structure
            setEtaMonth(res.data.data);
            props.initialState(res.data.data);

            //else load the tree structure
            // else{
            //   if(Object.keys(res.data).length === 0){
            //     updateCNCInputs("demand_treeData",[]);
            //   }
            //   else{
            //   updateCNCInputs("demand_treeData",res.data.data);
            //   }
            // }

            updateCNCInputs("loading", false);
            updateCNCInputs("fetch_status", true);
            setInputDisableFlag(false);

            updateCNCInputs("recommended_run_id", res?.run_id);
          } else if (res.status.statusCode === "cnc-300") {
            updateCNCInputs("loading", false);
            updateCNCInputs("fetch_status", true);
            setInputDisableFlag(false);
            updateCNCInputs("filteredData", []);
            updateCNCInputs("filteredDataCopy", []);

            updateCNCInputs("recommended_run_id", res?.run_id);
            updateCNCInputs("errorMessage", res.status.statusMessage);
          } else if (res.status.statusCode === "cnc-400") {
            updateCNCInputs("loading", false);
            updateCNCInputs("fetch_status", false);
            setInputDisableFlag(false);
            updateCNCInputs("filteredData", []);
            updateCNCInputs("filteredDataCopy", []);

            updateCNCInputs("errorMessage", res.status.statusMessage);
          } else {
            updateCNCInputs("loading", false);
            updateCNCInputs("fetch_status", false);
            setInputDisableFlag(false);
            updateCNCInputs("filteredData", []);
            updateCNCInputs("filteredDataCopy", []);

            updateCNCInputs(
              "errorMessage",
              "An unexpected error occured please try again"
            );
          }
        })
        .catch((error) => {
          updateCNCInputs("loading", false);
          updateCNCInputs("fetch_status", false);
          setInputDisableFlag(false);
          updateCNCInputs("filteredData", []);
          updateCNCInputs("filteredDataCopy", []);

          updateCNCInputs(
            "errorMessage",
            "An unexpected error occured please try again"
          );
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag: false,
          });
        });
    }
  }, [
    cncData.index_commodity_id,
    cncData.region_selection_value,
    cncData.country_selection_value,
    cncData.month_selection_value,
    cncData.factory_selection,
  ]);

  //calculate eta month for each row
  const setEtaMonth = (executionData) => {
    if (executionData.length > 0) {
      for (let i = 0; i < executionData.length; i++) {
        let dt = convertToUTC(executionData[i].contract_month);
        let eta_month = formattedDate(
          new Date(dt.setMonth(dt.getMonth() + cncData.eta_futures_month_lag))
        );
        executionData[i].eta_month = eta_month;
      }

      updateCNCInputs("filteredData", executionData);
      updateCNCInputs(
        "filteredDataCopy",
        JSON.parse(JSON.stringify(executionData))
      );
    } else {
      updateCNCInputs("filteredData", executionData);
      updateCNCInputs(
        "filteredDataCopy",
        JSON.parse(JSON.stringify(executionData))
      );
    }
  };

  return (
    <>
      <div className="cnc-toggle-icon">
        <label
          onClick={() => {
            setExpand(!expand);
            updateCNCInputs("expand", expand);
          }}
        >
          {!expand ? "Collapse" : "Expand"}
        </label>
        <i
          className="material-icons"
          onClick={() => {
            setExpand(!expand);
            updateCNCInputs("expand", expand);
          }}
        >
          {!expand ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
      </div>
      {!expand && (
        <div className="cnc-model-header">
          <Grid container spacing={1} style={{ padding: "2px 15px 0px 15px" }}>
            <Grid item xs={3} sm={3}>
              <div className="mg-model-header-item" style={{ height: "25px" }}>
                Select a Commodity:{" "}
                <p
                  style={{
                    display: "inline",
                    color: "red",
                    fontSize: 16,
                  }}
                >
                  *
                </p>
              </div>
              <div className="" style={{ marginBottom: "15px" }}>
                <Dropdown
                  options={
                    cncData.commodityOptions ? cncData.commodityOptions : []
                  }
                  callback={commodityChange}
                  disabled={
                    cncData.commodityOptions &&
                    cncData.commodityOptions.length === 1
                      ? true
                      : inputDisableFlag
                  }
                  updateValue={cncData.commodity_selection_value}
                  placeholder={"Select a Commodity"}
                />
              </div>
            </Grid>
            {cncData.commodity_selection_value !== "" && (
              <>
                <Grid item xs={3} sm={3}>
                  <div>
                    Select Decision Month:{" "}
                    <p
                      style={{
                        display: "inline",
                        color: "red",
                        fontSize: 16,
                      }}
                    >
                      *
                    </p>
                  </div>

                  <div className="" style={{ marginBottom: "15px" }}>
                    <Dropdown
                      options={cncData.month_options}
                      callback={decisionMonthChange}
                      disabled={inputDisableFlag}
                      updateValue={cncData.month_selection_value}
                      placeholder={"Select Decision Month"}
                    />
                  </div>
                </Grid>
                {/* <Grid item xs={4} sm={4}>
                  <div
                    className="mg-model-header-item"
                    style={{ height: "25px" }}
                  >
                    Description:{" "}
                  </div>
                  <div className="" style={{ marginBottom: "15px" }}>
                    <input
                      type="text"
                      placeholder="Enter a Description"
                      className="form-control"
                      disabled={(cncData.mode==="create"||cncData.mode==="edit")?false:true}
                      value={cncData.description}
                      onChange={handleDescriptionChange}
                    ></input>
                  </div>
                </Grid> */}
              </>
            )}

            {(cncData.mode === "create"
              ? cncData.month_selection_value !== "" &&
                cncData.isIndexVisible &&
                cncData.indexOptions.length > 0
              : cncData.month_selection_value !== "" &&
                cncData.isIndexVisible &&
                cncData.indexOptions.length > 0) && (
              <>
                <Grid item xs={3} sm={3}>
                  <div>
                    Select Index
                    <p
                      style={{
                        display: "inline",
                        color: "red",
                        fontSize: 16,
                      }}
                    >
                      *
                    </p>
                  </div>

                  <div className="">
                    <Dropdown
                      options={cncData.indexOptions}
                      callback={handleIndexChange}
                      disabled={
                        cncData.indexOptions.length === 1
                          ? true
                          : inputDisableFlag
                      }
                      updateValue={cncData.index_commodity_id}
                      placeholder={"Select Index"}
                    />
                  </div>
                </Grid>
              </>
            )}

            {cncData.month_selection_value !== "" &&
              cncData.regionOptions.length > 0 && (
                <Grid item xs={3} sm={3}>
                  <div
                    className="mg-model-header-item"
                    style={{ height: "25px" }}
                  >
                    Select Region:{" "}
                    <p
                      style={{
                        display: "inline",
                        color: "red",
                        fontSize: 16,
                      }}
                    >
                      *
                    </p>
                  </div>
                  <div className="" style={{ marginBottom: "15px" }}>
                    <ReactSelect
                      options={cncData.regionOptions}
                      // isOptionDisabled={(cncData.regionOptions) => cncData.regionOptions.disabled}
                      isMulti
                      isDisabled={
                        cncData.regionOptions.length === 1
                          ? true
                          : inputDisableFlag
                      }
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      value={cncData.regionOptions.filter((obj) =>
                        cncData.region_selection_value.some(
                          (item) => item.label === obj.label
                        )
                      )}
                      components={{ Option }}
                      allowSelectAll={true}
                      menuPortalTarget={document.body}
                      // styles={{ menuPortal: (base) => ({ ...base, maxHeight: "90%", fontSize: 12, fontFamily: "Hind Siliguri !important", zIndex: 9999 }) }}
                      styles={customStyles}
                      onChange={handleRegionChange}
                      menuPlacement="auto"
                      menuPosition="fixed"
                    />
                  </div>
                </Grid>
              )}

            {(cncData.mode === ""
              ? hideOnGlobalFlag &&
                cncData.isCountryVisible &&
                cncData.region_selection_value.length > 0 &&
                cncData.countryOptions.length > 0
              : cncData.isCountryVisible &&
                cncData.region_selection_value.length > 0 &&
                cncData.countryOptions.length > 0 &&
                cncData.country_selection_value.length > 0) && (
              <Grid item xs={3} sm={3}>
                <div
                  className="mg-model-header-item"
                  style={{ height: "25px" }}
                >
                  Select Country:{" "}
                </div>
                <div className="" style={{ marginBottom: "15px" }}>
                  <ReactSelect
                    options={cncData.countryOptions}
                    isMulti
                    isDisabled={inputDisableFlag}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={cncData.countryOptions.filter((obj) =>
                      cncData.country_selection_value.some(
                        (item) => item.label === obj.label
                      )
                    )}
                    components={{ Option }}
                    allowSelectAll={true}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                    onChange={handleCountryChange}
                    // styles={{ menuPortal: (base) => ({ ...base, maxHeight: "90%", fontSize: 12, fontFamily: "Hind Siliguri !important", zIndex: 9999 }) }}
                    menuPlacement="auto"
                    menuPosition="fixed"
                  />
                </div>
              </Grid>
            )}
            {/* {((cncData.mode==="")?(cncData.country_selection_value.length>0 &&cncData.isCountryVisible&&cncData.factoryOptions.length>0):(cncData.country_selection_value.length>0 &&cncData.isCountryVisible&&cncData.factoryOptions.length>0 &&cncData.factory_selection.length>0))&& (
              <Grid item xs={4} sm={4}>
                <div
                  className="mg-model-header-item"
                  style={{ height: "25px" }}
                >
                  Select Factory:{" "}
                </div>
                <div className="" style={{ marginBottom: "15px" }}>
                  <ReactSelect
                    options={cncData.factoryOptions}
                    isMulti
                    isDisabled={inputDisableFlag}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={cncData.factoryOptions.filter((obj) =>
                      cncData.factory_selection.some(
                        (item) => item.label === obj.label
                      )
                    )}
                    components={{ Option }}
                    allowSelectAll={true}
                    menuPortalTarget={document.body}
                    // styles={{ menuPortal: (base) => ({ ...base, maxHeight: "90%", fontSize: 12, fontFamily: "Hind Siliguri !important", zIndex: 9999 }) }}            
                    styles={customStyles}
                    onChange={handleFactorySelection}
                    menuPlacement="auto"
                    menuPosition="fixed"
                  />
                </div>
              </Grid>
            )} */}
          </Grid>
        </div>
      )}
    </>
  );
}

RecommendMetaSelection.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  cncData: state.cncinputs,
  allData: state.data,
});

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
  toggleSidenav,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecommendMetaSelection)
);
