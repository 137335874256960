/*
Created on September 2021
Confidential and Proprietary of Reckit
Project: Demand Signal MVP
Component name : Notifications
Component Description : To display notifications based on user
@author: tailwyndz
*/

// Import required libraies
import { config } from "../../config/config";
import React, { useEffect, useState, useRef } from "react";
import Popover from "@material-ui/core/Popover";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import TimeAgo from "react-timeago";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import Loader from "../Loader/Loader";
import {
  updateUserInfo,
  updateCNCInputs,
  updateCNCDatas,
  updateAlertCNC,
  update,
  updateFlag,
} from "../../redux/actions";
//import styles
import "./notifications.scss";
import { ca } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  bellIconStyledss: {
    "& .MuiBadge-dot": {
      backgroundColor: "#F10000 !important",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: 3,
      right: 6,
    },
  },
  root: {
    padding: 0,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

function Notification(props) {
  let api = config.api.api_url;
  let {
    user,
    notificationData,
    match,
    flags,
    updateCNCInputs,
    updateCNCDatas,
    updateAlertCNC,
    updateFlag,
  } = props;
  // let username = ;
  let username = user.preferred_username;
  // const [notificationData, setNotification] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const [callRefresh, setCallRefresh] = useState(false);
  const [notifId, setNotifId] = useState("");
  Number.prototype.padLeft = function (base, chr) {
    var len = String(base || 10).length - String(this).length + 1;
    return len > 0 ? new Array(len).join(chr || "0") + this : this;
  };
  useEffect(() => {
    if (callRefresh) {
      const formData = new FormData();
      var d = new Date(),
        dformat =
          [
            (d.getUTCMonth() + 1).padLeft(),
            d.getUTCDate().padLeft(),
            d.getUTCFullYear(),
          ].join("-") +
          " " +
          [
            d.getUTCHours().padLeft(),
            d.getUTCMinutes().padLeft(),
            d.getUTCSeconds().padLeft(),
          ].join(":");
      formData.append("timestamp", dformat);
      formData.append("user", user.preferred_username);
      formData.append("not_id", notifId);
      let reqOptions = {
        method: "POST",
        body: formData,
      };
      fetch(api + "/dataset_refresh", reqOptions)
        .then((response) => response.json())
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        });
    }
    setCallRefresh(false);
  }, [callRefresh]);

  //function to get list of notification based on user
  const getNotifications = () => {
    setLoading(true);
    fetch(api + "/notifications?user=" + username)
      .then((response) => response.json())
      .then((res) => {
        if (res.status.statusCode === "cnc-200") {
          try {
            for (var i = 0; i < res.data.length; i++) {
              if (!res.data[i].isDemand) {
                if (
                  res.data[i].status == "Unread" &&
                  res.data[i].notification_type == "Completed"
                ) {
                  setNotifId(res.data[i].notification_id);
                  setCallRefresh(true);
                }
                break;
              }
            }
          } catch (error) {
            console.log(error);
          }
          // setNotification(res.data)
          if (user.role === "admin" || user.commodity_manager) {
            updateCNCDatas("notification_data", res.data);
          } else {
            if (user.demand_planner) {
              let demand_planner_notification = res.data.filter((noti) => {
                return noti.isDemand === true;
              });
              updateCNCDatas("notification_data", demand_planner_notification);
            } else {
              let notification = res.data.filter((noti) => {
                return noti.isDemand === false;
              });
              updateCNCDatas("notification_data", notification);
            }
          }
          // updateCNCDatas("notification_data", res.data);
          setLoading(false);
          updateFlag("reloadNotification", false);
        } else {
          updateCNCDatas("notification_data", []);
          setLoading(false);
          updateFlag("reloadNotification", false);
        }
      })
      .catch((error) => {
        updateCNCDatas("notification_data", []);
        setLoading(false);
        updateFlag("reloadNotification", false);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag: false,
        });
      });
  };

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    });

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }

      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  }

  // useInterval(() => {
  //   getNotifications();
  // },[config.notifications.timeout])
  useEffect(() => {
    if (notificationData === null || flags.reloadNotification) {
      getNotifications();
    }
    // eslint-disable-next-line
  }, [flags.reloadNotification]);

  // useEffect(()=>{
  //   getNotifications();
  // },[user.demand_planner])
  // useEffect(() => {
  //   setInterval(() => {
  //     getNotifications();
  //   }, config.notifications.timeout);
  // }, []);

  const classes = useStyles();

  //function to open the notification popover
  const handleClick = (event) => {
    getNotifications();
    setAnchorEl(event.currentTarget);
  };

  //function to close the notification popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  //function to map Message for Notification Type
  const generateMessage = (name, type) => {
    let message = "";
    switch (type) {
      case "Completed":
        message = name + " run successfully completed ";
        break;
      case "Failed":
        message = name + " run failed ";
        break;
      case "Running":
        message = name + " run triggered  ";
        break;
      case "Queued":
        message = name + " run queued";
        break;
      default:
        message = "No notifications";
    }
    return message;
  };

  const markallRead = () => {
    let value = [];
    for (let i = 0; i < notificationData.length; i++) {
      value[i] = notificationData[i].notification_id;
    }
    markRead(value);
    handleClose();
  };

  //Marking notifications as read
  const markRead = (value) => {
    let notify_data = { notification: value };
    const reqOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(notify_data),
    };
    fetch(api + "/notifications", reqOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status.statusCode === "cnc-200") {
          getNotifications();
        }
      });
    handleClose();
  };

  //checking whether the badge dot should appear or not
  let invisible = notificationData
    ? notificationData.some((i) => i.status === "Unread")
      ? false
      : true
    : true;

  //notification render
  return (
    <div className="notificationdss-header-container">
      <Badge
        variant="dot"
        invisible={invisible}
        className={classes.bellIconStyledss}
      >
        <i
          className="material-icons-outlined header-menu-icon notification"
          onClick={handleClick}
        >
          notifications
        </i>
      </Badge>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="notificationdss-header-container">
          <p className="notification-header">notifications</p>
          {notificationData
            ? notificationData.some((i) => i.status === "Unread") && (
                <button
                  className="notification-button"
                  onClick={() => {
                    markallRead();
                  }}
                >
                  Mark all as read
                </button>
              )
            : null}
        </div>
        <div className="notificationdss-message-container">
          {loading ? (
            <Loader />
          ) : (
            <>
              {notificationData ? (
                notificationData.length > 0 ? (
                  notificationData.map((item, id) => (
                    <div
                      className={`notification-container ${item.status}`}
                      key={id}
                    >
                      <div className="notification-message-row">
                        <div
                          className={`run-icon ${item.notification_type}`}
                        ></div>
                        <p className="notification-message">
                          {!item.isDemand ? (
                            generateMessage(
                              item.user_commodity_name,
                              item.notification_type
                            )
                          ) : (
                            <>{item.message}</>
                          )}
                        </p>
                      </div>
                      <div className="notification-sub-container">
                        <p className="notification-time-message">
                          <TimeAgo date={new Date(+item.created_at)} />
                        </p>
                        {!item.isDemand && (
                          <NavLink
                            to={{
                              pathname: `/home/viewRuns/${item.user_commodity_name}_${item.cnc_id}_${item.triggered_on}`,
                            }}
                            onClick={() => {
                              updateCNCInputs("mode", "view");
                              updateCNCDatas("activeCommodityRun", item);
                              markRead([item.notification_id]);
                            }}
                            className="nav-run-item disabled-style"
                          >
                            <p
                              className=" notification-run-message"
                              onClick={() => {
                                handleClose();
                              }}
                            >
                              View Run
                            </p>
                          </NavLink>
                        )}
                      </div>
                      <hr className="notification-separator" />
                    </div>
                  ))
                ) : (
                  <div className="notification-container">
                    <p className="no-notification-message">No notifications</p>
                  </div>
                )
              ) : null}
            </>
          )}
        </div>
      </Popover>
    </div>
  );
}

Notification.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  notificationData: PropTypes.object,
  updateCNCDatas: PropTypes.func,
  updateCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  notificationData: state.data.notification_data,
  flags: state.flags,
});

const mapDispatchToProps = {
  // updateAlertInfo,
  updateCNCInputs,
  updateCNCDatas,
  updateAlertCNC,
  updateFlag,
  // toggleSidenav,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Notification)
);
