//Import required libraies
import React,{useState,useEffect} from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useHistory } from "react-router";
//Import Custom Component
import {config} from "../../config/config"
// import WorkInProgress from "../../components/WorkInProgress/WorkInProgress";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loader from '../../components/Loader/Loader';
//Import tabs
import MyCommodity from "./MyCommodity/MyCommodity";
import RecentCommodities from "./RecentCommodities/RecentCommodities";
import CommodityStarred from "./CommoditiesStarred/CommodityStarred";
import SharedCommodity from "./SharedCommodities/SharedCommodity";
import ViewCommodity from "./ViewCommodities/ViewCommodity";
import AddCommodity from "./AddCommodity/AddCommodity";
import UpdateDemand from "./UpdateDemand/UpdateDemand";
import Recommendation from "./Recommendations/Recommendation";
import Admin from "./Admin/Admin";
import UpdateForecast from "./UpdateForecast/UpdateForecast";
import Budget from "./Budget/Budget";
import Spots from "./Spots/Spots";
import CostInfo from "./CostInfo/CostInfo"
// Import action creators
import { updateUserInfo } from "../../redux/actions";
//Import Custom Hooks

//Import utils/data

function CNCApp(props) {
  let { user,match,updateUserInfo } = props;
  const [defaultRoute,setDefaultRoute]=useState('');
  const history = useHistory();
  const [SidebarItems,setSidebarItems]=useState(config.sidenav);
  let api = config.api.api_url;
  const [loading,setLoading]=useState(false);
  const tabMapping = {
    addHedging: {
      component: <AddCommodity match={match} />
    },
    myCommodityHedging: {
      component: (
        <MyCommodity match={match}>          
        </MyCommodity>
      )
    },
    recentCommodities: {
      component: (
        <RecentCommodities
          match={match}
        />
      )
    },
    sharedCommodities: {
      component: <SharedCommodity match={match} />
    },
    // sharedwithothers: {
    //   component: <WorkInProgress match={match} />,
    //   apps: ["MROI", "AUDIENCE ENGINE", "PNP", "MTA"],
    // },
    starredCommodities: {
      component: (
        <CommodityStarred
          match={match}
        />
      )
    },
    viewRuns: {
      component: <ViewCommodity match={match} />
    // geoTargeting: {
    //   component: <GeoTargeting match={match} />,
    //   apps: ["AUDIENCE ENGINE"],
    },
    updateDemand:{
      component : <UpdateDemand />,
    },
    execution:{
      component :<Recommendation/>
    },
    admin:{
      component:<Admin match={match}/>
    },
    uploadPriceForecast:{
      component:<UpdateForecast match={match}/>
    },
    budget:{
      component:<Budget match={match}/>
    },
    spots:{
      component:<Spots match={match}/>
    },
    // costInfo:{
    //   component:<CostInfo match={match}/>
    // }
  };
  useEffect(()=>{
      if(user.role==="admin"){
        setSidebarItems(config.sidenav_admin)
        setDefaultRoute(config.defaultRoute.admin)
        updateUserInfo({
          ...user,
          sidebarItems:config.sidenav_admin,
          demand_planner:true,
          demand_planner_info:user.demand_planner_info
       })
      }
      else if(user.groups){
        if(user.groups.includes('demand_planner')){
          setSidebarItems(config.sidenav_demandManager)
          setDefaultRoute(config.defaultRoute.demand_planner)
              updateUserInfo({
                ...user,
                sidebarItems:config.sidenav_demandManager,
                demand_planner:true,
                demand_planner_info:user.demand_planner_info
             })  
              setLoading(false)
        }
        else if(user.groups.includes('commodity_manager')){
          setSidebarItems(config.sidenav_commodityManager) 
          setDefaultRoute(config.defaultRoute.commodity_manager)
          updateUserInfo({
            ...user,
            sidebarItems:config.sidenav_commodityManager,
            commodity_manager:true,
            demand_planner:false,
            demand_planner_info:user.demand_planner_info
         })  
          setLoading(false)
        }  
        else{
          setSidebarItems(config.sidenav)
          setDefaultRoute(config.defaultRoute.CNC)
          updateUserInfo({
            ...user,
            sidebarItems:config.sidenav,
            demand_planner:false,
            commodity_manager:false,
         })  
          setLoading(false)
        }
      // })
    // }
      }
  },[user.groups])
  return (
    <>
      {match.params.tab ? (
        <LayoutTopSideBottom match={match} defaultRoute={defaultRoute}>
          <Breadcrumbs match={match} />
          {tabMapping[match.params.tab].component}
        </LayoutTopSideBottom>
        
        // <LayoutTopSideBottom match={match}>
        //   <Breadcrumbs match={match} />
        //   <WorkInProgress />
        // </LayoutTopSideBottom>
      ) : match.url === "/home/" ? (
        <Redirect to={`${match.url}${defaultRoute}`} />
      ) : (
        <Redirect to={`${match.url}/${defaultRoute}`} />
      )}
    </>
  );
}

CNCApp.propTypes = {
  userData:PropTypes.object,
  user:PropTypes.object,
  match: PropTypes.object,
  // children:PropTypes.node,
  // updateUserInfo:PropTypes.func,
  // updateScreen:PropTypes.func,
  // updateAlert:PropTypes.func
};

const mapStateToProps =(state)=>({
   user:state.user
})

const mapDispatchToProps ={
  updateUserInfo,
  // updateAlert,
  // updateScreen,
}



// Create a new component that is "connected" (to borrow redux terminology) to the router.
const ShowTheLocationWithRouter = withRouter(CNCApp);

export default connect(mapStateToProps, mapDispatchToProps)(ShowTheLocationWithRouter);
