/*
Created on March 2022
Confidential and Proprietary of Reckitt
Project: CNC
Screen name : UpdateDemand
Screen Description : 
@author: Tailwyndz
*/

//Import Libraries
import React, { useEffect} from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useState } from 'react';
import {config} from '../../../config/config';
import { withRouter } from "react-router";
// import Button from '@material-ui/core/Button';
import { updateCNCInputs, updateAlertCNC, updatewholeCNCInputs ,updateCNCDatas,updateFlag} from '../../../redux/actions';

//import styles
// import "./addcommodity.scss";
import Loader from '../../../components/Loader/Loader';
import MetaSelection from './components/MetaSelection';
import BottomSelection from './components/BottomSelection';
import Dialogs from "../../../components/Dialog/Dialog";
import nocommodity from '../../../../assets/images/undraw_empty.svg'

function UpdateDemand(props){
    let { match,user, cncData,updateCNCInputs, updatewholeCNCInputs,updateFlag } = props;
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [dialogData, setDialog] = React.useState([])
    let api = config.api.api_url;
    // useEffect(()=>{
    //   // initializeState();
    //   if(!user.demand_planner){
    //     history.push({
    //       pathname: `/home/myCommodityHedging`,
    //     });
    //   }
    // },[])



    function initializeState(){

      let initialState = [];
      initialState.fetch_status=true;
      initialState.loading=false;
      initialState.mode="";
      initialState.cncDataGraph=[];
      initialState.override_forecast_header=[];
      initialState.demand_header=[];
      initialState.demand_treeData=[];
      initialState.commodityOptions=[];
      initialState.commodity_selection="";
      initialState.commodity_selection_value="";
      initialState.month_selection="";
      initialState.month_selection_value="";
      initialState.description="";
      initialState.month_options=[];
      initialState.region_selection=[];
      initialState.regionOptions=[];
      initialState.regionOptionsAll=[];
      initialState.region_selection_value=[{
        label:"Global",
        value:"Global",
        geography_level:"region",
        parent_id:"null",
      }];
      initialState.isIndexVisible=false;
      initialState.isCountryVisible=false;
      initialState.isDemandEditable=false;
      initialState.scenarioFlag=false;
      initialState.scenario_flag_checkbox=false;
      initialState.isExpandable=false;
      initialState.indexOptions=[];
      initialState.index_commodity_id="";
      initialState.countryOptions=[];
      initialState.countryOptionsAll=[];
      initialState.country_selection="";
      initialState.country_selection_value=[];
      initialState.factoryOptions=[];
      initialState.factoryOptionsAll=[];
      initialState.factory_selection=[];
      initialState.use_for_final_hedging=false;
      initialState.index_source_weights=[];
      initialState.demandDataEdited=false;
      initialState.demand_difference_flag=false;
      initialState.graph_difference_flag=false;
      initialState.expand=true;
      updatewholeCNCInputs(initialState);
    }  

    //function to populate data for decision Month dropdown &Headers for Override and Demand tables
    /*
    if getNext_N_MonthNamesWithYear() is called only with 1 parameter ie)maxmonth it generates dropdown
      values for Decision month (month options)

    if  getNext_N_MonthNamesWithYear() is called only with 2 parameter ie)Max month and date
      it generates values for override price forecast table and demand tree header
    */
      function getNext_N_MonthNamesWithYear(maxMonth,date="") {
        var names = ['January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'];
        var now = new Date();
        var month;
        var year;
        if(arguments.length===1){
            // month=now.getMonth()-4;
            let before_three_month=new Date(now.setMonth(now.getMonth()-3))
            month=before_three_month.getMonth();
            year = before_three_month.getFullYear();
            maxMonth=13;
        }
        else{
            let month_in_text=date.split(" ")[0];
            month=names.indexOf(month_in_text);
            year=date.split(" ")[1];
        }
        
    
        let month_options = [];
        let override_forecast_header=[];
        let demand_header=[];
        for (var i = 0; i < maxMonth; ++i) {
            if(arguments.length===1){
                 month_options[i] = {
                    label: names[month] + ' ' + year,
                    value: names[month] + ' ' + year
                  };
            }
            else if(arguments.length>1){
              override_forecast_header[i] = {
                    label: names[month] + ' ' + year,
                    value: "",
                  };
              
              demand_header[i] = {
                    label: names[month] + ' ' + year,
                    value: "",
                  }; 
            }
          

          
          
          if (++month === 12) {
            month = 0;
            ++year;
          }
        }
        if(arguments.length===1){
          updateCNCInputs("month_options", month_options)
        }
        if(arguments.length>1){
          updateCNCInputs("override_forecast_header", override_forecast_header)
          updateCNCInputs("demand_header", demand_header)
        }
      }

    const setDemandPlannerInfo=()=>{
      if(user.group_add_info!==undefined &&user.group_add_info!==null){
        // updateCNCInputs("commodity_selection_value",user.demand_planner_info.commodity_name) 
        // getNext_N_MonthNamesWithYear(user.demand_planner_info.decision_months)
        // let indexOptions=[];
        // let e=user.demand_planner_info;
        // for (let i = 0; i < e.indices.length; i++) {
        //   indexOptions[i] = {
        //     label: e.indices[i].index_name,
        //     value: e.indices[i].index_commodity_id,
        //     source_weights: e.indices[i].source_weight
        //   }
        // }
        // if (indexOptions.length == 1) {
        //   updateCNCInputs("index_commodity_id", indexOptions[0].value)
        //   updateCNCInputs("index_source_weights",indexOptions[0].source_weights)
        // }
        // updateCNCInputs("indexOptions", indexOptions);
        let commodity=user.group_add_info.commodity;
        let commodityOptions=[];
        for(let i=0;i<commodity.length;i++){
          commodityOptions[i] = {
            value: commodity[i].commodity_id,
            label: commodity[i].commodity_name,
            decision_months:commodity[i].decision_months,
            indices:commodity[i].indices,
            factory_id:commodity[i].factory_id,
            isIndexVisible:commodity[i].isIndexVisible
          }
        }
        updateCNCInputs("commodityOptions", commodityOptions);
        if(commodityOptions.length ===1){
          updateCNCInputs("commodity_selection",commodityOptions[0].label)
          updateCNCInputs("commodity_selection_value",commodityOptions[0].value)
          getNext_N_MonthNamesWithYear(commodityOptions[0].decision_months);
          let indexOptions=[];
          let e=commodityOptions[0];
            for (let i = 0; i < e.indices.length; i++) {
              indexOptions[i] = {
                label: e.indices[i].index_name,
                value: e.indices[i].index_commodity_id,
                source_weights: e.indices[i].source_weight
              }
            }
            if (indexOptions.length === 1) {
              updateCNCInputs("index_commodity_id", indexOptions[0].value)
              updateCNCInputs("index_source_weights",indexOptions[0].source_weights)
            }
            updateCNCInputs("indexOptions", indexOptions);
            let factoryOptions=[];
            let factory_id=e.factory_id;
            for (let i = 0; i < factory_id.length; i++) {
              factoryOptions[i] = {
                value: factory_id[i],
                label: factory_id[i],
                geography_level:"factory",
              }
            }
            if(factoryOptions.length ===1){
              updateCNCInputs("factory_selection", [factoryOptions[0]]);
            }
              updateCNCInputs("factoryOptions",factoryOptions);    
        }
      }
    }

    useEffect(()=>{
      setLoading(true)
      getCommodity();
      initializeState();
      setDemandPlannerInfo();
      updateFlag("currentPageID",match.url.split("/")[2])
    },[])

      //get Commodities
    const getCommodity = () => {
      let commodityOptions = [];
      fetch(api + "/commodities")
        .then((respone) => respone.json())
        .then((response) => {
          if(response.status.statusCode==="cnc-200"){
            for (let i = 0; i < response.data.length; i++) {
              commodityOptions[i] = {
                value: response.data[i].commodity_id,
                label: response.data[i].commodity_name,
                decision_months:response.data[i].decision_months,
                isIndexVisible:response.data[i].isIndexVisible,
                isCountryVisible:response.data[i].isCountryVisible,
                isDemandEditable:response.data[i].isDemandEditable,
                scenarioFlag:response.data[i].scenarioFlag,
                weight_graphs:response.data[i].weight_graphs,
                isExpandable:response.data[i].isExpandable,
                indices:response.data[i].indices,
                report_url:response.data[i].report_url,
                report_params:response.data[i].report_params,
                demand_difference_url:response.data[i].demand_difference_url,
                demand_difference_url_params:response.data[i].demand_difference_url_params,
                grade_commodity_flag:response.data[i].grade_commodity_flag
              }
            }
            updateCNCInputs("commodityOptionsAll", commodityOptions);
            setLoading(false)
          }

          else{
            updateCNCInputs("commodityOptionsAll", []);
            setLoading(false)
          }
          
        })
        .catch((error) => {
          updateCNCInputs("commodityOptionsAll", []);
          setLoading(false)
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
        });  
    }

    useEffect(()=>{

      if(cncData.commodityOptionsAll !==undefined&&cncData.commodityOptionsAll.length >0 && cncData.commodity_selection_value!==""){
        cncData.commodityOptionsAll.filter((obj)=>{
          if(obj.value ===cncData.commodity_selection_value){
            updateCNCInputs("grade_commodity_flag",obj.grade_commodity_flag)
          }
        })
      }

    },[cncData.commodityOptionsAll,cncData.commodity_selection_value])

             

            //function to confirm Demand row addition
            const handleConfirmDialog = (item) => {
    
              setDialog({
                open: true,
                confirm: true,
                close: dialogClose,
                confirmFunction: addEmptyDemandData,
                deleteRow: {},
                name: "You are about enter <b>Demand Data</b> for a  <b>new decision month</b>",
                title:"Creation/Upload"
              });
              
            };
          
            //close confirm for new demand row addition
            const dialogClose = (action="") => {
              if(action==="close")
                {updateCNCInputs("demand_treeData",[]);}
              setDialog({
                ...dialogData,
                open: false,
              });
            };   


            

            //function to create Demand tree Data when no data available
            const addEmptyDemandData=()=>{
              updateFlag("unSavedChanges",true)
              //Create a Factory level data for each factory in the selection then inside its children
              //push  empty grade row 

              let factoryLevelData=[];

              for(let i=0;i<cncData.factory_selection.length;i++){
                let factoryRow={}
                if(cncData.grade_commodity_flag){
                  factoryRow={
                    parent:"",
                    level:"factory",
                    name:cncData.factory_selection[i].label,
                    grade:"Total Demand",
                  }
                }

                else{
                  factoryRow={
                    parent:"",
                    level:"factory",
                    name:cncData.factory_selection[i].label,
                  }
                }                  
                  for(let i=0;i<cncData.demand_header.length;i++){
                    factoryRow={
                      ...factoryRow,
                      [cncData.demand_header[i].label]:""
                    }
                  }

                  //After factory is created push a empty grade row in its children and append it
                  //to factoryLevelData list
                  let newFactoryData={
                    data:factoryRow,
                    children:[],
                    height:37
                  }
                 if(cncData.grade_commodity_flag){
                  //grade row
                  let gradeRow={
                    ...factoryRow,
                    parent:cncData.factory_selection[i].label,
                    level:"factory-grade",
                    name:"",
                    grade:""
                  }
                  let newGradeData={
                    data:gradeRow,
                    children:[],
                    height:37
                  }

                  newFactoryData.children.push(newGradeData);
                  }
                  factoryLevelData.push(newFactoryData);
                  updateCNCInputs("demand_treeData",factoryLevelData)

              }
              dialogClose();
            
            }

            function onCancel() {
              initializeState();
              getCommodity();
              setDemandPlannerInfo();
              history.push({
                pathname: `/home/updateDemand`,
              });
              updateFlag("unSavedChanges",false)
            }  
    

    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          
            (user.group_add_info!==undefined && user.group_add_info!==null)?
            <>
            <MetaSelection
              disabled={false}
              getNext_N_MonthNamesWithYear={getNext_N_MonthNamesWithYear}
              setDemandPlannerInfo={setDemandPlannerInfo}
              handleConfirmDialog={handleConfirmDialog}
            />
            {cncData.month_selection_value !== "" &&
              cncData.index_commodity_id !== "" &&
              cncData.factory_selection.length > 0 && (
                <>
                  <BottomSelection
                    setDemandPlannerInfo={setDemandPlannerInfo}
                    onCancel={onCancel}
                  />
                </>
              )}
            {dialogData.open && <Dialogs props={dialogData}></Dialogs>}
            </>
          :
          <>
            <div className="no-commodity">
                <img src={nocommodity} alt="" className="cnc-img"/>
                <p>No commodity configured for the user</p>
              </div>
          </>
          
         
        )}
      </>
    );
}


UpdateDemand.propTypes = {
    user: PropTypes.object,
    cncData: PropTypes.object,
    updateCNCInputs: PropTypes.func,
    updatewholeCNCInputs: PropTypes.func,
    updateAlertCNC: PropTypes.func,
    updateCNCDatas:PropTypes.func,
  };
  
  const mapStateToProps = (state) => ({
    user: state.user,
    cncData: state.cncinputs,
    allData:state.data
  });
  
  const mapDispatchToProps = {
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateCNCDatas,
    updateFlag
  };
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateDemand));