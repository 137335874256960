import * as React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from "@material-ui/core/TableContainer";
import Tooltip from "@material-ui/core/Tooltip";
import TablePaginate from "../../components/PaginationTable/TablePagination";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {updateAlertCNC} from "../../redux/actions";

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#e5e5e5",
      color: "#212529",
    },
    body: {
      maxWidth: "50%",
      fontSize: 12,
      textOverflow: "ellipsis",
      padding:"10px"
    },
  }))(TableCell);
  const useStyles = makeStyles({
    table: {
      minWidth: "100%",
    },
    container: {},
  });

  
  function TableRunComponent(props) {
    let { updateAlertCNC} = props;
    const classes = useStyles()

    function LinearProgressWithLabel(props) {
      return (
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" {...props} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
             props.value,
            )}%`}</Typography>
          </Box>
        </Box>
      );
    }
    return (
      <>
        {
          //Model Runs --
          props.type === "Runs" ? (
            <>
              <TableContainer className={classes.container}>
                <Table stickyHeader className={classes.table}>
                  <TableHead className="table-heading">
                    <TableRow>
                      {props.header.map((item, idx) => (
                        <StyledTableCell>{item}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="table_body_scenario">
                    {props.filteredData
                      .slice(
                        props.page * props.rowsPerpage,
                        props.page * props.rowsPerpage + props.rowsPerpage
                      )
                      .map((rows, id) => (
                        <TableRow style={{ cursor: "pointer" }} hover={true}>
                          <StyledTableCell>
                            <div className={`runs-icon ${rows.status}`}></div>
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(rows);
                            }}
                          >
                            {rows.user_commodity_name}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(rows);
                            }}
                          >
                            {rows.description}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(rows);
                            }}
                          >
                            {rows.created_on}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(rows);
                            }}
                          >
                            {rows.user}
                          </StyledTableCell>
                          <StyledTableCell
                          title={rows.run_id}
                            onClick={() => {
                              props.handleView(rows);
                            }}
                          >
                            {props.localTime(rows.triggered_on)}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(rows);
                            }}
                          >
                            {rows.created_by}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(rows);
                            }}
                          >
                            {props.timeDuration(rows.run_start_time, rows.completed_at)}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(rows);
                            }}
                          >
                            {rows.status}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${
                              props.username !== rows.created_by && "hide-icons"
                            }`}
                            onClick={() => {
                              // props.username === rows.created_by &&
                              //   props.handleDialog(rows);
                            }}
                          >
                            <i
                              className={`material-icons-outlined hide-icon `
                              // ${
                              //   props.username !== rows.created_by &&
                              //   "hide-icon"
                              // }
                            }
                              onClick={() => {
                                // props.username === rows.created_by &&
                                //   props.handleDialog(rows);
                              }}
                            >
                              delete
                            </i>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePaginate data={props.page_props} />
              </TableContainer>
            </>
          ) : (
            <>
              {/* <TableContainer className={classes.container}>
                <Table stickyHeader className={classes.table}>
                  <TableHead className="table-heading">
                    <TableRow>
                      {props.header.map((item, idx) => (
                        <StyledTableCell>{item}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="table_body_scenario">
                    {props.scheduledRun
                      .slice(
                        props.page * props.rowsPerpage,
                        props.page * props.rowsPerpage + props.rowsPerpage
                      )
                      .map((row, id) => (
                        <TableRow style={{ cursor: "pointer" }} hover={true}>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(row);
                            }}
                          >
                            <Tooltip 
                            title={row.model_name} 
                            placement="center"
                            >
                              <div>
                                {row.model_name.length > 30
                                  ? row.model_name.slice(0, 30) + "..."
                                  : row.model_name}
                              </div>
                            </Tooltip>
                          </StyledTableCell>

                          <StyledTableCell
                            onClick={() => {
                              props.handleView(row);
                            }}
                          >
                            {row.model_user}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(row);
                            }}
                          >
                            {row.created_on}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(row);
                            }}
                          >
                            {row.created_by}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ textTransform: "capitalize" }}
                            onClick={() => {
                              props.handleView(row);
                            }}
                          >
                            {row.scheduled_run.scheduledType}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(row);
                            }}
                          >
                            {row.scheduled_run.scheduledDate}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(row);
                            }}
                          >
                            {row.scheduled_run.scheduledDay}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(row);
                            }}
                          >
                            {props.localScheduletime(
                              row.nextRunDate,
                              row.scheduled_run.scheduledTime
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props. handleView(row);
                            }}
                          >
                            {row.lastRunDate}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {
                              props.handleView(row);
                            }}
                          >
                            {row.nextRunDate}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${
                              props.username !== row.created_by && "hide-icons"
                            }`}
                            onClick={() => {
                              props.username === row.created_by &&
                                props.deleteScheduleDialog(row);
                            }}
                          >
                            <i
                              className={`material-icons-outlined ${
                                props.username !== row.created_by && "hide-icon"
                              }`}
                              onClick={() => {
                                props.username === row.created_by &&
                                  props.deleteScheduleDialog(row);
                              }}
                            >
                              delete
                            </i>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePaginate data={props.scheduled_page_props} />
              </TableContainer> */}
            </>
          )
        }
      </>
    );
  

}


TableRunComponent.propTypes = {
  // match: PropTypes.object,
  updateAlertCNC: PropTypes.func,
};

const mapStateToProps = (state) => ({
  // data:state.dssStateData.data
});

const mapDispatchToProps = {
  updateAlertCNC
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableRunComponent)
);