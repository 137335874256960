import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
// import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { withStyles} from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useState } from "react";

const containedButton = {
    color: "white",
    backgroundColor: "#46596A",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "10px !important"
};
const outlinedButton = {
    borderColor: "#46596A",
    // background-color: #d7dadd,
    color: "#46596A",
    // width: '100%',
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "10px !important",
};
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <i className="material-icons-outlined">close</i>
            </IconButton>
        </MuiDialogTitle>
    );
});



const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function BudgetCommentsDialog(props) {
    let {
        user_comments
    }=props.props;

    const [commentsval,setComments]=useState(user_comments);

    let commentsCopy=user_comments;
    return (
      <Dialog
        aria-labelledby="Calculation Types"
        open={props.props.open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          onClick={() => {
            props.props.close();
          }}
        >
          Comments
        </DialogTitle>
        <MuiDialogContent dividers style={{ display: "flex" }}>
          <Typography>Enter Comments(if any)</Typography>
          <TextareaAutosize
            aria-label="minimum height"
            value={commentsval}
            minRows={3}
            style={{ width: "70%", marginLeft: "15px" }}
            placeholder=""
            onChange={(event)=>{setComments(event.target.value)}}
          />
        </MuiDialogContent>
        <DialogActions>
          <Button
            style={outlinedButton}
            onClick={() => {
              props.props.handleCommments(commentsCopy, props.props.id);
            }}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            style={containedButton}
            onClick={() => {
              props.props.handleCommments(commentsval, props.props.id);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
}