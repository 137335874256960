import React, { useEffect } from "react";
// import {use}
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Select, { components } from "react-select";
// import "./table.scss"
import TablePaginate from "../../../../components/PaginationTable/TablePagination";
import { updateAlertCNC } from "../../../../redux/actions";
import Tablestyles from "./ExecutionTable.module.css";
import { NumericFormat } from "react-number-format";
import { useState } from "react";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import { Grid } from "@material-ui/core";
import nocommodity from "../../../../../assets/images/undraw_empty.svg";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#e5e5e5",
    color: "#212529",
  },
  body: {
    fontSize: 12,
  },
  root: {
    padding: "5px",
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  container: {},
});

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    maxHeight: "80%",
    fontSize: 12,
    fontFamily: "Hind Siliguri !important",
    zIndex: 9999,
  }),
};

function ExecutionTable(props) {
  let { cncData } = props;
  const classes = useStyles();
  return (
    <>
      {props.filteredData && props.filteredData.length > 0 ? (
        <TableContainer className={Tablestyles.execution_tb}>
          <Table stickyHeader className={classes.table}>
            <TableHead className="table-heading">
              <TableRow>
                {props.header.map((item, idx) => (
                  <StyledTableCell>{item}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="table_body_scenario">
              {props.filteredData
                .slice(
                  props.page * props.rowsPerPage,
                  props.page * props.rowsPerPage + props.rowsPerPage
                )
                .map((row, id) => (
                  <TableRow
                    key={id}
                    // title={row.model_id}
                    hover={true}
                    style={
                      row.is_edited
                        ? {
                            background: "rgb(215 215 215)",
                          }
                        : {}
                    }
                  >
                    <StyledTableCell style={{ width: "12%" }}>
                      <Select
                        options={
                          props.factory_options ? props.factory_options : []
                        }
                        menuPortalTarget={document.body}
                        value={props.factory_options.filter((obj) => {
                          return obj.value === row.geography_id;
                        })}
                        styles={customStyles}
                        isDisabled={row.existing_data ? true : !row.edit_flag}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        placeholder={"Select Factory"}
                        onChange={(event) => {
                          props.handleTableFactoryChange(event, row.id);
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "12%" }}>
                      <Select
                        options={props.type_options ? props.type_options : []}
                        menuPortalTarget={document.body}
                        value={props.type_options.filter((obj) => {
                          return obj.value === row.type;
                        })}
                        styles={customStyles}
                        isDisabled={row.existing_data ? true : !row.edit_flag}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        placeholder={"Enter type"}
                        onChange={(event) => {
                          props.handleTypeChange(event, row.id);
                        }}
                      />
                    </StyledTableCell>

                    <StyledTableCell style={{ width: "12%" }}>
                      <input
                        className="form-control"
                        type="month"
                        data-clear-btn="true"
                        id="eta_month"
                        name="eta_month"
                        disabled={row.existing_data ? true : !row.edit_flag}
                        // min={row.decision_month}
                        value={props.formattedDate(row.eta_month)}
                        onChange={(event) => {
                          props.handleMonthChange(event, row.id);
                        }}
                      ></input>
                    </StyledTableCell>

                    <StyledTableCell style={{ width: "12%" }}>
                      <input
                        className="form-control"
                        type="month"
                        data-clear-btn="true"
                        id="contract_month"
                        name="contract_month"
                        disabled={true}
                        // min={row.decision_month}
                        value={props.formattedDate(row.contract_month)}
                        onChange={(event) => {
                          // props.handleMonthChange(event, row.id);
                        }}
                      ></input>
                    </StyledTableCell>

                    <StyledTableCell style={{ width: "12%" }}>
                      <Select
                        options={
                          props.status_options ? props.status_options : []
                        }
                        menuPortalTarget={document.body}
                        value={props.status_options.filter((obj) => {
                          return obj.value === row.status;
                        })}
                        styles={customStyles}
                        isDisabled={!row.edit_flag}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        placeholder={"Enter status"}
                        onChange={(event) => {
                          props.handleStatusChange(event, row.id);
                        }}
                      />
                    </StyledTableCell>

                    {cncData.isDairy && (
                      <StyledTableCell style={{ width: "10%" }}>
                        {/* <input
                      type="number"
                      placeholder="Enter a value"
                      className="form-control"
                      id={row.id}
                      value={row.executed_volume}
                      min={0}
                      disabled={!row.edit_flag}
                      style={{ textAlign: "right"}}
                      name={'executed_volume'}
                      onChange={(event) => {props.handleInputchange(event,row.id)}}
                    ></input> */}
                        <NumericFormat
                          displayType={"number"}
                          value={row.executed_volume}
                          disabled={!row.edit_flag}
                          valueIsNumericString={true}
                          thousandSeparator=","
                          thousandsGroupStyle="thousand"
                          prefix={""}
                          name={"executed_volume"}
                          onValueChange={(event, sourceInfo) => {
                            props.handleInputchange(
                              event,
                              row.id,
                              "executed_volume"
                            );
                          }}
                          id={row.id}
                          style={{ textAlign: "right" }}
                          className="form-control"
                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell style={{ width: "9%" }}>
                      {/* <input
                      type="number"
                      placeholder="Enter a value"
                      className="form-control"
                      id={row.id}
                      value={row.executed_price}
                      min={0}
                      max={100}
                      disabled={!row.edit_flag}
                      style={{ textAlign: "right" }}
                      name={'executed_price'}
                      onChange={(event) => {props.handleInputchange(event,row.id)}}
                    ></input> */}
                      <NumericFormat
                        displayType={"number"}
                        value={row.executed_price}
                        disabled={!row.edit_flag}
                        valueIsNumericString={true}
                        thousandSeparator=","
                        thousandsGroupStyle="thousand"
                        prefix={""}
                        min={0}
                        name={"executed_price"}
                        onValueChange={(event, sourceInfo) => {
                          props.handleInputchange(
                            event,
                            row.id,
                            "executed_price"
                          );
                        }}
                        id={row.id}
                        style={{ textAlign: "right" }}
                        className="form-control"
                      />
                    </StyledTableCell>
                    {!true && (
                      <StyledTableCell style={{ width: "11%" }}>
                        {/* <input
                      type="number"
                      placeholder="Enter a value"
                      className="form-control"
                      id={row.id}
                      value={row.executed_price_fcy}
                      min={0}
                      max={100}
                      disabled={!row.edit_flag}
                      style={{ textAlign: "right" }}
                      name={'executed_price_fcy'}
                      onChange={(event) => {props.handleInputchange(event,row.id)}}
                    ></input> */}

                        <NumericFormat
                          displayType={"number"}
                          value={row.executed_price_fcy}
                          disabled={!row.edit_flag}
                          valueIsNumericString={true}
                          thousandSeparator=","
                          thousandsGroupStyle="thousand"
                          prefix={""}
                          name={"executed_price_fcy"}
                          onValueChange={(event, sourceInfo) => {
                            props.handleInputchange(
                              event,
                              row.id,
                              "executed_price_fcy"
                            );
                          }}
                          id={row.id}
                          style={{ textAlign: "right" }}
                          className="form-control"
                        />
                      </StyledTableCell>
                    )}

                    <StyledTableCell style={{ width: "11%" }}>
                      <i
                        aria-describedby={id}
                        id={row.cnc_id}
                        className="material-icons-outlined "
                        value={id}
                        onClick={() => {
                          props.handleDialog(
                            row.user_comments !== "null"
                              ? row.user_comments
                              : "",
                            row.id
                          );
                        }}
                      >
                        comments
                      </i>

                      {!row.edit_flag ? (
                        <i
                          aria-describedby={id}
                          id={row.id}
                          className="material-icons-outlined "
                          value={id}
                          onClick={() => {
                            props.handleRowEdit(row.id, "edit", id);
                          }}
                        >
                          edit
                        </i>
                      ) : (
                        <>
                          <i
                            aria-describedby={id}
                            id={row.cnc_id}
                            className="material-icons-outlined "
                            value={id}
                            style={{ color: "red" }}
                            onClick={() => {
                              props.handleRowEdit(row.id, "clear", id);
                            }}
                          >
                            close
                          </i>
                          <i
                            aria-describedby={id}
                            id={row.cnc_id}
                            className="material-icons-outlined "
                            value={id}
                            style={{ color: "green" }}
                            onClick={() => {
                              props.handleRowEdit(row.id, "save", id);
                            }}
                          >
                            done
                          </i>
                        </>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <div className="no-commodity">
            <img src={nocommodity} alt="" className="cnc-img" />
            <p>No data available for the applied filter</p>
          </div>
        </>
      )}
      <TablePaginate data={props.page_props} />
    </>
  );
}

ExecutionTable.propTypes = {
  updateAlertCNC: PropTypes.func,
};

const mapStateToProps = (state) => ({
  // data:state.dssStateData.data
  user: state.user,
  cncData: state.cncinputs,
});

const mapDispatchToProps = {
  updateAlertCNC,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExecutionTable)
);
