import C from "../constants";

export default function admin_data(state={},payload){
    let { type, key, data } = payload;
    switch(type){
        case C.UPDATE_WHOLE_ADMIN_INPUT:
            return data;
        case C.UPDATE_ADMIN_INPUTS :
            return {
                ...state,
                [key]:data
              };
        default:
            return state
    }
}