/*
Created on June 2022
Confidential and Proprietary of Reckitt
Project: CNC
Component name : BottomSelection
Component Description : This Component Displays the Bottom Panel (Price Forecast Graph &Demand Tree) 
@author: Tailwyndz
*/

import React, { useEffect,useState,useRef } from "react";
import {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
  updateCNCDatas,
  updateFlag
} from "../../../../redux/actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {config} from '../../../../config/config';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
// import "../addcommodity.scss";
import Loader from '../../../../components/Loader/Loader';
import TreeTableComponent from "../../../../components/TreeTable/TreeTable"
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import nocommodity from '../../../../../assets/images/undraw_empty.svg'
import FileUpload from "./FileUpload";
import { IconButton } from "@material-ui/core";
import Dialogs from "../../../../components/Dialog/Dialog";
import { CSVLink } from "react-csv";
import UploadDemandTreeTable from "./UploadDemandTreeTable";
import UnSavedConfirmationDialog from "../../../../utils/UnSavedConfirmationDialog";
import Button from "@material-ui/core/Button";
function BottomSelection(props) {
    let {
      user,
      cncData,
      allData,
      updateCNCInputs,
      updateAlertCNC,
      onCancel,
      updateFlag
    } = props;   
    const outlinedCNCButton = {
      borderColor: '#46596A',
      // background-color: #d7dadd,
      color: '#46596A',
      // width: '100%',
      fontFamily: "Hind Siliguri",
      fontSize: '12px',
      fontWeight: "bold",
      marginRight: "5px",
  }

  const containedButton = {
      color: "white",
      backgroundColor: "#46596A",
      fontFamily: "Hind Siliguri",
      fontSize: "12px",
      fontWeight: "bold",
      // marginLeft: "10px !important",
      marginRight: "5px",
  };
  const outlinedButton = {
      borderColor: "#46596A",
      // background-color: #d7dadd,
      color: "#46596A",
      // width: '100%',
      fontFamily: "Hind Siliguri",
      fontSize: "12px",
      fontWeight: "bold",
      marginRight: "10px !important",
  };
    
    let api = config.api.api_url;
    let cncpanels = config.cnc_panel_demand;
    const [dialogData, setDialog] = React.useState([])
    const [downloadData, setDownload] = useState([]);
    let csvlink = useRef(null);
    const [value, setValue] = useState(0);
    let today = new Date();
  let currentUTC =
  today.getUTCFullYear() +
  "-" +
  (today.getUTCMonth() < 9
    ? "0" + (today.getUTCMonth() + 1)
    : today.getUTCMonth() + 1) +
  "-" +
  (today.getUTCDate() < 10 ? "0" + today.getUTCDate() : today.getUTCDate());
 

      useEffect(()=>{
        updateCNCInputs("demand_difference_flag",false);
        getDemandinfoData ();
      },[])
    
   


    
 
    function handleTabs(value) {
        setValue(value)
        updateFlag("unSavedChanges",false)
      }
    
    

     
    //Handle checkbox toggle for the Scenario Checkbox
    const handleScenarioChange = (event) => {
    //   setScenarioFlagState(event.target.checked)
      
     if(event.target.checked){
       updateAlertCNC({open:true,message:config.messages.scenario_flag_enabled,severity:"info"})
     }
    
     else{
       updateAlertCNC({open:true,message:config.messages.scenario_flag_disabled,severity:"info"})
       let override_forecast_header=cncData.override_forecast_header;

       for(let i=0;i<override_forecast_header.length;i++){
          override_forecast_header[i].value="" 
       }
       updateCNCInputs("override_forecast_header", override_forecast_header)
     }
     if(event.target.name ==="Scenario_Flag"){
       updateCNCInputs("scenario_flag_checkbox",event.target.checked);
       handleTabs(0);//if Flag is enabled user has to fill the Override weight and values
     }
    }
    
    
    
    
   

    function formattedDate(date,previous=false) {
        let d =new Date(date)
    let month = previous ? String(d.getMonth()):String(d.getMonth() + 1);
      let day = String(d.getDate());
      const year = String(d.getFullYear());
    
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
    
      return `${year}-${month}-${day}`;
      }
    
  const handleDialog = (value) => {
    setDialog({
        open: true,
        value: value,
        data:cncData.DemandinfoData,
        close: DialoghandleClose,
        handleDownload:handleDownload,
    })
  }
  const DialoghandleClose = () => {
      setDialog({
          ...dialogData,
          open: false,
      });
  };

  const getDemandinfoData=()=>{
    fetch(api + `/html_file?location=cnc_tailwyndz/Help Files/DemandDataFileUploadInfo.html `)
    .then((res) => res.json())
    .then((res)=>{
        if(res.status.statusCode==="cnc-200"){
          updateCNCInputs("DemandinfoData",res.data);
            setDialog(prevState => {
              return {
                  ...prevState,
                  data: res.data,
                  header:"Demand Data Upload  Details"
              }});
        }
        else{
          updateCNCInputs("DemandinfoData",res.data);
          setDialog(prevState => {    
            return {
                ...prevState,
                data: res.data,
                header:"Demand Data Upload  Details"
      
            }});
        }
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });

    
  }

  //Function to Handle download of Sample csv file
  const handleDownload = (event) => {
    fetch(api + "/download_file?location=cnc_tailwyndz/Help Files/sample_demand_data.csv")
    .then((response) => response.json())
    .then((res) => {
      if(res.status.statusCode==="cnc-200"){
        updateCNCInputs("download_data", res.data);
        updateCNCInputs("file_name", "Demand Data Sample.csv");
        setDownload({ ...downloadData, download_ready: true });
        setTimeout(() => {
          csvlink.current.link.click();
        }, 2000);
      }

      else if(res.status.statusCode==="cnc-400"){
        updateAlertCNC({
          open: true,
          message: res.status.statusMessage,
          severity: "info",
          closeFlag:false
        });
      }

      else{
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      }
        
    })
    .catch((error) => {
      updateAlertCNC({
        open: true,
        message: config.messages.unexpected_error,
        severity: "info",
        closeFlag:false
      });
    });
  }

 

  const updateDemand=()=>{
    
    if(cncData.demand_treeData.length===0){
      updateAlertCNC({open:true,message:"Cannot save when demand data is empty",severity:"info"})
      return;
    }
    //Check for empty grade rows for commodities with grade commodity flag set to true

    let empty_flag=false;

    if(cncData.grade_commodity_flag){

      let data=cncData.ancestorData.data//data holds all the data to be updated

      for(let i=0;i<data.length;i++){
        if(data[i].data.level==="factory-grade" &&data[i].data.grade===""){
          empty_flag=true;
          break;
        }
        
        if(data[i].data.level==="factory-grade" ){

          for(let j=0;j<cncData.demand_header.length;j++){
            if(data[i].data[cncData.demand_header[j].label]===""||isNaN(data[i].data[cncData.demand_header[j].label])){
              empty_flag=true;
              break;
            }
          }
          
        }
        
      } 

    }

    if(!cncData.grade_commodity_flag){
      let data=cncData.ancestorData.data//data holds all the data to be updated
      for(let i=0;i<data.length;i++){
        if(data[i].data.level==="factory" ){

          for(let j=0;j<cncData.demand_header.length;j++){
            if(data[i].data[cncData.demand_header[j].label]===""||isNaN(data[i].data[cncData.demand_header[j].label])){
              empty_flag=true;
              break;
            }
          }
          
        }
      } 
    }
 

    if(empty_flag){
      updateAlertCNC({open:true,message:"Fill all the Fields",severity:"info"})
      return;
    }

    else{
      updateCNCInputs("loading",true)
    updateCNCInputs("loading_message","Demand Data Updating...")
    let triggered_time =
      currentUTC +
      " " +
      (today.getUTCHours() < 10
        ? "0" + today.getUTCHours()
        : today.getUTCHours()) +
      ":" +
      (today.getUTCMinutes() < 10
        ? "0" + today.getUTCMinutes()
        : today.getUTCMinutes()) +
      ":" +
      (today.getUTCSeconds() < 10
        ? "0" + today.getUTCSeconds()
        : today.getUTCSeconds());
    let factory_selection=[];
    cncData.factory_selection.filter((factory)=>{factory_selection.push(factory.value)})   

      let input={
        timestamp:triggered_time,
        commodity_selection_value:cncData.commodity_selection_value,
        ancestorData:cncData.ancestorData,
        // prevAncestorData:GlobalAncestorData,
        index_commodity_id:cncData.index_commodity_id,
        user:user.preferred_username,
        decision_month:formattedDate(cncData.month_selection_value),
        geography_id:factory_selection,
      }

    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };
    updateAlertCNC({
      open: true,
      message:config.messages.demand_updating,
      severity: "info",
      closeFlag:false
    });
    fetch(api + "/update_demand", reqOptions)
    .then((response) => response.json())
    .then((response) => {
      updateFlag("unSavedChanges",false)
      if (response.status.statusCode === "cnc-200") {
        
        fetch(api+"/sync_bronze_to_silver?commodity_selection_value="+cncData.commodity_selection_value)
        .then((res)=>(res.json()))
        .then((res)=>{
          if(response.status.statusCode === "cnc-200"){
            updateAlertCNC({
              open: true,
              message:config.messages.demand_update_success,
              severity: "info",
              closeFlag:false
            });
            onCancel()
          }

          else{
            updateAlertCNC({
              open: true,
              message: config.messages.unexpected_error,
              severity: "info",
              closeFlag:false
            });
            onCancel()
          }
        })
        .catch((error) => {
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
          onCancel()
        });
         
          
        }
      else if(response.status.statusCode === "cnc-400"){
        updateAlertCNC({
          open: true,
          message:response.status.statusMessage,
          severity: "info",
          closeFlag:false
        });
        onCancel()
      }  

      else{
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
        onCancel()
      }
      })
      .catch((error) => {
        updateFlag("unSavedChanges",false)
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
        onCancel()
      });
    }   
  }



    return (
      <>
        <div className="cnc-models-menu">
          {cncpanels.map((item, index) => (
            <div
              key={item}
              className="models-item"
              onClick={() => {
                  handleTabs(index);
              }}
            >
              <div
                className={`models-item-div ${index === value ? "active" : ""}`}
              >
                <p>{item}</p>
              </div>
            </div>
          ))}
          {cncData.scenarioFlag && cncData.mode !== "view" && (
            <div className="cnc-scenario-flag">
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        size="medium"
                        checked={cncData.scenario_flag_checkbox}
                        disabled={cncData.mode === "create" ? false : true}
                        onChange={handleScenarioChange}
                        name="Scenario_Flag"
                      />
                    }
                    label="Scenario"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </div>
          )}
          <IconButton aria-label="info" style={{marginLeft: "-20px"}} onClick={() => handleDialog("Demand info")}>
              <i className="material-icons-outlined" style={{ fontSize: 20, fontWeight: 800 }} >info</i>
           </IconButton>
           {dialogData.open && <Dialogs props={dialogData}></Dialogs>} 
        </div>
        {cncData.mode !== "view" && (
          <>
            {value === 1 && (
              <>
                <Box sx={{ minWidth: 275, padding: 10 }}>
                  <Card
                    variant="outlined"
                    style={{
                      padding: 10,
                      maxHeight: "310px",
                      // height:cncData.loading &&"290px",
                      // backgroundColor: "#f9f9f9",
                    }}
                  >
                    <FileUpload onClear={onCancel}/>
                  </Card>
                </Box>
              </>
            )}

            {value === 0 && (
              <>
                {/* <div
                  style={{
                    padding: "3px",
                    maxHeight: cncData.expand ? "300px" : "700px",
                    marginBottom: "23px",
                  }}
                > */}
                  <>
                    {/* <Box sx={{ minWidth: 275, padding: 10 }}> */}
                      <Card
                        variant="outlined"
                        style={{
                          padding: 0,
                          maxHeight: (!cncData.loading &&!cncData.expand) ?"65vh":"44vh",
                          height:
                            cncData.loading
                               &&
                            "44vh",
                        }}
                      >
                        {cncData.loading ? (
                          <center>
                            <Loader />
                            <div>
                            <p style={{textAlign:"center"}}>{(cncData.loading_message &&cncData.loading_message!=="")?cncData.loading_message: "Fetching demand data..."}</p>
                            </div>
                          </center>
                        ) : (
                          <>
                          {
                            cncData.grade_commodity_flag?
                            cncData.demand_treeData.length > 0 ? (
                            <UploadDemandTreeTable
                                 month_options={cncData.demand_header}
                              />
                            ):(
                              <div className="no-commodity">
                                <img
                                  src={nocommodity}
                                  alt=""
                                  className="cnc-img"
                                />
                                {
                                        cncData.fetch_status?(
                                          <p style={{ marginLeft: "25px" }}>
                                        No data available
                                        </p>
                                        ):(
                                          <p style={{ marginLeft: "25px" }}>
                                         {cncData.errorMessage}
                                          </p>
                                        )
                                }
                              </div>
                            )
                              
                              :
                             cncData.demand_treeData.length > 0 ? (
                              <TreeTableComponent
                                data={cncData.demand_treeData}
                                month_options={cncData.demand_header}
                                demandFlag={false}
                              />
                            ) : (
                              <div className="no-commodity">
                                <img
                                  src={nocommodity}
                                  alt=""
                                  className="cnc-img"
                                />
                                {
                                        cncData.fetch_status?(
                                          <p style={{ marginLeft: "25px" }}>
                                        No data available
                                        </p>
                                        ):(
                                          <p style={{ marginLeft: "25px" }}>
                                         {cncData.errorMessage}
                                          </p>
                                        )
                                }
                              </div>
                            )
                          } 
                          </>
                        )}
                      </Card>
                    {/* </Box> */}
                  </>
                {/* </div> */}
                {
                  <div className="cnc-button-footer">
                    <div className="button-new">
                        <Button
                        autoFocus
                        variant={cncData.loading?"":"outlined"}
                        style={cncData.loading?outlinedButton:outlinedCNCButton}
                        onClick={onCancel}
                        disabled={cncData.loading?true:false}
                      >
                        Cancel
                    </Button>
                    <Button
                        autoFocus
                        style={cncData.loading?outlinedButton:containedButton}
                        onClick={updateDemand}
                        disabled={cncData.loading?true:false}
                      >
                        Update
                    </Button>
                    </div>
                   
                    {/* <Buttons
                      button={[
                        {
                          name: "Cancel",
                          callback: onCancel,
                          flag: disable,
                          style: "outlined",
                        },
                      ]}
                    />
                    <Buttons
                      button={[
                        {
                          name: "Update",
                          callback: updateDemand,
                          flag: disable,
                          style: "contained",
                        },
                      ]}
                    /> */}
                  </div>
                }
              </>
            )}

          {downloadData.download_ready && (
                          <CSVLink
                            data={cncData.download_data}
                            filename={cncData.file_name}
                            className="hidden"
                            ref={csvlink}
                            target="_blank"
                          />
                        )}

          {
            value===0 && allData.unSavedChangesDialogData.open &&(
              <UnSavedConfirmationDialog props={{savefunction:updateDemand,cancelfunction:onCancel}}></UnSavedConfirmationDialog>
            )
          }              
          </>
        )}
      </>
    );
}

BottomSelection.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  cncData: state.cncinputs,
  allData: state.data,
});

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
  updateFlag
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BottomSelection)
);
