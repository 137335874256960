import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
// import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { withStyles} from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { updateCNCDatas,updateAlertCNC,updateFlag } from "../redux/actions";
import { useHistory } from "react-router";

const containedButton = {
    color: "white",
    backgroundColor: "#46596A",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "10px !important"
};
const outlinedButton = {
    borderColor: "#46596A",
    // background-color: #d7dadd,
    color: "#46596A",
    // width: '100%',
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "10px !important",
};
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <i className="material-icons-outlined">close</i>
            </IconButton>
        </MuiDialogTitle>
    );
});



const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


function UnSavedConfirmationDialog (props) {
    
    

    let {
        allData,
        updateCNCDatas,
        updateFlag
    }=props
    const history = useHistory();
    return(
    <Dialog
        aria-labelledby="Calculation Types"
        open={allData.unSavedChangesDialogData.open}
        maxWidth="sm"
        fullWidth
    >
        <DialogTitle onClick={()=>{
            let dialogData={
                ...allData.unSavedChangesDialogData,
                open:false
            }
            updateCNCDatas("unSavedChangesDialogData",dialogData)
        }}>Confirm</DialogTitle>
        <MuiDialogContent dividers>
            <Typography>
              You have <b>Unsaved changes in current screen</b> Do you wish to <b>Save</b> before exiting?
            </Typography>
        </MuiDialogContent>
        <DialogActions>
            <Button style={outlinedButton} onClick={()=>{
                let dialogData={
                    ...allData.unSavedChangesDialogData,
                    open:false
                }
                updateCNCDatas("unSavedChangesDialogData",dialogData)
                history.push({
                    pathname: `/home/${allData.unSavedChangesDialogData.navigateTo}`,
                  });
                  updateFlag("unSavedChanges",false)
               
                }}>
                No
            </Button>
            <Button
                autoFocus
                style={containedButton}
                onClick={() => {
                    let dialogData={
                        ...allData.unSavedChangesDialogData,
                        open:false
                    }
                    updateCNCDatas("unSavedChangesDialogData",dialogData)
                    props.props.savefunction()
                    
                }}
            >
                Yes
            </Button>
        </DialogActions>
    </Dialog>
    )
}


UnSavedConfirmationDialog.propTypes = {
    user: PropTypes.object,
    userData: PropTypes.object,
    cncData: PropTypes.object,
    updateCNCInputs: PropTypes.func,
    updatewholeCNCInputs: PropTypes.func,
    updateAlertCNC: PropTypes.func,
    updateCNCDatas:PropTypes.func,
  };
  
  const mapStateToProps = (state) => ({
    user: state.user,
    cncData: state.cncinputs,
    allData:state.data
  });
  
  const mapDispatchToProps = {
    updateAlertCNC,
    updateCNCDatas,
    updateFlag
  };
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)( UnSavedConfirmationDialog));