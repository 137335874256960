/*
Created on September 2021
Confidential and Proprietary of Reckit
Project: Demand Signal MVP
Component name : Dialog
Component Description : To show dialog on some actions
@author: tailwyndz
*/

import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { default as ReactSelect } from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { components } from "react-select";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Select from "react-select";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {config} from "../../config/config";
import TableComponent from "../Table/table";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { Grid, responsiveFontSizes } from "@material-ui/core";

import "./dialog.scss";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from "@material-ui/core/TableContainer";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Fragment } from 'react'
import Loader from "../Loader/Loader";
import Buttons from "../Button/Buttons";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "auto",
    maxHeight: "100px",
  }),

  option: (provided, state) => ({
    ...provided,
    fontSize: 12,
   fontFamily: "Hind Siliguri !important",
  }),
};
const containedButton = {
  color: "white",
  backgroundColor: "#46596A",
  fontFamily: "Hind Siliguri",
  fontSize: "12px",
  fontWeight: "bold",
  marginLeft: "10px !important",
};
const outlinedButton = {
  borderColor: "#46596A",
  // background-color: #d7dadd,
  color: "#46596A",
  // width: '100%',
  fontFamily: "Hind Siliguri",
  fontSize: "12px",
  fontWeight: "bold",
  marginRight: "10px !important",
};
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {/* {onClose ? ( */}
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <i className="material-icons-outlined">close</i>
      </IconButton>
      {/* ) : null} */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: "600px",
    // width:1500
  },
}))(MuiDialogContent);
const DialogContents = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: "500px",
    // width:1500
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  container: {
    maxHeight: 350
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
      backgroundColor: "#e5e5e5",
      color: "#212529",
      fontSize: 12,
      height: 10, textAlign: 'left'
  },
  body: {
      fontSize: 12, textAlign: 'left', padding: 6
  },
}))(TableCell);

function Dialogs(props) {
  const classAccordion = useStyles();
  let { open, value, modelid } = props.props;
  let {cncData}=props;
  let dialogopen = open;
  const [expanded, setExpanded] = useState("panel1");
  const [onSelectData, setOnSelect] = useState(props.props.type);
  const [onSelectDate, setOnSelectDate] = useState(props.props.date);
  const [onSelectDay, setOnSelectDay] = useState(props.props.day);
  const [onSelectTime, setOnSelectTime] = useState(props.props.time);

  let selectOptions = config.shareOptions;

  let userdata = props.props.userinfo;
  let scheduledModelData = props.props.scheduledModelData;
  let sch_date = onSelectDate;
  let sch_day = onSelectDay;
  let sch_type = onSelectData;
  let sch_time = onSelectTime;

  let deletedata = props.props.deleteRow;
  const selectSchedule = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
  ];
  const selectDay = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];
  const selectDate = [
    {
      value: "01",
      label: "1",
    },
    {
      value: "02",
      label: "2",
    },
    {
      value: "03",
      label: "3",
    },
    {
      value: "04",
      label: "4",
    },
    {
      value: "05",
      label: "5",
    },
    {
      value: "06",
      label: "6",
    },
    {
      value: "07",
      label: "7",
    },
    {
      value: "08",
      label: "8",
    },
    {
      value: "09",
      label: "9",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "17",
      label: "17",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "19",
      label: "19",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "21",
      label: "21",
    },
    {
      value: "22",
      label: "22",
    },
    {
      value: "23",
      label: "23",
    },
    {
      value: "24",
      label: "24",
    },
    {
      value: "25",
      label: "25",
    },
    {
      value: "26",
      label: "26",
    },
    {
      value: "27",
      label: "27",
    },
    {
      value: "28",
      label: "28",
    }
  ];
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  function onSelect(e) {
    setOnSelect(e.value);
    // scheduled_run_type = e.value;
  }

  function scheduleFunction() {
    let data = {
      scheduledDate: onSelectData,
    };
  }
  const [html, setHtml] = useState("");
     // function for multiselect inputs
     const Option = (props) => {
      return (
        <div>
          <components.Option {...props}>
            <input
              id="productDimension"
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            // style={{display:"flex",flexWrap:"wrap"}}
            />{" "}
            <label>
              {props.label}
            </label>
          </components.Option>
        </div>
      );
    };
  return (
    <>
      {
      
      props.props.delete ? (
        <Dialog
          aria-labelledby="Calculation Types"
          open={dialogopen}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle onClick={props.props.close}>Confirm Delete</DialogTitle>
          <MuiDialogContent dividers>
            <Typography>
              Are you sure want to delete the selected {props.props.name} ?
            </Typography>
          </MuiDialogContent>
          <DialogActions>
            <Button style={outlinedButton} onClick={props.props.close}>
              No
            </Button>
            <Button
              autoFocus
              style={containedButton}
              onClick={() => {
                props.props.deleteFunction(deletedata);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      ): props.props.confirm ? (
        <Dialog
          aria-labelledby="Calculation Types"
          open={dialogopen}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle onClick={()=>{props.props.close("close")}}>Confirm {props.props.title}</DialogTitle>
          <MuiDialogContent dividers>
            <Typography>
              {ReactHtmlParser(`${props.props.name}`)} Are you sure you want to proceed?
            </Typography>
          </MuiDialogContent>
          <DialogActions>
            <Button style={outlinedButton} onClick={()=>{props.props.close("close")}}>
              No
            </Button>
            <Button
              autoFocus
              style={containedButton}
              onClick={() => {
                props.props.confirmFunction();
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      ):props.props.selection?(
        <Dialog
        aria-labelledby="Schedule Run"
        open={dialogopen}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle onClose={props.props.close}>Select factories for adding new row</DialogTitle>
        <MuiDialogContent dividers>
          <Grid
            container
            spacing={1}
            style={{ padding: "2px 15px 10px 15px" }}
          >
             <Grid item xs={8} sm={8}>
                <div
                  className="mg-model-header-item"
                  style={{ height: "25px" }}
                >
                  Select Factory:{" "}
                </div>
                <div className="" style={{ marginBottom: "15px" }}>
                  <Select
                    options={props.props.factoryOptions}
                    isMulti
                    
                    value={props.props.factoryOptions.filter((obj) =>
                      cncData.factory_chosen.some(
                        (item) => item.label === obj.label
                      )
                    )}
                    // value={cncData.factoryOptions.filter((obj) =>
                    //   {return obj.label ==cncData.factory_chosen}
                    // )}
                    // menuPortalTarget={document.body}            
                    styles={customStyles}
                    onChange={(event)=>{props.props.handleFactorySelection(event)}}
                    menuPlacement="auto"
                    menuPosition="fixed"
                  />
                </div>
              </Grid>
          </Grid>
        </MuiDialogContent>
        <DialogActions>
          <Button
            autoFocus
            style={containedButton}
            onClick={() => {
                props.props.createFunction(cncData.factory_chosen);
              // scheduleFunction()
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
      ): props.props.share ? (
        // null
        <Dialog
          aria-labelledby="Share Commodity hedging"
          open={dialogopen}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle onClose={props.props.close}>Share Commodity hedging</DialogTitle>
          <DialogContents dividers>
            <div>
              <Select
                options={props.props.users}
                isMulti
                // value={props.props.users.find(
                //   (obj) => obj.value === item.accessType
                // )}
                value = {props.props.users.filter(
                  (obj) =>
                    userdata.some(
                      (item) => item.username=== obj.value
                    )
                )}
                placeholder="Select users to share Commodity Hedging"
                onChange={(event) => {
                  props.props.userFunction(event,userdata);
                }}
              />
            </div>
            {userdata !== undefined && (
              <div className="dss-share-cards" style={{ paddngTop: 10 }}>
                {userdata.length > 0 &&
                  userdata.map((item, id) => (
                    <div className="single-share-card">
                      <div className="share-card-left">
                        <div className="share-card-email">
                          {/* <div className="col-6">  */}
                          <label className="primary-name">{item.label}</label>
                          <label className="secondary-name">
                            {item.username}
                          </label>
                        </div>
                      </div>
                      <div className="share-card-action">
                        <Select
                          options={selectOptions}
                          menuPortalTarget={document.body}
                          value={selectOptions.find(
                            (obj) => obj.value === item.accessType
                          )}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              maxHeight: "90%",
                              fontSize: 14,
                              fontFamily: "Hind Siliguri !important",
                              zIndex: 9999,
                            }),
                          }}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          placeholder="Select user access"
                          onChange={(event) => {
                            props.props.userAccess(event, id, userdata);
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </DialogContents>
          <DialogActions>
            <Button
              autoFocus
              style={containedButton}
              onClick={() => {
                props.props.shareFunction(userdata);
              }}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      ) : props.props.schedule ? (
        // null
        <Dialog
          aria-labelledby="Schedule Run"
          open={dialogopen}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle onClose={props.props.close}>Schedule run</DialogTitle>
          <DialogContents dividers>
            <Grid
              container
              spacing={2}
              style={{ padding: "2px 15px 10px 15px" }}
            >
              {props.props.scheduleFlag && (
                <>
                  <Grid item xs={12} sm={12}>
                    <div className="">
                      <label style={{ fontSize: "10px", color: "red" }}>
                        Model already has other Scheduled Runs. Please check
                        these before scheduling.
                      </label>
                    </div>
                  </Grid>
                </>
              )}
              <Grid item xs={6} sm={6}>
                <div className="">
                  <label>Schedule Run Type</label>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <div className="">
                  <Select
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        maxHeight: "90%",
                        fontSize: 12,
                        fontFamily: "Hind Siliguri !important",
                        zIndex: 9999,
                      }),
                    }}
                    options={selectSchedule}
                    value={selectSchedule.find((obj) => obj.value === sch_type)}
                    placeholder="Select option to schedule run"
                    onChange={(event) => {
                      props.props.selectTypeFunction(event);
                      setOnSelect(event.value);
                      // setOnSelectCounter(1);
                    }}
                  />
                </div>
              </Grid>
              {onSelectData === "monthly" && (
                <>
                  <Grid item xs={6} sm={6}>
                    <div className="">
                      <label>Schedule Run For Every</label>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <div className="">
                      <Select
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            maxHeight: "90%",
                            fontSize: 12,
                            fontFamily: "Hind Siliguri !important",
                            zIndex: 9999,
                          }),
                        }}
                        options={selectDate}
                        placeholder="Select date"
                        value={selectDate.find((obj) => obj.value === sch_date)}
                        onChange={(event) => {
                          props.props.selectDateFunction(event);
                          setOnSelectDate(event.value);
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <div className="">
                      <label>Schedule Run Time </label>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <input
                      class="form-control"
                      type="time"
                      id="appt"
                      name="appt"
                      value={sch_time}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          maxHeight: "90%",
                          fontSize: 14,
                          fontFamily: "Hind Siliguri !important",
                          zIndex: 9999,
                        }),
                      }}
                      onChange={(event) => {
                        props.props.selectTimeFunction(event);
                        setOnSelectTime(event.value);
                      }}
                    ></input>
                  </Grid>
                </>
              )}
              {onSelectData === "weekly" && (
                <>
                  <Grid item xs={6} sm={6}>
                    <div className="">
                      <label>Schedule Run For Every</label>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <div className="">
                      <Select
                        options={selectDay}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            maxHeight: "90%",
                            fontSize: 12,
                            fontFamily: "Hind Siliguri !important",
                            zIndex: 9999,
                          }),
                        }}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        placeholder="Select day"
                        value={selectDay.find((obj) => obj.value === sch_day)}
                        onChange={(event) => {
                          props.props.selectDayFunction(event);
                          setOnSelectDay(event.value);
                        }}
                        
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <div className="">
                      <label>Schedule Run Time </label>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <input
                      class="form-control"
                      type="time"
                      id="appt"
                      name="appt"
                      value={sch_time}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          maxHeight: "90%",
                          fontSize: 14,
                          fontFamily: "Hind Siliguri !important",
                          zIndex: 9999,
                        }),
                      }}
                      onChange={(event) => {
                        props.props.selectTimeFunction(event);
                        setOnSelectTime(event.value);
                      }}
                    ></input>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContents>
          <DialogActions>
            <Button
              autoFocus
              style={containedButton}
              onClick={() => {
                props.props.scheduleFunction();
                // scheduleFunction()
              }}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      ) :props.props.trigger ?(
        <Dialog
        aria-labelledby="Share Model"
        open={dialogopen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle onClose={props.props.close}>MOJO Trigger</DialogTitle>
        <DialogContents dividers>
        <div className="mg-model-header-item" style={{ height: "25px" }}>
                Select the Top Features:
                <p
                  style={{
                    display: "inline",
                    color: "red",
                    fontSize: 16,
                  }}
                >
                  *
                </p>
          </div>
          <div className="" style={{ marginBottom: "15px" }}>
            <Select
              options={props.props.feature_list}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              // defaultValue={props.props.feature_list.length<=20?props.props.feature_list:props.props.feature_list.slice(0,20)}
              value={props.props.feature_list.filter((obj) =>
                props.props.features.some(
                (item) => item.value === obj.value
              )
                )}
              styles={customStyles}
              maxLength={20}
              placeholder="Select Top Features for MOJO"
              onChange={props.props.handleFeatureSelection}
            />

            {props.props.features !== undefined && (
              <div className="dss-share-cards" style={{ paddngTop: 10 }}>
                {props.props.features.length > 0 &&
                  props.props.features.map((item, id) => (
                    <div className="single-share-card">
                      <div className="share-card-left">
                        <div className="share-card-email">
                          {/* <div className="col-6">  */}
                          <label className="primary-name">{item.label}</label>
                          <label className="secondary-name">
                            {item.username}
                          </label>
                        </div>
                      </div>
                      <div className="share-card-action">
                        
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </DialogContents>
        <DialogActions>
          <Button
            autoFocus
            style={containedButton}
            onClick={() => {
              // console.log(props.props)
              // props.props.triggerMojo(props.props.row,props.props.features);
              props.props.triggerMojo(props.props.row,props.props.features,props.props.feature_list);
            }}
          >
            Trigger
          </Button>
        </DialogActions>
      </Dialog>
      ): (
          value === "Demand info" ? (
          <Dialog
            aria-labelledby="Features List"
            open={dialogopen}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle onClose={props.props.close}>Upload Info</DialogTitle>
            <DialogContent dividers>
            {props.props.data!==undefined ?(
                    props.props.data.length ==0 ?
                    (<Loader></Loader>):( 
                <>
                {ReactHtmlParser(`${props.props.data}`)}
                <Buttons
                      button={[
                        {
                          name: "Download Sample File",
                          callback: props.props.handleDownload,
                          flag: false,
                          style: "contained",
                        },
                      ]}
                    />
                </>
                    )
             ):(
                <Loader></Loader>
             )
             
             } 
            </DialogContent>
          </Dialog>
        ) 
        : (
          <Dialog
            aria-labelledby="Advance Setting info"
            open={dialogopen}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle onClose={props.props.close}>
              Advance Setting info
            </DialogTitle>
            <DialogContents dividers>
            {props.props.data2!==undefined ?(
                    props.props.data2.length ==0 ?
                    (<Loader></Loader>):( 
                
                ReactHtmlParser(`${props.props.data2}`)

                    )
             ):(
                <Loader></Loader>
             )
             
             } 
            </DialogContents>
          </Dialog>
        ) 

      ) 
                     
      }
    </>
  );
}

Dialogs.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  // updateCNCInputs: PropTypes.func,
  // updatewholeCNCInputs: PropTypes.func,
  // updateAlertCNC: PropTypes.func,
  // updateCNCDatas:PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  cncData: state.cncinputs,
  allData:state.data
});

const mapDispatchToProps = {
  // updateCNCInputs,
  // updatewholeCNCInputs,
  // updateAlertCNC,
  // updateCNCDatas,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dialogs));
