import React from "react";
import Select from "react-select";


const customStyles = {
  menuPortal: (base) => ({
    ...base,
    maxHeight: "90%",
    fontSize: 12,
    fontFamily: "Hind Siliguri !important",
    zIndex: 9999,
  }),
};

export default function Dropdown(props) {

  let { options, callback, placeholder,updateValue,disabled } = props;

  return (
    <Select
      options={options}
      menuPortalTarget={document.body}
      value={options.filter((obj) => {
        return obj.value === updateValue;
      })}
      styles={customStyles}
      isDisabled={disabled}
      menuPlacement="auto"
      menuPosition="fixed"
      placeholder={placeholder}
      onChange={callback}
    />
  );
}
