// Import required libraries
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
// import {  } from "react";
import {config} from "../../config/config"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useHistory } from "react-router";
// Import custom components

// Import styles
import "./Sidenav.scss";

// Import action creators
import { toggleSidenav,updateAlertCNC,updateCNCInputs,updateCNCDatas } from "../../redux/actions";

// Import data & utils
// import sidenavData from "../../../assets/data/sidenavData.json";

const useStyles = makeStyles(() => ({
  list: {
    width: 500,
  },
  typographyStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    color: "#46596a",
    minWidth: 400,
    maxWidth: 600,
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));
const alertTheme = createMuiTheme({
  palette: {
    success: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
    info: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
    error: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
  },
  "& .MuiAlert-filledSuccess": {
    color: "#46596a",
    //font-weight: 1000,
    backgroundColor: "#f0f2f4",
    width: "800px",
  },
});
function Alert(props) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={alertTheme}>
      <MuiAlert
        className={classes.typographyStyle}
        elevation={6}
        variant="filled"
        {...props}
      />
    </ThemeProvider>
  );
}
function Sidenav(props) {
  const {
    user,
    SidebarItems,
    toggleSidenav,
    updateAlertCNC,
    updateCNCInputs,
    flags,
    updateCNCDatas,
    allData,
    match
  } = props;
  let sidenavData =SidebarItems
  let api = config.api.api_url
  const history = useHistory();
  let admin_screen={
    "id": "admin",
    "name": "Admin Panel",
    "materialUiIconName": "admin_panel_settings",
    "materialUiClassName": "material-icons-outlined",
    "specialStyling": false
    
  }
  //get Commodities
  const getCommodity = () => {
    let commodityOptions = [];
    fetch(api + "/commodities")
      .then((respone) => respone.json())
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          commodityOptions[i] = {
            value: response.data[i].commodity_id,
            label: response.data[i].commodity_name,
            decision_months:response.data[i].decision_months,
            isIndexVisible:response.data[i].isIndexVisible,
            isCountryVisible:response.data[i].isCountryVisible,
            isDemandEditable:response.data[i].isDemandEditable,
            scenarioFlag:response.data[i].scenarioFlag,
            weight_graphs:response.data[i].weight_graphs,
            isExpandable:response.data[i].isExpandable,
            indices:response.data[i].indices,
            report_url:response.data[i].report_url,
            report_params:response.data[i].report_params,
            demand_difference_url:response.data[i].demand_difference_url,
            demand_difference_url_params:response.data[i].demand_difference_url_params,
          }
        }
        updateCNCInputs("commodityOptions", commodityOptions);
      });
  }
  //Close alert snackbar
  const handleClose = () => {
    updateAlertCNC({ ...user.alertInfo, open: false,closeFlag:true });
};
  const handleMenuClose = () => {
    toggleSidenav();
  };


  const handleNavlinkChange=(obj)=>{
    if(obj.id!==flags.currentPageID &&flags.unSavedChanges){
                        
      let dialogData={
        ...allData.unSavedChangesDialogData,
        open:true,
        navigateTo:obj.id
      }
      updateCNCDatas("unSavedChangesDialogData",dialogData)
      history.push({
        pathname: `/home/${flags.currentPageID}`,
      });
    }

    else{
      history.push({
        pathname: `/home/${obj.id}`,
      });
      
    }
  }

  return (
    <>
      <aside
        className={`${user.theme} ${
          user.screen.isSidenavBarExpanded
            ? "sidenav-expanded"
            : "sidenav-collapsed"
        }`}
      >
        <nav>
          <div>
            {
              <div>
                <div
                  className="nav-item-menu disabled-style"
                  onClick={handleMenuClose}
                >
                  <i className={`material-icons-outlined nav-icon-menu`}>
                    reorder
                  </i>
                  <p
                    className={`${
                      user.screen.isSidenavBarExpanded ? "visible" : "hidden"
                    } nav-text-menu`}
                  >
                    Menu
                    {/* <b>Menu</b> */}
                  </p>
                </div>
                {user.sidebarItems.map((obj) => (
                  <NavLink
                    to={!flags.unSavedChanges&&`/home/${obj.id}`||obj.id===flags.currentPageID &&`/home/${obj.id}`}
                    className={`nav-item disabled-style ${
                      obj.specialStyling ? "special" : ""
                    }`}
                    key={obj.id}
                    
                    onClick={()=>{
                      handleNavlinkChange(obj)
                    }}
                    title={obj.name}
                  >
                    <i className={`${obj.materialUiClassName} nav-icon`}>
                      {obj.materialUiIconName}
                    </i>
                    <p
                      className={`${
                        user.screen.isSidenavBarExpanded ? "visible" : "hidden"
                      } nav-text`}
                    >
                      {obj.name}
                    </p>
                  </NavLink>
                ))}
              </div>
            }
          </div>
        </nav>
        {user.role=="admin"&&
        <nav className="admin-container">
          <div>
            {
              <div>
                  <NavLink
                    to={!flags.unSavedChanges&&`/home/admin`||"admin"===flags.currentPageID &&`/home/admin`}
                    className={`nav-item disabled-style ${
                      admin_screen.specialStyling ? "special" : ""
                    }`}
                    onClick={()=>{
                      handleNavlinkChange({id:"admin"})
                    }}
                    key={admin_screen.id}
                    title={admin_screen.name}
                  >
                    <i className={`${admin_screen.materialUiClassName} nav-icon`}>
                      {admin_screen.materialUiIconName}
                    </i>
                    <p
                      className={`${
                        user.screen.isSidenavBarExpanded ? "visible" : "hidden"
                      } nav-text`}
                    >
                      {admin_screen.name}
                    </p>
                  </NavLink>
              </div>
            }
          </div>
        </nav>
        }
      </aside>
      <ThemeProvider theme={alertTheme}>
        <Snackbar
          open={user.alertInfo.open} autoHideDuration={config.autoHideDuration}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} icon={false} severity="success">
            {user.alertInfo.message.split("\n").map((i, key) => {
              return <div key={key}>{i}</div>;
            })}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  flags:state.flags
});

const mapDispatchToProps = {
  toggleSidenav,
  updateAlertCNC,
  updateCNCInputs,
  updateCNCDatas
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
