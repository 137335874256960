import C from "./constants";

export const updateUserInfo = (data) => ({
  type: C.UPDATE_USER_INFO,
  data,
});

export const changeTheme = (theme) => ({
  type: C.CHANGE_THEME,
  theme,
});

export const toggleSidenav = () => ({
  type: C.TOGGLE_SIDENAV,
});

export const updateCNCDatas = (key, data) => ({
  type: C.UPDATE_CNC_DATAS,
  key,
  data,
});
export const updateCNCInputs = (key, data) => ({
  type: C.UPDATE_CNC_INPUTS,
  key,
  data,
});

export const updatewholeCNCInputs = (data) => ({
  type: C.UPDATE_WHOLE_CNC_INPUTS,
  data,
});

export const updateFlag = (key, data) => ({
  type: C.UPDATE_FLAGS,
  key,
  data,
});

export const updateAlertCNC = (data) => ({
  type: C.UPDATE_ALERT_CNC,
  data,
});
export const updateScreen = (data) => ({
  type: C.UPDATE_SCREEN,
  data,
});
export const updateAdminInput = (key, data) => ({
  type: C.UPDATE_ADMIN_INPUTS,
  key,
  data,
});

export const updatewholeAdminInputs = (data) => ({
  type: C.UPDATE_WHOLE_ADMIN_INPUT, 
  data,
});
