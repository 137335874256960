import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./TreeTable.scss";
import { TreeTable, TreeState } from 'cp-react-tree-table';
import { updateCNCInputs, updateAlertCNC, updatewholeCNCInputs ,updateCNCDatas,updateFlag} from '../../redux/actions';
// const TREE_DATA = genData();
// const { TreeTable, TreeState } = cpReactTreeTable;
import { NumericFormat } from 'react-number-format';

function TreeTableComponent(props) {

    let{
        data,
        month_options,
        cncData,
        demandFlag,
        updateCNCInputs,
        updateFlag
    }=props;
    const TREE_DATA = data;
    const treeTableRef = useRef(null);
    const[treeValue,setTreeValue]=useState(TREE_DATA)
    useEffect(()=>{
      if(cncData.demand_treeData.length>0){
        setTreeValue(TreeState.create(cncData.demand_treeData))
      }
    },[cncData.demand_treeData])

    
    useEffect(()=>{
      updateCNCInputs("ancestorData",treeValue)
    },[treeValue])
    const handleOnChange = (newValue) => {
        setTreeValue(newValue);
        updateCNCInputs("ancestorData",newValue)
      }     


      
      const renderIndexCell = (row) => {
        return (
          <div style={{ paddingLeft: (row.metadata.depth * 5) + 'px'}}
            className={row.metadata.hasChildren ? 'with-children' : 'without-children'}>
            
            {(row.metadata.hasChildren)
              ? (
                  <button className={`toggle-button ${!row.$state.isExpanded ? "collapsed" :""}`} onClick={row.toggleChildren}></button>
                )
              : ''
            }
            
            <span>{row.data.name}</span>
          </div>
        );
      }
    
      /*RollUpValues is a recursive function which is used to rollup and sum the input values
      TreeState.expandAncestors(treeValue, rowModel)-->returns all the ancestor rowmodels for the given row
      */
      const RollUpValues=(row,depth,label,value)=>{
        let sum=0;
        const rowModel = treeValue.findRowModel(row);
        let ancestorData=TreeState.expandAncestors(treeValue, rowModel);
          ancestorData.data.filter((obj)=>{
            if(obj.data.parent===row.data.parent &&obj.data.name!==row.data.name){
              sum+=obj.data[label]
            }
          })
          sum+=value;
          if(depth==0)
          {return;}
          let parentRow;
          ancestorData.data.filter((obj)=>{
            if(obj.data.name ===row.data.parent){
              obj.data[label]=sum;
              parentRow=obj;
              return;
            }
          })
          RollUpValues(parentRow,depth-1,label,sum)
      }
      
      function getPreviousMonth(label) {
        const date = new Date(label);
        const prevMonth = date.getMonth() - 1;
        const firstDay = 1;
          const pdate=new Date(date.getFullYear(), prevMonth, firstDay)
         
          const date1=new Date(pdate) .toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
        return date1;
      }
      const renderEditableCell = (row,label) => {
        let month_label=label.split(" ").join('');
         month_label=label+'_flag';
         let demand_label=label+'_diff'
          //  if(cncData.mode==="view")
        //  label =label.split(' ').join('')
        let flag=true;
        //if demand editable  is enabled 
        if(demandFlag){
          flag=true;
        }

        else {
          if(row.data.level==="factory")
            {flag=false;
            }
            else{
              flag=true;
            }
        }
        let value;
        return (
          <>
            <NumericFormat
              value={
                row.data[label] !== undefined &&
                row.data[label] !== "" &&
                row.data[label] !== NaN
                  ? !demandFlag
                    ? parseFloat(row.data[label]).toFixed(0)
                    : parseFloat(row.data[label]).toFixed(0)
                  : ""
              }
              displayType={"number"}
              disabled={
                cncData.mode !== "view" && cncData.mode !== "view-shared"
                  ? flag
                  : true
              }
              valueIsNumericString={true}
              thousandSeparator=","
              thousandsGroupStyle="thousand"
              prefix={""}
              // onValueChange={(values, sourceInfo) => {
              //   console.log(values, sourceInfo);
              // }}
              onValueChange={(event,sourceInfo) => {
                // value=parseInt(event.target.value);
                // console.log(event,event.floatValue)
                row.updateData({
                  ...row.data,
                  [label]: event.floatValue,
                });

                updateCNCInputs("demandDataEdited", true);
                updateFlag("unSavedChanges", true);
                // if (row.metadata.depth > 0) {
                //   RollUpValues(row, row.metadata.depth, label, value);
                // }
              }}
              style={{ padding: "5px", textAlign: "right" }}
              className="table-input"
            />
            {/* <input
              type="text"
              min="0"
              step="any"
              disabled={
                cncData.mode !== "view" && cncData.mode !== "view-shared"
                  ? flag
                  : true
              }
              // value={
              //   row.data[label] !== undefined &&
              //   row.data[label] !== "" &&
              //   row.data[label] !== NaN
              //     ? !demandFlag
              //       ? parseFloat(row.data[label])
              //       : parseFloat(row.data[label]).toFixed(2)
              //     : ""
              // }
              value={
                row.data[label] !== undefined &&
                row.data[label] !== "" &&
                row.data[label] !== NaN
                  ? !demandFlag
                    ? addCommas(removeNonNumeric(parseInt(row.data[label])))
                    : addCommas(removeNonNumeric(parseInt(row.data[label])))
                  : ""
              }
              onChange={(event) => {
                // value=parseInt(event.target.value);
                console.log("removeNon:", removeNonNumeric(event.target.value));
                console.log(
                  "addcommas",
                  addCommas(removeNonNumeric(event.target.value))
                );
                let val = addCommas(removeNonNumeric(event.target.value));
                console.log(parseInt(val));
                row.updateData({
                  ...row.data,
                  [label]: addCommas(removeNonNumeric(event.target.value)),
                });

                updateCNCInputs("demandDataEdited", true);
                updateFlag("unSavedChanges", true);
                if (row.metadata.depth > 0) {
                  RollUpValues(row, row.metadata.depth, label, value);
                }
              }}
              style={{ padding: "5px", textAlign: "right" }}
              className="table-input"
              // title={(row.data[label]!==undefined &&row.data[label]!==""&&row.data[label]!==NaN)?parseFloat(row.data[label]).toFixed(2):''}
            /> */}
            {cncData.demand_difference_flag && (
              <>
                <div
                  className={`${
                    row.data[month_label]
                      ? "triangle_indicator_profit"
                      : "triangle_indicator_loss"
                  }`}
                >
                  <span
                    class="material-icons"
                    style={{
                      fontSize:
                        cncData.max_decision_months > 9 ||
                        cncData.month_options.length > 9
                          ? "16px"
                          : "18px",
                      marginBottom: "5px",
                      marginTop: "4px",
                      visibility:
                        row.data[month_label] === null ? "hidden" : "visible",
                    }}
                  >
                    play_arrow
                  </span>
                </div>

                <div
                  title={
                    row.data[demand_label] !== undefined
                      ? isNaN(row.data[demand_label])
                        ? row.data[demand_label]
                        : `${row.data[demand_label].toFixed(2)}%`
                      : ""
                  }
                  className={`${
                    cncData.max_decision_months > 9 ||
                    cncData.month_options.length > 9
                      ? "difference-div"
                      : "difference-div-normal"
                  }`}
                >
                  (
                  {row.data[demand_label] !== undefined
                    ? isNaN(row.data[demand_label])
                      ? row.data[demand_label]
                      : `${row.data[demand_label].toFixed(2)}%`
                    : ""}
                  )
                </div>
              </>
            )}
          </>
        );
      }

    
    return (
      <>
      {
        cncData.demand_treeData.length>0&&(
          <div className={`${(cncData.max_decision_months >9 ||cncData.demand_header.length>9 )?"treetable-Demand":"treetable-normal"}`}>
          <TreeTable
          value={treeValue}
          ref={treeTableRef}
          onChange={handleOnChange}>
        <TreeTable.Column basis="150px" grow="0"
          renderCell={renderIndexCell}
          renderHeaderCell={() => <span>Region</span>}
          // style={{margin:"5px"}}
          />
         {
             month_options.map((item,id)=>(
                <TreeTable.Column
                renderCell={(row)=>renderEditableCell(row,item.label)}
                renderHeaderCell={()=><span>{item.label}</span>}
                // style={{margin:"5px"}}
                />   
             ))
         
        }
          </TreeTable>
          </div>
        )

        
        
      }
    	
      </>
    );

  
 
}


TreeTableComponent.propTypes = {
    updateAlertCNC: PropTypes.func,
    cncData: PropTypes.object,
   
  };
  
  const mapStateToProps = (state) => ({
    // data:state.dssStateData.data
    cncData: state.cncinputs,
  });
  
  const mapDispatchToProps = {
    updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
  updateFlag
  };
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TreeTableComponent)
  );