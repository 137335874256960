import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { config } from "../../../../config/config";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TablePaginate from "../../../../components/PaginationTable/TablePagination";
import { updateAdminInput, updateAlertCNC } from "../../../../redux/actions";
import Usergroup from "./Usergroup";
import Dialogs from "../../../../components/Dialog/Dialog";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#e5e5e5",
    color: "#212529",
  },
  body: {
    maxWidth: "50%",
    fontSize: 12,
    textOverflow: "ellipsis",
    padding: "10px",
  },
}))(TableCell);
const useStyles = makeStyles({
  table: {
    minWidth: "100%",
  },
  container: {},
});

function DisplayUsers(props) {
  let { admin_data, updateAlertCNC, updateAdminInput } = props;
  // console.log(props)
  let rowsPerpage = config.table_rows.pagination_size;
  const [dialog, setDialog] = useState([]);
  const [dialogData, setDialogData] = useState([]);
  let api = config.api.api_url;
  const [page, setPage] = useState(0);

  function initializeState() {
    // let initialState = [];
    // initialState = admin_data;
    updateAdminInput("activeData", []);
    // initialState.activeData=[];
    let activeSelections = {
      indexSelection: [],
      commoditySelection: [],
      regionSelection: [],
      countrySelection: [],
      factorySelection: [],
    };
    updateAdminInput("activeSelections", activeSelections);
    updateAdminInput("selected_user", "");
    // initialState.selected_user="";
    // updatewholeAdminInputs(initialState);
  }

  //function to set page for changing table row
  const pageChange = (event, value) => {
    setPage(value);
  };
  let page_props = {
    total: admin_data.users_firestore.length,
    page: page,
    changePage: pageChange,
  };
  const openDialog = (id) => {
    updateAdminInput("selected_user", id.preferred_username);
    let activeData = admin_data.activeData;
    activeData = id;
    if (
      id.groups.includes("demand_planner") ||
      (id.groups.includes("commodity_manager") &&
        id.demand_planner_info.commodity.length > 0)
    ) {
      let activeSelections = admin_data.activeSelections;
      let commodity = id.demand_planner_info.commodity[0];
      activeSelections.indexSelection = commodity.indices;
      activeSelections.commoditySelection = [commodity.commodity_id];
      activeSelections.regionSelection = commodity.region;
      activeSelections.countrySelection = commodity.country;
      activeSelections.factorySelection = commodity.factory_id;
    }
    updateAdminInput("planner_info", id.demand_planner_info);
    updateAdminInput("activeData", activeData);
    setDialog({
      open: true,
      data: id,
      type: "edit",
      title: "Edit User -" + id.name,
      close: dialogClose,
    });
  };

  //close after shared or cancel sharing Commodity
  const dialogClose = () => {
    initializeState();
    setDialog({
      ...dialog,
      open: false,
    });
  };

  //delete a user
  const deleteUser = (item) => {
    const reqOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      api + "/user_details?preferred_username=" + item.preferred_username,
      reqOptions
    )
      .then((response) => response.json())
      .then((res) => {
        updateAdminInput("reload_flag", true);
        if (res.status.statusCode === "cnc-200") {
          updateAlertCNC({
            open: true,
            message: "User Deleted Successfully",
            closeFlag: true,
          });
        } else {
          updateAlertCNC({
            open: true,
            message: "User Deletion unsuccessful.please try again",
            closeFlag: true,
          });
        }
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag: false,
        });
      });
    dialogConfirmClose();
  };

  //function to confirm delete
  const handleDialog = (item) => {
    setDialogData({
      open: true,
      delete: true,
      close: dialogConfirmClose,
      deleteFunction: deleteUser,
      deleteRow: item,
      name: "User",
      title: "Delete",
    });
  };

  //close confirm deletion
  const dialogConfirmClose = () => {
    setDialogData({
      ...dialogData,
      open: false,
    });
  };
  const classes = useStyles();
  return (
    <>
      {
        dialog.open && <Usergroup props={dialog}></Usergroup>
        // null
      }
      {dialogData.open && <Dialogs props={dialogData}></Dialogs>}
      {
        <>
          <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table}>
              <TableHead className="table-heading">
                <TableRow>
                  {props.header.map((item, idx) => (
                    <StyledTableCell>{item}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="table_body_scenario">
                {admin_data.users_firestore
                  .slice(page * rowsPerpage, page * rowsPerpage + rowsPerpage)
                  .map((rows, id) => (
                    <TableRow style={{ cursor: "pointer" }} hover={true}>
                      {/* <StyledTableCell>
                            <div className={`runs-icon ${rows.status}`}></div>
                          </StyledTableCell> */}
                      <StyledTableCell
                      // onClick={() => {
                      //   props.handleView(rows);
                      // }}
                      >
                        {rows.name}
                      </StyledTableCell>
                      <StyledTableCell
                      // onClick={() => {
                      //   props.handleView(rows);
                      // }}
                      >
                        {rows.email}
                      </StyledTableCell>
                      <StyledTableCell
                      // onClick={() => {
                      //   props.handleView(rows);
                      // }}
                      >
                        {rows.created_by}
                      </StyledTableCell>
                      <StyledTableCell
                      // className={`${
                      //   props.username !== rows.created_by && "hide-icons"
                      // }`}
                      // onClick={() => {
                      //   // props.username === rows.created_by &&
                      //   //   props.handleDialog(rows);
                      // }}
                      >
                        <i
                          className={`material-icons-outlined`}
                          onClick={() => {
                            openDialog(rows);
                          }}
                        >
                          edit
                        </i>
                        <i
                          className={`material-icons-outlined`}
                          onClick={() => {
                            handleDialog(rows);
                          }}
                        >
                          delete
                        </i>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePaginate data={page_props} />
          </TableContainer>
        </>
      }
    </>
  );
}

DisplayUsers.propTypes = {
  // match: PropTypes.object,
  updateAlertCNC: PropTypes.func,
};

const mapStateToProps = (state) => ({
  admin_data: state.admin_data,
});

const mapDispatchToProps = {
  updateAlertCNC,
  updateAdminInput,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DisplayUsers)
);
