/*
Created on April 2022
Confidential and Proprietary of Reckit
Project: Demand Signal MVP
Component name : PaginationTable
Component Description : To add pagination to tables
@author: tailwyndz
*/

//Import Libraries
import TablePagination from '@material-ui/core/TablePagination';
import {config} from '../../config/config'

export default function TablePaginate(props){
    return(
        <>
        {props.data.show_pageOptions?(
             <TablePagination
             rowsPerPageOptions={[5,10,15]}
             component="div"
             count={props.data.total}
             rowsPerPage={props.data.rowsPerPage?props.data.rowsPerPage:config.table_rows.pagination_size}
             page={props.data.page}
             onChangePage={props.data.changePage}
             onChangeRowsPerPage={props.data.handleChangeRowsPerPage}
         />
        ):(
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={props.data.total}
                rowsPerPage={config.table_rows.pagination_size}
                page={props.data.page}
                onChangePage={props.data.changePage}
                
            />
        )
        }     
        </>
        
    )
}