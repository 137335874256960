/*
Created on September 2021
Confidential and Proprietary of Reckitt
Project: Demand Signal MVP
Component name : Add Scenario - Buttons
Component Description : To display action buttons
@author: Tailwyndz
*/

//Import Libraries
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {config} from "../../config/config";
import { updateCNCInputs, updateAlertCNC, updatewholeCNCInputs } from '../../redux/actions';
import { useHistory } from "react-router";
import { withRouter } from "react-router";
import "./button.scss"

function Buttons(props) {
    let {match, user,allData, cncinputs, updateScenarioInput, 
        // updateUserInfo,
        button,
        updateAlert } = props;
    let api = config.api.api_url
    const containedCNCButton = {
        color: "white",
        backgroundColor: "#46596A",
        fontFamily: "Hind Siliguri",
        fontSize: "12px",
        fontWeight: "bold",
        marginRight:"10px !important"
    }
    const outlinedCNCButton = {
        borderColor: '#46596A',
        // background-color: #d7dadd,
        color: '#46596A',
        // width: '100%',
        fontFamily: "Hind Siliguri",
        fontSize: '12px',
        fontWeight: "bold",
        marginRight: "5px",
    }
    const history = useHistory();
    let today = new Date();
    let usermail = user.email
    let currentDate = today.getFullYear() + "-" + (today.getMonth() < 9 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + "-" + (today.getDate() < 10 ? "0" + (today.getDate()) : (today.getDate()));
    let currentUTC = today.getUTCFullYear() + "-" + (today.getUTCMonth() < 9 ? "0" + (today.getUTCMonth() + 1) : (today.getUTCMonth() + 1)) + "-" + (today.getUTCDate() < 10 ? "0" + (today.getUTCDate()) : (today.getUTCDate()));

    

    //function to handle save the Commodity after validation
    function onSubmit() {
    }

    //function to initilaize all the state values
    function initializeState(initialState){
    }
    //function to clear all the selections done
    function onCancel() {
    }
    return (
        <>
            <div className="button-new" >
                {button.map((item,id)=>(
                    <Button className="button-align" disableRipple  variant={item.style==="contained"?"contained":"outlined"} onClick={()=>item.callback(item.value)} disabled={item.flag} style={item.style==="contained"?containedCNCButton:outlinedCNCButton}>
                        {item.name}
                    </Button>
                ))}
            </div>
        </>
    )
}

Buttons.propTypes = {
    user: PropTypes.object,
    // match: PropTypes.object,
    cncinputs: PropTypes.object,
    updateCNCInputs: PropTypes.func,
    updateAlertCNC: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.user,
    allData:state.data,
    cncinputs: state.cncinputs
})

const mapDispatchToProps = {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Buttons));

// export default Buttons;