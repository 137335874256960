import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useState } from "react";
import {config} from "../../../config/config";
// import Button from '@material-ui/core/Button';
import {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
  updateCNCDatas,
  updateFlag
} from "../../../redux/actions";
import Buttons from "../../../components/Button/Buttons";
import Loader from "../../../components/Loader/Loader";
import nocommodity from "../../../../assets/images/undraw_empty.svg";
import { v4 } from "uuid";
import Card from '@material-ui/core/Card';
import new_entry_2 from "../../../../assets/images/add_new_entry_2.svg"

import UnSavedConfirmationDialog from "../../../utils/UnSavedConfirmationDialog";
import CostInfoFilterSelection from "./components/CostInfoFilterSelection"
import CostInfoTable from "./components/CostInfoTable";
export const CostInfo= (props) => {
  let {
    user,
    cncData,
    allData,
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateFlag,
    match,
    flags
  } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let api = config.api.api_url;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let today = new Date();
  let currentUTC =
    today.getUTCFullYear() +
    "-" +
    (today.getUTCMonth() < 9
      ? "0" + (today.getUTCMonth() + 1)
      : today.getUTCMonth() + 1) +
    "-" +
    (today.getUTCDate() < 10 ? "0" + today.getUTCDate() : today.getUTCDate());
  //function to set page for changing table row
  const pageChange = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let page_props = {
    total: cncData.filteredData ? cncData.filteredData.length : 0,
    page: page,
    changePage: pageChange,
    rowsPerPage:rowsPerPage,
    handleChangeRowsPerPage:handleChangeRowsPerPage,
    show_pageOptions:true
  };



 
  const handleGradeChange=(event,id)=>{
    let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
  
      newFilteredData.map((obj,idx)=>{
        if(obj.id===id){
          obj.grade=event.value
        }
      })
  
      updateCNCInputs("filteredData",newFilteredData)
  }


  const handleCurrencyChange=(event,id)=>{
    let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
  
      newFilteredData.map((obj,idx)=>{
        if(obj.id===id){
          obj.currency=event.value
        }
      })
  
      updateCNCInputs("filteredData",newFilteredData)
  }


  

  //to handle month change 
  const handleMonthChange=(event,id)=>{
    let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
    let name=event.target.name
    let dt=event.target.value
    newFilteredData.map((obj,idx)=>{
      if(obj.id===id){
        obj[name]=dt
      }
    })

    updateCNCInputs("filteredData",newFilteredData)
  }

  

 

  function initializeState() {
    let initialState = [];
    initialState.loading = false;
    initialState.mode = "";
    initialState.commodityOptions = [];
    initialState.commodity_selection = "";
    initialState.commodity_selection_value = "";
    initialState.month_selection = "";
    initialState.month_selection_value = "";
    initialState.month_options = [];
    initialState.isIndexVisible = false;
    initialState.isCountryVisible = false;
    initialState.indexOptions = [];
    initialState.index_commodity_id = "";
    initialState.index_source_weights = [];
    initialState.factory_chosen = [];
    initialState.filteredData = [];
    initialState.filteredDataCopy = [];
    initialState.grade_commodity_flag=false;
    updatewholeCNCInputs(initialState)
  }


  

  useEffect(() => {
    setLoading(true)
    initializeState();
    getCommodity();
    setDemandPlannerInfo();
    updateFlag("currentPageID",match.url.split("/")[2])
  }, []);



  //get Commodities
  const getCommodity = () => {
    let commodityOptions = [];
    fetch(api + "/commodities")
      .then((respone) => respone.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          for (let i = 0; i < response.data.length; i++) {
            commodityOptions[i] = {
              value: response.data[i].commodity_id,
              label: response.data[i].commodity_name,
              decision_months: response.data[i].decision_months,
              isIndexVisible: response.data[i].isIndexVisible,
              isCountryVisible: response.data[i].isCountryVisible,
              indices: response.data[i].indices,
              report_url: response.data[i].report_url,
              report_params: response.data[i].report_params,
              isDairy: response.data[i].isDairy,
              grade_commodity_flag : response.data[i].grade_commodity_flag,
              Grade_options:response.data[i].Grade_options,
              Currency_options:response.data[i].Currency_options
            };
          }
          updateCNCInputs("commodityOptionsAll", commodityOptions);
          setLoading(false)
        } else {
          updateCNCInputs("commodityOptionsAll", []);
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
        updateCNCInputs("commodityOptionsAll", []);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag: false,
        });
      });
  };

  useEffect(()=>{

    if(cncData.commodityOptionsAll !==undefined&&cncData.commodityOptionsAll.length >0 && cncData.commodity_selection_value!==""){
      cncData.commodityOptionsAll.filter((obj)=>{
        if(obj.value ===cncData.commodity_selection_value){
          updateCNCInputs("grade_commodity_flag",obj.grade_commodity_flag)
          updateCNCInputs("isCountryVisible",obj.isCountryVisible)
          updateCNCInputs("isDairy",obj.isDairy)
          updateCNCInputs("isIndexVisible",obj.isIndexVisible)
          updateCNCInputs("Grade_options",obj.Grade_options)
          updateCNCInputs("Currency_options",obj.Currency_options)
        }
      })
    }

  },[cncData.commodityOptionsAll,cncData.commodity_selection_value])

  const setDemandPlannerInfo=()=>{
    if(user.group_add_info!==undefined &&user.group_add_info!==null){
      let commodity=user.group_add_info.commodity;
      let commodityOptions=[];
      for(let i=0;i<commodity.length;i++){
        commodityOptions[i] = {
          value: commodity[i].commodity_id,
          label: commodity[i].commodity_name,
          decision_months:commodity[i].decision_months,
          indices:commodity[i].indices,
          factory_id:commodity[i].factory_id,
          country:commodity[i].country,
          isIndexVisible:commodity[i].isIndexVisible,
        }
      }
      updateCNCInputs("commodityOptions", commodityOptions);
      if(commodityOptions.length ===1){
        updateCNCInputs("commodity_selection",commodityOptions[0].label)
        updateCNCInputs("commodity_selection_value",commodityOptions[0].value)
        // getNext_N_MonthNamesWithYear(commodityOptions[0].decision_months);
        let indexOptions=[];
        let e=commodityOptions[0];
          for (let i = 0; i < e.indices.length; i++) {
            indexOptions[i] = {
              label: e.indices[i].index_name,
              value: e.indices[i].index_commodity_id,
              source_weights: e.indices[i].source_weight
            }
          }
          if (indexOptions.length === 1) {
            updateCNCInputs("index_commodity_id", indexOptions[0].value)
            updateCNCInputs("index_source_weights",indexOptions[0].source_weights)
          }
          updateCNCInputs("indexOptions", indexOptions);
          let countryOptions=[];
          let country=e.country;
          for (let i = 0; i < country.length; i++) {
            countryOptions[i] = {
              value: country[i],
              label: country[i],
              geography_level:"country",
            }
          }
          if(countryOptions.length ===1){
            updateCNCInputs("country_selection", [countryOptions[0]]);
          }
            updateCNCInputs("countryOptions",countryOptions);    
      }
    }
  }

    //to handle row edit,save,cancel
    const handleRowEdit=(id,type,index)=>{
        if(!flags.unSavedChanges)
        updateFlag("unSavedChanges",true) //to notify some changes are being done in screen
        let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
        
        if(type==="edit"){
          newFilteredData.map((obj,idx)=>{
            if(obj.id===id){
              
              obj.edit_flag=true
            }
          })
          updateCNCInputs("filteredData",newFilteredData)
        }
    
        else if(type==="save"){
          let isValid=false;//used to check whether row has all fields selected
          let notDuplicate=false;//used to check whether row is not duplicate
          let Row;
          newFilteredData.map((obj,idx)=>{
            if(obj.id===id){
              Row=obj;
              if (
                  obj.geography === ""||obj.grade===""||obj.contract_month==="" || isNaN(obj.total_overhead_cost) ||obj.total_overhead_cost===""||obj.total_overhead_cost===null||obj.currency===""
              ){
                updateAlertCNC({
                  open: true,
                  message: "Fill all Mandatory Fields",
                  severity: "info",
                  closeFlag: false,
                });
              }

              else{
                obj.edit_flag = false;
                isValid = true;
              }
              
            }
          })

          notDuplicate=checkforDuplicateRow(Row)

          if(isValid &&notDuplicate){
              updateCNCInputs("filteredData",newFilteredData)

            //update the changed row in  filterDataCopy as well
            let index;
            let filteredDataCopy=JSON.parse(JSON.stringify(cncData.filteredDataCopy))
            filteredDataCopy.map((obj,idx)=>{
              if(obj.id===id){
                index=idx
              }
            })
            filteredDataCopy[index]={...newFilteredData[index]}
            updateCNCInputs("filteredDataCopy",filteredDataCopy)
          }

          else{
            return;
          }
          
        }
    
        else{
         let isEmptyRow=false;
         let index;
         let filteredDataCopy=JSON.parse(JSON.stringify(cncData.filteredDataCopy))
         newFilteredData.map((obj,idx)=>{
          if(obj.id===id){
            // index=idx
            obj.edit_flag=false
          }
        })

          //check the copy arr for the empty row as well
          filteredDataCopy.map((obj,idx)=>{
            if(obj.id===id){
              index=idx
              if (
                obj.geography === ""&&obj.grade===""&&obj.contract_month==="" &&(isNaN(obj.total_overhead_cost) ||obj.total_overhead_cost===""||obj.total_overhead_cost===null)&&obj.currency===""      
              ){
                isEmptyRow=true
                return;
              }
              else{
                obj.edit_flag=false
              }
              
            }
          })

          //if all values of the row are empty and user click cancel remove the row else replace row from copy array
          if(isEmptyRow){
            let newArr=newFilteredData.filter((obj,idx)=>{
              return index!==idx
            })
            updateCNCInputs("filteredData",newArr) 
            
            let newCopyArr=filteredDataCopy.filter((obj,idx)=>{
              return index!==idx
            })
            updateCNCInputs("filteredDataCopy",newCopyArr) 
          }

          else{

              for(let i=0;i<newFilteredData.length;i++){
                if(newFilteredData[i].id===id){
                  newFilteredData[i]={...cncData.filteredDataCopy[index]}
                  break;
                }
              }
              updateCNCInputs("filteredData",newFilteredData) 
          }

       
        }  
      }


      function formattedDate(date) {
        let d = new Date(date)
        d=convertToUTC(d)
        let month = String(d.getMonth() + 1);
        let day = '01';
        const year = String(d.getFullYear());
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '01';
        return `${year}-${month}`;
      } 

 const convertToUTC=(last_date)=>{    
    last_date =new Date(last_date)
      if (last_date.getMonth() === 1) {
        if ((last_date.getFullYear() % 100 === 0) ? (last_date.getFullYear() % 400 === 0) : (last_date.getFullYear() % 4 === 0)) {
            if (last_date.getDate() === 29) {
                last_date.setDate(last_date.getDate() + 1);
                last_date.setHours(0, 0, 0, 0);
            }
        }
        else if (last_date.getDate() === 28) {
            last_date.setDate(last_date.getDate() + 1)

            last_date.setHours(0, 0, 0, 0);
        }
    }
    else if (last_date.getMonth() === 0 || last_date.getMonth() === 2 || last_date.getMonth() === 4 || last_date.getMonth() === 6 || last_date.getMonth() === 7 || last_date.getMonth() === 9 || last_date.getMonth() === 11) {
        if (last_date.getDate() === 31) {
            last_date.setDate(last_date.getDate() + 1);
            last_date.setHours(0, 0, 0, 0);
        }
    }
    else {
        if (last_date.getDate() === 30) {
            last_date.setDate(last_date.getDate() + 1);
            last_date.setHours(0, 0, 0, 0);
        }
    }
    return last_date;
  }

 
 const validateRow=()=>{
    if (cncData.filteredData !== undefined && cncData.filteredData.length > 0) {
      for (let i = 0; i < cncData.filteredData.length; i++) {
        let row = { ...cncData.filteredData[i] };
        if (
           row.date === "" ||isNaN(row.total_overhead_cost)||row.total_overhead_cost===""||row.total_overhead_cost===null
        ) {
          return false;
        }
      }

      return true;
    }
    
  }

  const checkforDuplicateRow=(row)=>{

    let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData));
    let row_date=new Date(row.contract_month)
    let row_date_utc=convertToUTC(row_date)
    for(let i=0;i<newFilteredData.length;i++){
      if(row.id!==newFilteredData[i].id){
        let filter_row_date=new Date(newFilteredData[i].contract_month) 
        let filter_row_date_utc=convertToUTC(filter_row_date)
          if(newFilteredData[i].geography===row.geography&&newFilteredData[i].grade===row.grade&&row_date_utc.toString()===filter_row_date_utc.toString())
          {
            updateAlertCNC({
              open: true,
              message: "Cannot add a Duplicate row,There is already a row with current selection ",
              severity: "info",
              closeFlag: false,
            });
            return false
          }
        
      }
     
    }

    return true


  }
 
 const addNewRow=(isEmpty=false)=>{
    if(!flags.unSavedChanges)
    updateFlag("unSavedChanges",true) //to notify some changes are being done in screen
    //isEmpty is used when no execution data available & a new empty row needs to be added
    if(isEmpty){
      let newRow={
            id:v4(),
            index_commodity_id:"",
            geography:"",
            grade:"",
            contract_month:"",
            total_overhead_cost:"",
            currency:"",
            user:"",
            timestamp:"",
            edit_flag:true,
            exisiting_data:false
        }
      
  
      
      let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
      newFilteredData.push(newRow);
      updateCNCInputs("filteredData",newFilteredData)
  
      //push the new row in filteredDataCopy as well
      let newFiltereddatacopy=JSON.parse(JSON.stringify(cncData.filteredDataCopy))
      newFiltereddatacopy.push(newRow);
      updateCNCInputs("filteredDataCopy",newFiltereddatacopy)
      
    }

    else{
      if(!validateRow()){
        updateAlertCNC({
          open: true,
          message: config.messages.fill_mandatory,
          severity: "info",
          closeFlag: false,
        });
      }

      if(checkforeditrowsOnly()){
        updateAlertCNC({
          open: true,
          message: "Save already editing rows before adding new row",
          severity: "info",
          closeFlag: false,
        });
      }

  
      else{

        let newRow={
          id:v4(),
          index_commodity_id:"",
          geography:"",
          grade:"",
          contract_month:"",
          total_overhead_cost:"",
          currency:"",
          user:"",
          timestamp:"",
          edit_flag:true,
          exisiting_data:false
        }
    
        
        let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
         //before adding a new row reset edit flag to false for other rows(if any)
         newFilteredData.map((obj)=>{
          if(obj.edit_flag){
            obj.edit_flag=false
          }
        })
        newFilteredData.push(newRow);

        
        updateCNCInputs("filteredData",newFilteredData)
    
        //push the new row in filteredDataCopy as well
        
        let newFiltereddatacopy=JSON.parse(JSON.stringify(cncData.filteredDataCopy))
        newFiltereddatacopy.push(newRow);
        updateCNCInputs("filteredDataCopy",newFiltereddatacopy)
      }
    }

   
 
  }

  function onCancel() {
    setLoading(true)
    updateCNCInputs("commodity_selection","")
    updateCNCInputs("commodity_selection_value","")
    updateCNCInputs("index_commodity_id", "")
    initializeState();
    getCommodity();
    setDemandPlannerInfo();
    updateFlag("unSavedChanges",false)
    history.push({
      pathname: `/home/costInfo`,
    });
  } 

 
  //to handle input changes for (input fields) ie)Cost Info value
  const handleInputchange=(event,id)=>{
    let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
    let name=event.target.name
    newFilteredData.map((obj,idx)=>{
      if(obj.id===id){
        obj[name]=parseFloat(event.target.value)
      }
    })

    updateCNCInputs("filteredData",newFilteredData) 
  }

    //to handle factory change in table 
    const handleTableCountryChange=(event,id)=>{
      // let newFilteredData=[...cncData.filteredData];
      let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
  
      newFilteredData.map((obj,idx)=>{
        if(obj.id===id){
          obj.geography=event.value
        }
      })
  
      updateCNCInputs("filteredData",newFilteredData)
    }

  //this function is used to check whether any rows is currently being edited
  const checkforeditrows=()=>{
    let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
    let hasEditRow=false; 
    
      for(let i=0;i<newFilteredData.length;i++){
        if(newFilteredData[i].edit_flag){
          hasEditRow=true
          break
        }
      }

      if(hasEditRow){
        updateAlertCNC({
          open: true,
          message:"Cannot save when rows are being edited",
          severity: "info",
          closeFlag:false
        });
      }

      else{
       saveCostInfoData()
      }
       
        
  }

  //this function is used to check whether any rows is currently being edited
  const checkforeditrowsOnly=()=>{
    let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
    let hasEditRow=false; 
    
      for(let i=0;i<newFilteredData.length;i++){
        if(newFilteredData[i].edit_flag){
          hasEditRow=true
          break
        }
      }

      if(hasEditRow){
        updateAlertCNC({
          open: true,
          message:"Cannot save when rows are being edited",
          severity: "info",
          closeFlag:false
        });
        return true
      }

      return false    
        
  }




  const saveCostInfoData=()=>{
    updateCNCInputs("loading",true)
    updateCNCInputs("loading_message","Costing Information Data Uploading...")
    let triggered_time =
    currentUTC +
    " " +
    (today.getUTCHours() < 10
      ? "0" + today.getUTCHours()
      : today.getUTCHours()) +
    ":" +
    (today.getUTCMinutes() < 10
      ? "0" + today.getUTCMinutes()
      : today.getUTCMinutes()) +
    ":" +
    (today.getUTCSeconds() < 10
      ? "0" + today.getUTCSeconds()
      : today.getUTCSeconds());

  let filteredData=JSON.parse(JSON.stringify(cncData.filteredData)) 
  //updating date format of date while sending to table 
  filteredData.map((obj)=>{
    if (obj.contract_month.includes("T")){
      obj.contract_month=obj.contract_month.split("T")[0]
    }
    else{
      obj.contract_month=obj.contract_month+'-01'
    }
  })  

  let input={
    timestamp:triggered_time,
    commodity_selection_value:cncData.commodity_selection_value,
    index_commodity_id:cncData.index_commodity_id,
    user:user.preferred_username,
    filteredData:filteredData,
    type:cncData.type_selection_value
  }

  let reqOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };
  updateAlertCNC({
    open: true,
    message:"Costing Information data will be uploaded shortly",
    severity: "info",
    closeFlag:true
  });
  fetch(api + "/update_cost_info", reqOptions)
  .then((response) => response.json())
  .then((response) => {
    updateFlag("unSavedChanges",false)
    setPage(0)
    if (response.status.statusCode === "cnc-200") {
      
      fetch(api+"/sync_cost_info_data?commodity_selection_value="+cncData.commodity_selection_value)
          .then((res)=>(res.json()))
          .then((res)=>{
            updateFlag("unSavedChanges",false)
            setLoading(true)
            if(response.status.statusCode=== "cnc-200"){
              updateAlertCNC({
                open: true,
                message:"Costing Information data uploaded successfully",
                severity: "info",
                closeFlag:true
              });
              updateCNCInputs("loading",false)
              initializeState();
              getCommodity();
              setDemandPlannerInfo()
            }

            else{
              updateAlertCNC({
                open: true,
                message: config.messages.unexpected_error,
                severity: "info",
                closeFlag:false
              });
              updateCNCInputs("loading",false)
              initializeState();
              getCommodity();
              setDemandPlannerInfo()
            }
    
          })
          .catch((error) => {
            setLoading(true)
            updateAlertCNC({
              open: true,
              message: config.messages.unexpected_error,
              severity: "info",
              closeFlag:false
            });
            updateCNCInputs("loading",false)
            updateFlag("unSavedChanges",false)
            initializeState();
            getCommodity();
            setDemandPlannerInfo()
          });     
    }

    else if(response.status.statusCode === "cnc-400"){
      setLoading(true)
      updateCNCInputs("loading",false)
      updateAlertCNC({
        open: true,
        message:response.status.statusMessage,
        severity: "info",
        closeFlag:false
      });
      initializeState();
      getCommodity();
      setDemandPlannerInfo()
      history.push({
        pathname: `/home/costInfo`,
      });
    }
  })
  .catch((error)=>{
    setLoading(true)
    setPage(0)
    updateCNCInputs("loading",false)
    updateFlag("unSavedChanges",false)
    updateAlertCNC({
      open: true,
      message:config.messages.unexpected_error,
      severity: "info",
      closeFlag:false
    });
    initializeState();
    getCommodity();
    setDemandPlannerInfo()
    history.push({
      pathname: `/home/costInfo`,
    });
  })
  }


  return (
    <>
      {loading ? (
        <Loader />
      ) : user.group_add_info !== undefined && user.group_add_info !== null ? (
        (cncData.grade_commodity_flag)?
        <>
          <CostInfoFilterSelection setPage={setPage} />
          {cncData.loading ? (
            <>
              <Card
                variant="outlined"
                style={{
                  padding: 0,
                  maxHeight:
                    !cncData.loading && !cncData.expand ? "65vh" : "45vh",
                  height: cncData.loading && "290px",
                }}
              >
                <Loader />
                <p style={{ textAlign: "center" }}>
                  {cncData.loading_message && cncData.loading_message !== ""
                    ? cncData.loading_message
                    : "Fetching Costing Information data..."}
                </p>
              </Card>
            </>
          ) : (
            <>
              {cncData.commodity_selection_value !== "" &&
                cncData.index_commodity_id !== "" && (
                  <>
                    {
                      <>
                        {cncData.filteredData !== undefined &&
                        cncData.filteredData.length > 0 ? (
                          <>
                            <CostInfoTable
                              component="CostInfo"
                              filteredData={cncData.filteredData}
                              page_props={page_props}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              handleRowEdit={handleRowEdit}
                              handleInputchange={handleInputchange}
                              handleMonthChange={handleMonthChange}
                              handleGradeChange={handleGradeChange}
                              handleCurrencyChange={handleCurrencyChange}
                              handleTableCountryChange={handleTableCountryChange}
                              formattedDate={formattedDate}
                            />

                            {
                              <div
                                // className="cnc-button-footer"
                                style={{
                                  position: "absolute",
                                  bottom: "30px",
                                  right: "30px",
                                }}
                              >
                                <div className="jss546">
                                  <Buttons
                                    button={[
                                      {
                                        name: "Cancel",
                                        callback: () => {
                                          onCancel();
                                        },
                                        flag: false,
                                        style: "outlined",
                                      },
                                    ]}
                                  />
                                  <Buttons
                                    button={[
                                      {
                                        name: "Add New",
                                        callback: () => {
                                          addNewRow(false);
                                        },
                                        flag: false,
                                        style: "contained",
                                      },
                                    ]}
                                  />
                                  <Buttons
                                    button={[
                                      {
                                        name: "Save",
                                        callback: checkforeditrows,
                                        flag: false,
                                        style: "contained",
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                            }
                          </>
                        ) : (
                          <>
                            <Card
                              variant="outlined"
                              style={{
                                maxHeight: "45vh",
                              }}
                            >
                              {/* {cncData.fetch_status ? ( */}
                                <div
                                  className="no-commodity"
                                  style={{ padding: "3%" }}
                                >
                                  <img
                                    src={new_entry_2}
                                    alt=""
                                    className="cnc-img"
                                    style={{
                                      height: "25vh",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      addNewRow(true);
                                    }}
                                  />

                                  <p
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Click add icon for creating new entries
                                  </p>
                                </div>
                              {/* ) : (
                                <div className="no-commodity">
                                  <img
                                    src={nocommodity}
                                    alt=""
                                    className="cnc-img"
                                  />
                                  <p
                                    style={{
                                      marginLeft: "25px",
                                      width: "120%",
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {cncData.errorMessage
                                      ? cncData.errorMessage
                                      : "No Costing Information data available"}
                                  </p>
                                </div>
                              )} */}
                            </Card>
                            <div
                              // className="cnc-button-footer"
                              style={{
                                position: "absolute",
                                bottom: "30px",
                                right: "30px",
                              }}
                            >
                              <div className="jss546">
                                <Buttons
                                  button={[
                                    {
                                      name: "Cancel",
                                      callback: () => {
                                        onCancel();
                                      },
                                      flag: false,
                                      style: "outlined",
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    }
                  </>
                )}
            </>
          )}

          {allData.unSavedChangesDialogData.open && (
            <UnSavedConfirmationDialog
              props={{
                savefunction: checkforeditrows,
                cancelfunction: onCancel,
              }}
            ></UnSavedConfirmationDialog>
          )}
        </>
        :
        <>
            <div className="no-commodity">
            <img src={nocommodity} alt="" className="cnc-img" />
            <p>This Feature is Not supported for <b>{cncData.commodity_selection}</b></p>
          </div>
        </>
      ) : (
        <>
          <div className="no-commodity">
            <img src={nocommodity} alt="" className="cnc-img" />
            <p>No commodity configured for the user</p>
          </div>
        </>
      )}
    </>
  );
}

CostInfo.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
  updateFlag: PropTypes.func,
}

const mapStateToProps = (state) => ({
    user: state.user,
    cncData: state.cncinputs,
    allData: state.data,
    flags:state.flags,
})

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
  updateFlag
}

export default connect(mapStateToProps, mapDispatchToProps)(CostInfo)