import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import { config } from "../../../../config/config";
// import Loader from "../../../components/Loader/Loader";
// import TableComponent from "../../../components/Table/table";


//actions
import {
  updateAlertCNC,
  updateCNCInputs,
  updateAdminInput,
} from "../../../../redux/actions";


let api = config.api.api_url;

function AddUserGroup(props) {
  let {
    updateAlertCNC,
    updateAdminInput,
    admin_data,
    onCancel,
    initializeState
  } = props;

  const containedButton = {
    color: "white",
    backgroundColor: "#46596A",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "10px !important",
    marginRight: 5
  };
  const outlinedButton = {
    borderColor: "#46596A",
    // background-color: #d7dadd,
    color: "#46596A",
    // width: '100%',
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: 5,
  };

  const onLabelChange = (event) => {
    console.log(event);
    updateAdminInput("group_label", event.target.value);
  }

  const onValueChange = (event) => {
    updateAdminInput("group_value", event.target.value);
  }


  


  const onSave = () => {
    if (admin_data.group_label!=='' && admin_data.group_value !=='') {
      let groupdata ={label :admin_data.group_label,value:admin_data.group_value}
      let reqOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(groupdata),
      };
      fetch(api + "/admin_configs", reqOptions)
        .then((res) => res.json())
        .then((res) => {
          if (res.status.statusCode === "cnc-200") {
            updateAlertCNC({
              open: true,
              message: "New User Group added successfully",
              severity: "info",
              closeFlag: false
            });
            updateAdminInput("type_selection_value", "");
            initializeState();
          }

          else {
            updateAlertCNC({
              open: true,
              message: "Sorry Couldnt Save Groups.Please try again...",
              severity: "info",
              closeFlag: false
            });
            updateAdminInput("type_selection_value", "");
            initializeState();
          }

        })
        .catch((error)=>{
          updateAlertCNC({
            open: true,
            message: "Sorry Couldnt Save Groups.Please try again...",
            severity: "info",
            closeFlag: false
          });
          updateAdminInput("type_selection_value", "");
          initializeState();
        })
    }

    else {
      updateAlertCNC({
        open: true,
        message: "Please fill all mandatory fields..",
        severity: "info",
        closeFlag: true
      });
    }
  }

  

  return (
    <>
      <div className="admin-util-header">
        <Grid container spacing={1} className="grid-container-style">
          <Grid item xs={4} sm={4}>
            <div className="">
              <label>
                Group Name
                <span
                  style={{
                    display: "inline",
                    color: "red",
                    fontSize: 12,
                  }}
                ></span>
                <p className="mandatory-field">*</p>
              </label>
            </div>
            <div className="">
              <input
                type="text"
                autoComplete="off"
                value={admin_data.group_label}
                className="form-control"
                id="scenario"
                placeholder="Enter Group Label"
                name="scenario"
                onChange={onLabelChange}
              ></input>
            </div>
          </Grid>
          <Grid item xs={4} sm={4}>
            <div className="">
              <label>
                Group ID
                <span
                  style={{
                    display: "inline",
                    color: "red",
                    fontSize: 12,
                  }}
                ></span>
                <p className="mandatory-field">*</p>
              </label>
            </div>
            <div className="">
              <input
                type="text"
                autoComplete="off"
                value={admin_data.group_value}
                className="form-control"
                id="scenario"
                placeholder="Enter Group Value"
                name="scenario"
                onChange={onValueChange}
              ></input>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* {admin_data.groupData !== undefined &&
        admin_data.groupData.length > 0 && (
          <div className="admin-util-header">
            <div className="admin-share-cards" style={{ paddngTop: 10 }}>
              <div className="admin-single-share-card">
                <div className="share-card-left">
                  <div className="share-card-email">
                    <label
                      style={{ fontWeight: 800, fontSize: "16px" }}
                      className="primary-name"
                    >
                      Group Name
                    </label>
                  </div>
                </div>
                <div className="share-card-left">
                  <div className="share-card-email">
                    <label
                      style={{ fontWeight: 800, fontSize: "16px" }}
                      className="primary-name"
                    >
                      Group Id
                    </label>
                  </div>
                </div>
                <div className="share-card-action">
                  <label
                    style={{ fontWeight: 800, fontSize: "16px" }}
                    className="primary-name"
                  ></label>
                </div>
              </div>
              {admin_data.groupData.length > 0 &&
                admin_data.groupData.map((item, id) => (
                  <div className="admin-single-share-card">
                    <div className="share-card-left">
                      <div className="share-card-email">
                        <label className="primary-name"
                          style={{ fontSize: "15px" }}
                        >
                          {item.role_name}
                        </label>
                      </div>
                    </div>
                    <div className="share-card-left" style={{ marginLeft: "45px" }}>
                      <div className="share-card-email">
                        <label className="primary-name"
                          style={{ fontSize: "15px", marginLeft: "15px" }}
                        >
                          {item.role_id}
                        </label>
                      </div>
                    </div>
                    <div className="share-card-action">
                      <i
                        className={"material-icons-outlined"}
                        onClick={() => { removeGroup(item.id) }}
                      >
                        delete
                      </i>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )} */}

      <div className="cnc-button-footer">
        <div>
          {/* <div className="jss546" style={{ marginTop: 30 }}>
            <Button
              className="jss2372"
              variant="contained"
              style={containedButton}
              onClick={addGroup}
            >
              Add Group
            </Button>
          </div> */}
          <div className="jss546" style={{ marginTop: 30 }}>
            <Button
              disableRipple
              className="jss275"
              variant="outlined"
              style={outlinedButton}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              disableRipple
              className="jss2372"
              variant="contained"
              style={containedButton}
              onClick={onSave}
            >
              {"Add Group"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}


AddUserGroup.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  updateAlertCNC: PropTypes.func,
  updateAdminInput: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  modelInputs: state.modelInputs,
  admin_data: state.admin_data
});

const mapDispatchToProps = {
  updateCNCInputs,
  updateAlertCNC,
  updateAdminInput,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddUserGroup)
);