import C from "../constants";
export default function cncinputs(state={},payload){
    let { type, key, data } = payload;
    switch(type){
        case C.UPDATE_WHOLE_CNC_INPUTS:
            return data;
        case C.UPDATE_CNC_INPUTS :
            return {
                ...state,
                [key]:data
              };
        default:
            return state
    }
}