/*
Created on March 2022
Confidential and Proprietary of Reckitt
Project: CNC
Screen name : AddCommodity
Screen Description : 
@author: Tailwyndz
*/

//Import Libraries
import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useState } from 'react';
import {config} from '../../../config/config';
import { withRouter } from "react-router";
import { updateCNCInputs, updateAlertCNC, updatewholeCNCInputs ,updateCNCDatas} from '../../../redux/actions';
//import styles
import "./addcommodity.scss";
import Loader from '../../../components/Loader/Loader';
import MetaSelection from './components/MetaSelection';
import BottomSelection from './components/BottomSelection';



function AddCommodity(props) {
  let { match,user,cncData,allData, updateCNCInputs, updatewholeCNCInputs, updateAlertCNC,mode,updateCNCDatas } = props;
  
  let api = config.api.api_url;
  const history = useHistory();
  let username = user.preferred_username;
  const [loading, setLoading] = useState(false);
  // const [mode,setMode]=useState("view");
  const [visible,setVisible]=useState(false);
  let today = new Date();
  let currentDate =
    today.getFullYear() +
    "-" +
    (today.getMonth() < 9
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

  function initializeState(){

    let initialState = [];
    initialState.loading=false;
    initialState.mode="";
    initialState.cncDataGraph=[];
    initialState.tableHeader=[];
    initialState.futures_graph1=[];
    initialState.futures_graph2=[];
    initialState.futures_graph3=[];
    initialState.futures_graph4=[];
    initialState.weight_graph1=20;
    initialState.weight_graph2=20;
    initialState.weight_graph3=20;
    initialState.weight_graph4=20;
    initialState.weight_override=0;
    initialState.weight_default_total = 100;
    initialState.weight_graphs=[];
    initialState.weight_graphs_copy=[];
    initialState.weight_min=0;
    initialState.weight_max=100;
    initialState.importance_type="";
    initialState.override_values=[];
    initialState.demand_in_mt=[];
    initialState.override_forecast_header=[];
    initialState.demand_header=[];
    initialState.demand_treeData=[];
    initialState.commodityOptions=[];
    initialState.commodity_selection="";
    initialState.commodity_selection_value="";
    initialState.month_selection="";
    initialState.month_selection_value="";
    initialState.description="";
    initialState.month_options=[];
    initialState.region_selection=[];
    initialState.regionOptions=[];
    initialState.regionOptionsAll=[];
    initialState.region_selection_value=[{
      label:"Global",
      value:"Global",
      geography_level:"region",
      parent_id:"null",
    }];
    initialState.isIndexVisible=false;
    initialState.isCountryVisible=false;
    initialState.isDemandEditable=false;
    initialState.scenarioFlag=false;
    initialState.scenario_flag_checkbox=false;
    initialState.isExpandable=false;
    initialState.indexOptions=[];
    initialState.index_commodity_id="";
    initialState.countryOptions=[];
    initialState.countryOptionsAll=[];
    initialState.country_selection="";
    initialState.country_selection_value=[];
    initialState.factoryOptions=[];
    initialState.factoryOptionsAll=[];
    initialState.factory_selection=[];
    initialState.use_for_final_hedging=false;
    initialState.index_source_weights=[];
    initialState.demandDataEdited=false;
    initialState.demand_difference_flag=false;
    initialState.expand=true;
    initialState.status = initialState.message=''
    updatewholeCNCInputs(initialState);
  }

  //function fetch commodity runs based on the username
  function fetchRuns(id, time) {
    fetch(api + "/view_runs?user=" + username)
      .then((respone) => respone.json())
      .then((response) => {
        if(response.status.statusCode ==="cnc-200"){
          updateCNCDatas("commodity_runs", response.data);
          let runData = response.data?.find(
            (run) =>
              run.cnc_id.toLowerCase() === id.toLowerCase() &&
              run.triggered_on === time
          );
          updateCNCDatas("activeCommodityRun",runData);
          mapCommodityInputs("view", runData);
        }

        else{
          if(response !==undefined){
            updateCNCDatas("commodity_runs", response.data);
          let runData = response.data?.find(
            (run) =>
              run.cnc_id.toLowerCase() === id.toLowerCase() &&
              run.triggered_on === time
          );
          updateCNCDatas("activeCommodityRun",runData);
          mapCommodityInputs("view", runData);
          }
        }
        
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
  }
  useEffect(() => {
    setLoading(true);
    // getCommodity();
  }, [])
  useEffect(() => {
    mode = mode;
    if (match.params.commodityName!== undefined) {
      setLoading(true);
      initializeState();
      getCommodity();
      getRegions();
      updateCNCInputs("mode", "edit");
      let cnc_id = match.params.commodityName.split("_").splice(-1)[0];
      // mode = mode;
      if (allData.activeCommodity=== null) {
        let defaultCommodity = cncData;
        defaultCommodity.user = username;
        updateCNCDatas("defaultCommodity", defaultCommodity);
        fetchCommodity(cnc_id);
      } else {
        mapCommodityInputs(mode, allData.activeCommodity);
      }
    } else if (match.params.commodityRunName !== undefined) {
      mode = "";
      setLoading(true);
      let cnc_id = match.params.commodityRunName.split("_").splice(-2)[0];
      let run_time = match.params.commodityRunName.split("_").splice(-1)[0];
      mode = "view";
      initializeState();
      getCommodity();
      updateCNCInputs("mode", "view");
      updateCNCInputs("forecastloading",true);
        if (allData.activeCommodityRun===null) {
          let defaultCommodity = cncData;
          defaultCommodity.createdby = username;
          updateCNCDatas("defaultCommodity", defaultCommodity);
          fetchRuns(cnc_id, run_time);
        } else {
          mapCommodityInputs(
            mode,
            allData.activeCommodityRun,
            cnc_id,
            run_time
          );
        }
      
    } else if (match.url.endsWith("addHedging")) {  
      setLoading(true);
      initializeState();
      getCommodity();
      updateCNCInputs("mode", "create");
    
      setLoading(false);
    }
  }, [allData.activeCommodity, allData.activeCommodityRun]);

   //function fetch Commodity
   function fetchCommodity(id) {
    fetch(api + "/userCommodities?cnc_id=" + id)
      .then((respone) => respone.json())
      .then((response) => {
       if(response.status.statusCode==="cnc-200"){
        let access_mode = "edit";
        updateCNCInputs("mode", access_mode);
        let commodityData = response.data[0];
        updateCNCDatas("activeCommodity", commodityData);
        if (
          commodityData.shared_with !== undefined &&
          commodityData.shared_with.length > 0
        ) {
          let user_access = commodityData.shared_with?.find(
            (user) => (user.username = username)
          );
          access_mode = user_access.accessType.includes("view")
            ? "view-shared"
            : "edit";
        }
        mapCommodityInputs(access_mode, commodityData);
       } 
       else{
        history.push({
          pathname: `/home/myCommodityHedging`,
        });  
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
       }
        
      })
      .catch((error) => {
        history.push({
          pathname: `/home/myCommodityHedging`,
        });
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
  }



  function mapCommodityInputs(mode,data,cnc_id="",run_time=""){
    if(mode =="edit" ||mode=="view-shared"){
      let region_selection_value=[];
      let country_selection_value=[];
      let factory_selection=[];
      for(let i=0;i<data.region_selection.length;i++){
        region_selection_value[i]={
          label:data.region_selection[i],
          value:data.region_selection[i],
        }
      }

      if(data.country_selection.length>0){
        setVisible(true);
        getCountries(data.region_selection)
        for(let i=0;i<data.country_selection.length;i++){
          country_selection_value[i]={
            label:data.country_selection[i],
            value:data.country_selection[i],
          }
  
        }
      }
      
      if(data.factory_selection.length>0){
        getFactories(data.country_selection)
        for(let i=0;i<data.factory_selection.length;i++){
          factory_selection[i]={
            label:data.factory_selection[i],
            value:data.factory_selection[i],
          }
        }
      }
      
      let commodity_data={
        loading:cncData.loading,
        cnc_id: data.cnc_id,
        run_id:data.run_id, 
        user:data.user,
        cncDataGraph:[],
        override_forecast_header:data.override_forecast_header,
        demand_header:data.demand_header,
        demand_treeData:[],
        commodityOptions:cncData.commodityOptions,
        commodity_selection: data.commodity_selection,
        commodity_selection_value: data.commodity_selection_value,
        user_commodity_name:data.user_commodity_name,
        use_for_final_hedging:data.use_for_final_hedging,
        commodity_created_dt: currentDate,
        month_options:data.month_options,
        month_selection_value: data.month_selection_value,
        description:data.description,
        regionOptions:cncData.regionOptions,
        regionOptionsAll:cncData.regionOptionsAll,
        region_selection_value:region_selection_value,
        isIndexVisible:cncData.isIndexVisible,
        isCountryVisible:cncData.isCountryVisible,
        isDemandEditable:cncData.isDemandEditable,
        scenarioFlag:cncData.scenarioFlag,
        scenario_flag_checkbox:data.scenario_flag_checkbox,
        isExpandable:data.isExpandable,
        indexOptions:cncData.indexOptions,
        index_commodity_id:data.index_commodity_id,
        index_source_weights:{},
        countryOptions:cncData.countryOptions,
        countryOptionsAll:cncData.countryOptionsAll,
        country_selection_value:country_selection_value,
        factoryOptions:cncData.factoryOptions,
        factoryOptionsAll:cncData.factoryOptionsAll,
        factory_selection:factory_selection,
        weight_default_total: data.weight_default_total,
        weight_graph1: data.weight_graph1,
        weight_graph2: data.weight_graph2,
        weight_graph3: data.weight_graph3,
        weight_graph4: data.weight_graph4,
        weight_graphs: data.weight_graphs,
        weight_graphs_copy:data.weight_graphs,
        weight_max: data.weight_max,
        weight_min: data.weight_min,
        weight_override: data.weight_override,
        demand_in_mt: data.demand_in_mt,
        override_values:data.override_values,
        shared: data.shared,
        shared_with: data.shared_with,
        starred: data.starred,
        total_runs: data.total_runs,
        unique_values:data.unique_values,
        mode:mode,
        demandDataEdited:cncData.demandDataEdited,
        report_url:cncData.report_url,
        expand:true,
        loading:true,
        forecastloading:true,
      }
      updatewholeCNCInputs(commodity_data)
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

    if (mode == "view"){
     let id;
     if(typeof data !=='undefined' &&data!==null){
      id=data.cnc_id;
      let status =
        data.notification_id !== undefined && data.notification_id !== null
          ? data.notification_type
          : data.status;    
      let disabled =
        status === "Failed" || status === "Running" || status === "Queued"
          ? true
          : false;
      let status_name =
        status === "Running" || status === "Queued" ? status : "Error Message";
      let status_flag =
        status === "Running" || status === "Queued" ? true : false;
      fetch(api + "/userCommodities?cnc_id=" + id)
        .then((respone) => respone.json())
        .then((response) => {
        if(response.status.statusCode==="cnc-200"){  
          let data1=response.data[0];
          let region_selection_value=[];
          let country_selection_value=[];
          let factory_selection=[];
          for(let i=0;i<data1.region_selection.length;i++){
            region_selection_value[i]={
              label:data1.region_selection[i],
              value:data1.region_selection[i],
            }
          }

            if(data1.country_selection.length>0){
              setVisible(true);
              getCountries(data1.region_selection)
              for(let i=0;i<data1.country_selection.length;i++){
                country_selection_value[i]={
                  label:data1.country_selection[i],
                  value:data1.country_selection[i],
                }
        
              }
            }
            
            if(data1.factory_selection.length>0){
              getFactories(data1.country_selection)
              for(let i=0;i<data1.factory_selection.length;i++){
                factory_selection[i]={
                  label:data1.factory_selection[i],
                  value:data1.factory_selection[i],
                }
              }
            }
          let commodity_data_run={
            // loading:cncData.loading,
            cnc_id: data1.cnc_id,
            run_id:data.run_id,
            user:data.user,
            cncDataGraph:[],
            override_forecast_header:data1.override_forecast_header,
            demand_header:data1.demand_header,
            demand_treeData:[],
            commodityOptions:cncData.commodityOptions,
            commodity_selection: data1.commodity_selection,
            commodity_selection_value: data1.commodity_selection_value,
            user_commodity_name:data1.user_commodity_name,
            use_for_final_hedging:data.use_for_final_hedging,
            commodity_created_dt: data.commodity_created_dt,
            month_options:data1.month_options,
            month_selection_value: data1.month_selection_value,
            decision_month:data1.decision_month,
            description:data1.description,
            regionOptions:data1.regionOptions,
            regionOptionsAll:data1.regionOptionsAll,
            region_selection_value:region_selection_value,
            isIndexVisible:data1.isIndexVisible,
            isCountryVisible:cncData.isCountryVisible,
            isDemandEditable:cncData.isDemandEditable,
            scenarioFlag:cncData.scenarioFlag,
            scenario_flag_checkbox:data1.scenario_flag_checkbox,
            isExpandable:data1.isExpandable,
            indexOptions:data1.indexOptions,
            index_commodity_id:data1.index_commodity_id,
            index_source_weights:data1.index_source_weights,
            countryOptions:data1.countryOptions,
            countryOptionsAll:data1.countryOptionsAll,
            country_selection_value:country_selection_value,
            factoryOptions:data1.factoryOptions,
            factoryOptionsAll:data1.factoryOptionsAll,
            factory_selection:factory_selection,
            weight_default_total: data.weight_default_total,
            weight_graphs: data1.weight_graphs,
            weight_graphs_copy:data1.weight_graphs,
            weight_max: data1.weight_max,
            weight_min: data1.weight_min,
            weight_override: data1.weight_override,
            demand_in_mt: data1.demand_in_mt,
            override_values:data1.override_values,
            shared: data1.shared,
            shared_with: data1.shared_with,
            starred: data1.starred,
            total_runs: data1.total_runs,
            unique_values:data1.unique_values,
            mode:mode,
            demandDataEdited:cncData.demandDataEdited,
            report_url:cncData.report_url,
            message:data.message,
            status: status,
            status_flag: status_flag,
            disabled_flag: disabled,
            expand:true,
            loading:true,
            forecastloading:true,
          }
          updatewholeCNCInputs(commodity_data_run)
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
        
          else{
            setLoading(false);
            history.push({
              pathname: `/home/myCommodityHedging`,
            });
            updateAlertCNC({
              open: true,
              message: config.messages.unexpected_error,
              severity: "info",
              closeFlag:false
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          history.push({
            pathname: `/home/myCommodityHedging`,
          });
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
        });
     
    }
     else{
      fetchRuns(cnc_id,run_time);
     
     }
    }

  }

  



  

  

 //Function to get Regions
const getRegions=()=>{

  let regionOptions=[];

  //fetch call
  let input={
    level:"region",
    parent_id:"",
  }
  let reqOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  fetch(api + "/geography",reqOptions)
  .then((respone) => respone.json())
  .then((response) => {
   if(response.status.statusCode ==="cnc-200"){
      for (let i = 0; i < response.data.length; i++) {
        regionOptions[i] = {
          value: response.data[i].geography_id,
          label: response.data[i].geography,
          geography_level:response.data[i].geography_level,
          parent_id:response.data[i].parent_id
        }
      }
      updateCNCInputs("regionOptions",regionOptions);

   } 

   else{
    updateCNCInputs("regionOptions",[]);
   }
    
  })
  .catch((error) => {
    updateCNCInputs("regionOptions",[]);
    updateAlertCNC({
      open: true,
      message: config.messages.unexpected_error,
      severity: "info",
      closeFlag:false
    });
  });

  

}

  //Fucntion to get Countries
const getCountries=(regions)=>{
  let countryOptions=[];

  let input={
    level:"country",
    parent_id:regions,
  }

  //fetch call
  let reqOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };


  fetch(api + "/geography",reqOptions)
  .then((respone) => respone.json())
  .then((response) => {
    if(response.status.statusCode==="cnc-200"){ 
    for (let i = 0; i < response.data.length; i++) {
      countryOptions[i] = {
        value: response.data[i].geography_id,
        label: response.data[i].geography,
        geography_level:response.data[i].geography_level,
        parent_id:response.data[i].parent_id
      }
    }
    updateCNCInputs("countryOptions",countryOptions);
  } 

  else{
    updateCNCInputs("countryOptions",[]);
  }
  })
  .catch((error) => {
    updateCNCInputs("countryOptions",[]);
    updateAlertCNC({
      open: true,
      message: config.messages.unexpected_error,
      severity: "info",
      closeFlag:false
    });
  });
}

//Function to getFactories
const getFactories=(countries)=>{
  let factoryOptions=[];

  let input={
    level:"factory",
    parent_id:countries,
  }

  //fetch call
  let reqOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };


  fetch(api + "/geography",reqOptions)
  .then((respone) => respone.json())
  .then((response) => {
    if(response.status.statusCode==="cnc-200"){
    for (let i = 0; i < response.data.length; i++) {
      factoryOptions[i] = {
        value: response.data[i].geography_id,
        label: response.data[i].geography,
        geography_level:response.data[i].geography_level,
        parent_id:response.data[i].parent_id
      }
    }
    updateCNCInputs("factoryOptions",factoryOptions);
    }
    else{
      updateCNCInputs("factoryOptions",[]);
    }
  })
  .catch((error) => {
    updateCNCInputs("factoryOptions",[]);
    updateAlertCNC({
      open: true,
      message: config.messages.unexpected_error,
      severity: "info",
      closeFlag:false
    });
  });

}


  

  //get Commodities
  const getCommodity = () => {
    let commodityOptions = [];
    fetch(api + "/commodities")
      .then((respone) => respone.json())
      .then((response) => {
        if(response.status.statusCode==="cnc-200"){
          for (let i = 0; i < response.data.length; i++) {
            commodityOptions[i] = {
              value: response.data[i].commodity_id,
              label: response.data[i].commodity_name,
              decision_months:response.data[i].decision_months,
              isIndexVisible:response.data[i].isIndexVisible,
              isCountryVisible:response.data[i].isCountryVisible,
              isDemandEditable:response.data[i].isDemandEditable,
              scenarioFlag:response.data[i].scenarioFlag,
              weight_graphs:response.data[i].weight_graphs,
              isExpandable:response.data[i].isExpandable,
              indices:response.data[i].indices,
              report_url:response.data[i].report_url,
              report_params:response.data[i].report_params,
              demand_difference_url:response.data[i].demand_difference_url,
              demand_difference_url_params:response.data[i].demand_difference_url_params,
              report_url_execution:response.data[i].report_url_execution,
              report_params_execution:response.data[i].report_params_execution,
              isDairy:response.data[i].isDairy,
              show_execution_dashboard:response.data[i].show_execution_dashboard
            }
          }
          updateCNCInputs("commodityOptions", commodityOptions);
        }

        else{
          updateCNCInputs("commodityOptions", []);
        }
        
      })
      .catch((error) => {
        updateCNCInputs("commodityOptions", []);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });  
  }



  function formattedDate(date) {
    let d =new Date(date)
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${year}-${month}-${day}`;
  }

  return (
    <>
      {cncData.mode == "create" && (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <MetaSelection disabled={false} />
              {cncData.month_selection_value !== "" &&
                cncData.index_commodity_id !== "" && (
                  <>
                    <BottomSelection/>
                  </>
                )}
            </>
          )}
        </>
      )}

      {(cncData.mode == "edit" ||cncData.mode == "view-shared" || cncData.mode =="view") && (
        <>
        {loading ? (
            <Loader />
          ) : (
              <>
                <MetaSelection disabled={true} />
                <BottomSelection />
              </>
          )}
        </>
      )}
    </>
  );
}
AddCommodity.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas:PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  cncData: state.cncinputs,
  allData:state.data
});

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddCommodity));
