/*-------------------------Actual APIs-------------------------*/
const local = false;
const reckitt_domain = local ? "https://data-reckitt-dev.rbone.net" : "";
const backendApi = "/api";
const signInUrl = `/auth/sign_in?post_sign_in_url=https%3A%2F%2F`;
const signOutUrl = `/auth/sign_out`;
const authUrl = `${backendApi}/isauthenticated`;
const userInfoUrl = `${backendApi}/userinfo?post_sign_in_url=https%3A%2F%2F`;
const authoriseUrl = `${backendApi}/isauthorised?post_sign_in_url=https%3A%2F%2F`;
const demoAppUrl = `${backendApi}/demoApp`;
const masterAppUrl = `/master/demoApp`;
const api_url = `${backendApi}/cnc`;
const userListUrl = `${backendApi}/users?app_handle=cnc`;
const api_db_url = backendApi;
const app_handle = "cnc";
const appUserRoleUrl = `${reckitt_domain}${backendApi}/appuserrole`;

const sidenav_admin = [
  {
    id: "addHedging",
    name: "Add new Hedging",
    materialUiIconName: "add_circle",
    materialUiClassName: "material-icons-outlined",
    specialStyling: true,
  },
  {
    id: "myCommodityHedging",
    name: "My Commodity Hedging",
    materialUiIconName: "settings",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "recentCommodities",
    name: "Recent",
    materialUiIconName: "query_builder_rounded",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "sharedCommodities",
    name: "Shared with me",
    materialUiIconName: "group",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "starredCommodities",
    name: "Starred",
    materialUiIconName: "star_border",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "viewRuns",
    name: "View Runs",
    materialUiIconName: "visibility",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "execution",
    name: "Execution",
    materialUiIconName: "app_registration",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "updateDemand",
    name: "Update Demand",
    materialUiIconName: "update",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "uploadPriceForecast",
    name: "Upload Price Forecast",
    materialUiIconName: "file_upload",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "budget",
    name: "Budget",
    materialUiIconName: "query_stats",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "spots",
    name: "Update Manual Spots",
    materialUiIconName: "analytics",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  // {
  //   "id": "costInfo",
  //   "name": "Cost Information",
  //   "materialUiIconName": "monetization_on",
  //   "materialUiClassName": "material-icons-outlined",
  //   "specialStyling": false
  // }
];
const sidenav = [
  {
    id: "addHedging",
    name: "Add new Hedging",
    materialUiIconName: "add_circle",
    materialUiClassName: "material-icons-outlined",
    specialStyling: true,
  },
  {
    id: "myCommodityHedging",
    name: "My Commodity Hedging",
    materialUiIconName: "settings",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "recentCommodities",
    name: "Recent",
    materialUiIconName: "query_builder_rounded",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "sharedCommodities",
    name: "Shared with me",
    materialUiIconName: "group",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "starredCommodities",
    name: "Starred",
    materialUiIconName: "star_border",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "viewRuns",
    name: "View Runs",
    materialUiIconName: "visibility",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
];

const sidenav_commodityManager = [
  {
    id: "addHedging",
    name: "Add new Hedging",
    materialUiIconName: "add_circle",
    materialUiClassName: "material-icons-outlined",
    specialStyling: true,
  },
  {
    id: "myCommodityHedging",
    name: "My Commodity Hedging",
    materialUiIconName: "settings",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "recentCommodities",
    name: "Recent",
    materialUiIconName: "query_builder_rounded",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "sharedCommodities",
    name: "Shared with me",
    materialUiIconName: "group",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "starredCommodities",
    name: "Starred",
    materialUiIconName: "star_border",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "viewRuns",
    name: "View Runs",
    materialUiIconName: "visibility",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "execution",
    name: "Execution",
    materialUiIconName: "app_registration",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "uploadPriceForecast",
    name: "Upload Price Forecast",
    materialUiIconName: "file_upload",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "updateDemand",
    name: "Update Demand",
    materialUiIconName: "update",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "budget",
    name: "Budget",
    materialUiIconName: "query_stats",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  {
    id: "spots",
    name: "Update Manual Spots",
    materialUiIconName: "analytics",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
  // {
  //   "id": "costInfo",
  //   "name": "Cost Information",
  //   "materialUiIconName": "monetization_on",
  //   "materialUiClassName": "material-icons-outlined",
  //   "specialStyling": false
  // }
];

const sidenav_demandManager = [
  {
    id: "updateDemand",
    name: "Update Demand",
    materialUiIconName: "update",
    materialUiClassName: "material-icons-outlined",
    specialStyling: false,
  },
];

const messages = {
  workInProgressMessage: "Your content here.",
  authenticatingUserMessage: "Authenticating user",
  loginPrimaryText: "Log in to find out more!",
  UnauthorisedMessage:
    "You don't have access to this app. Please contact support for the access.",
  weight_validation: "Sum of Weights cannot exceed 100",
  override_weight_validation: "Override Weight cannot be 0 or Empty",
  override_weight_empty_check: "Fill all the Override Values",
  No_Price_Forecast_data:
    "No Price Forecast available for the current selection",
  commodity_save_success: "Commodity Hedging Saved Successfully",
  commodity_update_success: "Commodity Hedging Updated Successfully",
  commodity_save_run_success:
    "Commodity Hedging Saved and Run Triggered Successfully",
  weight_individual_validation: "Please provide value between 0 and 100",
  fill_mandatory: "Please select all mandatory fields",
  scenario_flag_enabled: "Scenario Flag enabled for Commodity",
  scenario_flag_disabled: "Scenario Flag diabled for Commodity",
  show_demand_flag_enabled: "Demand difference is viewable ",
  show_demand_flag_disabled: "Demand Difference Flag is disabled",
  commodity_starred: "Commodity Starred Successfully",
  commodity_unstarred: "Commodity UnStarred Successfully",
  commodity_deleted: "Commodity Hedging Deleted Successfully",
  commodity_copy: "Commodity Copied Successfully",
  select_one_user: "Please select atleast one user",
  commodity_shared: "Commodity Shared Successfully",
  run_triggered: "Run has been initiated & it is currently queued.",
  run_selected_for_final_hedging: "Run Selected For Final Hedging",
  run_unselected_for_final_hedging: "Run UnSelected From Final Hedging",
  csv_file_format_validation:
    "File Format Mismatch.Only .csv Files are allowed for Upload",
  access_restricted:
    "Access to the Page is restricted for the Current user role",
  upload_file: "Kindly Select a File for Upload",
  demand_updating: "Demand Data will be Updated to table shortly... ",
  demand_update_success: "Demand Data Updated to table Successfully ",
  forecast_updating: "Price Data will be Updated to table shortly... ",
  forecast_update_success: "Demand Data Updated to table Successfully ",
  execution_updating:
    "Executed Volume & Price will be Updated to table shortly...",
  execution_update_success:
    "Executed Volume  & Price Updated to table Successfully ",
  unexpected_error: "Unexpected error occured please try again...",
  grade_empty_update: "Cannot Update Demand when Grade column is empty",
  forecast_demand_empty_check:
    "Cannot save hedging when Price forecast (or) Demand data not available",
  file_upload_failed: "File upload failed,please try again",
};

const hardCoded = {
  homeLocation: "https://admin-aide-dev.rbone.net",
  appName: "Base App",
  app_id: "cnc",
};

const api = {
  authUrl,
  userInfoUrl,
  signInUrl,
  signOutUrl,
  demoAppUrl,
  masterAppUrl,
  authoriseUrl,
  api_db_url,
  api_url,
  userListUrl,
  appUserRoleUrl,
  app_handle,
  local,
};

const demand_treeData_height = 37;
const cnc_panel = [
  "Forecast Weight",
  "Demand",
  "Forecast/ Distribution Importance",
];
const cnc_panel_demand = ["Demand", "Upload Demand"];
const cnc_panel_forecast = ["Upload Forecast"];
const cnc_panel_recommendations = ["Execution"];
const table_rows = {
  pagination_size: 5,
};
const notifications = {
  timeout: 60000,
};

const myCommodityHeader = [
  "Commodity-Decision Month-Geography",
  "Created On",
  "Description",
  "Total Runs",
  "",
];

const RunsHeader = [
  "",
  "Commodity-Decision Month-Geography",
  "Description",
  "Created On",
  "Created By",
  "Triggered On",
  "Triggered By",
  "Duration",
  "Status",
  "",
];

const sharedCommodityHeader = [
  "Commodity-Decision Month-Geography",
  "Owned By",
  "Created On",
  "Description",
  "Total Runs",
  "",
];

const view_runs = {
  viewRunPanels: ["All", "Currently Running", "Queued", "Previous Runs"],
  timeout: 60000,
};
const shareOptions = [
  { label: "View", value: "view" },
  { label: "View & Run", value: "view&run" },
  { label: "Edit & Run", value: "edit&run" },
];
const defaultRoute = {
  CNC: "myCommodityHedging",
  admin: "myCommodityHedging",
  commodity_manager: "myCommodityHedging",
  demand_planner: "updateDemand",
};

const admin_user_header = ["Username", "Email", "Created By", "Actions"];

// const ExecutionTableHeader = [
//   "Factory",
//   "Type",
//   "ETA Month",
//   "Futures Month",
//   "Status",
//   "Executed Volume",
//   "Executed Price",
//   "Executed Price(FCY)",
//   "",
// ];
const ExecutionTableHeader = [
  "Factory",
  "Type",
  "ETA Month",
  "Futures Month",
  "Status",
  "Price",
  "",
];

const ExecutionTableHeaderDairy = [
  "Factory",
  "Type",
  "ETA Month",
  "Futures Month",
  "Status",
  "Executed Volume",
  "Executed Price",
  "",
];

const BudgetTableHeader = ["Type", "Year", "Month", "Value", ""];

const SpotsTableHeader = ["Date", "Value", ""];

const CostInfoTableHeader = [
  "Geography",
  "Decision Month",
  "Total Overhead Cost",
  "Currency",
  "Units",
  "",
];
const config = {
  footerText: "Powered by RBOne",
  api,
  messages,
  hardCoded,
  autoHideDuration: 5000,
  defaultRoute,
  sidenav,
  sidenav_commodityManager,
  sidenav_demandManager,
  sidenav_admin,
  table_rows,
  notifications,
  cnc_panel,
  cnc_panel_demand,
  cnc_panel_recommendations,
  myCommodityHeader,
  sharedCommodityHeader,
  RunsHeader,
  view_runs,
  shareOptions,
  demand_treeData_height,
  admin_user_header,
  cnc_panel_forecast,
  ExecutionTableHeader,
  BudgetTableHeader,
  ExecutionTableHeaderDairy,
  SpotsTableHeader,
  CostInfoTableHeader,
};

export { config };
