/*
Created on June 2022
Confidential and Proprietary of Reckitt
Project: CNC
Component name : BottomSelectionPriceForecast
Component Description : This Component Displays the Bottom Panel (Price Forecast Graph &Demand Tree) 
@author: Tailwyndz
*/

import React, { useEffect,useState,useRef } from "react";
import {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
  updateCNCDatas,
} from "../../../../redux/actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {config} from '../../../../config/config';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
// import "../addcommodity.scss";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FileUploadPriceForecast from "./FileUploadPriceForecast";
import { IconButton } from "@material-ui/core";
import Dialogs from "../../../../components/Dialog/Dialog";
import { CSVLink } from "react-csv";

function BottomSelectionPriceForecast(props) {
    let {
      cncData,
      updateCNCInputs,
      updateAlertCNC,
      onCancel
    } = props;

    
    let api = config.api.api_url;
    
    
    let cncpanels = config.cnc_panel_forecast;
    const [dialogData, setDialog] = React.useState([])
    const [downloadData, setDownload] = useState([]);
    let csvlink = useRef(null);
    
    const [value, setValue] = useState(0);

      useEffect(()=>{
        updateCNCInputs("demand_difference_flag",false);
        getForecastinfoData ();
      },[])
    
   


    
 
    function handleTabs(value) {
        setValue(value)
      }
    
    

     
    //Handle checkbox toggle for the Scenario Checkbox
    const handleScenarioChange = (event) => {
    //   setScenarioFlagState(event.target.checked)
      
     if(event.target.checked){
       updateAlertCNC({open:true,message:config.messages.scenario_flag_enabled,severity:"info"})
     }
    
     else{
       updateAlertCNC({open:true,message:config.messages.scenario_flag_disabled,severity:"info"})
       let override_forecast_header=cncData.override_forecast_header;

       for(let i=0;i<override_forecast_header.length;i++){
          override_forecast_header[i].value="" 
       }
       updateCNCInputs("override_forecast_header", override_forecast_header)
     }
     if(event.target.name ==="Scenario_Flag"){
       updateCNCInputs("scenario_flag_checkbox",event.target.checked);
       handleTabs(0);//if Flag is enabled user has to fill the Override weight and values
     }
    }
    
    
    
    
   

   
    
  const handleDialog = (value) => {
    setDialog({
        open: true,
        value: value,
        data:cncData.DemandinfoData,
        close: DialoghandleClose,
        handleDownload:handleDownload,
    })
  }
  const DialoghandleClose = () => {
      setDialog({
          ...dialogData,
          open: false,
      });
  };

  const getForecastinfoData=()=>{
    fetch(api + `/html_file?location=cnc_tailwyndz/Help Files/PriceForecastDataFileUploadInfo.html`)
    .then((res) => res.json())
    .then((res)=>{
        if(res.status.statusCode==="cnc-200"){
          updateCNCInputs("DemandinfoData",res.data);
            setDialog(prevState => {
              return {
                  ...prevState,
                  data: res.data,
                  header:"Price Forecast Upload  Details"
        
              }});
        }
        else{
          updateCNCInputs("DemandinfoData",res.data);
          setDialog(prevState => {    
            return {
                ...prevState,
                data: res.data,
                header:"Price Forecast Upload  Details"
      
            }});
        }
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });

    
  }

  //Function to Handle download of Sample csv file
  const handleDownload = (event) => {
    fetch(api + "/download_file?location=cnc_tailwyndz/Help Files/sample_price_forecast_data.csv")
    .then((response) => response.json())
    .then((res) => {
      if(res.status.statusCode==="cnc-200"){
        updateCNCInputs("download_data", res.data);
        updateCNCInputs("file_name", "Price Forecast Data Sample.csv");
        setDownload({ ...downloadData, download_ready: true });
        setTimeout(() => {
          csvlink.current.link.click();
        }, 2000);
      }

      else if(res.status.statusCode==="cnc-400"){
        updateAlertCNC({
          open: true,
          message: res.status.statusMessage,
          severity: "info",
          closeFlag:false
        });
      }

      else{
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      }
        
    })
    .catch((error) => {
      updateAlertCNC({
        open: true,
        message: config.messages.unexpected_error,
        severity: "info",
        closeFlag:false
      });
    });
  }

 





    return (
      <>
        <div className="cnc-models-menu">
          {cncpanels.map((item, index) => (
            <div
              key={item}
              className="models-item"
              onClick={() => {
                  handleTabs(index);
              }}
            >
              <div
                className={`models-item-div ${index === value ? "active" : ""}`}
              >
                <p>{item}</p>
              </div>
            </div>
          ))}
          {cncData.scenarioFlag && cncData.mode !== "view" && (
            <div className="cnc-scenario-flag">
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        size="medium"
                        checked={cncData.scenario_flag_checkbox}
                        disabled={cncData.mode === "create" ? false : true}
                        onChange={handleScenarioChange}
                        name="Scenario_Flag"
                      />
                    }
                    label="Scenario"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </div>
          )}
          <IconButton aria-label="info" style={{marginLeft: "-20px"}} onClick={() => handleDialog("Demand info")}>
              <i className="material-icons-outlined" style={{ fontSize: 20, fontWeight: 800 }} >info</i>
           </IconButton>
           {dialogData.open && <Dialogs props={dialogData}></Dialogs>} 
        </div>
        {cncData.commodity_selection_value !== "" && cncData.month_selection_value !==""&&
        cncData.index_commodity_id !=="" &&

        (
          <>
            {value === 0 && (
              <>
                <Box sx={{ minWidth: 275, padding: 10 }}>
                  <Card
                    variant="outlined"
                    style={{
                      padding: 10,
                      maxHeight: "310px",
                      // height:cncData.loading &&"290px",
                      // backgroundColor: "#f9f9f9",
                    }}
                  >
                    <FileUploadPriceForecast onClear={onCancel}/>
                  </Card>
                </Box>
              </>
            )}

           

          {downloadData.download_ready && (
                          <CSVLink
                            data={cncData.download_data}
                            filename={cncData.file_name}
                            className="hidden"
                            ref={csvlink}
                            target="_blank"
                          />
                        )}
          </>
        )}
      </>
    );
}

BottomSelectionPriceForecast.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  cncData: state.cncinputs,
  allData: state.data,
});

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BottomSelectionPriceForecast)
);
