import React, { useState,useRef } from "react";
import {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
  updateCNCDatas,
  updateFlag
} from "../../../../redux/actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {config} from "../../../../config/config";
import uploadFile from "../../../../../assets/images/upload-file.svg"
import addUploadFile from "../../../../../assets/images/addUpload-file.svg";
// import "./FileUpload.scss";
import {makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";
import Loader from "../../../../components/Loader/Loader";
import UploadDialog from "./UploadDialog";
import UnSavedConfirmationDialog from "../../../../utils/UnSavedConfirmationDialog";
import Button from "@material-ui/core/Button";
function FileUploadPriceForecast(props) {
  let {
    user,
    cncData,
    allData,
    updateCNCInputs,
    updateAlertCNC,
    style={},
    id,
    updateFlag,
    onClear
  } = props;
  const useStyles = makeStyles(() => ({
    uploadFileClassCNC: {
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        minHeight: 150,
        justifyContent: "center",
        display: "flex",
        "& P": {
          margin: 0,
          padding: 0,
          color: "#4EAFB3",
          fontSize: 13,
        },
        "& .uploadImageContainerCNC": {
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          "& P": {
            margin: 0,
            padding: 0,
            color: "#4EAFB3",
            fontSize: 13,
          },
          "& .orContainerCNC": {
            margin: "0  40px",
          },
          "& .imageContainerCNC": {
            textAlign: "center",
          },
        },
        "& .loaderImageContainerCNC": {
          textAlign: "center",
        },
        "& .loaderBoxCNC": {
          marginTop: 10,
          textAlign: "center",
          "& p": {
            marginTop: 10,
          },
        },
        "& .uploadedContainerCNC": {
          display: "flex",
          marginTop: "30px",
          alignItems: "center",
        },
        "& .fileInfoContainerCNC":{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          "& P": {
            margin: 0,
            padding: 0,
            color: "#4EAFB3",
            fontSize: 16,
            marginRight:"15px",
            fontWeight:"bolder"
          },
          "& .successMessage":{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            "& P": {
              margin: 0,
              padding: 0,
              color: "#4EAFB3",
              fontSize: 16,
              marginRight:"15px",
              fontWeight:"bolder"
            },


          },

          "& .errorMessage":{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            "& P": {
              margin: 0,
              padding: 0,
              color: "orangered",
              fontSize: 16,
              marginRight:"15px",
              fontWeight:"bolder"
            },
            "& I":{
              fontSize: 26, 
              fontWeight: 300,
              color: "orangered",
              margin:"5px"
            }

          }
        }
      },
    root: {
      marginRight: style?.margin?.right || "0px",
      marginLeft: style?.margin?.left || "0px",
      position: "relative",
      backgroundColor: "#f9f9f9",
    },
    inputFile: {
      opacity: "0",
      width: "0.1px",
      height: "0.1px",
      position: "absolute",
      cursor: "pointer",
      "&:disabled": {
        cursor: "default",
      },
    },
    inputFileSelected: {
      opacity: "0",
      width: "0.1px",
      height: "0.1px",
      position: "absolute",
      "&:disabled": {
        cursor: "default",
      },
    },
    replace: {
      backgroundColor: "#465A69",
      borderRadius: "5px",
      padding: "7px 15px",
      color: "white",
      fontWeight: "500",
      fontSize: "12px",
      position: "absolute",
      cursor: "pointer",
      right: 13,
      bottom: 11,
      margin: 0,
    },
    label: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "max-content",
      width: "100%",
      boxSizing: "border-box",
      border: "1px dashed rgba(0, 0, 0, 0.3)",
      borderRadius: "3px",
      padding: "20px 20px",
      backgroundColor: "transparent",
      cursor: "pointer",
      "&:disabled": {
        cursor: "default",
      },
    },
    selectedLabel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "max-content",
      width: "100%",
      boxSizing: "border-box",
      border: "1px dashed rgba(0, 0, 0, 0.3)",
      borderRadius: "3px",
      padding: "20px 20px",
      backgroundColor: "transparent",
      "&:disabled": {
        cursor: "default",
      },
    },
  }));

  const outlinedCNCButton = {
    borderColor: '#46596A',
    // background-color: #d7dadd,
    color: '#46596A',
    // width: '100%',
    fontFamily: "Hind Siliguri",
    fontSize: '12px',
    fontWeight: "bold",
    marginRight: "5px",
}

const containedButton = {
    color: "white",
    backgroundColor: "#46596A",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    // marginLeft: "10px !important",
    marginRight: "5px",
};
const outlinedButton = {
    borderColor: "#46596A",
    // background-color: #d7dadd,
    color: "#46596A",
    // width: '100%',
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "10px !important",
};

  const classes = useStyles();
  const initialId = id ? id : v4();
  const fileId = useRef(initialId);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [uploadState,setUploadState]=useState("");
  let api = config.api.api_url;
  const [fileUploadDialogData,setFileUploadDialogData]=useState([]);
  const [filestatus,setFileStatus]=useState("");
  //Function to Validate the Uploaded File Size 
  const fileValidate=(file)=>{
    // let fileInputField = document.getElementById(fileId.current);

    let fileSize = file?.size;
    // for (const file of fileInputField.files) {
    //   fileSize += +file.size;
    // }

    if (fileSize === 0) {
      updateAlertCNC({
        open: true,
        message: "Please select a non blank file",
        severity: "error",
        closeFlag:true,
      });
      return false;
    } else if (fileSize >= 5242880) {
      updateAlertCNC({
        open: true,
        message: "Allowed maximum size is 5MB",
        severity: "error",
        closeFlag:true,
      });
      return false;
    } else {
     return true;
    }
  }


  const FileChangeHandler=(event,option)=>{
    setUploadState("");
    if(option==="select"){
      if(fileValidate(event.target?.files?.[0])){
          let fileName = String(event.target?.files?.[0]?.name);
          if (!fileName.endsWith("csv")) {
            updateAlertCNC({
              open: true,
              message: config.messages.csv_file_format_validation,
              severity: "info",
              closeFlag: true,
            });
            setSelectedFile("");
            setIsFilePicked(false);
          }
          else{
            setSelectedFile(event.target?.files?.[0]);
            setIsFilePicked(true);
            updateFlag("unSavedChanges",true)
          }  
      }   
    }

    if(option==="drop"){
      if(fileValidate(event.dataTransfer.files?.[0])){
          let fileName = String(event.dataTransfer.files?.[0]?.name);
          if (!fileName.endsWith("csv")) {
            updateAlertCNC({
              open: true,
              message: config.messages.csv_file_format_validation,
              severity: "info",
              closeFlag: true,
            });
            setSelectedFile("");
            setIsFilePicked(false);
          }
          else{
            setSelectedFile(event.dataTransfer.files?.[0]);
            setIsFilePicked(true);
            updateFlag("unSavedChanges",true)
          } 
      } 
       
    }
  }


  Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
  }

  const handleSubmission = () => {

    if(isFilePicked){
      const formData = new FormData();
      var d = new Date(),
    dformat = [(d.getUTCMonth()+1).padLeft(),
               d.getUTCDate().padLeft(),
               d.getUTCFullYear()].join('-') +' ' +
              [d.getUTCHours().padLeft(),
               d.getUTCMinutes().padLeft(),
               d.getUTCSeconds().padLeft()].join(':');
    formData.append("selectedAttachments", selectedFile);
    formData.append("commodity_selection_value",cncData.commodity_selection_value)
    formData.append("timestamp",dformat)
    formData.append("forecast_user",user.preferred_username)
    formData.append("index_commodity_id",cncData.index_commodity_id)
    formData.append("decision_month",cncData.decision_month)
    let region_selection =[];
    cncData.region_selection_value.filter((region)=>{region_selection.push(region.value)})

    formData.append("geography_id",region_selection)
    let reqOptions = {
      method: "POST",
      body: formData,
    };
    setFileStatus("");
    setUploadState("loading");
    updateCNCInputs("uploadResponse","")
    fetch(api+"/price_forecast_file_validation",reqOptions)
      .then((response) => response.json())
      .then((res) => {
        updateFlag("reloadNotification",true)
        if(res.status.statusCode==="cnc-200"){
          updateFlag("unSavedChanges",true)
          handleFileUploadDialog("write",res.data,"/price_forecast_file_data_upload");
        }

        else if(res.status.statusCode==="cnc-300"){
          updateFlag("unSavedChanges",true)
          setFileUploadDialogData({
            open:true,
            handlefunction:handleFileUploadDialog,
            mismatch_cols:res.data.mismatch_columns.join(","),
            data:res.data,
            endpoint:"/price_forecast_file_data_upload",
          })
          setUploadState("error");
        }

        else if(res.status.statusCode==="cnc-400"){
          setSelectedFile("");
          setIsFilePicked(false);
          updateCNCInputs("uploadResponse",res.status.statusMessage)
          updateAlertCNC({
            open: true,
            message: res.status.statusMessage,
            severity: "error",
            closeFlag: true,
          });
          setUploadState("error");
          updateFlag("unSavedChanges",false)
        }
      })
      .catch((error) => {
        setSelectedFile("");
        setIsFilePicked(false);
        updateCNCInputs("uploadResponse","unexpected error occured please try again")
        setUploadState("error");
        updateFlag("unSavedChanges",false)
      });
    }

    else{
      updateAlertCNC({
        open: true,
        message: config.messages.upload_file,
        severity: "info",
        closeFlag: true,
      });
    }
    
  };





  const handleFileUploadDialog=(operation,data=[],endpoint="")=>{
    if(operation==='close'){
      setFileUploadDialogData({open:false})
      setSelectedFile("");
      setIsFilePicked(false);
      setUploadState("");
      updateFlag("unSavedChanges",false)
    }

    else{
          let input=[]
          input =data
          let reqOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(input),
          };
          setFileUploadDialogData({open:false})
          setUploadState("loading");
          setFileStatus("uploading");
          fetch(api + endpoint, reqOptions)
            .then((response) => response.json())
            .then((response) => {
              updateFlag("reloadNotification",true)
              updateFlag("unSavedChanges",false)
              if (response.status.statusCode === "cnc-200") {
             
                fetch(api+"/sync_price_forecast?commodity_selection_value="+cncData.commodity_selection_value)
                .then((res)=>(res.json()))
                .then((res)=>{
                  if (response.status.statusCode === "cnc-200") {
                    setSelectedFile("");
                    updateCNCInputs("uploadResponse",response.status.statusMessage)
                    setIsFilePicked(false);
                    updateAlertCNC({
                      open: true,
                      message: response.status.statusMessage,
                      severity: "success",
                      closeFlag: true,
                    });
                    setUploadState("success");
                    onClear();
                  }

                  else{
                    setSelectedFile("");
                    updateCNCInputs("uploadResponse",config.messages.file_upload_failed)
                    updateAlertCNC({
                      open: true,
                      message: config.messages.file_upload_failed,
                      severity: "error",
                      closeFlag: true,
                    });
                    setIsFilePicked(false);
                    setUploadState("error");
                  }
                })
                .catch((error)=>{
                  setSelectedFile("");
                  updateCNCInputs("uploadResponse",config.messages.file_upload_failed)
                  updateAlertCNC({
                    open: true,
                    message: config.messages.file_upload_failed,
                    severity: "error",
                    closeFlag: true,
                  });
                  setIsFilePicked(false);
                  setUploadState("error");
                })
                
              }
              else if (response.status.statusCode === "cnc-400") {
                setSelectedFile("");
                updateCNCInputs("uploadResponse",response.status.statusMessage)
                updateAlertCNC({
                  open: true,
                  message: response.status.statusMessage,
                  severity: "error",
                  closeFlag: true,
                });
                setIsFilePicked(false);
                setUploadState("error");
              }

              else{
                setSelectedFile("");
                updateCNCInputs("uploadResponse",response.status.statusMessage)
                updateAlertCNC({
                  open: true,
                  message: response.status.statusMessage,
                  severity: "error",
                  closeFlag: true,
                });
                setIsFilePicked(false);
                setUploadState("error");
              }
            })   
            .catch((error) => {
              // console.log(error)
              updateFlag("reloadNotification",true)
              updateFlag("unSavedChanges",false)
              setSelectedFile("");
                updateCNCInputs("uploadResponse",config.messages.file_upload_failed)
                updateAlertCNC({
                  open: true,
                  message: config.messages.file_upload_failed,
                  severity: "error",
                  closeFlag: true,
                });
                setIsFilePicked(false);
                setUploadState("error");
            });
    }
  }
  return (
    <>
    
    <div className={classes.root}>
      {(uploadState === "" || uploadState === "error"||uploadState === "success") && (
        <label
        //   disabled={disabled}
          htmlFor={fileId.current}
          className={classes.label}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            setIsFilePicked(false);
            setUploadState("");
            FileChangeHandler(e, "drop");
          }}
        >
          <input
            type="file"
            id={fileId.current}
            className={classes.inputFile}
            accept={".csv"}
            multiple={false}
            onChange={(e) => {
              e.preventDefault();
              setIsFilePicked(false)
              setUploadState("")
              FileChangeHandler(e,"select")
            }}
            // disabled={disabled}
          />
          <div className={classes.uploadFileClassCNC}>
            <div className="uploadImageContainerCNC">
                  <div className="imageContainerCNC">
                    <img src={uploadFile} alt="" draggable="false" />
                    <p>Drag and Drop</p>
                  </div>
                  <div className="orContainerCNC">or</div>
                  <div className="imageContainerCNC">
                    <img src={addUploadFile} alt="" draggable="false" />
                    <p>Upload from local</p>
                  </div>
                </div>
            {
              (uploadState==="" &&isFilePicked) &&(
                <>
                  <div className="fileInfoContainerCNC">
                    <p>Filename: {selectedFile.name}</p>
                    <p>Filetype: {selectedFile.type}</p>
                    <p>Size in bytes: {selectedFile.size}</p>
                  </div>
                </>
              )
            }   
             {/* <div className="fileInfoContainerCNC"> */}
                {/* <div className="errorMessage">
                        <i className="material-icons-outlined" >error</i>
                        <p>There is a mismatch of values between uploaded file and selection.kindly check and reupload </p>
                        </div>  */}
                  {/* <p>Price Forecast data uploaded to table successfully</p>       */}
             {/* </div> */}
            

            {
              (uploadState==="success"||uploadState==="error") &&(
                <>
                  <div className="fileInfoContainerCNC">
                    {
                      uploadState==="success" ?(
                        <p>{cncData.uploadResponse}</p>
                      ):(
                        <div className="errorMessage">
                        <i className="material-icons-outlined" >error</i>
                        <p>{cncData.uploadResponse}</p>
                        </div>
                      )
                    }
                  </div>
                </>
              )
            }    
          </div>      
        </label>
       )}
     {(uploadState === "loading") &&(
      <>
      <div className={classes.uploadFileClassCNC}>
        <div className="uploadImageContainerCNC">
        <Loader/>
        {
          filestatus===""?
          <p>Wait until File gets Validated....</p>:
          <p>File Validation Success,Price Forecast loading to table...</p>
        }        
        </div>
      </div>
      </>
     )}  
      {/* {(uploadState === "success" || uploadState === "error") && (
        <>
          <div
            className={classes.selectedLabel}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={(e) => {
              e.preventDefault();
              onChange(e, "drop");
            }}
            disabled={disabled}
          >
            {children}
            <div>
              <label htmlFor={fileId.current} disabled={disabled}>
                <input
                  type="file"
                  id={fileId.current}
                  className={classes.inputFileSelected}
                  accept={accept}
                  onChange={(e) => handleChange(e)}
                  disabled={disabled}
                />
                <p className={classes.replace}>Replace and Upload</p>
              </label>
            </div>
          </div>
        </>
      )} */}
    </div>
    {fileUploadDialogData.open && <UploadDialog props={fileUploadDialogData}/> }
    

                <div
                  style={{ margin: "3px !important" }}
                  className="cnc-button-footer"
                >
                  <div className="button-new">
                        <Button
                        autoFocus
                        variant={uploadState==="loading"?"":"outlined"}
                        style={uploadState==="loading"?outlinedButton:outlinedCNCButton}
                        onClick={onClear}
                        disabled={uploadState==="loading"?true:false}
                      >
                        Cancel
                    </Button>
                    <Button
                        autoFocus
                        style={uploadState==="loading"?outlinedButton:containedButton}
                        onClick={handleSubmission}
                        disabled={uploadState==="loading"?true:false}
                      >
                        Upload
                    </Button>
                    </div>
                  {/* <Buttons
                    button={[
                      {
                        name: "Cancel",
                        callback: onClear,
                        flag: false,
                        style: "outlined",
                      },
                    ]}
                  />
                  <Buttons
                    button={[
                      {
                        name: "Upload",
                        callback: handleSubmission,
                        flag: false,
                        style: "contained",
                      },
                    ]}
                  /> */}
                </div>
      {
        allData.unSavedChangesDialogData.open &&(
          <UnSavedConfirmationDialog props={{savefunction:handleSubmission,cancelfunction:onClear}}></UnSavedConfirmationDialog>
        )
      }            
    </>
  );
}

FileUploadPriceForecast.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
  updateFlag: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  cncData: state.cncinputs,
  allData: state.data,
});

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
  updateFlag,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FileUploadPriceForecast)
);
