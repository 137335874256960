import React from "react";
import { useEffect } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { config } from "../../../config/config";
import Dropdown from "./components/Dropdown";

//custom components

import AddUserGroup from "./components/AddUserGroup";

//actions
import {
  updateAlertCNC,
  updateAdminInput,
  updatewholeAdminInputs,
  updateFlag,
} from "../../../redux/actions";

//styles
import "./admin.scss";
import ManageUsers from "./components/ManageUsers";
let api = config.api.api_url;

function Admin(props) {
  let {
    user,
    admin_data,
    updateAdminInput,
    updatewholeAdminInputs,
    updateFlag,
    match,
  } = props;
  let username = user.preferred_username;

  function initializeState() {
    let initialState = [];
    initialState = admin_data;

    initialState.type_selection_value = "";
    initialState.userSelectedData = [];
    initialState.selected_user = [];
    initialState.users = [];
    initialState.userSelectedData = [];
    initialState.users_firestore = [];
    initialState.userGroupOptions = [];
    initialState.userList = [];
    initialState.group_label = "";
    initialState.group_value = "";
    initialState.groupData = [];
    initialState.activeData = [];
    initialState.activeSelections = {
      indexSelection: [],
      commoditySelection: [],
      regionSelection: [],
      countrySelection: [],
      factorySelection: [],
    };
    initialState.selected_user = "";
    initialState.reload_flag = false;
    updatewholeAdminInputs(initialState);
  }

  const userTypeChange = (e) => {
    updateAdminInput("type_selection_value", e.value);
    updateAdminInput("userSelectedData", []);
    // updateAdminInput("selected_user",[]);
    if (e.value === "edit_user") {
      userList();
    }
  };

  //function to get list of users from Data @Reckitt
  const userList = (item) => {
    fetch(config.api.userListUrl)
      .then((response) => response.json())
      .then((res) => {
        if (res.status.statusCode === "datareckitt-200") {
          let user_list = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].email.split("@")[0] !== username) {
              user_list[i] = {
                label: res.data[i].name,
                value: res.data[i].email.split("@")[0],
                email: res.data[i].email,
                groups: [{ label: "DEFAULT USER", value: "cnc" }],
              };
            }
          }
          updateAdminInput("userList", user_list);
        } else {
          updateAdminInput("userList", []);
        }
      })
      .catch((error) => {
        updateAdminInput("userList", []);
      });
  };

  //function to get User info from Firestore
  const getUser_from_firestore = () => {
    fetch(api + "/user_details")
      .then((res) => res.json())
      .then((res) => {
        if (res.status.statusCode === "cnc-200") {
          updateAdminInput("users_firestore", res.data);
          updateAdminInput("reload_flag", false);
        } else {
          updateAdminInput("users_firestore", []);
          updateAdminInput("reload_flag", false);
        }
      })
      .catch((error) => {
        updateAdminInput("users_firestore", []);
        updateAdminInput("reload_flag", false);
      });
  };
  //get Commodities
  const getCommodity = () => {
    let commodityOptions = [];
    fetch(api + "/commodities")
      .then((respone) => respone.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          for (let i = 0; i < response.data.length; i++) {
            commodityOptions[i] = {
              value: response.data[i].commodity_id,
              label: response.data[i].commodity_name,
              decision_months: response.data[i].decision_months,
              isIndexVisible: response.data[i].isIndexVisible,
              isCountryVisible: response.data[i].isCountryVisible,
              isDemandEditable: response.data[i].isDemandEditable,
              scenarioFlag: response.data[i].scenarioFlag,
              weight_graphs: response.data[i].weight_graphs,
              isExpandable: response.data[i].isExpandable,
              indices: response.data[i].indices,
              report_url: response.data[i].report_url,
              report_params: response.data[i].report_params,
              demand_difference_url: response.data[i].demand_difference_url,
              demand_difference_url_params:
                response.data[i].demand_difference_url_params,
            };
          }
          updateAdminInput("commodityOptions", commodityOptions);
        } else {
          updateAdminInput("commodityOptions", []);
        }
      })
      .catch((error) => {
        updateAdminInput("commodityOptions", []);
      });
  };
  //Function to get Regions
  const getRegions = () => {
    let regionOptions = [];

    //fetch call
    let input = {
      level: "region",
      parent_id: "",
    };
    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };

    fetch(api + "/geography", reqOptions)
      .then((respone) => respone.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          for (let i = 0; i < response.data.length; i++) {
            // if(response.data[i].geography !=="Global"){
            regionOptions[i] = {
              value: response.data[i].geography_id,
              label: response.data[i].geography,
              geography_level: response.data[i].geography_level,
              parent_id: response.data[i].parent_id,
            };
            // }
          }
          updateAdminInput("regionOptions", regionOptions);
        } else {
          updateAdminInput("regionOptions", []);
        }
      })
      .catch((error) => {
        updateAdminInput("regionOptions", []);
      });
  };

  useEffect(() => {
    if (
      admin_data.users_firestore !== undefined &&
      admin_data.userList !== undefined &&
      admin_data.type_selection_value === "add_user"
    ) {
      let users = []; //this array will contain all the users who are in Data@Reckitt and not in firestore
      let fs_users = admin_data.users_firestore;
      let dr_users = admin_data.userList;
      for (let i = 0; i < dr_users.length; i++) {
        let flag = 0;
        for (let j = 0; j < fs_users.length; j++) {
          if (
            dr_users[i] !== undefined &&
            dr_users[i].value === fs_users[j].preferred_username
          ) {
            flag = 1;
            break;
          }
        }

        if (flag === 0) {
          users.push(dr_users[i]);
        }
      }
      users = users.filter((x) => x !== undefined);
      updateAdminInput("users", users);
    } else if (
      admin_data.users_firestore !== undefined &&
      admin_data.userList !== undefined &&
      admin_data.type_selection_value === "edit_user"
    ) {
      let users = []; //this array will contain all the users who are in firestore
      let fs_users = admin_data.users_firestore;

      for (let i = 0; i < fs_users.length; i++) {
        let groups = [];

        for (let j = 0; j < fs_users[i].groups.length; j++) {
          groups[j] = {
            label: fs_users[i].groups[j],
            value: fs_users[i].groups[j],
          };
        }
        users[i] = {
          label: fs_users[i].name,
          value: fs_users[i].preferred_username,
          email: fs_users[i].email,
          groups: groups,
        };
      }
      updateAdminInput("users", users);
    }
  }, [admin_data.users_firestore, admin_data.userList]);

  useEffect(() => {
    fetch(api + "/admin_configs")
      .then((res) => res.json())
      .then((res) => {
        if (res.status.statusCode === "cnc-200") {
          updateAdminInput("admin_options", res.data.admin_options);
          updateAdminInput("userGroupOptions", res.data.user_roles);
          updateAdminInput("type_selection_value", "");
        } else {
          updateAdminInput("admin_options", []);
          updateAdminInput("userGroupOptions", []);
          updateAdminInput("type_selection_value", "");
        }
        // let user_group_opt=[];
        // for(let i=0;i<res.data.length;i++){
        //     user_group_opt[i]={
        //         label:res.data[i].role_name,
        //         value:res.data[i].role_id
        //     }
        // }

        // updateAdminInput("userGroupOptions",user_group_opt);
      })
      .catch((error) => {
        updateAdminInput("admin_options", []);
        updateAdminInput("userGroupOptions", []);
        updateAdminInput("type_selection_value", "");
      });
    updateFlag("currentPageID", match.url.split("/")[2]);
  }, []);
  useEffect(() => {
    // getUserGroupOptions()
    getRegions();
    getCommodity();
    getUser_from_firestore();
    userList();
  }, [admin_data.type_selection_value]);

  useEffect(() => {
    if (admin_data.reload_flag) {
      getUser_from_firestore();
    }
  }, [admin_data.reload_flag]);

  const onCancel = () => {
    updateAdminInput("type_selection_value", "");
    initializeState();
  };

  useEffect(() => {
    updateAdminInput("type_selection_value", "");
    initializeState();
  }, []);

  let initial_props = {
    options: admin_data.admin_options,
    callback: userTypeChange,
    placeholder: "Select a Type",
  };
  return (
    <>
      <div className="admin-util-header">
        <Grid container spacing={1} className="grid-container-style">
          <Grid item xs={5} sm={5}>
            <div className="admin-util-header-item">
              Select a Type: <p className="mandatory-field">*</p>
            </div>
            <div className="admin-util-body-item">
              {admin_data.admin_options && (
                <Dropdown
                  {...initial_props}
                  updateValue={admin_data.type_selection_value}
                ></Dropdown>
              )}
            </div>
          </Grid>
          {/* {(admin_data.type_selection_value ==="add_user"||admin_data.type_selection_value ==="edit_user") &&
              admin_data.users !== undefined &&
              admin_data.users.length >= 0 && (
                <>
                  <Grid item xs={7} sm={7}>
                    <div className="admin-util-header-item">
                      Select User(s): <p className="mandatory-field">*</p>
                    </div>
                    <div className="admin-util-body-item">
                        <ReactSelect
                            options={admin_data.users}
                            isMulti
                            hideSelectedOptions={false}
                            value={admin_data.users.filter((obj) =>
                                admin_data.selected_user.some(
                                  (item) => item.value === obj.value
                                )
                            )}
                            placeholder="Select user"
                            components={{ Option }}
                            allowSelectAll={true}
                            menuPortalTarget={document.body} 
                            onChange={handleuserSelection}
                        />
                    </div>
                    
                  </Grid>

                    
                </>
              )} */}
        </Grid>
      </div>
      {admin_data.type_selection_value === "add_user_group" ? (
        <AddUserGroup onCancel={onCancel} initializeState={initializeState} />
      ) : (
        admin_data.type_selection_value && <ManageUsers />
      )}
      {/* {admin_data.userSelectedData !== undefined &&admin_data.selected_user.length>0&&(
                <div className="cnc-button-footer">
                        <div className="jss546" style={{ marginTop: 30 }}>
                            <Button
                            disableRipple
                            className="jss275"
                            variant="outlined"
                            style={outlinedButton}
                            onClick={onCancel}
                            >
                            Cancel
                            </Button>
                            <Button
                            disableRipple
                            className="jss2372"
                            variant="contained"
                            style={containedButton}
                            onClick={onSave}
                            >
                            {admin_data.type_selection_value === "add_user" ? "Save" : "Update"}

                            </Button>
                            </div>

                </div>
                
            )}    */}
    </>
  );
}

Admin.propTypes = {
  user: PropTypes.object,
  updateAlertCNC: PropTypes.func,
  updateFlag: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  admin_data: state.admin_data,
});

const mapDispatchToProps = {
  updateAlertCNC,
  updateAdminInput,
  updatewholeAdminInputs,
  updateFlag,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
