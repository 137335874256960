import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useState } from "react";
import {config} from "../../../config/config";
// import Button from '@material-ui/core/Button';
import {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
  updateCNCDatas,
  updateFlag
} from "../../../redux/actions";
import Buttons from "../../../components/Button/Buttons";
import Loader from "../../../components/Loader/Loader";
import nocommodity from "../../../../assets/images/undraw_empty.svg";
import BudgetTable from "./components/BudgetTable";
import BudgetFilterSelection from "./components/BudgetFilterSelection";
import { v4 } from "uuid";
import BudgetCommentsDialog from "./components/BudgetCommentsDialog";
import Card from '@material-ui/core/Card';
import new_entry_2 from "../../../../assets/images/add_new_entry_2.svg"

import UnSavedConfirmationDialog from "../../../utils/UnSavedConfirmationDialog";
export const Budget = (props) => {
  let {
    user,
    cncData,
    allData,
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateCNCDatas,
    updateFlag,
    match,
    flags
  } = props;
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  let api = config.api.api_url;
  const [page, setPage] = React.useState(0);
  const [CommentsDialogData, setCommentsDialogData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let today = new Date();
  let currentUTC =
    today.getUTCFullYear() +
    "-" +
    (today.getUTCMonth() < 9
      ? "0" + (today.getUTCMonth() + 1)
      : today.getUTCMonth() + 1) +
    "-" +
    (today.getUTCDate() < 10 ? "0" + today.getUTCDate() : today.getUTCDate());
  //function to set page for changing table row
  const pageChange = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let page_props = {
    total: cncData.filteredData ? cncData.filteredData.length : 0,
    page: page,
    changePage: pageChange,
    rowsPerPage:rowsPerPage,
    handleChangeRowsPerPage:handleChangeRowsPerPage,
    show_pageOptions:true
  };

  const handleCommments = (user_comments, id) => {
    let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));
    newFilteredData.map((obj, idx) => {
      if (obj.id === id) {
        obj.user_comments = user_comments;
      }
    });

    updateCNCInputs("filteredData", newFilteredData);

    let newBudgetData=JSON.parse(JSON.stringify(allData.budget_data))

    newBudgetData.map((obj,idx)=>{
      if(obj.id===id){
        obj.user_comments = user_comments;
      }
    })

    updateCNCDatas("budget_data",newBudgetData)

    DialoghandleClose();
  };

  const handleDialog = (user_comments, id) => {
    setCommentsDialogData({
      open: true,
      user_comments: user_comments,
      id: id,
      handleCommments: handleCommments,
      close: DialoghandleClose,
    });
  };
  const DialoghandleClose = () => {
    setCommentsDialogData({
      ...CommentsDialogData,
      open: false,
    });
  };

  

  const handleTypeChange=(event,id)=>{
    let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))

    newFilteredData.map((obj,idx)=>{
      if(obj.id===id){
        obj.type=event.value
        obj.month_visible=event.month_visible
        obj.month=""
      }
    })

    updateCNCInputs("filteredData",newFilteredData)

    let newBudgetData=JSON.parse(JSON.stringify(allData.budget_data))

    newBudgetData.map((obj,idx)=>{
      if(obj.id===id){
        obj.type=event.value
        obj.month_visible=event.month_visible
        obj.month=""
      }
    })

    updateCNCDatas("budget_data",newBudgetData)
  }

  

  const handleYearChange=(event,id)=>{
    
    
    let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))

    newFilteredData.map((obj,idx)=>{
      if(obj.id===id){
        obj.year=event.value
      }
    })

    updateCNCInputs("filteredData",newFilteredData)

    let newBudgetData=JSON.parse(JSON.stringify(allData.budget_data))

    newBudgetData.map((obj,idx)=>{
      if(obj.id===id){
        obj.year=event.value
      }
    })

    updateCNCDatas("budget_data",newBudgetData)
  }

   //to handle input changes for executed volume,exe price LCY &FCY
   const handleMonthChange=(event,id)=>{
    let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))

    newFilteredData.map((obj,idx)=>{
      if(obj.id===id){
        obj.month=event.value
      }
    })
    updateCNCInputs("filteredData",newFilteredData)

    let newBudgetData=JSON.parse(JSON.stringify(allData.budget_data))

    newBudgetData.map((obj,idx)=>{
      if(obj.id===id){
        obj.month=event.value
      }
    })

    updateCNCDatas("budget_data",newBudgetData)
  }


  

  const monthOptions=[
    {
        label:"January",
        value:"January"
    },
    {
        label:"February",
        value:"February"
    },
    {
        label:"March",
        value:"March"
    },
    {
        label:"April",
        value:"April"
    },
    {
        label:"May",
        value:"May"
    },
    {
        label:"June",
        value:"June"
    },
    {
        label:"July",
        value:"July"
    },
    {
        label:"August",
        value:"August"
    },
    {
        label:"September",
        value:"September"
    },
    {
        label:"October",
        value:"October"
    },
    {
        label:"November",
        value:"November"
    },
    {
        label:"December",
        value:"December"
    },

  ]

  function initializeState() {
    let initialState = [];
    initialState.loading = false;
    initialState.mode = "";
    initialState.commodityOptions = [];
    initialState.commodity_selection = "";
    initialState.commodity_selection_value = "";
    initialState.month_selection = "";
    initialState.month_selection_value = "";
    initialState.month_options = [];
    initialState.yearOptions=[];
    initialState.isIndexVisible = false;
    initialState.isCountryVisible = false;
    initialState.indexOptions = [];
    initialState.index_commodity_id = "";
    initialState.index_source_weights = [];
    initialState.factory_chosen = [];
    initialState.filteredData = [];
    initialState.filteredDataCopy = [];
    initialState.budget_type_options=[];
    initialState.type_filter="";
    initialState.year_filter="";
    updatewholeCNCInputs(initialState);
    updateCNCDatas("budget_data",[])
  }

  useEffect(() => {
    setLoading(true)
    initializeState();
    getCommodity();
    generateYearsOptions();
    setDemandPlannerInfo();
    updateFlag("currentPageID",match.url.split("/")[2])
  }, []);

  //generate year options with current +2 years
  const generateYearsOptions=()=>{
    const year=new Date().getFullYear()-2;
    
    let yearOptions=[]

    for(let i=0;i<6;i++){
        yearOptions[i]={
            label:year+i,
            value:year+i 
        }
    }
    updateCNCInputs("yearOptions",yearOptions)
  }

  //get Commodities
  const getCommodity = () => {
    let commodityOptions = [];
    fetch(api + "/commodities")
      .then((respone) => respone.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          for (let i = 0; i < response.data.length; i++) {
            commodityOptions[i] = {
              value: response.data[i].commodity_id,
              label: response.data[i].commodity_name,
              decision_months: response.data[i].decision_months,
              isIndexVisible: response.data[i].isIndexVisible,
              isCountryVisible: response.data[i].isCountryVisible,
              indices: response.data[i].indices,
              report_url: response.data[i].report_url,
              report_params: response.data[i].report_params,
              demand_difference_url: response.data[i].demand_difference_url,
              demand_difference_url_params:
                response.data[i].demand_difference_url_params,
              isDairy: response.data[i].isDairy,
              budget_type_options:response.data[i].budget_type
            };
          }
          updateCNCInputs("commodityOptionsAll", commodityOptions);
          setLoading(false)
        } else {
          updateCNCInputs("commodityOptionsAll", []);
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
        updateCNCInputs("commodityOptionsAll", []);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag: false,
        });
      });
  };

  useEffect(()=>{

    if(cncData.commodityOptionsAll !==undefined&&cncData.commodityOptionsAll.length >0 && cncData.commodity_selection_value!==""){
      cncData.commodityOptionsAll.filter((obj)=>{
        if(obj.value ===cncData.commodity_selection_value){
          updateCNCInputs("grade_commodity_flag",obj.grade_commodity_flag)
          updateCNCInputs("isCountryVisible",obj.isCountryVisible)
          updateCNCInputs("isDairy",obj.isDairy)
          updateCNCInputs("budget_type_options",obj.budget_type_options)
          updateCNCInputs("isIndexVisible",obj.isIndexVisible)
        }
      })
    }

  },[cncData.commodityOptionsAll,cncData.commodity_selection_value])

  const setDemandPlannerInfo=()=>{
    if(user.group_add_info!==undefined &&user.group_add_info!==null){
      let commodity=user.group_add_info.commodity;
      let commodityOptions=[];
      for(let i=0;i<commodity.length;i++){
        commodityOptions[i] = {
          value: commodity[i].commodity_id,
          label: commodity[i].commodity_name,
          decision_months:commodity[i].decision_months,
          indices:commodity[i].indices,
          factory_id:commodity[i].factory_id,
          isIndexVisible:commodity[i].isIndexVisible,
        }
      }
      updateCNCInputs("commodityOptions", commodityOptions);
      if(commodityOptions.length ===1){
        updateCNCInputs("commodity_selection",commodityOptions[0].label)
        updateCNCInputs("commodity_selection_value",commodityOptions[0].value)
        // getNext_N_MonthNamesWithYear(commodityOptions[0].decision_months);
        let indexOptions=[];
        let e=commodityOptions[0];
          for (let i = 0; i < e.indices.length; i++) {
            indexOptions[i] = {
              label: e.indices[i].index_name,
              value: e.indices[i].index_commodity_id,
              source_weights: e.indices[i].source_weight
            }
          }
          if (indexOptions.length === 1) {
            updateCNCInputs("index_commodity_id", indexOptions[0].value)
            updateCNCInputs("index_source_weights",indexOptions[0].source_weights)
          }
          updateCNCInputs("indexOptions", indexOptions);
          // let factoryOptions=[];
          // let factory_id=e.factory_id;
          // for (let i = 0; i < factory_id.length; i++) {
          //   factoryOptions[i] = {
          //     value: factory_id[i],
          //     label: factory_id[i],
          //     geography_level:"factory",
          //   }
          // }
          // if(factoryOptions.length ==1){
          //   updateCNCInputs("factory_selection", [factoryOptions[0]]);
          // }
          //   updateCNCInputs("factoryOptions",factoryOptions);    
      }
    }
  }

    //to handle row edit,save,cancel
    const handleRowEdit=(id,type,index)=>{
        if(!flags.unSavedChanges)
        updateFlag("unSavedChanges",true) //to notify some changes are being done in screen
        let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
        let newBudgetData=JSON.parse(JSON.stringify(allData.budget_data))
        
        if(type==="edit"){
          newFilteredData.map((obj,idx)=>{
            if(obj.id===id){
              
              obj.edit_flag=true
            }
          })
          updateCNCInputs("filteredData",newFilteredData)

          newBudgetData.map((obj,idx)=>{
            if(obj.id===id){
              
              obj.edit_flag=true
            }
          })
          updateCNCDatas("budget_data",newBudgetData)
        }
    
        else if(type==="save"){
          let isValid=false;//used to check whether row has all fields selected
          let notDuplicate=false;//used to check whether row is not duplicate
          let Row;
          newFilteredData.map((obj,idx)=>{
            if(obj.id===id){
              Row=obj;
              if (
                obj.month_visible
                  ? obj.type === "" ||
                    obj.year === "" ||
                    obj.month === "" ||
                    (isNaN(obj.value) || obj.value === ""||obj.value===null)
                  : obj.type === "" || obj.year === "" || (isNaN(obj.value) || obj.value === ""||obj.value===null)
              ){
                updateAlertCNC({
                  open: true,
                  message: "Fill all Mandatory Fields",
                  severity: "info",
                  closeFlag: false,
                });
              }

              else{
                obj.edit_flag = false;
                isValid = true;
              }
              
            }
          })

          notDuplicate=checkforDuplicateRow(Row)

          if(isValid &&notDuplicate){
              updateCNCInputs("filteredData",newFilteredData)

            newBudgetData.map((obj,idx)=>{
              if(obj.id===id){ 
                obj.edit_flag=false
              }
            })
            updateCNCDatas("budget_data",newBudgetData)
      
            //update the changed row in  filterDataCopy as well
            let index;
            let filteredDataCopy=JSON.parse(JSON.stringify(cncData.filteredDataCopy))
            filteredDataCopy.map((obj,idx)=>{
              if(obj.id===id){
                index=idx
              }
            })
            filteredDataCopy[index]={...newBudgetData[index]}
            updateCNCInputs("filteredDataCopy",filteredDataCopy)
          }

          else{
            return;
          }
          
        }
    
        else{
         let isEmptyRow=false;
         let index;
         let filteredDataCopy=JSON.parse(JSON.stringify(cncData.filteredDataCopy))
         newFilteredData.map((obj,idx)=>{
          if(obj.id===id){
            // index=idx
            obj.edit_flag=false
          }
        })

          //check the copy arr for the empty row as well
          filteredDataCopy.map((obj,idx)=>{
            if(obj.id===id){
              index=idx
              if (
                obj.month_visible
                ? obj.type === "" &&
                  obj.year === "" &&
                  obj.month === "" &&
                  (isNaN(obj.value)  || obj.value === ""||obj.value===null)
                : obj.type === "" && obj.year === "" && (isNaN(obj.value) || obj.value === ""||obj.value===null)       
              ){
                
                isEmptyRow=true
                return;
              }
              else{
                obj.edit_flag=false
              }
              
            }
          })

          //if all values of the row are empty and user click cancel remove the row else replace row from copy array
          if(isEmptyRow){
            let newArr=newFilteredData.filter((obj,idx)=>{
              return index!==idx
            })
            updateCNCInputs("filteredData",newArr) 
            
            let newCopyArr=filteredDataCopy.filter((obj,idx)=>{
              return index!==idx
            })
            updateCNCInputs("filteredDataCopy",newCopyArr) 

            let newBudgArr=newBudgetData.filter((obj,idx)=>{
              return index!==idx
            })
            updateCNCDatas("budget_data",newBudgArr) 
          }

          else{
            newBudgetData.map((obj,idx)=>{
              if(obj.id===id){ 
                index=idx
                obj.edit_flag=false
              }
            })
            updateCNCDatas("budget_data",newBudgetData)
            console.log("index in else",index)

              for(let i=0;i<newFilteredData.length;i++){
                if(newFilteredData[i].id===id){
                  newFilteredData[i]={...cncData.filteredDataCopy[index]}
                  break;
                }
              }
              // newFilteredData[index]={
              //   ...cncData.filteredDataCopy[index],
              // };

              newBudgetData[index]={
                ...cncData.filteredDataCopy[index],
              };
        
              updateCNCInputs("filteredData",newFilteredData) 
              updateCNCDatas("budget_data",newBudgetData)
          }

       
        }  
      }

function formattedDate(date) {
    let d = new Date(date);
    let month = String(d.getMonth() + 1);
    let day = "01";
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "01";
    return `${year}-${month}-${day}`;
 }  

 
 const validateRow=()=>{
    if (cncData.filteredData !== undefined && cncData.filteredData.length > 0) {
      for (let i = 0; i < cncData.filteredData.length; i++) {
        let row = { ...cncData.filteredData[i] };

        if (
          row.month_visible
            ? row.type === "" ||
              row.year === "" ||
              row.month === "" ||
              isNaN(row.value) || row.value === ""||row.value === null
            : row.type === "" || row.year === "" || (isNaN(row.value) || row.value === ""||row.value === null)
        ) {
          return false;
        }
      }

      return true;
    }
    
  }

  const checkforDuplicateRow=(row)=>{
    let newBudgetData=JSON.parse(JSON.stringify(allData.budget_data));

    for(let i=0;i<newBudgetData.length;i++){
      if(row.id!==newBudgetData[i].id){
        if(row.month_visible){
          if(newBudgetData[i].type===row.type &&newBudgetData[i].year===row.year &&newBudgetData[i].month===row.month){
            updateAlertCNC({
              open: true,
              message: "Cannot add a Duplicate row,There is already a row with current selection ",
              severity: "info",
              closeFlag: false,
            });
            return false
          }
        }
  
        else{
          if(newBudgetData[i].type===row.type &&newBudgetData[i].year===row.year){
            updateAlertCNC({
              open: true,
              message: "Cannot add a Duplicate row,There is already a row with current selection ",
              severity: "info",
              closeFlag: false,
            });
            return false
          }
        }
      }
     
    }

    return true


  }
 
 const addNewRow=(isEmpty=false)=>{
    setPage(0)
    if(!flags.unSavedChanges)
    updateFlag("unSavedChanges",true) //to notify some changes are being done in screen
    //isEmpty is used when no execution data available & a new empty row needs to be added
    if(isEmpty){
      let newRow={
            id:v4(),
            commodity:"",
            index:"",
            type:"",
            year:"",
            month:"",
            value:NaN,
            user_comments:"",
            plan_user:"",
            timestamp:"",
            edit_flag:true,
            month_visible:false,
            exisiting_data:false
        }
      
  
      
      let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
      newFilteredData.unshift(newRow);
      updateCNCInputs("filteredData",newFilteredData)
  
      //push the new row in filteredDataCopy as well
      let newFiltereddatacopy=JSON.parse(JSON.stringify(cncData.filteredDataCopy))
      newFiltereddatacopy.unshift(newRow);
      updateCNCInputs("filteredDataCopy",newFiltereddatacopy)
      
      //push the new row in budget_data in Datas state as well
      let newBudgetData=JSON.parse(JSON.stringify(allData.budget_data))
      newBudgetData.unshift(newRow);
      updateCNCDatas("budget_data",newBudgetData)

    }

    else{
      if(!validateRow()){
        updateAlertCNC({
          open: true,
          message: config.messages.fill_mandatory,
          severity: "info",
          closeFlag: false,
        });
      }

      if(checkforeditrowsOnly()){
        updateAlertCNC({
          open: true,
          message: "Save already editing rows before adding new row",
          severity: "info",
          closeFlag: false,
        });
      }

  
      else{

        let newRow={
            id:v4(),
            commodity:"",
            index:"",
            type:"",
            year:"",
            month:"",
            value:NaN,
            user_comments:"",
            plan_user:"",
            timestamp:"",
            edit_flag:true,
            month_visible:false,
            exisiting_data:false
        }
    
        
        let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
         //before adding a new row reset edit flag to false for other rows(if any)
         newFilteredData.map((obj)=>{
          if(obj.edit_flag){
            obj.edit_flag=false
          }
        })
        newFilteredData.unshift(newRow);

        
        updateCNCInputs("filteredData",newFilteredData)
    
        //push the new row in filteredDataCopy as well
        
        let newFiltereddatacopy=JSON.parse(JSON.stringify(cncData.filteredDataCopy))
        newFiltereddatacopy.unshift(newRow);
        updateCNCInputs("filteredDataCopy",newFiltereddatacopy)

        //push the new row in budget_data in Datas state as well
        let newBudgetData=JSON.parse(JSON.stringify(allData.budget_data))
        newBudgetData.unshift(newRow);
        updateCNCDatas("budget_data",newBudgetData)
      }
    }

   
 
  }

  function onCancel() {
    setLoading(true)
    updateCNCInputs("commodity_selection","")
    updateCNCInputs("commodity_selection_value","")
    updateCNCInputs("index_commodity_id", "")
    initializeState();
    getCommodity();
    generateYearsOptions();
    setDemandPlannerInfo();
    updateFlag("unSavedChanges",false)
    history.push({
      pathname: `/home/budget`,
    });
  } 

 
  //to handle input changes for executed volume,exe price LCY &FCY
  const handleInputchange=(event,id,name)=>{
    let newFilteredData=JSON.parse(JSON.stringify(cncData.filteredData))
    // let name=event.target.name
    newFilteredData.map((obj,idx)=>{
      if(obj.id===id){
        obj[name]=event.floatValue
      }
    })

    updateCNCInputs("filteredData",newFilteredData)


    let newBudgetData=JSON.parse(JSON.stringify(allData.budget_data))

    newBudgetData.map((obj,idx)=>{
      if(obj.id===id){
        obj[name]=event.floatValue
      }
    })

    updateCNCDatas("budget_data",newBudgetData)

    
  }

  //this function is used to check whether any rows is currently being edited
  const checkforeditrows=()=>{
    let newBudgetData=JSON.parse(JSON.stringify(allData.budget_data))
    let hasEditRow=false; 
    
      for(let i=0;i<newBudgetData.length;i++){
        if(newBudgetData[i].edit_flag){
          hasEditRow=true
          break
        }
      }

      if(hasEditRow){
        updateAlertCNC({
          open: true,
          message:"Cannot save when rows are being edited",
          severity: "info",
          closeFlag:false
        });
      }

      else{
       saveBudgetData()
      }
       
        
  }

  //this function is used to check whether any rows is currently being edited
  const checkforeditrowsOnly=()=>{
    let newBudgetData=JSON.parse(JSON.stringify(allData.budget_data))
    let hasEditRow=false; 
    
      for(let i=0;i<newBudgetData.length;i++){
        if(newBudgetData[i].edit_flag){
          hasEditRow=true
          break
        }
      }

      if(hasEditRow){
        updateAlertCNC({
          open: true,
          message:"Cannot save when rows are being edited",
          severity: "info",
          closeFlag:false
        });
        return true
      }

      return false    
        
  }




  const saveBudgetData=()=>{
    updateCNCInputs("loading",true)
    updateCNCInputs("loading_message","Budget Data Uploading...")
    let triggered_time =
    currentUTC +
    " " +
    (today.getUTCHours() < 10
      ? "0" + today.getUTCHours()
      : today.getUTCHours()) +
    ":" +
    (today.getUTCMinutes() < 10
      ? "0" + today.getUTCMinutes()
      : today.getUTCMinutes()) +
    ":" +
    (today.getUTCSeconds() < 10
      ? "0" + today.getUTCSeconds()
      : today.getUTCSeconds());

  let filteredData=JSON.parse(JSON.stringify(allData.budget_data)) 

  let input={
    timestamp:triggered_time,
    commodity_selection_value:cncData.commodity_selection_value,
    index_commodity_id:cncData.index_commodity_id,
    user:user.preferred_username,
    filteredData:filteredData,
  }

  let reqOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };
  updateAlertCNC({
    open: true,
    message:"Budget data will be uploaded shortly",
    severity: "info",
    closeFlag:true
  });
  fetch(api + "/update_budget", reqOptions)
  .then((response) => response.json())
  .then((response) => {
    
    updateFlag("unSavedChanges",false)
    if (response.status.statusCode === "cnc-200") {
      
      fetch(api+"/sync_budget_data?commodity_selection_value="+cncData.commodity_selection_value)
          .then((res)=>(res.json()))
          .then((res)=>{
            if (response.status.statusCode === "cnc-200"){
              updateCNCInputs("loading",false)
              updateAlertCNC({
                open: true,
                message:"Budget data uploaded successfully",
                severity: "info",
                closeFlag:true
              });
              initializeState();
              getCommodity();
              generateYearsOptions();
              setDemandPlannerInfo()
            }

            else{
              updateCNCInputs("loading",false)
              updateAlertCNC({
                open: true,
                message: config.messages.unexpected_error,
                severity: "info",
                closeFlag:false
              });
              initializeState();
              getCommodity();
              generateYearsOptions();
              setDemandPlannerInfo()
            }
           
          })
          .catch((error) => {
            updateCNCInputs("loading",false)
            updateAlertCNC({
              open: true,
              message: config.messages.unexpected_error,
              severity: "info",
              closeFlag:false
            });
            initializeState();
            getCommodity();
            generateYearsOptions();
            setDemandPlannerInfo()
          });   
     
      
    }

    else if(response.status.statusCode === "cnc-400"){
      updateCNCInputs("loading",false)
      updateAlertCNC({
        open: true,
        message:response.status.statusMessage,
        severity: "info",
        closeFlag:false
      });
      initializeState();
      getCommodity();
      generateYearsOptions();
      setDemandPlannerInfo()
      history.push({
        pathname: `/home/budget`,
      });
    }
  })
  .catch((error)=>{
    updateCNCInputs("loading",false)
    updateFlag("unSavedChanges",false)
    updateAlertCNC({
      open: true,
      message:config.messages.unexpected_error,
      severity: "info",
      closeFlag:false
    });
    initializeState();
    getCommodity();
    generateYearsOptions();
    setDemandPlannerInfo()
    history.push({
      pathname: `/home/budget`,
    });
  })
  }
  return (
    <>
    {
      loading?(
        <Loader />
      ):(
        (user.group_add_info!==undefined && user.group_add_info!==null)?
        <>
        <BudgetFilterSelection setPage={setPage}/>
      {cncData.loading ? (
        <>
          <Card
            variant="outlined"
            style={{
              padding: 0,
              maxHeight: !cncData.loading && !cncData.expand ? "65vh" : "45vh",
              height: cncData.loading && "290px",
            }}
          >
            <Loader />
            <p style={{ textAlign: "center" }}>
              {cncData.loading_message && cncData.loading_message !== ""
                ? cncData.loading_message
                : "Fetching Budget data..."}
            </p>
          </Card>
       
        </>
      ) : (
        <>
          {cncData.commodity_selection_value !== "" &&
            cncData.index_commodity_id !== "" && (
              <>
                {allData.budget_data && allData.budget_data.length > 0 ? (
                  <>
                    {cncData.filteredData !== undefined &&
                    cncData.filteredData.length > 0 ? (
                      <>
                        <BudgetTable
                          component="Budget"
                          filteredData={cncData.filteredData}
                          page_props={page_props}
                          page={page}
                          yearOptions={cncData.yearOptions}
                          monthOptions={monthOptions}
                          rowsPerPage={rowsPerPage}
                          handleTypeChange={handleTypeChange}
                          handleDialog={handleDialog}
                          handleRowEdit={handleRowEdit}
                          handleInputchange={handleInputchange}
                          handleMonthChange={handleMonthChange}
                          handleYearChange={handleYearChange}
                          formattedDate={formattedDate}
                        />

                        {
                          <div
                            // className="cnc-button-footer"
                            style={{
                              position: "absolute",
                              bottom: "30px",
                              right: "30px",
                            }}
                          >
                            <div className="jss546">
                              <Buttons
                                button={[
                                  {
                                    name: "Cancel",
                                    callback: () => {
                                      onCancel();
                                    },
                                    flag: false,
                                    style: "outlined",
                                  },
                                ]}
                              />
                              <Buttons
                                button={[
                                  {
                                    name: "Add New",
                                    callback: () => {
                                      addNewRow(false);
                                    },
                                    flag: false,
                                    style: "contained",
                                  },
                                ]}
                              />
                              <Buttons
                                button={[
                                  {
                                    name: "Save",
                                    callback: checkforeditrows,
                                    flag: false,
                                    style: "contained",
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        }
                      </>
                    ) : (
                      <>
                        <Card
                          variant="outlined"
                          style={{
                            maxHeight: "45vh",
                          }}
                        >
                          <div
                            className="no-commodity"
                            style={{ padding: "3%" }}
                          >
                            <img
                              src={nocommodity}
                              alt=""
                              className="cnc-img"
                              style={{ height: "25vh", cursor: "pointer" }}
                            />

                            <p
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "14px",
                              }}
                            >
                              No Budget data available
                            </p>
                          </div>
                        </Card>
                        <div
                      // className="cnc-button-footer"
                      style={{
                        position: "absolute",
                        bottom: "30px",
                        right: "30px",
                      }}
                    >
                      <div className="jss546">
                        <Buttons
                          button={[
                            {
                              name: "Cancel",
                              callback: () => {
                                onCancel();
                              },
                              flag: false,
                              style: "outlined",
                            },
                          ]}
                        />
                      
                      </div>
                    </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                   <Card
                        variant="outlined"
                        style={{
                          maxHeight: "45vh",
                        }}
                      >
                        {
                          cncData.fetch_status?(
                            <div className="no-commodity" style={{ padding: "3%" }}>
                      <img
                        src={new_entry_2}
                        alt=""
                        className="cnc-img"
                        style={{ height: "25vh", cursor: "pointer" }}
                        onClick={() => {
                          addNewRow(true);
                        }}
                      />

                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "14px",
                        }}
                      >
                        No Budget data available for the selection, Click add
                        icon for creating new entries
                      </p>
                            </div>
                          ):(
                            <div className="no-commodity">
                            <img
                              src={nocommodity}
                              alt=""
                              className="cnc-img"
                            />
                            <p style={{ marginLeft: "25px",width:"120%",justifyContent:"center",display:"flex" }}>
                              {cncData.errorMessage?cncData.errorMessage:"No Budget data available"}
                              </p>
                               
                          </div>
                          )
                        }
                    

                    </Card>
                    <div
                      // className="cnc-button-footer"
                      style={{
                        position: "absolute",
                        bottom: "30px",
                        right: "30px",
                      }}
                    >
                      <div className="jss546">
                        <Buttons
                          button={[
                            {
                              name: "Cancel",
                              callback: () => {
                                onCancel();
                              },
                              flag: false,
                              style: "outlined",
                            },
                          ]}
                        />
                      
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
        </>
      )}

      {CommentsDialogData.open && (
        <BudgetCommentsDialog props={CommentsDialogData}></BudgetCommentsDialog>
      )}

      {
        allData.unSavedChangesDialogData.open &&(
          <UnSavedConfirmationDialog props={{savefunction:checkforeditrows,cancelfunction:onCancel}}></UnSavedConfirmationDialog>
        )
      }

        </>
        :
        <>
           <div className="no-commodity">
               <img src={nocommodity} alt="" className="cnc-img"/>
               <p>No commodity configured for the user</p>
             </div>
       </>
      )
    }
      
    </>
  );
}

Budget.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
  updateFlag: PropTypes.func,
}

const mapStateToProps = (state) => ({
    user: state.user,
    cncData: state.cncinputs,
    allData: state.data,
    flags:state.flags,
})

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
  updateFlag
}

export default connect(mapStateToProps, mapDispatchToProps)(Budget)