/*
Created on March 2022
Confidential and Proprietary of Reckitt
Project: CNC
Screen name : AddCommodity
Screen Description : 
@author: Tailwyndz
*/

//Import Libraries
import React, { useEffect,useRef } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from 'react';
import {config} from '../../../config/config';
import { withRouter } from "react-router";
import Dialogs from '../../../components/Dialog/Dialog';
import TableRunComponent from "../../../components/Table/tablerun"
import { updateCNCInputs,updateAlertCNC,updatewholeCNCInputs,updateCNCDatas, updateFlag } from '../../../redux/actions';
import Search from "../../../components/Search/Search";
import { useHistory } from "react-router-dom";
import "./ViewRuns.scss";
import nocommodity from '../../../../assets/images/undraw_empty.svg'
import Loader from '../../../components/Loader/Loader';
import moment from "moment";
//import styles
// import "../CNCView/cncview.scss";


function ViewCommodity(props) {
    let {
        user,
        allData,
        updatewholeCNCInputs,
        updateCNCDatas,
        updateAlertCNC,
        updateFlag
      } = props;

      function initializeState(){

        let initialState = [];
        initialState.mode="";
        initialState.tableHeader=[];
        initialState.futures_graph1=[];
        initialState.futures_graph2=[];
        initialState.futures_graph3=[];
        initialState.futures_graph4=[];
        initialState.weight_graph1=20;
        initialState.weight_graph2=20;
        initialState.weight_graph3=20;
        initialState.weight_graph4=20;
        initialState.weight_override=20;
        initialState.weight_default_total = 100;
        initialState.weight_graphs=[];
        initialState.weight_min=0;
        initialState.weight_max=100;
        initialState.importance_type="";
        initialState.override_values=[];
        initialState.demand_in_mt=[];
        initialState.commodityOptions=[];
        initialState.commodity_selection="";
        initialState.commodity_selection_value="";
        initialState.month_selection="";
        initialState.month_selection_value="";
        initialState.month_options=[];
        initialState.region_selection="";
        initialState.region_selection_value=[];
        initialState.countryOptions=[];
        initialState.country_selection="";
        initialState.country_selection_value=[];
        initialState.factoryOptions=[];
        initialState.factory_selection=[];

        updatewholeCNCInputs(initialState);
      }

      useEffect(() => {
        setLoading(true);
        // initializeState();
      }, [])
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const runspanel = config.view_runs.viewRunPanels;
    const [dialogData, setDialog] = useState([]);
    const [value, setValue] = React.useState(0);
    const [page, setPage] = React.useState(0);
    let username = user.preferred_username;
    let rowsPerpage = config.table_rows.pagination_size;
    const history = useHistory();
    // const [tabValue, setTabValue] = useState(false);
    // const [setflagValue , onSetFlagValue] = useState(0)
    let api = config.api.api_url;

    //Close alert snackbar
  const alertHandleClose = () => {
    if(user.alertInfo.closeFlag===true)
    updateAlertCNC({ ...user.alertInfo, open: false,closeFlag:true});
  };

  function timeDuration(starttime, endTime) {
    let start_time = new Date(starttime).toUTCString();
    start_time = new Date(start_time);
    start_time = new Date(
      start_time.getTime() - start_time.getTimezoneOffset() * 60 * 1000
    );
    // let endtime = new Date(endTime).toUTCString();
    let start = start_time.setSeconds(
      start_time.getSeconds() - start_time.getTimezoneOffset() * 60 * 1000
    );
    let end_time = new Date(endTime);
    let end = end_time.setSeconds(
      end_time.getSeconds() - end_time.getTimezoneOffset() * 60 * 1000
    );
    const difftime = moment.duration(moment(end).diff(moment(start)));
    return `${difftime._data.minutes}min ${difftime._data.seconds}sec`;
  }

  function localTime(triggered_on) {
    let localtime = new Date(triggered_on).toUTCString();
    let utc_time = new Date(localtime);
    utc_time = new Date(
      utc_time.getTime() - utc_time.getTimezoneOffset() * 60 * 1000
    );
    utc_time =
      utc_time.getFullYear() +
      "-" +
      (utc_time.getMonth() < 9
        ? "0" + (utc_time.getMonth() + 1)
        : utc_time.getMonth() + 1) +
      "-" +
      (utc_time.getDate() < 10 ? "0" + utc_time.getDate() : utc_time.getDate()) +
      " " +
      (utc_time.getHours() < 10
        ? "0" + utc_time.getHours()
        : utc_time.getHours()) +
      ":" +
      (utc_time.getMinutes() < 10
        ? "0" + utc_time.getMinutes()
        : utc_time.getMinutes()) +
      ":" +
      (utc_time.getSeconds() < 10
        ? "0" + utc_time.getSeconds()
        : utc_time.getSeconds());
    return utc_time;
  }

     //To handle the navigation of tabs to display data
  function handleTabs(value) {
    let result = [];

    
    setPage(0);
    // eslint-disable-next-line
    if (value === 4) {
      // setTabValue(true);
      setValue(value);
    } else {
      result = allData.commodity_runs.filter((data) => {
        if (value === 0) {
          return data;
        } else if (value === 3) {
          return data.flag === 1 || data.flag === 2;
        } else if (value === 1) {
          return data.flag === 0 && data.status === "Running";
        } else if (value === 2) {
          return data.flag === 3;
        }
      //  onSetFlagValue(data.flag);
      }
      
      );
      setValue(value);
      setFilteredData(result);
      // setTabValue(false);
 
    }
  }

    //function to set page for changing table row
    const pageChange = (event, value) => {
      setPage(value);
    };

    let page_props = {
      total: filteredData.length,
      page: page,
      changePage: pageChange,
    };

    //function to get all the Commodity Hedging created by the user

    function getCommodityData(){
      fetch(api+"/userCommodities?user="+username)
        .then((response)=>response.json())
        .then((response)=>{
          if(response.status.statusCode==="cnc-200"){
            updateCNCDatas("saved_commodity",response.data)
          }

          else{
            updateCNCDatas("saved_commodity",[])
          }
          
        })
        .catch((error) => {
          updateCNCDatas("saved_commodity",[])
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
        });

    }

    useEffect(() => {
      setLoading(true); 
      // if(userData.demand_planner){
      //   // updateAlertCNC({
      //   //   open: true,
      //   //   message: config.messages.access_restricted,
      //   //   severity: "info",
      //   //   closeFlag:false
      //   // });
      //   history.push({
      //     pathname: `/home/${match.params.function}/CNC/updateDemand/`,
      //   });
      // }
      // else{
        getCommodityData();   
        initializeState();
        getRuns();
        alertHandleClose();
      // }    
    }, []);

    function useInterval(callback, delay) {
      const savedCallback = useRef();
    
      useEffect(() => {
        savedCallback.current = callback;
      });
    
      useEffect(() => {
        function tick() {
          savedCallback.current();
        }
    
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }, [delay]);
    }

    useInterval(() => {
      getRuns();
      updateFlag("reloadNotification",true)
      }, config.view_runs.timeout);
   

  //get runs based on user
  const getRuns = () => {
    fetch(api + "/view_runs?user=" + username)
      .then((response) => response.json())
      .then((res) => {
        updateCNCDatas("commodity_runs", res.data);
        if (res.status.statusCode === "cnc-200") {
          // if(val!==""){
              allData.commodity_runs=res.data;
              // Search(val,"reset");
           
          // }
          // else{
            setFilteredData(res.data);
            setLoading(false);
          // }
        }
        else{
          setFilteredData(res.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setFilteredData([]);
        setLoading(false);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
  };

    //function to navigate to view run details
  const handleView = (item) => {
    if (item.cnc_id !== "") {
      updateCNCDatas("activeCommodityRun", item);
      history.push({
        pathname: `/home/viewRuns/${item.user_commodity_name}_${item.cnc_id}_${item.triggered_on}`,
      });
    }
  };

  //delete runs of user
  const deleteRuns = (item) => {
    const reqOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };  
    let commodity_selection_value;
    allData.saved_commodity.filter((obj)=>{
      if(obj.cnc_id===item.cnc_id){
        commodity_selection_value=obj.commodity_selection_value
      }
    })
    fetch(api + "/view_runs?runId=" + item.run_id, reqOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status.statusCode === "cnc-200") {
          updateAlertCNC({
            open: true,
            message: "Run Deleted Successfully",
            closeFlag:true
          });
          setValue(0);
          getRuns();
          fetch(api+"/db_delete?runId="+item.run_id+"&cncId="+item.cnc_id+"&commodity_selection_value="+commodity_selection_value,reqOptions)
          .then((response) => response.json())
          .then((response) => {})
          .catch((error) => {
            updateAlertCNC({
              open: true,
              message: config.messages.unexpected_error,
              severity: "info",
              closeFlag:false
            });
          });   
        }

        else{
          setValue(0);
          getRuns();
        }
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
    dialogClose();
  };

  //function to confirm delete
  const handleDialog = (item) => {
    
    setDialog({
      open: true,
      delete: true,
      close: dialogClose,
      deleteFunction: deleteRuns,
      deleteRow: item,
      name: "Run",
      title:"Delete"
    });
    
  };

  //close confirm deletion
  const dialogClose = () => {
    setDialog({
      ...dialogData,
      open: false,
    });
  };

    return (
        <>
        {loading ? (
          <Loader />
        ) : (
          <>
          {
            allData.commodity_runs!==null &&allData.commodity_runs.length>0?(
              <>
              <Search setFilteredData={setFilteredData} searchData={allData.commodity_runs} setPage={setPage} />
              <div
                className="cnc-view-runs-sidenav-container"
                style={{ marginTop: "-15px" }}
              >
                <div className="models-menu">
                  {runspanel.map((item, index) => (
                    <div
                      key={item}
                      className="models-item"
                      onClick={() => {
                        handleTabs(index);
                      }}
                    >
                      <div
                        className={`models-item-div ${
                          index === value ? "active" : ""
                        }`}
                      >
                        <p>{item}</p>
                      </div>
                    </div>
                  ))}
                </div>
  
                {filteredData.length > 0 ? (
                  <TableRunComponent
                    type="Runs"
                    component="MyCommodityRuns"
                    header={config.RunsHeader}
                    filteredData={filteredData}
                    page_props={page_props}
                    page={page}
                    rowsPerpage={rowsPerpage}
                    handleView={handleView}
                    username={username}
                    handleDialog={handleDialog}
                    timeDuration={timeDuration}
                    localTime={localTime}
                  />
                ) : (
                  <div className="no-commodity">
                    <img src={nocommodity} alt="" className="cnc-img"/>
                    <p className="work-in-progress-text">
                      No runs available under this category
                    </p>
                  </div>
                )}
              </div>
              </>
            ):(
              <div className="no-commodity">
                  <img src={nocommodity} alt="" className="cnc-img"/>
                  <p className="work-in-progress-text">
                    No runs available under this category
                  </p>
                </div>
            )
          }
           
          </>
        )}
        {dialogData.open && <Dialogs props={dialogData}></Dialogs>}
        </>
    );

}


ViewCommodity.propTypes = {
    user: PropTypes.object,
    cncData:PropTypes.object,
    updateCNCInputs: PropTypes.func,
    updatewholeCNCInputs: PropTypes.func,
    updateAlertCNC: PropTypes.func,
    updateCNCDatas:PropTypes.func,
};

const mapStateToProps = (state) => ({
    user: state.user,
    cncData:state.cncinputs,
    allData:state.data,
});

const mapDispatchToProps = {
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateCNCDatas,
    updateFlag
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewCommodity));