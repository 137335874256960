import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from "react";
import {config} from "../../../../config/config";
import { Grid } from '@material-ui/core';
import {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
  updateCNCDatas,
  updateFlag
} from "../../../../redux/actions";
import Dropdown from "../../../../components/Dropdown/Dropdown";

export const CostInfoFilterSelection = (props) => {
    let {
        cncData,
        updateCNCInputs,
        updateAlertCNC,
      } = props;
    const [expand, setExpand] = useState(false);
    let api = config.api.api_url;
    const [inputDisableFlag,setInputDisableFlag]=useState(false);
     //handles the change for Commodity selection
 const commodityChange = (e) => {
    updateCNCInputs("month_selection_value","");
    updateCNCInputs("month_options",[]);
    updateCNCInputs("indexOptions",[]);
    updateCNCInputs("countryOptions",[]);
    updateCNCInputs("factoryOptions",[]);
    updateCNCInputs("commodity_selection", e.label);
    updateCNCInputs("commodity_selection_value", e.value);
    updateCNCInputs("index_commodity_id","");
    updateCNCInputs("isIndexVisible",e.isIndexVisible);
    
    if(e.isIndexVisible){
      // getIndexOption(e.value);
      let indexOptions=[];
      for (let i = 0; i < e.indices.length; i++) {
        indexOptions[i] = {
          label: e.indices[i].index_name,
          value: e.indices[i].index_commodity_id,
          source_weights: e.indices[i].source_weight
        }
      }
      if (indexOptions.length === 1) {
        updateCNCInputs("index_commodity_id", indexOptions[0].value)
        updateCNCInputs("index_source_weights",indexOptions[0].source_weights)
      }
      updateCNCInputs("indexOptions", indexOptions);
    }
    updateCNCInputs("isCountryVisible",e.isCountryVisible);
    updateCNCInputs("isDemandEditable",e.isDemandEditable);
    updateCNCInputs("report_url",e.report_url);
    updateCNCInputs("demand_difference_url",e.demand_difference_url);
  }

  const handleIndexChange=(e)=>{
    updateCNCInputs("index_commodity_id",e.value);
    updateCNCInputs("index_source_weights",e.source_weights)
    updateCNCInputs("demand_difference_flag",false);
  }


  useEffect(()=>{
    if(cncData.commodity_selection_value!=="" && cncData.index_commodity_id!==""){
      updateCNCInputs("filteredData", []);
      updateCNCInputs("filteredDataCopy",[])
      updateCNCInputs("loading", true);
      //fetch call
      let input = {
        commodity_selection_value:cncData.commodity_selection_value,
        index_commodity_id:cncData.index_commodity_id,
      };
      let reqOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(input),
      };
      setInputDisableFlag(true);

      // let dummy_data=[
      //   {
      //       id:v4(),
      //       index_commodity_id:"",
      //       geography:"India",
      //       grade:"80:20",
      //       contract_month:"2022-09",
      //       total_overhead_cost:26482,
      //       currency:'Malaysian Ringgit',
      //       user:"",
      //       timestamp:"",
      //       edit_flag:false,
      //       exisiting_data:false
      //   },
      //     {
      //       id:v4(),
      //       index_commodity_id:"",
      //       geography:"India",
      //       grade:"85:15",
      //       contract_month:"2022-10",
      //       total_overhead_cost:26396,
      //       currency:'Malaysian Ringgit',
      //       user:"",
      //       timestamp:"",
      //       edit_flag:false,
      //       exisiting_data:false
      //   },
      //   {
      //     id:v4(),
      //     index_commodity_id:"",
      //     geography:"China",
      //     grade:"80:20",
      //     contract_month:"2022-09",
      //     total_overhead_cost:26785,
      //     currency:'Malaysian Ringgit',
      //     user:"",
      //     timestamp:"",
      //     edit_flag:false,
      //     exisiting_data:false
      // },
      // {
      //   id:v4(),
      //   index_commodity_id:"",
      //   geography:"China",
      //   grade:"85:15",
      //   contract_month:"2022-10",
      //   total_overhead_cost:25932,
      //   currency:'Malaysian Ringgit',
      //   user:"",
      //   timestamp:"",
      //   edit_flag:false,
      //   exisiting_data:false
      // }
      // ]

      // updateCNCInputs("filteredData",dummy_data)
      // updateCNCInputs("filteredDataCopy",dummy_data)
      fetch(api + "/db/costInfo", reqOptions)
        .then((res) => res.json())
        .then((res) => {
          setInputDisableFlag(false);
          if (res.status.statusCode === "cnc-200") {
            updateCNCInputs("fetch_status",true);
            updateCNCInputs("filteredData",JSON.parse(JSON.stringify(res.data.data)))
            updateCNCInputs("filteredDataCopy",JSON.parse(JSON.stringify(res.data.data)))
            updateCNCInputs("loading", false);
            
          } else if (res.status.statusCode === "cnc-300") {
            updateCNCInputs("fetch_status",true);
            updateCNCInputs("filteredData",[])
            updateCNCInputs("filteredDataCopy",[])
            updateCNCInputs("loading", false)
            
          }else if (res.status.statusCode === "cnc-400") {
            updateCNCInputs("fetch_status",false);
            updateCNCInputs("filteredData",[])
            updateCNCInputs("filteredDataCopy",[])
            updateCNCInputs("loading", false)
            
            updateCNCInputs("errorMessage",res.status.statusMessage);
          } else {
            updateCNCInputs("fetch_status",false);
            updateCNCInputs("filteredData",[])
            updateCNCInputs("filteredDataCopy",[])
            updateCNCInputs("loading", false)
            
            updateCNCInputs("errorMessage","An unexpected error occured please try again");
          }
        })
        .catch((error) => {
          setInputDisableFlag(false);
          updateCNCInputs("fetch_status",false);
          updateCNCInputs("filteredData",[])
          updateCNCInputs("filteredDataCopy",[])
          updateCNCInputs("loading", false)
          
          updateCNCInputs("errorMessage","An unexpected error occured please try again");
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
        });
    }
  },[cncData.commodity_selection_value,cncData.index_commodity_id])

  return (
    <>
      <div className="cnc-toggle-icon">
        <label
          onClick={() => {
            setExpand(!expand);
            updateCNCInputs("expand", expand);
          }}
        >
          {!expand ? "Collapse" : "Expand"}
        </label>
        <i
          className="material-icons"
          onClick={() => {
            setExpand(!expand);
            updateCNCInputs("expand", expand);
          }}
        >
          {!expand ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
      </div>
      {!expand && (
        <div className="cnc-model-header">
          <Grid container spacing={1} style={{ padding: "2px 15px 0px 15px" }}>
            <Grid item xs={4} sm={4}>
              <div className="mg-model-header-item" style={{ height: "25px" }}>
                Select a Commodity:{" "}
                <p
                  style={{
                    display: "inline",
                    color: "red",
                    fontSize: 16,
                  }}
                >
                  *
                </p>
              </div>
              <div className="" style={{ marginBottom: "15px" }}>
                <Dropdown
                  options={cncData.commodityOptions?cncData.commodityOptions:[]}
                  callback={commodityChange}
                  disabled={cncData.commodityOptions&&cncData.commodityOptions.length === 1
                    ? true
                    : inputDisableFlag}
                  updateValue={cncData.commodity_selection_value}
                  placeholder={"Select a Commodity"}
                />
              </div>
            </Grid>

            {/* {cncData.commodity_selection_value !== ""&& (
              <>
                <Grid item xs={4} sm={4}>
                  <div>
                    Select Decision Month:{" "}
                    <p
                      style={{
                        display: "inline",
                        color: "red",
                        fontSize: 16,
                      }}
                    >
                      *
                    </p>
                  </div>

                  <div className="" style={{ marginBottom: "15px" }}>
                    <Dropdown
                      options={cncData.month_options}
                      callback={decisionMonthChange}
                      disabled={inputDisableFlag}
                      updateValue={cncData.month_selection_value}
                      placeholder={"Select Decision Month"}
                    />
                  </div>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <div
                    className="mg-model-header-item"
                    style={{ height: "25px" }}
                  >
                    Description:{" "}
                  </div>
                  <div className="" style={{ marginBottom: "15px" }}>
                    <input
                      type="text"
                      placeholder="Enter a Description"
                      className="form-control"
                      disabled={(cncData.mode==="create"||cncData.mode==="edit")?false:true}
                      value={cncData.description}
                      onChange={handleDescriptionChange}
                    ></input>
                  </div>
                </Grid>
              </>
            )} */}

            {
              
              cncData.indexOptions.length > 0 && (
                <>
                  <Grid item xs={4} sm={4}>
                    <div>
                      Select Index
                      <p
                        style={{
                          display: "inline",
                          color: "red",
                          fontSize: 16,
                        }}
                      >
                        *
                      </p>
                    </div>

                    <div className="">
                      <Dropdown
                        options={cncData.indexOptions?cncData.indexOptions:[]}
                        callback={handleIndexChange}
                        disabled={
                          cncData.indexOptions&&cncData.indexOptions.length === 1
                              ? true
                              : inputDisableFlag   
                        }
                        updateValue={cncData.index_commodity_id}
                        placeholder={"Select Index"}
                      />
                    </div>
                  </Grid>
                </>
              )}


          </Grid>
        </div>
      )}
    </>
  );
  
}

CostInfoFilterSelection.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
}

const mapStateToProps = (state) => ({
    user: state.user,
    cncData: state.cncinputs,
    allData: state.data,
})

const mapDispatchToProps = {
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateCNCDatas,
    updateFlag
}

export default connect(mapStateToProps, mapDispatchToProps)(CostInfoFilterSelection)