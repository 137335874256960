import C from "../constants";

export default function flags(state = {}, payload) {
    let { type, key, data } = payload;
    // console.log(payload)
    switch (type) {
      case C.UPDATE_FLAGS:
    return { ...state, [key]:data };
      default:
        return state;
    }
  }