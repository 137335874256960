import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from "react";
import {config} from "../../../../config/config";
import { Grid } from '@material-ui/core';
import {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
  updateCNCDatas,
  updateFlag
} from "../../../../redux/actions";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import Select, { components} from 'react-select';
const customStyles = {
    menuPortal: (base) => ({
      ...base,
      maxHeight: "90%",
      fontSize: 12,
      fontFamily: "Hind Siliguri !important",
      zIndex: 9999,
    }),
  };

export const BudgetFilterSelection = (props) => {
    let {
        cncData,
        allData,
        updateCNCInputs,
        updateAlertCNC,
        updateCNCDatas,
        setPage,
      } = props;
    const [expand, setExpand] = useState(false);
    let api = config.api.api_url;
    const [inputDisableFlag,setInputDisableFlag]=useState(false);
    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <i
              aria-describedby={""}
              className="material-icons-outlined "
              value={""}
            >
              filter_alt
            </i>
          </components.DropdownIndicator>
        );
      };
     //handles the change for Commodity selection
 const commodityChange = (e) => {
    updateCNCInputs("month_selection_value","");
    updateCNCInputs("month_options",[]);
    updateCNCInputs("indexOptions",[]);
    updateCNCInputs("countryOptions",[]);
    updateCNCInputs("factoryOptions",[]);
    updateCNCInputs("commodity_selection", e.label);
    updateCNCInputs("commodity_selection_value", e.value);
    updateCNCInputs("index_commodity_id","");
    updateCNCInputs("isIndexVisible",e.isIndexVisible);
    updateCNCInputs("budget_type_options",e.budget_type_options);
    setPage(0)
    if(e.isIndexVisible){
      // getIndexOption(e.value);
      let indexOptions=[];
      for (let i = 0; i < e.indices.length; i++) {
        indexOptions[i] = {
          label: e.indices[i].index_name,
          value: e.indices[i].index_commodity_id,
          source_weights: e.indices[i].source_weight
        }
      }
      if (indexOptions.length === 1) {
        updateCNCInputs("index_commodity_id", indexOptions[0].value)
        updateCNCInputs("index_source_weights",indexOptions[0].source_weights)
      }
      updateCNCInputs("indexOptions", indexOptions);
    }
    updateCNCInputs("isCountryVisible",e.isCountryVisible);
    updateCNCInputs("isDemandEditable",e.isDemandEditable);
    updateCNCInputs("report_url",e.report_url);
    updateCNCInputs("demand_difference_url",e.demand_difference_url);
  }

  const handleIndexChange=(e)=>{
    updateCNCInputs("index_commodity_id",e.value);
    updateCNCInputs("index_source_weights",e.source_weights)
    updateCNCInputs("demand_difference_flag",false);
  }


  useEffect(()=>{
    if(cncData.commodity_selection_value!=="" && cncData.index_commodity_id!==""){
      updateCNCInputs("filteredData", []);
      updateCNCInputs("filteredDataCopy",[])
      updateCNCDatas("budget_data",[])
      updateCNCInputs("loading", true);
      //fetch call
      let input = {
        commodity_selection_value:cncData.commodity_selection_value,
        index_commodity_id:cncData.index_commodity_id,
      };
      let reqOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(input),
      };
      setInputDisableFlag(true);
      fetch(api + "/db/budget", reqOptions)
        .then((res) => res.json())
        .then((res) => {
          setInputDisableFlag(false);
          if (res.status.statusCode === "cnc-200") {
            updateCNCInputs("fetch_status",true);
            updateCNCDatas("budget_data",JSON.parse(JSON.stringify(res.data.data)))
            updateCNCInputs("filteredData",JSON.parse(JSON.stringify(res.data.data)))
            updateCNCInputs("filteredDataCopy",JSON.parse(JSON.stringify(res.data.data)))
            updateCNCInputs("loading", false);
            
          } else if (res.status.statusCode === "cnc-300") {
            updateCNCInputs("fetch_status",true);
            updateCNCDatas("budget_data",[])
            updateCNCInputs("filteredData",[])
            updateCNCInputs("filteredDataCopy",[])
            updateCNCInputs("loading", false)
            
          }else if (res.status.statusCode === "cnc-400") {
            updateCNCInputs("fetch_status",false);
            updateCNCDatas("budget_data",[])
            updateCNCInputs("filteredData",[])
            updateCNCInputs("filteredDataCopy",[])
            updateCNCInputs("loading", false)
            
            updateCNCInputs("errorMessage",res.status.statusMessage);
          } else {
            updateCNCInputs("fetch_status",false);
            updateCNCDatas("budget_data",[])
            updateCNCInputs("filteredData",[])
            updateCNCInputs("filteredDataCopy",[])
            updateCNCInputs("loading", false)
            
            updateCNCInputs("errorMessage","An unexpected error occured please try again");
          }
        })
        .catch((error) => {
          setInputDisableFlag(false);
          updateCNCInputs("fetch_status",false);
          updateCNCDatas("budget_data",[])
          updateCNCInputs("filteredData",[])
          updateCNCInputs("filteredDataCopy",[])
          updateCNCInputs("loading", false)
          
          updateCNCInputs("errorMessage","An unexpected error occured please try again");
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
        });
    }
  },[cncData.commodity_selection_value,cncData.index_commodity_id])



  useEffect(()=>{
    if(cncData.year_filter!==undefined&&cncData.type_filter!==undefined)
      handleFilter()

  },[cncData.year_filter,cncData.type_filter])

  
  const handleFilter=()=>{
    if(cncData.filteredData!==undefined){
      setPage(0)
      let res = allData.budget_data

      if(cncData.type_filter==="" &&cncData.year_filter!==""){
        let result=res.filter((obj) => {
          return obj.year === cncData.year_filter
        })
        updateCNCInputs("filteredData",result)
      }

      else if(cncData.type_filter!=="" &&cncData.year_filter===""){
        let result=res.filter((obj) => {
          return obj.type === cncData.type_filter
        })
        updateCNCInputs("filteredData",result)
      }

      else if(cncData.type_filter!=="" &&cncData.year_filter!==""){
        let result=res.filter((obj) => {
          return obj.type === cncData.type_filter
        })
        
        let result2=result.filter((obj) => {
          return obj.year === cncData.year_filter
        })
        
        updateCNCInputs("filteredData",result2)
      }

      else{
        updateCNCInputs("filteredData",allData.budget_data)
      }
     
    
    }
    
  }

  const handleTypeFilter=(event)=>{
    if(event!==null){
      updateCNCInputs("type_filter",event.value)
    }    

    else{
      updateCNCInputs("type_filter","")
    }
    
  }

  const handleYearFilter=(event)=>{
    if(event!==null){
      updateCNCInputs("year_filter",event.value)
    }    

    else{
      updateCNCInputs("year_filter","")
    }
    
  }
  return (
    <>
      <div className="cnc-toggle-icon">
        <label
          onClick={() => {
            setExpand(!expand);
            updateCNCInputs("expand", expand);
          }}
        >
          {!expand ? "Collapse" : "Expand"}
        </label>
        <i
          className="material-icons"
          onClick={() => {
            setExpand(!expand);
            updateCNCInputs("expand", expand);
          }}
        >
          {!expand ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
      </div>
      {!expand && (
        <div className="cnc-model-header">
          <Grid container spacing={1} style={{ padding: "2px 15px 0px 15px" }}>
            <Grid item xs={4} sm={4}>
              <div className="mg-model-header-item" style={{ height: "25px" }}>
                Select a Commodity:{" "}
                <p
                  style={{
                    display: "inline",
                    color: "red",
                    fontSize: 16,
                  }}
                >
                  *
                </p>
              </div>
              <div className="" style={{ marginBottom: "15px" }}>
                <Dropdown
                  options={cncData.commodityOptions?cncData.commodityOptions:[]}
                  callback={commodityChange}
                  disabled={cncData.commodityOptions&&cncData.commodityOptions.length === 1
                    ? true
                    : inputDisableFlag}
                  updateValue={cncData.commodity_selection_value}
                  placeholder={"Select a Commodity"}
                />
              </div>
            </Grid>

            {
              
              cncData.indexOptions.length > 0 && (
                <>
                  <Grid item xs={4} sm={4}>
                    <div>
                      Select Index
                      <p
                        style={{
                          display: "inline",
                          color: "red",
                          fontSize: 16,
                        }}
                      >
                        *
                      </p>
                    </div>

                    <div className="">
                      <Dropdown
                        options={cncData.indexOptions?cncData.indexOptions:[]}
                        callback={handleIndexChange}
                        disabled={
                          cncData.indexOptions&&cncData.indexOptions.length === 1
                              ? true
                              : inputDisableFlag   
                        }
                        updateValue={cncData.index_commodity_id}
                        placeholder={"Select Index"}
                      />
                    </div>
                  </Grid>
                </>
              )}
          </Grid>
        </div>
      )}
      {cncData.commodity_selection_value !== "" &&
        cncData.index_commodity_id !== "" &&
        allData.budget_data &&
        allData.budget_data.length>0 &&
        !cncData.loading &&
        (
          <Grid
            container
            spacing={1}
            style={{ padding: "5px", justifyContent: "right" }}
          >
          

            <Grid item xs={2} sm={2} style={{zIndex: 999}}>
          <div className="">
            <Select
              closeMenuOnSelect={false}
              isClearable
              components={{ DropdownIndicator }}
              options={cncData.budget_type_options?cncData.budget_type_options:[]}
              menuPlacement="auto"
              style={customStyles}
              placeholder={"Type"}
              menuPosition="fixed"
              value={cncData.budget_type_options &&cncData.budget_type_options.filter((obj) => {
                return obj.value === cncData.type_filter;
              })}
              onChange={handleTypeFilter}
            />
          </div>
        </Grid>
        <Grid item xs={2} sm={2} style={{zIndex: 999}}>
          <div className="">
            <Select
              isClearable
              closeMenuOnSelect={false}
              components={{ DropdownIndicator }}
              options={cncData.yearOptions?cncData.yearOptions:[]}
              menuPlacement="auto"
              style={customStyles}
              placeholder={"Year"}
              menuPosition="fixed"
              value={cncData.yearOptions&&cncData.yearOptions &&cncData.yearOptions.filter((obj) => {
                return obj.value === cncData.year_filter;
              })
              }
              onChange={handleYearFilter}
            />
          </div>
        </Grid>
          </Grid>
        )}
    </>
  );
  
}

BudgetFilterSelection.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
}

const mapStateToProps = (state) => ({
    user: state.user,
    cncData: state.cncinputs,
    allData: state.data,
})

const mapDispatchToProps = {
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateCNCDatas,
    updateFlag
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetFilterSelection)