/*
Created on March 2022
Confidential and Proprietary of Reckitt
Project: CNC
Screen name : MyCommodity Hedging
Screen Description : 
This screen is used to display the Commodity hedging created by the user
@author: Tailwyndz
*/

//Import Libraries
import React, { useEffect} from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from 'react';
import {config} from '../../../config/config';
// import LayoutCNC from '../../components/Layout/LayoutCNC';
import Loader from "../../../components/Loader/Loader";
import { withRouter } from "react-router";
import Dialogs from '../../../components/Dialog/Dialog';
import { updateCNCInputs,updateAlertCNC,updatewholeCNCInputs,updateUserInfo,updateCNCDatas } from '../../../redux/actions';
import Search from "../../../components/Search/Search";
import TableComponent from '../../../components/Table/table'
import { useHistory } from "react-router-dom";
// import forecast from "../../../assets/images/forecast.png";
import nocommodity from '../../../../assets/images/undraw_empty.svg'
//import styles
import "./mycommodity.scss";



  

function MyCommodity(props) {
  let {
    cncData,
    user,
    allData,
    defaultCommodity,
    updateCNCInputs,
    updateCNCDatas,
    updatewholeCNCInputs,
    updateAlertCNC,
  } = props;
  let api = config.api.api_url;
 
  const [filteredData, setFilteredData] = useState([]);
  let username = user.preferred_username;
  let usermail = user.email;
  const [loading, setLoading] = useState(false);
  const [dialogData, setDialog] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setvalue] = React.useState(null);
  const [page, setPage] = React.useState(0);
  let rowsPerpage = config.table_rows.pagination_size;
  const history = useHistory();
  let shared = [];
  let today = new Date();
  let currentUTC =
  today.getUTCFullYear() +
  "-" +
  (today.getUTCMonth() < 9
    ? "0" + (today.getUTCMonth() + 1)
    : today.getUTCMonth() + 1) +
  "-" +
  (today.getUTCDate() < 10 ? "0" + today.getUTCDate() : today.getUTCDate());
  let currentDate =
    today.getFullYear() +
    "-" +
    (today.getMonth() < 9
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

    function initializeState(){

      let initialState = [];
      initialState.loading=false;
      initialState.mode="";
      initialState.cncDataGraph=[];
      initialState.tableHeader=[];
      initialState.futures_graph1=[];
      initialState.futures_graph2=[];
      initialState.futures_graph3=[];
      initialState.futures_graph4=[];
      initialState.weight_graph1=20;
      initialState.weight_graph2=20;
      initialState.weight_graph3=20;
      initialState.weight_graph4=20;
      initialState.weight_override=0;
      initialState.weight_default_total = 100;
      initialState.weight_graphs=[];
      initialState.weight_graphs_copy=[];
      initialState.weight_min=0;
      initialState.weight_max=100;
      initialState.importance_type="";
      initialState.override_values=[];
      initialState.demand_in_mt=[];
      initialState.override_forecast_header=[];
      initialState.demand_header=[];
      initialState.demand_treeData=[];
      initialState.commodityOptions=[];
      initialState.commodity_selection="";
      initialState.commodity_selection_value="";
      initialState.month_selection="";
      initialState.month_selection_value="";
      initialState.description="";
      initialState.month_options=[];
      initialState.region_selection=[];
      initialState.regionOptions=[];
      initialState.regionOptionsAll=[];
      initialState.region_selection_value=[{
        label:"Global",
        value:"Global",
        geography_level:"region",
        parent_id:"null",
      }];
      initialState.isIndexVisible=false;
      initialState.isCountryVisible=false;
      initialState.isDemandEditable=false;
      initialState.scenarioFlag=false;
      initialState.scenario_flag_checkbox=false;
      initialState.isExpandable=false;
      initialState.indexOptions=[];
      initialState.index_commodity_id="";
      initialState.countryOptions=[];
      initialState.countryOptionsAll=[];
      initialState.country_selection="";
      initialState.country_selection_value=[];
      initialState.factoryOptions=[];
      initialState.factoryOptionsAll=[];
      initialState.factory_selection=[];
      initialState.use_for_final_hedging=false;
      initialState.index_source_weights=[];
      initialState.demandDataEdited=false;
      updatewholeCNCInputs(initialState);
    }


  // search and filter x rows based on keyword
  function SearchVal(e,flag=false) {
    let value = flag ? e : e.target.value;
    updateCNCInputs("searchValue",value);
    // setVal(value);
    // if (value.length > 0) {
    //   settextClearVisible(true);
    // }
    // if (value == "" || value == "" || value.length == 0) {
    //   settextClearVisible(false);
    // }
    let result = [];
    result = allData.saved_commodity.filter((data) => {
      return (
        data.user_commodity_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        (data.commodity_created_dt!==undefined&& data.commodity_created_dt.includes(value)) ||
        data.description.toString().toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredData(result);
    !flag && setPage(0);
  }

  //Close alert snackbar
  const alertHandleClose = () => {
    if(user.alertInfo.closeFlag===true)
    updateAlertCNC({ ...user.alertInfo, open: false,closeFlag:true});
  };

  //close after shared or cancel sharing Commodity
  const dialogClose = () => {
    setDialog({
      ...dialogData,
      open: false,
    });
  };

  //function to set page for changing table row
  const pageChange = (event, value) => {
    setPage(value);
  };

  let page_props = {
    total: filteredData.length,
    page: page,
    changePage: pageChange,
  };

  //function to get all the Commodity Hedging created by the user

  function getCommodityData(delete_flag=false){
    fetch(api+"/userCommodities?user="+username)
      .then((response)=>response.json())
      .then((response)=>{
        if(response.status.statusCode ==="cnc-200"){
          updateCNCDatas("saved_commodity",response.data)
          if(cncData.searchValue!==undefined&& cncData.searchValue!=="" &&delete_flag){
            allData.saved_commodity=response.data;
            SearchVal(cncData.searchValue,true);
          }
          else{
            setFilteredData(response.data);
          }
          setLoading(false);
        }
        else{
          updateCNCDatas("saved_commodity",[])
          setFilteredData([]);
          setLoading(false);
        }      
      })
      .catch((error) => {
        updateCNCDatas("saved_commodity",[])
        setFilteredData([]);
        setLoading(false);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
  }

  useEffect(() => {
    // updateUserInfo({
    //   username: userData.email,
    //   name: userData.name,
    //   isAuth: userData.isAuth,
    //   role: userData.role,
    //   error: userData.error,
    //   status: userData.status,
    //   alertInfo: userData.alertInfo,
    //   screen: userData.screen,
    //   email:userData.email
    // });
    setLoading(true);
    if (allData.defaultCommodity !== null) {
      updatewholeCNCInputs(allData.defaultCommodity);
    }
    getCommodityData();
    if (allData.defaultCommodity === null) {
      let initialData = defaultCommodity;
      updateCNCDatas("defaultCommodity", initialData);
    }
    // eslint-disable-next-line
  }, [allData.saved_commodity === null]);

  useEffect(()=>{
    alertHandleClose();
    // if(userData.demand_planner){
    //   // updateAlertCNC({
    //   //   open: true,
    //   //   message: config.messages.access_restricted,
    //   //   severity: "info",
    //   //   closeFlag:false
    //   // });
    //   history.push({
    //     pathname: `/home/${match.params.function}/CNC/updateDemand/`,
    //   });
    // }
  },[])


  //function to open popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setvalue(event.target.id);
  };

  //function to close popover
  const handleClose = () => {
    setAnchorEl(null);
  };

   //function to Edit a particular Commodity
   const handleEdit = (item) => {
     initializeState();
    let data = allData.saved_commodity.filter((obj) => item === obj.cnc_id);
    data = data[0];
    updateCNCInputs("mode", "edit");
    updateCNCDatas("activeCommodity", data);
    history.push({
      pathname: `/home/myCommodityHedging/${data.user_commodity_name}_${data.cnc_id}`,
    });
  };

  // function to copy a particular  model
  const copyCommodity = (id) => {
    initializeState();
    let data = filteredData.filter((obj) => id === obj.cnc_id);
    data = data[0];
    var input = { ...data };
    // var input = data
    input.cnc_id = "";
    input.run_id = "";
    input.user_commodity_name = input.user_commodity_name+ " Copy";
    input.commodity_created_dt = currentDate;
    // input.scenario_model_output = [];
    // input.scenario_rescore_output = [];
    let triggered_time =
            currentUTC +
            " " +
            (today.getUTCHours() < 10
              ? "0" + today.getUTCHours()
              : today.getUTCHours()) +
            ":" +
            (today.getUTCMinutes() < 10
              ? "0" + today.getUTCMinutes()
              : today.getUTCMinutes()) +
            ":" +
            (today.getUTCSeconds() < 10
              ? "0" + today.getUTCSeconds()
              : today.getUTCSeconds());
    input.total_runs = 0;
    input.starred = 0;
    input.shared = 0;
    input.shared_with = [];
    input.timestamp=triggered_time
    setAnchorEl(null);
    const reqOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    };
    fetch(api + "/userCommodities", reqOptions)
      .then((response) => response.json())
      .then((res) => {
        let data = res.data;
        if (res.status.statusCode === "cnc-200") {
          // updateModelInput("model_id", "");
          updateCNCInputs("mode", "edit");
          data.copyFlag=true;
          updateCNCDatas("activeCommodity", data);
          updateAlertCNC({
                open: true,
                message: config.messages.commodity_copy,
                severity: "info",
                closeFlag:false
              });
              getCommodityData();
              reqOptions.body = JSON.stringify(res.data)
              setTimeout(() => {
                reqOptions.method="POST"
                fetch(api+"/db/hedging_forecast",reqOptions)
                .then((response) => response.json())
                .then((response) => {})
                .catch((error) => {
                  updateAlertCNC({
                    open: true,
                    message: config.messages.unexpected_error,
                    severity: "info",
                    closeFlag:false
                  });
                });  
              }, 1000);
              history.push({
                pathname: `/home/myCommodityHedging/${data.user_commodity_name}_${data.cnc_id}`,
              });        
        } 
        else{
          updateCNCDatas("activeCommodity", []);
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
        }
      })
      .catch((error) => {
        updateCNCDatas("activeCommodity", []);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
  };

  
  const starCommodity=(id)=>{
    let data = filteredData.filter((obj) => id === obj.cnc_id);
    const input = data[0];
    input.starred = 1;
    input.update = false;
    const reqOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    };
    fetch(api + "/userCommodities?cnc_id="+id, reqOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status.statusCode === "cnc-200") {
          updateAlertCNC({
            open: true,
            message: config.messages.commodity_starred,
            severity: "info",
            closeFlag:true
          });
        }
        else{
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:true
          });
        }
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
    handleClose();
   
  }

   //function to get list of users
   const userList = (item) => {
    fetch(config.api.userListUrl)
      .then((response) => response.json())
      .then((res) => {
        if (res.status.statusCode === "datareckitt-200") {
          let user_list = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].email.split('@')[0] !== username) {
              user_list[i] = {
                label: res.data[i].name,
                value: res.data[i].email.split('@')[0],
              };
            }
          }
          if (shared.shared_with !== undefined) {
            for (let i = 0; i < shared.shared_with.length; i++) {
              let value = "";
              user_list.forEach((item, index) => {
                if (item.value === shared.shared_with[i].username) {
                  value = item.label;
                  // user_list.splice(index, 1);
                }
              });
              shared.shared_with[i].label = value;
            }
          }
          setDialog({
            open: true,
            users: user_list,
            scenario: item,
            share: true,
            close: dialogClose,
            userFunction: handleuserSelection,
            userAccess: handleuserAccess,
            shareFunction: getSharedUsers,
            userinfo: shared.shared_with,
          });
        }
        else{
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:true
          });
        }
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
  };

  //function to handle user selection while sharing
  const handleuserSelection = (e,userinfo) => {
    let user_Selected = e;
    let userData = null;
    // if (shared.shared_with !== undefined && shared.shared_with.length>0) {
    //   let userdata = [...shared.shared_with];
    //   
    //   for (let i = 0; i < user_Selected.length; i++) {
    //     userdata.push({
    //       username: user_Selected[i].value,
    //       label: user_Selected[i].label,
    //       accessType : (user_Selected[i] !== undefined && user_Selected[i] !== '') ? user_Selected[i].accessType : ""
    //     });
    //   }
    //   userData = userdata;
    // }
    if(userinfo !==undefined && userinfo.length>0){
      let userdata = [];
      for (let i = 0; i < user_Selected.length; i++) {
        userdata.push({
          username: user_Selected[i].value,
          label: user_Selected[i].label,
          accessType : (userinfo[i] !== undefined && userinfo[i] !== '') ? userinfo[i].accessType : ""
        });
      }
      userData = userdata;
    }
    else {
      let userdata = [];
      for (let i = 0; i < user_Selected.length; i++) {
        userdata.push({
          username: user_Selected[i].value,
          label: user_Selected[i].label,
        });
      }
      userData = userdata;
    }
    setDialog((prevState) => {
      return {
        ...prevState,
        userinfo: userData,
      };
    });
  };

  //function to provide access to selected users for the scenario to share
  const handleuserAccess = (e, id, userinfo) => {
    let row = userinfo[id];
    row.accessType = e.value;
    userinfo[id] = row;
    setDialog((prevState) => {
      return {
        ...prevState,
        userinfo,
      };
    });
  };

  //function to check if shared users has access value
  const getSharedUsers = (userdata) => {
    let user_flag = false;
    let user_select_flag = false;
    for (let i = 0; i < userdata.length; i++) {
      if (
        userdata[i].accessType === undefined ||
        userdata[i].accessType === ""
      ) {
        user_flag = true;
      }
    }
    if (userdata.length <= 0) {
      user_select_flag = true;
    }
    if (user_flag) {
      updateAlertCNC({ open: true, message: config.messages.scenario_access,closeFlag:true });
    } else if (user_select_flag) {
      updateAlertCNC({ open: true, message: config.messages.select_one_user,closeFlag:true });
    } else {
      shareCommodity(userdata);
    }
  };

  //Open Dialog to share scenario with user
  const handleDialog = (item) => {
    let data = filteredData.filter((obj) => item === obj.cnc_id);
    userList(data[0].model_resource_name);
    shared = data[0];
    handleClose();
  };

  //Share the data with users
  const shareCommodity = (selectedUsers) => {
    var input = shared;
    input.shared = 1;
    input.update = false;
    let sharedData = [];
    for (let i = 0; i < selectedUsers.length; i++) {
      sharedData.push({
        username: selectedUsers[i].username,
        accessType: selectedUsers[i].accessType,
      });
    }
    input.shared_with = sharedData;
    dialogClose();
    const reqOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    };
    fetch(api + "/userCommodities", reqOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status.statusCode === "cnc-200") {
          updateAlertCNC({ open: true, message:config.messages.commodity_shared,closeFlag:true });
        }
        else{
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:true
          });
        }
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
  };


  const deleteDialog = (id) => {
    setDialog({
      open: true,
      delete: true,
      deleteRow: id,
      close: dialogClose,
      deleteFunction: deleteCommodity,
      name: "Commodity Hedging",
    });
  };

  //function to Delete a particular Commodity Hedging
  const deleteCommodity = (id) => {
    let data = filteredData.filter((obj) => id === obj.cnc_id);
    const input = data[0];
    if (input.starred === 1) {
      input.starred = 0;
    }
    if (input.shared === 1) {
      input.shared = 0;
      input.shared_with = [];
    }
    input.deleted = true;
    input.update = false;
    const reqOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    };
    setAnchorEl(null);

    fetch(api + "/userCommodities?cnc_id="+id, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        if(response.status.statusCode ==="cnc-200"){
          updateAlertCNC({ open: true, message: config.messages.commodity_deleted ,closeFlag:true});
          getCommodityData(true);
        }

        else{
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
          getCommodityData();
        }
        
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
        getCommodityData();
      });
    dialogClose();
  };

  const runCommodity = (id) => {
    let data = allData.saved_commodity.filter((obj) => id === obj.cnc_id);
    data = data[0];
    let commodity_created_dt=data.commodity_created_dt;
    let triggered_time =
      currentUTC +
      " " +
      (today.getUTCHours() < 10
        ? "0" + today.getUTCHours()
        : today.getUTCHours()) +
      ":" +
      (today.getUTCMinutes() < 10
        ? "0" + today.getUTCMinutes()
        : today.getUTCMinutes()) +
      ":" +
      (today.getUTCSeconds() < 10
        ? "0" + today.getUTCSeconds()
        : today.getUTCSeconds());

        const run = {
          run_id: data.cnc_id,
          cnc_id: data.cnc_id,
          user_commodity_name:data.user_commodity_name,
          use_for_final_hedging:data.use_for_final_hedging,
          created_by:username,
          user: data.user,
          created_on: commodity_created_dt,
          triggered_on: triggered_time,
          duration: "",
          flag: null,
          status: "",
          message: "",
          email:usermail,
          description:data.description,
          run_start_time:"",
          isDataLoaded:false,
        };

        const notification = {
          notification_id: "",
          username: data.user,
          triggered_on: triggered_time,
          cnc_id: data.cnc_id,
          user_commodity_name:data.user_commodity_name,
          notification_type: "",
          status: "Unread",
          message: "",
        };

    const input = {
      cnc_commodities: data,
      runs: run,
      notification:notification,
    };

    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };

    handleClose();
    fetch(api + "/run", reqOptions)
    .then((response) => response.json())
    .then((response) => {
      if (response.status.statusCode === "cnc-200") {
        response.commodity_data.timestamp=response.run_data['triggered_on']
        reqOptions.body = JSON.stringify(response.commodity_data)
        // let run_id=response.data.run_id
        let run_data=response.run_data
        let cnc_commodities=response.commodity_data
        let notification=response.notification;
        // initializeState();
        let input={
          runs:run_data,
          cnc_commodities:cnc_commodities,
          notification:notification,
        }
          let req = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(input),
          };
          fetch(api+"/trigger_job",req)
          .then((res)=>res.json())
          .then((res)=>{

          })
          .catch((error) => {
            updateAlertCNC({
              open: true,
              message: config.messages.unexpected_error,
              severity: "info",
              closeFlag:false
            });
          });
        setTimeout(() => {
          reqOptions.method="POST"
          fetch(api+"/db/hedging_forecast",reqOptions)
          .then((response) => response.json())
          .then((response) => {})
          .catch((error) => {
            updateAlertCNC({
              open: true,
              message: config.messages.unexpected_error,
              severity: "info",
              closeFlag:false
            });
          }); 
          // history.push({
          //   pathname: `/home/${match.params.function}/CNC/myCommodityHedging`,
          // });
        }, 1000);
      }
      else{
        updateAlertCNC({
          open: true,
          message:
            response.status.statusMessage ,
          severity: "info",
          closeFlag:true
        });
      }
    })
    .catch((error) => {
      updateAlertCNC({
        open: true,
        message: config.messages.unexpected_error,
        severity: "info",
        closeFlag:false
      });
    });

    updateAlertCNC({
      open: true,
      message: config.messages.run_triggered,
      closeFlag: true,
    });
  };
  const menulist = [
    { label:"Edit", func: handleEdit },    
    { label:"Run", func: runCommodity },
    { label: "Copy", func: copyCommodity},
    { label:"Delete", func: deleteDialog },
    { label: "Add to Starred", func: starCommodity},
    { label:"Share", func: handleDialog },

  ];
  return (
    // <LayoutCNC>
      <>
      {
          dialogData.open && <Dialogs props={dialogData}></Dialogs>
          // null
      }
      {loading ? (
          <Loader />
        ) : (
          <>

          {allData.saved_commodity !== null &&
              allData.saved_commodity.length !== 0 ? (
                <>
              <Search setFilteredData={setFilteredData} searchData={allData.saved_commodity} setPage={setPage}/>
              <div className="configs-container">
                <div className="apps-row">
                  {filteredData.length > 0 ? 
              
                    <TableComponent
                    component="MyCommodity"
                    header={config.myCommodityHeader}
                    filteredData={filteredData}
                    page_props={page_props}
                    page={page}
                    rowsPerpage={rowsPerpage}
                    handleEdit={handleEdit}
                    handleClick={handleClick}
                    handleClose={handleClose}
                    anchorEl={anchorEl}
                    menulist={menulist}
                    value={value}
                  />
                  
                  :
                  <div className="no-commodity">
                <img src={nocommodity} alt="" className="cnc-img"/>
                <p>No commodity available</p>
              </div>
                  }
                </div>
              </div>
              </>
              ) : (
              <div className="no-commodity">
                <img src={nocommodity} alt="" className="cnc-img"/>
                <p>No commodity available</p>
              </div>
            )}  
          </>
          

        )
      }
      </>
    //  </LayoutCNC> 
  );
}


MyCommodity.propTypes = {
    user: PropTypes.object,
    cncData:PropTypes.object,
    updateCNCInputs: PropTypes.func,
    updatewholeCNCInputs: PropTypes.func,
    updateAlertCNC: PropTypes.func,
    updateCNCDatas: PropTypes.func,
};

const mapStateToProps = (state) => ({
    user: state.user,
    cncData:state.cncinputs,
    allData:state.data,
    defaultCommodity: state.cncinputs,
});

const mapDispatchToProps = {
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateUserInfo,
    updateCNCDatas,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyCommodity));