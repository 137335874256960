import React from "react";
import { useState} from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { config } from "../../../../config/config";
import DisplayUsers from "./DisplayUsers";
import Usergroup from "./Usergroup";
import { updateAlertCNC,updateAdminInput } from "../../../../redux/actions";
const outlinedButton = {
    borderColor: "#46596A",
    // background-color: #d7dadd,
    color: "#46596A",
    // width: '100%',
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: 5,
    left: 10
}
function ManageUsers(props) {
    let { admin_data,updateAdminInput } = props;
    const [dialog,setDialog] = useState([])

    function initializeState() {
      // let initialState = [];
      // initialState = admin_data;
      updateAdminInput("activeData",[])
      // initialState.activeData=[];
      let activeSelections={
                    indexSelection:[],
                    commoditySelection:[],
                    regionSelection:[],
                    countrySelection:[],
                    factorySelection:[],
                  }
      updateAdminInput("activeSelections",activeSelections)  
      updateAdminInput("selected_user","");        
      // initialState.selected_user="";
      // updatewholeAdminInputs(initialState);
  }

    const openDialog = () => {
      setDialog({
        open: true,
        type:'add',
        title:'Add New User',
        data:[],
        close: dialogClose,
      });
    };

    //close after shared or cancel sharing Commodity
    const dialogClose = () => {
      initializeState()
      setDialog({
        ...dialog,
        open: false,
      });
    };

 
  

    return (
        <>
        {dialog.open && <Usergroup props={dialog}></Usergroup>
        }
        <div className="manage-header">
            <div className="manage-card">
                <div className="display-left" >
                    <div className="display-label" >
                        <label style={{ fontWeight: 800, fontSize: "16px" }} className="primary-name">Manage Users</label>
                    </div>

                    {/* <Button
                        disableRipple
                        className="jss546"
                        variant="outlined"
                        style={outlinedButton}
                        // startIcon={<DeleteIcon />}
                        // onClick={onCancel}
                    >
                        Add new users
                    </Button> */}
                </div>
                <div className="shared-card-action" >
                    {/* {button.map((item, id) => ( */}
                    <Button className="button-align" disableRipple variant="outlined" onClick={openDialog} style={outlinedButton}>
                        Add New User
                    </Button>
                    {/* // ))} */}
                </div>
            </div>
            {admin_data.users_firestore.length > 0 &&
                <DisplayUsers header={config.admin_user_header} />
            }
        </div>
        </>
    )
}

ManageUsers.propTypes = {
    // match: PropTypes.object,
    updateAlertCNC: PropTypes.func,
};

const mapStateToProps = (state) => ({
    admin_data: state.admin_data
});

const mapDispatchToProps = {
    updateAlertCNC,
    updateAdminInput
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ManageUsers)
);