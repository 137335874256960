/*
Created on March 2022
Confidential and Proprietary of Reckitt
Project: CNC
Screen name : AddCommodity
Screen Description : 
@author: Tailwyndz
*/

//Import Libraries
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from "react";
import { config } from "../../../../config/config";
import { withRouter } from "react-router";
import { Grid } from "@material-ui/core";
import { components } from "react-select";
// import Button from '@material-ui/core/Button';
import { default as ReactSelect } from "react-select";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
  updateCNCDatas,
} from "../../../../redux/actions";
// import forecast from "../../../assets/images/forecast.png";
//import styles
import "../../AddCommodity/addcommodity.scss";

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    maxHeight: "90%",
    fontSize: 12,
    fontFamily: "Hind Siliguri !important",
    zIndex: 9999,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "auto",
    maxHeight: "7vh",
  }),

  option: (provided, state) => ({
    ...provided,
    fontSize: 12,
    fontFamily: "Hind Siliguri !important",
  }),
};

function MetaSelection(props) {
  let {
    user,
    cncData,
    updateCNCInputs,
    updateAlertCNC,
    setDemandPlannerInfo,
    getNext_N_MonthNamesWithYear,
    handleConfirmDialog,
  } = props;

  const [expand, setExpand] = useState(false);
  let api = config.api.api_url;
  //When Global is chosen as region country and factories should be hidden
  const [inputDisableFlag, setInputDisableFlag] = useState(false);

  //function to populate data for decision Month dropdown &Headers for Override and Demand tables
  /*
    if getNext_N_MonthNamesWithYear() is called only with 1 parameter ie)maxmonth it generates dropdown
      values for Decision month (month options)

    if  getNext_N_MonthNamesWithYear() is called only with 2 parameter ie)Max month and date
      it generates values for override price forecast table and demand tree header
    */
  // function getNext_N_MonthNamesWithYear(maxMonth,date="") {
  //   var names = ['January', 'February', 'March', 'April', 'May', 'June',
  //     'July', 'August', 'September', 'October', 'November', 'December'];
  //   var now = new Date();
  //   var month;
  //   var year;
  //   if(arguments.length==1){
  //       // month=now.getMonth()-4;
  //       month=0;
  //       year = now.getFullYear();
  //   }
  //   else{
  //       let month_in_text=date.split(" ")[0];
  //       month=names.indexOf(month_in_text)+1;
  //       year=date.split(" ")[1];
  //   }

  //   let month_options = [];
  //   let override_forecast_header=[];
  //   let demand_header=[];
  //   for (var i = 0; i < maxMonth; ++i) {
  //       if(arguments.length==1){
  //            month_options[i] = {
  //               label: names[month] + ' ' + year,
  //               value: names[month] + ' ' + year
  //             };
  //       }
  //       else if(arguments.length>1){
  //         override_forecast_header[i] = {
  //               label: names[month] + ' ' + year,
  //               value: "",
  //             };

  //         demand_header[i] = {
  //               label: names[month] + ' ' + year,
  //               value: "",
  //             };
  //       }

  //     if (++month === 12) {
  //       month = 0;
  //       ++year;
  //     }
  //   }
  //   if(arguments.length==1){
  //     updateCNCInputs("month_options", month_options)
  //   }
  //   if(arguments.length>1){
  //     updateCNCInputs("override_forecast_header", override_forecast_header)
  //     updateCNCInputs("demand_header", demand_header)
  //   }
  //   setLoading(false);
  // }
  //handles the change for Commodity selection
  const commodityChange = (e) => {
    updateCNCInputs("month_selection_value", "");
    updateCNCInputs("month_options", []);
    updateCNCInputs("indexOptions", []);
    updateCNCInputs("countryOptions", []);
    updateCNCInputs("factoryOptions", []);
    updateCNCInputs("factory_selection", []);
    updateCNCInputs("commodity_selection", e.label);
    updateCNCInputs("commodity_selection_value", e.value);
    updateCNCInputs("index_commodity_id", "");
    updateCNCInputs("region_selection_value", [
      {
        label: "Global",
        value: "Global",
        geography_level: "region",
        parent_id: "null",
      },
    ]);
    getRegions();
    getNext_N_MonthNamesWithYear(e.decision_months);
    // updateCNCInputs("isIndexVisible",e.isIndexVisible);
    if (e.isIndexVisible) {
      // getIndexOption(e.value);
      let indexOptions = [];
      for (let i = 0; i < e.indices.length; i++) {
        indexOptions[i] = {
          label: e.indices[i].index_name,
          value: e.indices[i].index_commodity_id,
          source_weights: e.indices[i].source_weight,
        };
      }
      if (indexOptions.length === 1) {
        updateCNCInputs("index_commodity_id", indexOptions[0].value);
        updateCNCInputs("index_source_weights", indexOptions[0].source_weights);
      }
      updateCNCInputs("indexOptions", indexOptions);
    }
    let factoryOptions = [];
    let factory_id = e.factory_id;
    for (let i = 0; i < factory_id.length; i++) {
      factoryOptions[i] = {
        value: factory_id[i],
        label: factory_id[i],
        geography_level: "factory",
        // parent_id:factory_id[i]
      };
    }
    updateCNCInputs("factoryOptions", factoryOptions);
    if (factoryOptions.length === 1) {
      updateCNCInputs("factory_selection", [factoryOptions[0]]);
    }

    // updateCNCInputs("isCountryVisible",e.isCountryVisible);
    // updateCNCInputs("isDemandEditable",e.isDemandEditable);
    // updateCNCInputs("scenarioFlag",e.scenarioFlag);
    // updateCNCInputs("isExpandable",e.isExpandable);
    // updateCNCInputs("report_url",e.report_url);
  };

  useEffect(() => {
    setDemandPlannerInfo();
  }, [user.groups]);

  useEffect(() => {
    // getIndexOption(cncData.commodity_selection_value)
    if (
      cncData.mode === "edit" ||
      cncData.mode === "view" ||
      cncData.mode === "view-shared"
    ) {
      let indexOptions = [];
      cncData.commodityOptions.filter((obj) => {
        if (
          obj.value === cncData.commodity_selection_value &&
          obj.isIndexVisible
        ) {
          for (let i = 0; i < obj.indices.length; i++) {
            indexOptions[i] = {
              label: obj.indices[i].index_name,
              value: obj.indices[i].index_commodity_id,
              source_weights: obj.indices[i].source_weight,
            };
          }
          updateCNCInputs("indexOptions", indexOptions);
          updateCNCInputs("isIndexVisible", obj.isIndexVisible);
          updateCNCInputs("isCountryVisible", obj.isCountryVisible);
          updateCNCInputs("isDemandEditable", obj.isDemandEditable);
          updateCNCInputs("scenarioFlag", obj.scenarioFlag);
          updateCNCInputs("report_url", obj.report_url);
        }
      });
    }
  }, [cncData.commodity_selection_value, cncData.commodityOptions]);

  // useEffect(()=>{
  //   if(cncData.mode=="edit"||cncData.mode=="view"){
  //     getNext_N_MonthNamesWithYear(cncData.month_options.length,cncData.month_selection_value);
  //   }
  // },[cncData.month_options,cncData.scenario_flag_checkbox])
  //handles the change for Commodity selection
  const decisionMonthChange = (e) => {
    // getRegions();
    updateCNCInputs("month_selection", e.label);
    updateCNCInputs("month_selection_value", e.value);
    updateCNCInputs("decision_month", formattedDate(e.value));
    // updateCNCInputs("index_commodity_id","");
    if (cncData.indexOptions.length === 1) {
      updateCNCInputs("index_commodity_id", cncData.indexOptions[0].value);
      updateCNCInputs(
        "index_source_weights",
        cncData.indexOptions[0].source_weights
      );
    }
    let month;
    cncData.commodityOptions.filter((obj) => {
      if (
        obj.label === cncData.commodity_selection ||
        obj.value === cncData.commodity_selection_value
      ) {
        month = obj.decision_months;
      }
    });
    updateCNCInputs("max_decision_months", month);
    getNext_N_MonthNamesWithYear(month, e.value);
  };

  //Function to get Regions
  const getRegions = () => {
    let regionOptions = [];

    //fetch call
    let input = {
      level: "region",
      parent_id: "",
    };
    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };

    fetch(api + "/geography", reqOptions)
      .then((respone) => respone.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          for (let i = 0; i < response.data.length; i++) {
            regionOptions[i] = {
              value: response.data[i].geography_id,
              label: response.data[i].geography,
              geography_level: response.data[i].geography_level,
              parent_id: response.data[i].parent_id,
            };
          }
          updateCNCInputs("regionOptions", regionOptions);
        } else {
          updateCNCInputs("regionOptions", []);
        }
      })
      .catch((error) => {
        updateCNCInputs("regionOptions", []);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag: false,
        });
      });
  };

  // function for multiselect inputs
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            id="productDimension"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            // style={{display:"flex",flexWrap:"wrap"}}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleIndexChange = (e) => {
    updateCNCInputs("index_commodity_id", e.value);
    updateCNCInputs("index_source_weights", e.source_weights);
  };

  const handleFactorySelection = (e) => {
    if (e !== null && e.length > 0) {
      let flag = true;
      e.map((itm) => {
        if (itm.value == "<SELECT_ALL>") {
          flag = false;
          updateCNCInputs("factory_selection", cncData.factoryOptions);
        }
      });
      if (flag) {
        updateCNCInputs("factory_selection", e);
      }

      // updateCNCInputs("factoryOptionsRowAddition",e)
    } else {
      updateCNCInputs("factory_selection", []);
    }
  };

  function formattedDate(date, previous = false) {
    let d = new Date(date);
    let month = previous ? String(d.getMonth()) : String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (
      cncData.index_commodity_id !== "" &&
      cncData.month_selection_value !== "" &&
      cncData.factory_selection.length > 0
    ) {
      let geography_id = [];
      let demand_input;

      if (cncData.factory_selection.length > 0) {
        for (let i = 0; i < cncData.factory_selection.length; i++) {
          geography_id.push(cncData.factory_selection[i].value);
        }
        demand_input = {
          isGlobal: false,
          isFactory: true,
          level: "factory",
          height: config.demand_treeData_height,
          input: {
            geography_id: geography_id.join(","),
            index_commodity_id: cncData.index_commodity_id,
            decision_month: formattedDate(cncData.month_selection_value),
          },
          prevInput: {
            geography_id: geography_id.join(","),
            index_commodity_id: cncData.index_commodity_id,
            decision_month: formattedDate(cncData.month_selection_value, true),
          },
          commodity_selection_value: cncData.commodity_selection_value,
        };
      } else if (cncData.country_selection_value.length > 0) {
        for (let i = 0; i < cncData.country_selection_value.length; i++) {
          geography_id.push(cncData.country_selection_value[i].value);
        }
        demand_input = {
          isGlobal: false,
          isFactory: false,
          level: "country",
          height: 37,
          input: {
            country: geography_id.join(","),
            index_commodity_id: cncData.index_commodity_id,
            decision_month: formattedDate(cncData.month_selection_value),
          },
          prevInput: {
            country: geography_id.join(","),
            index_commodity_id: cncData.index_commodity_id,
            decision_month: formattedDate(cncData.month_selection_value, true),
          },
          commodity_selection_value: cncData.commodity_selection_value,
        };
      } else {
        for (let i = 0; i < cncData.region_selection_value.length; i++) {
          geography_id.push(cncData.region_selection_value[i].value);
        }
        if (cncData.region_selection_value[0].value === "Global") {
          demand_input = {
            isGlobal: true,
            isFactory: false,
            level: "region",
            height: 37,
            input: {
              region: "",
              index_commodity_id: cncData.index_commodity_id,
              decision_month: formattedDate(cncData.month_selection_value),
            },
            prevInput: {
              region: "",
              index_commodity_id: cncData.index_commodity_id,
              decision_month: formattedDate(
                cncData.month_selection_value,
                true
              ),
            },
            commodity_selection_value: cncData.commodity_selection_value,
          };
        } else {
          demand_input = {
            isGlobal: false,
            isFactory: false,
            level: "region",
            height: 37,
            input: {
              region: geography_id.join(","),
              index_commodity_id: cncData.index_commodity_id,
              decision_month: formattedDate(cncData.month_selection_value),
            },
            prevInput: {
              region: geography_id.join(","),
              index_commodity_id: cncData.index_commodity_id,
              decision_month: formattedDate(
                cncData.month_selection_value,
                true
              ),
            },
            commodity_selection_value: cncData.commodity_selection_value,
          };
        }
      }
      // let region_selection_list=[];

      //fetch call
      let reqOptionsDemand = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(demand_input),
      };

      setInputDisableFlag(true);
      updateCNCInputs("loading", true);
      fetch(api + "/db/demand/planner", reqOptionsDemand)
        .then((res) => res.json())
        .then((res) => {
          if (res.status.statusCode === "cnc-200") {
            if (Object.keys(res.data).length === 0) {
              // if(cncData.grade_commodity_flag){
              handleConfirmDialog();
              // }
              updateCNCInputs("demand_treeData", []);
              updateCNCInputs(
                "factoryOptionsRowAddition",
                cncData.factory_selection
              );
            } else {
              updateCNCInputs("demand_treeData", res.data.data);
              let factoryOptionsRowAddition = [];
              if (res.unique_factories) {
                for (let i = 0; i < res.unique_factories.length; i++) {
                  factoryOptionsRowAddition[i] = {
                    label: res.unique_factories[i],
                    value: res.unique_factories[i],
                  };
                }
                updateCNCInputs(
                  "factoryOptionsRowAddition",
                  factoryOptionsRowAddition
                );
              } else {
                updateCNCInputs("factoryOptionsRowAddition", []);
              }
            }
            updateCNCInputs("loading", false);
            setInputDisableFlag(false);
            updateCNCInputs("fetch_status", true);
          } else if (res.status.statusCode === "cnc-400") {
            updateCNCInputs("loading", false);
            setInputDisableFlag(false);
            updateCNCInputs("demand_treeData", []);
            updateCNCInputs("fetch_status", false);
            updateCNCInputs("errorMessage", res.status.statusMessage);
          } else {
            updateCNCInputs("loading", false);
            setInputDisableFlag(false);
            updateCNCInputs("demand_treeData", []);
            updateCNCInputs("fetch_status", false);
            updateCNCInputs(
              "errorMessage",
              "Unable to fetch demand data please try again"
            );
          }
        })
        .catch((error) => {
          updateCNCInputs("loading", false);
          setInputDisableFlag(false);
          updateCNCInputs("demand_treeData", []);
          updateCNCInputs("fetch_status", false);
          updateCNCInputs(
            "errorMessage",
            "Unable to fetch demand data please try again"
          );
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag: false,
          });
        });
    }
  }, [
    cncData.index_commodity_id,
    cncData.region_selection_value,
    cncData.month_selection_value,
    cncData.factory_selection,
  ]);

  return (
    <>
      <div className="cnc-toggle-icon">
        <label
          onClick={() => {
            setExpand(!expand);
            updateCNCInputs("expand", expand);
          }}
        >
          {!expand ? "Collapse" : "Expand"}
        </label>
        <i
          className="material-icons"
          onClick={() => {
            setExpand(!expand);
            updateCNCInputs("expand", expand);
          }}
        >
          {!expand ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
      </div>
      {!expand && (
        <div className="cnc-model-header">
          <Grid container spacing={1} style={{ padding: "2px 15px 0px 15px" }}>
            <Grid item xs={3} sm={3}>
              <div className="mg-model-header-item" style={{ height: "25px" }}>
                Select a Commodity:{" "}
                <p
                  style={{
                    display: "inline",
                    color: "red",
                    fontSize: 16,
                  }}
                >
                  *
                </p>
              </div>
              <div className="" style={{ marginBottom: "15px" }}>
                <Dropdown
                  options={
                    cncData.commodityOptions !== undefined
                      ? cncData.commodityOptions
                      : []
                  }
                  callback={commodityChange}
                  disabled={
                    cncData.commodityOptions !== undefined &&
                    cncData.commodityOptions.length === 1
                      ? true
                      : inputDisableFlag
                  }
                  updateValue={cncData.commodity_selection_value}
                  placeholder={"Select a Commodity"}
                />
              </div>
            </Grid>
            {cncData.commodity_selection_value !== "" && (
              <>
                <Grid item xs={3} sm={3}>
                  <div>
                    Select Decision Month:{" "}
                    <p
                      style={{
                        display: "inline",
                        color: "red",
                        fontSize: 16,
                      }}
                    >
                      *
                    </p>
                  </div>

                  <div className="" style={{ marginBottom: "15px" }}>
                    <Dropdown
                      options={cncData.month_options}
                      callback={decisionMonthChange}
                      disabled={inputDisableFlag}
                      updateValue={cncData.month_selection_value}
                      placeholder={"Select Decision Month"}
                    />
                  </div>
                </Grid>
              </>
            )}

            {cncData.month_selection_value !== "" &&
              cncData.indexOptions.length > 0 && (
                <>
                  <Grid item xs={3} sm={3}>
                    <div>
                      Select Index
                      <p
                        style={{
                          display: "inline",
                          color: "red",
                          fontSize: 16,
                        }}
                      >
                        *
                      </p>
                    </div>

                    <div className="">
                      <Dropdown
                        options={cncData.indexOptions}
                        callback={handleIndexChange}
                        disabled={
                          cncData.indexOptions.length === 1
                            ? true
                            : inputDisableFlag
                        }
                        updateValue={cncData.index_commodity_id}
                        placeholder={"Select Index"}
                      />
                    </div>
                  </Grid>
                </>
              )}

            {cncData.month_selection_value !== "" &&
              cncData.factoryOptions.length > 0 && (
                <Grid item xs={3} sm={3}>
                  <div
                    className="mg-model-header-item"
                    style={{ height: "25px" }}
                  >
                    Select Factory:{" "}
                  </div>
                  <div className="" style={{ marginBottom: "15px" }}>
                    <ReactSelect
                      options={[
                        {
                          value: "<SELECT_ALL>",
                          label: "Select all",
                        },
                        ...cncData.factoryOptions,
                      ]}
                      isMulti
                      isDisabled={
                        cncData.factoryOptions.length === 1
                          ? true
                          : inputDisableFlag
                      }
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      value={cncData.factoryOptions.filter((obj) =>
                        cncData.factory_selection.some(
                          (item) => item.label === obj.label
                        )
                      )}
                      components={{ Option }}
                      allowSelectAll={true}
                      menuPortalTarget={document.body}
                      styles={customStyles}
                      onChange={handleFactorySelection}
                      menuPlacement="auto"
                      menuPosition="fixed"
                    />
                  </div>
                </Grid>
              )}
          </Grid>
        </div>
      )}
    </>
  );
}

MetaSelection.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  cncData: state.cncinputs,
  allData: state.data,
});

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MetaSelection)
);
