/*
Created on March 2022
Confidential and Proprietary of Reckitt
Project: CNC
Screen name : AddCommodity
Screen Description : 
@author: Tailwyndz
*/

//Import Libraries
import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from 'react';
import {config} from '../../../../config/config';
import { withRouter } from "react-router";
import { Grid } from '@material-ui/core';

import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { updateCNCInputs, updateAlertCNC, updatewholeCNCInputs ,updateCNCDatas} from '../../../../redux/actions';


const customStyles = {
  menuPortal: (base) => ({
    ...base,
    maxHeight: "90%",
    fontSize: 12,
    fontFamily: "Hind Siliguri !important",
    zIndex: 9999,
  }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "auto",
      maxHeight: "7vh",
    }),
  
    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
      fontFamily: "Hind Siliguri !important",
    }),
  };

function MetaSelection(props){
    let { cncData, updateCNCInputs,  updateAlertCNC} = props;

    const [expand, setExpand] = useState(false);
    let api = config.api.api_url;
    //When Global is chosen as region country and factories should be hidden
    const [hideOnGlobalFlag,setHideOnGlobalFlag]=useState(false);
    const [inputDisableFlag,setInputDisableFlag]=useState(false);

    
  

    //function to populate data for decision Month dropdown &Headers for Override and Demand tables
    /*
    if getNext_N_MonthNamesWithYear() is called only with 1 parameter ie)maxmonth it generates dropdown
      values for Decision month (month options)

    if  getNext_N_MonthNamesWithYear() is called only with 2 parameter ie)Max month and date
      it generates values for override price forecast table and demand tree header
    */
      function getNext_N_MonthNamesWithYear(maxMonth,date="") {
        var names = ['January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'];
        var now = new Date();
        var month;
        var year;
        if(arguments.length===1){
            // month=now.getMonth()-4;
            let before_three_month=new Date(now.setMonth(now.getMonth()-3))
            month=before_three_month.getMonth();
            year = before_three_month.getFullYear();
            maxMonth=13;
        }
        else{
            let month_in_text=date.split(" ")[0];
            month=names.indexOf(month_in_text)+1;
            year=date.split(" ")[1];
            if(month==12){month=0;year=parseInt(year)+1;}
        }
        
    
        let month_options = [];
        let override_forecast_header=[];
        let demand_header=[];
        for (var i = 0; i < maxMonth; ++i) {
            if(arguments.length===1){
                 month_options[i] = {
                    label: names[month] + ' ' + year,
                    value: names[month] + ' ' + year
                  };
            }
            else if(arguments.length>1){
              override_forecast_header[i] = {
                    label: names[month] + ' ' + year,
                    value: "",
                  };
              
              demand_header[i] = {
                    label: names[month] + ' ' + year,
                    value: "",
                  }; 
            }
          

          
          
          if (++month === 12) {
            month = 0;
            ++year;
          }
        }
        if(arguments.length===1){
          updateCNCInputs("month_options", month_options)
        }
        if(arguments.length>1){
          updateCNCInputs("override_forecast_header", override_forecast_header)
          updateCNCInputs("demand_header", demand_header)
        }
      }
 //handles the change for Commodity selection
 const commodityChange = (e) => {
  updateCNCInputs("month_selection_value","");
  updateCNCInputs("month_options",[]);
  updateCNCInputs("indexOptions",[]);
  updateCNCInputs("countryOptions",[]);
  updateCNCInputs("factoryOptions",[]);
  updateCNCInputs("commodity_selection", e.label);
  updateCNCInputs("commodity_selection_value", e.value);
  updateCNCInputs("index_commodity_id","");
  updateCNCInputs("override_forecast_header", []);
  updateCNCInputs("demand_header", []);
  updateCNCInputs("scenario_flag_checkbox",false);
  updateCNCInputs("demand_difference_flag",false);
  updateCNCInputs("region_selection_value",[
    {
      label:"Global",
      value:"Global",
      geography_level:"region",
      parent_id:"null",
    }
  ]);
  getRegions();
  getNext_N_MonthNamesWithYear(e.decision_months);
  updateCNCInputs("isIndexVisible",e.isIndexVisible);
  if(e.isIndexVisible){
    // getIndexOption(e.value);
    let indexOptions=[];
    for (let i = 0; i < e.indices.length; i++) {
      indexOptions[i] = {
        label: e.indices[i].index_name,
        value: e.indices[i].index_commodity_id,
        source_weights: e.indices[i].source_weight
      }
    }
    if (indexOptions.length === 1) {
      updateCNCInputs("index_commodity_id", indexOptions[0].value)
      updateCNCInputs("index_source_weights",indexOptions[0].source_weights)
    }
    updateCNCInputs("indexOptions", indexOptions);
  }
  updateCNCInputs("isCountryVisible",e.isCountryVisible);
  updateCNCInputs("isDemandEditable",e.isDemandEditable);
  updateCNCInputs("scenarioFlag",e.scenarioFlag);
  updateCNCInputs("isExpandable",e.isExpandable);
  updateCNCInputs("report_url",e.report_url);
  updateCNCInputs("demand_difference_url",e.demand_difference_url);
  if(e.scenarioFlag===false){
    // updateCNCInputs("weight_graphs",e.weight_graphs)
    // updateCNCInputs("weight_graphs_copy",e.weight_graphs)
  }

}

useEffect(()=>{
  getCommodity();
},[])


useEffect(()=>{
  // getIndexOption(cncData.commodity_selection_value)
  if(cncData.mode ==="edit" ||cncData.mode ==="view"||cncData.mode ==="view-shared"){
    let indexOptions=[];
  cncData.commodityOptions.filter((obj)=>{
    if(obj.value ===cncData.commodity_selection_value &&obj.isIndexVisible){
      for(let i=0;i<obj.indices.length;i++){
            indexOptions[i]={
              label:obj.indices[i].index_name,
              value:obj.indices[i].index_commodity_id,
              source_weights: obj.indices[i].source_weight
            }
          }
      updateCNCInputs("indexOptions",indexOptions);
      updateCNCInputs("isIndexVisible",obj.isIndexVisible)
      updateCNCInputs("isCountryVisible",obj.isCountryVisible)
      updateCNCInputs("isDemandEditable",obj.isDemandEditable)
      updateCNCInputs("scenarioFlag",obj.scenarioFlag)
      updateCNCInputs("report_url",obj.report_url);
      updateCNCInputs("isDairy",obj.isDairy)
      updateCNCInputs("show_execution_dashboard",obj.show_execution_dashboard)
      
    }
  })

  //also Update the index source weights for the selected index
  if(cncData.mode ==="edit"||cncData.mode ==="view-shared"||cncData.mode==='view'){
    indexOptions.filter((obj)=>{
      if(obj.value===cncData.index_commodity_id){
        updateCNCInputs("index_source_weights",obj.source_weights)
      }
    })
  }
    // updateCNCInputs("loading",true);
    // updateCNCInputs("forecastloading",true);
  }
  
},[cncData.commodity_selection_value,cncData.commodityOptions])

  
  // useEffect(()=>{
  //   if(cncData.mode=="edit"||cncData.mode=="view"){
  //     getNext_N_MonthNamesWithYear(cncData.month_options.length,cncData.month_selection_value);
  //   }
  // },[cncData.month_options,cncData.scenario_flag_checkbox])
 //handles the change for Commodity selection
 const decisionMonthChange = (e) => {
  // getRegions();
  updateCNCInputs("month_selection", e.label);
  updateCNCInputs("month_selection_value", e.value);
  updateCNCInputs("decision_month",formattedDate(e.value))
  updateCNCInputs("demand_difference_flag",false);
  // updateCNCInputs("index_commodity_id","");
  if (cncData.indexOptions.length === 1) {
    updateCNCInputs("index_commodity_id", cncData.indexOptions[0].value)
    updateCNCInputs("index_source_weights",cncData.indexOptions[0].source_weights)
  }
  let month;
  cncData.commodityOptions.filter((obj)=>{
      if(obj.label===cncData.commodity_selection){
        month=obj.decision_months
      }
  })
  updateCNCInputs("max_decision_months",month);
  getNext_N_MonthNamesWithYear(month,e.value);
}

//Function to get Regions
const getRegions=()=>{

  let regionOptions=[];

  //fetch call
  let input={
    level:"region",
    parent_id:"",
  }
  let reqOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  fetch(api + "/geography",reqOptions)
  .then((respone) => respone.json())
  .then((response) => {
    if(response.status.statusCode==="cnc-200"){
    for (let i = 0; i < response.data.length; i++) {
      regionOptions[i] = {
        value: response.data[i].geography_id,
        label: response.data[i].geography,
        geography_level:response.data[i].geography_level,
        parent_id:response.data[i].parent_id
      }
    }
    updateCNCInputs("regionOptions",regionOptions);
  }

  else{
    updateCNCInputs("regionOptions",[]);
  }
  })
  .catch((error) => {
    updateCNCInputs("regionOptions",[]);
    updateAlertCNC({
      open: true,
      message: config.messages.unexpected_error,
      severity: "info",
      closeFlag:false
    });
  });

  

}

//Fucntion to get Countries
const getCountries=(regions)=>{
  let countryOptions=[];

  let input={
    level:"country",
    parent_id:regions,
  }

  //fetch call
  let reqOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  
  fetch(api + "/geography",reqOptions)
  .then((respone) => respone.json())
  .then((response) => {
    if(response.status.statusCode==="cnc-200"){
      for (let i = 0; i < response.data.length; i++) {
        countryOptions[i] = {
          value: response.data[i].geography_id,
          label: response.data[i].geography,
          geography_level:response.data[i].geography_level,
          parent_id:response.data[i].parent_id
        }
      }
      updateCNCInputs("countryOptions",countryOptions);
    }

    else{
      updateCNCInputs("countryOptions",[]);
    }
  })
  .catch((error) => {
    updateCNCInputs("countryOptions",[]);
    updateAlertCNC({
      open: true,
      message: config.messages.unexpected_error,
      severity: "info",
      closeFlag:false
    });
  });
}

//Function to getFactories
const getFactories=(countries)=>{
  let factoryOptions=[];

  let input={
    level:"factory",
    parent_id:countries,
  }

  //fetch call
  let reqOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

 
  fetch(api + "/geography",reqOptions)
  .then((respone) => respone.json())
  .then((response) => {
    if(response.status.statusCode==="cnc-200"){ 
      for (let i = 0; i < response.data.length; i++) {
        factoryOptions[i] = {
          value: response.data[i].geography_id,
          label: response.data[i].geography,
          geography_level:response.data[i].geography_level,
          parent_id:response.data[i].parent_id
        }
      }
      updateCNCInputs("factoryOptions",factoryOptions);
    }
    else{
      updateCNCInputs("factoryOptions",[]);
    }
  })
  .catch((error) => {
    updateCNCInputs("factoryOptions",[]);
    updateAlertCNC({
      open: true,
      message: config.messages.unexpected_error,
      severity: "info",
      closeFlag:false
    });
  });

}


   // function for multiselect inputs
   const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            id="productDimension"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          // style={{display:"flex",flexWrap:"wrap"}}
          />{" "}
          <label>
            {props.label}
          </label>
        </components.Option>
      </div>
    );
  };

  const handleRegionChange = (e) => {
    let regionList=[];
    updateCNCInputs("demand_difference_flag",false);
    updateCNCInputs("factory_selection", []);
    // updateCNCInputs("factoryOptions",[]);
    // updateCNCInputs("countryOptions", []);
    updateCNCInputs("country_selection", "");
    updateCNCInputs("country_selection_value", []);
    // updateCNCInputs("region_selection_value", e);
    if(e!== null &&e.length>0){
      setHideOnGlobalFlag(true);
      for(let i=0;i<e.length;i++){
        regionList.push(e[i].value)
      }
      
      let option=e;

      if(option.length >1){
         option = option.filter((item) => item.label !== "Global");
      }
      updateCNCInputs("region_selection", e[0].label);
      updateCNCInputs("region_selection_value", option);
      getCountries(regionList)
      
    }
    else{
      setHideOnGlobalFlag(false);
      updateCNCInputs("region_selection", "");
      updateCNCInputs("region_selection_value", [{
        label:"Global",
        value:"Global",
        geography_level:"region",
        parent_id:"null",
      }]);
      updateCNCInputs("factory_selection", []);
      updateCNCInputs("factoryOptions",[]);
      updateCNCInputs("countryOptions",[]);
        updateCNCInputs("country_selection", "");
        updateCNCInputs("country_selection_value", []);
    }
  }

 
  const handleIndexChange=(e)=>{
    updateCNCInputs("index_commodity_id",e.value);
    updateCNCInputs("index_source_weights",e.source_weights)
    updateCNCInputs("demand_difference_flag",false);
  }
  const handleCountryChange = (e) => {
    let countryList=[];
    updateCNCInputs("demand_difference_flag",false);
    if(e!== null &&e.length>0){
      for(let i=0;i<e.length;i++){
        countryList.push(e[i].value)
      }
      getFactories(countryList)
    updateCNCInputs("country_selection_value", e);
    }

    else{
      updateCNCInputs("country_selection", "");
      updateCNCInputs("country_selection_value", []);
      updateCNCInputs("factoryOptions", []);
      updateCNCInputs("factory_selection", []);
    }
  }

  const handleFactorySelection = (e) => {
    updateCNCInputs("demand_difference_flag",false);
    if(e!== null &&e.length>0){
    updateCNCInputs("factory_selection", e);
    }

    else{
      updateCNCInputs("factory_selection", []);
    }
  }

  function formattedDate(date,previous=false) {
      let d =new Date(date)
    let month = previous ? String(d.getMonth()):String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${year}-${month}-${day}`;
  }

  const convertToUTC=(last_date)=>{    
    last_date =new Date(last_date)
      if (last_date.getMonth() === 1) {
        if ((last_date.getFullYear() % 100 === 0) ? (last_date.getFullYear() % 400 === 0) : (last_date.getFullYear() % 4 === 0)) {
            if (last_date.getDate() === 29) {
                last_date.setDate(last_date.getDate() + 1);
                last_date.setHours(0, 0, 0, 0);
            }
        }
        else if (last_date.getDate() === 28) {
            last_date.setDate(last_date.getDate() + 1)

            last_date.setHours(0, 0, 0, 0);
        }
    }
    else if (last_date.getMonth() === 0 || last_date.getMonth() === 2 || last_date.getMonth() === 4 || last_date.getMonth() === 6 || last_date.getMonth() === 7 || last_date.getMonth() === 9 || last_date.getMonth() === 11) {
        if (last_date.getDate() === 31) {
            last_date.setDate(last_date.getDate() + 1);
            last_date.setHours(0, 0, 0, 0);
        }
    }
    else {
        if (last_date.getDate() === 30) {
            last_date.setDate(last_date.getDate() + 1);
            last_date.setHours(0, 0, 0, 0);
        }
    }
    return last_date;
  }

  useEffect(()=>{
    if(cncData.mode==="create"?(cncData.index_commodity_id!==""&&cncData.month_selection_value!==""):  
    cncData.index_commodity_id!==""&&cncData.month_selection_value!=="" &&
    (cncData.index_source_weights!==undefined&&Object.keys(cncData.index_source_weights).length !== 0)){
          let geography_id=[];
          let demand_input;
          let price_input;

          if(cncData.factory_selection.length>0){
            for(let i=0;i<cncData.factory_selection.length;i++){
              geography_id.push(cncData.factory_selection[i].value);
            }
             demand_input={
               isGlobal:false,
               isFactory:true,
               level:"factory",
               height:config.demand_treeData_height,
               input:{
                geography_id:geography_id.join(","),
                index_commodity_id:cncData.index_commodity_id,
                decision_month:formattedDate(cncData.month_selection_value),
                // projection_source:null,
               },
               prevInput:{
                geography_id:geography_id.join(","),
                index_commodity_id:cncData.index_commodity_id,
                decision_month:formattedDate(cncData.month_selection_value,true),
                // projection_source:null,
               },
               commodity_selection_value:cncData.commodity_selection_value
              
            }
          }

          else if(cncData.country_selection_value.length>0){
            for(let i=0;i<cncData.country_selection_value.length;i++){
              geography_id.push(cncData.country_selection_value[i].value);
            }
            demand_input={
              isGlobal:false,
              isFactory: false,
              level: "country",
              height: 37,
              input:{
                country:geography_id.join(","),
                index_commodity_id:cncData.index_commodity_id,
                decision_month:formattedDate(cncData.month_selection_value),
                // projection_source:null,
              },
              prevInput:{
                country:geography_id.join(","),
                index_commodity_id:cncData.index_commodity_id,
                decision_month:formattedDate(cncData.month_selection_value,true),
                // projection_source:null,
               },
              commodity_selection_value:cncData.commodity_selection_value
            }
          }

          else{
            for(let i=0;i<cncData.region_selection_value.length;i++){
              geography_id.push(cncData.region_selection_value[i].value);
            }
            if(cncData.region_selection_value[0].value==="Global"){
              demand_input={
                isGlobal:true,
                isFactory: false,
                level: "region",
                height: 37,
                input:{
                  region:"",
                  index_commodity_id:cncData.index_commodity_id,
                  decision_month:formattedDate(cncData.month_selection_value),
                  // projection_source:null,
                },
                prevInput:{
                  region:"",
                  index_commodity_id:cncData.index_commodity_id,
                  decision_month:formattedDate(cncData.month_selection_value,true),
                  // projection_source:null,
                 },
                commodity_selection_value:cncData.commodity_selection_value
              }
            }
            else{
              demand_input={
                isGlobal:false,
                isFactory: false,
                level: "region",
                height: 37,
                input:{
                  region:geography_id.join(","),
                  index_commodity_id:cncData.index_commodity_id,
                  decision_month:formattedDate(cncData.month_selection_value),
                  // projection_source:null,
                },
                prevInput:{
                  region:geography_id.join(","),
                  index_commodity_id:cncData.index_commodity_id,
                  decision_month:formattedDate(cncData.month_selection_value,true),
                  // projection_source:null,
                 },
                commodity_selection_value:cncData.commodity_selection_value
              }
            }
            
          }
          // let region_selection_list=[];
          if(cncData.mode!=="view"){
            price_input={
              price_input_params:{
                geography_id:geography_id.join(","),
              index_commodity_id:cncData.index_commodity_id,
              decision_month:formattedDate(cncData.month_selection_value),
              },
              forecast_input_params:{
                geography_id:geography_id.join(","),
              index_commodity_id:cncData.index_commodity_id,
              decision_month:formattedDate(cncData.month_selection_value),
              cnc_id:cncData.cnc_id,
              run_id:cncData.run_id,
              },
              is_edit:cncData.mode==="create"?false:true,
              is_forecast_weight:false,
              index_source_weights:cncData.index_source_weights,
              commodity_selection_value:cncData.commodity_selection_value
            }
          }

          else{
            price_input={
              price_input_params:{
                geography_id:geography_id.join(","),
                index_commodity_id:cncData.index_commodity_id,
                decision_month:formattedDate(cncData.month_selection_value)    
              },

              forecast_input_params:{
                geography_id:geography_id.join(","),
                index_commodity_id:cncData.index_commodity_id,
                decision_month:formattedDate(cncData.month_selection_value),
                cnc_id:cncData.cnc_id,
                run_id:cncData.run_id,        
              },
              is_edit:false,
              is_forecast_weight:true,
              index_source_weights:cncData.index_source_weights,
              commodity_selection_value:cncData.commodity_selection_value,          

            }

          }
          
          //fetch call
          let reqOptionsDemand = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(demand_input),
          };

          let reqOptionsPrice = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(price_input),
          };

          
          updateCNCInputs("loading",true);
          updateCNCInputs("forecastloading",true);
          setInputDisableFlag(true);
          // let cncDataGraph=[];
          fetch(api + "/db/read_price_forecasts",reqOptionsPrice)
          .then((respone) => respone.json())
          .then((response) => {
            if(response.status.statusCode==="cnc-200"){
              
              if(response.data.length>0){
                for(let i=0;i<response.data.length;i++){
                  for(let j=0;j<response.data[i].data.length;j++){
                    response.data[i].data[j].forecast_month = convertToUTC(response.data[i].data[j].forecast_month)
                  }      
                }             
                for(let i=0;i<response.data.length;i++){
                  response.data[i].data.sort(function(a,b){
                    var c = new Date(a.forecast_month);
                    var d = new Date(b.forecast_month);           
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    // return new Date(b.forecast_month) - new Date(a.forecast_month);
                    return c-d;
                  });
                  // if( response.data[i].data.length>cncData.max_decision_months){
                  //   response.data[i].data=response.data[i].data.splice(0,cncData.max_decision_months);
                  // } 
                }         
                updateCNCInputs("cncDataGraph",response.data);
                updateCNCInputs("unique_values",response.unique_values);
                
                updateCNCInputs("weight_graphs",cncData.index_source_weights);
                updateCNCInputs("weight_graphs_copy",cncData.index_source_weights);
              }
              else{
                updateCNCInputs("cncDataGraph",[]);
              }
              updateCNCInputs("forecastloading",false);
              updateCNCInputs("forecast_fetch_status",true);
            }

            else if(response.status.statusCode==="cnc-400"){
              updateCNCInputs("cncDataGraph",[]);
              updateCNCInputs("forecastloading",false);
              updateCNCInputs("forecast_fetch_status",false);
              updateCNCInputs("forecast_errorMessage",response.status.statusMessage);
            }
            else{
              updateCNCInputs("cncDataGraph",[]);
              updateCNCInputs("forecastloading",false);
              updateCNCInputs("forecast_fetch_status",false);
              updateCNCInputs("forecast_errorMessage",response.status.statusMessage);
            }
            
          })
          .catch((error) => {
            // console.log(error)
            updateCNCInputs("cncDataGraph",[]);
            updateCNCInputs("forecastloading",false);
            updateCNCInputs("forecast_fetch_status",false);
            updateCNCInputs("forecast_errorMessage","Unable to fetch forecast data please try again...");
          });
          // updateCNCInputs("loading",true);
          fetch(api+"/db/demand",reqOptionsDemand)
          .then((res)=>res.json())
          .then((res)=>{

            if(res.status.statusCode==="cnc-200"){
              if(Object.keys(res.data).length === 0){
                updateCNCInputs("demand_treeData",[]);
              }
              else{
              updateCNCInputs("demand_treeData",res.data.data);
              }
              updateCNCInputs("loading",false);
              setInputDisableFlag(false);
              updateCNCInputs("fetch_status",true);
            }

            else if(res.status.statusCode==="cnc-400"){
              updateCNCInputs("loading",false);
              setInputDisableFlag(false);
              updateCNCInputs("demand_treeData",[]);
              updateCNCInputs("fetch_status",false);
              updateCNCInputs("errorMessage",res.status.statusMessage);
            }
            else{
              updateCNCInputs("loading",false);
              setInputDisableFlag(false);
              updateCNCInputs("demand_treeData",[]);
              updateCNCInputs("fetch_status",false);
              updateCNCInputs("errorMessage",res.status.statusMessage);
            }
              
          })  
          .catch((error) => {
            // console.log(error)
            updateCNCInputs("loading",false);
              setInputDisableFlag(false);
              updateCNCInputs("demand_treeData",[]);
              updateCNCInputs("fetch_status",false);
              updateCNCInputs("errorMessage","Unable to fetch Demand data please try again...");
          });
      }   

       
  },[cncData.index_commodity_id,cncData.index_source_weights,cncData.region_selection_value,cncData.country_selection_value,cncData.month_selection_value,cncData.factory_selection])

  //get Commodities
  const getCommodity = () => {
    let commodityOptions = [];
    fetch(api + "/commodities")
      .then((respone) => respone.json())
      .then((response) => {

       if(response.status.statusCode ==="cnc-200") {
        for (let i = 0; i < response.data.length; i++) {
          commodityOptions[i] = {
            value: response.data[i].commodity_id,
            label: response.data[i].commodity_name,
            decision_months:response.data[i].decision_months,
            isIndexVisible:response.data[i].isIndexVisible,
            isCountryVisible:response.data[i].isCountryVisible,
            isDemandEditable:response.data[i].isDemandEditable,
            scenarioFlag:response.data[i].scenarioFlag,
            weight_graphs:response.data[i].weight_graphs,
            isExpandable:response.data[i].isExpandable,
            indices:response.data[i].indices,
            report_url:response.data[i].report_url,
            report_params:response.data[i].report_params,
            demand_difference_url:response.data[i].demand_difference_url,
            demand_difference_url_params:response.data[i].demand_difference_url_params,
            report_url_execution:response.data[i].report_url_execution,
            report_params_execution:response.data[i].report_params_execution,
            isDairy:response.data[i].isDairy,
            show_execution_dashboard:response.data[i].show_execution_dashboard
          }
        }
        updateCNCInputs("commodityOptions", commodityOptions);
       }

       else{
        updateCNCInputs("commodityOptions", []);
       }
       
      })
      .catch((error) => {
        updateCNCInputs("commodityOptions", []);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });  
  }
  //Function to handle description Change
  const handleDescriptionChange=(e)=>{
    updateCNCInputs("override_active","");
    updateCNCInputs("description",e.target.value);
  }

  return (
    <>
      <div className="cnc-toggle-icon">
        <label
          onClick={() => {
            setExpand(!expand);
            updateCNCInputs("expand",expand)
          }}
        >
          {!expand ? "Collapse" : "Expand"}
        </label>
        <i
          className="material-icons"
          onClick={() => {
            setExpand(!expand);
            updateCNCInputs("expand",expand)
          }}
        >
          {!expand ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
      </div>
      {!expand && (
        <div className="cnc-model-header">
          <Grid container spacing={1} style={{ padding: "2px 15px 0px 15px" }}>
            <Grid item xs={4} sm={4}>
              <div className="mg-model-header-item" style={{ height: "25px" }}>
                Select a Commodity:{" "}
                <p
                  style={{
                    display: "inline",
                    color: "red",
                    fontSize: 16,
                  }}
                >
                  *
                </p>
              </div>
              <div className="" style={{ marginBottom: "15px" }}>
                <Dropdown
                  options={cncData.commodityOptions}
                  callback={commodityChange}
                  disabled={cncData.mode==="create"?(inputDisableFlag):props.disabled}
                  updateValue={cncData.commodity_selection_value}
                  placeholder={"Select a Commodity"}
                />
              </div>
            </Grid>
            {cncData.commodity_selection_value !== ""&& (
              <>
                <Grid item xs={4} sm={4}>
                  <div>
                    Select Decision Month:{" "}
                    <p
                      style={{
                        display: "inline",
                        color: "red",
                        fontSize: 16,
                      }}
                    >
                      *
                    </p>
                  </div>

                  <div className="" style={{ marginBottom: "15px" }}>
                    <Dropdown
                      options={cncData.month_options}
                      callback={decisionMonthChange}
                      disabled={cncData.mode==="create"?(inputDisableFlag):props.disabled}
                      updateValue={cncData.month_selection_value}
                      placeholder={"Select Decision Month"}
                    />
                  </div>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <div
                    className="mg-model-header-item"
                    style={{ height: "25px" }}
                  >
                    Description:{" "}
                  </div>
                  <div className="" style={{ marginBottom: "15px" }}>
                    <input
                      type="text"
                      placeholder="Enter a Description"
                      className="form-control"
                      disabled={(cncData.mode==="create"||cncData.mode==="edit")?false:true}
                      value={cncData.description}
                      onChange={handleDescriptionChange}
                    ></input>
                  </div>
                </Grid>
              </>
            )}

            
            {(cncData.mode==="create"? (cncData.month_selection_value!==""&& cncData.isIndexVisible && cncData.indexOptions.length>0):
            (cncData.month_selection_value!==""&&cncData.isIndexVisible && cncData.indexOptions.length>0))
            && 
            
            (
                <>
                  <Grid item xs={3} sm={3}>
                    <div>
                      Select Index
                      <p
                        style={{
                          display: "inline",
                          color: "red",
                          fontSize: 16,
                        }}
                      >
                        *
                      </p>
                    </div>

                    <div className="">
                      <Dropdown
                        options={cncData.indexOptions}
                        callback={handleIndexChange}
                        disabled={cncData.mode==="create"?(cncData.indexOptions.length===1?true:inputDisableFlag):props.disabled}
                        updateValue={cncData.index_commodity_id}
                        placeholder={"Select Index"}
                      />
                    </div>
                  </Grid>
                </>
              )}    

            {cncData.month_selection_value !== "" && cncData.regionOptions.length>0 && (
              <Grid item xs={3} sm={3}>
                <div
                  className="mg-model-header-item"
                  style={{ height: "25px" }}
                >
                  Select Region:{" "}
                  <p
                    style={{
                      display: "inline",
                      color: "red",
                      fontSize: 16,
                    }}
                  >
                    *
                  </p>
                </div>
                <div className="" style={{ marginBottom: "15px" }}>
                  <ReactSelect
                    options={cncData.regionOptions}
                    // isOptionDisabled={(cncData.regionOptions) => cncData.regionOptions.disabled}
                    isMulti
                    isDisabled={cncData.mode==="create"?(inputDisableFlag):props.disabled}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={cncData.regionOptions.filter((obj) =>
                      cncData.region_selection_value.some(
                        (item) => item.label === obj.label
                      )
                    )}
                    components={{ Option }}
                    allowSelectAll={true}
                    menuPortalTarget={document.body}       
                    styles={customStyles}
                    onChange={handleRegionChange}
                    menuPlacement="auto"
                    menuPosition="fixed"
                  />
                </div>
              </Grid>
            )}

            

              {((cncData.mode==="create")?(hideOnGlobalFlag&&cncData.isCountryVisible&&cncData.region_selection_value.length>0 &&cncData.countryOptions.length>0)
              :(cncData.isCountryVisible&&cncData.region_selection_value.length>0 &&cncData.countryOptions.length>0&&cncData.country_selection_value.length>0))
              &&(

                <Grid item xs={3} sm={3}>
                  <div
                    className="mg-model-header-item"
                    style={{ height: "25px" }}
                  >
                    Select Country:{" "}
                  </div>
                  <div className="" style={{ marginBottom: "15px" }}>
                    <ReactSelect
                      options={cncData.countryOptions}
                      isMulti
                      isDisabled={cncData.mode==="create"?(inputDisableFlag):props.disabled}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      value={cncData.countryOptions.filter((obj) =>
                        cncData.country_selection_value.some(
                          (item) => item.label === obj.label
                        )
                      )}
                      components={{ Option }}
                      allowSelectAll={true}
                      menuPortalTarget={document.body}
                       styles={customStyles}
                      onChange={handleCountryChange}            
                      menuPlacement="auto"
                      menuPosition="fixed"
                    />
                  </div>
                </Grid>
              )}
            {((cncData.mode==="create")?(cncData.country_selection_value.length>0 &&cncData.isCountryVisible&&cncData.factoryOptions.length>0):(cncData.country_selection_value.length>0 &&cncData.isCountryVisible&&cncData.factoryOptions.length>0 &&cncData.factory_selection.length>0))&& (
              <Grid item xs={3} sm={3}>
                <div
                  className="mg-model-header-item"
                  style={{ height: "25px" }}
                >
                  Select Factory:{" "}
                </div>
                <div className="" style={{ marginBottom: "15px" }}>
                  <ReactSelect
                    options={cncData.factoryOptions}
                    isMulti
                    isDisabled={cncData.mode==="create"?(inputDisableFlag):props.disabled}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={cncData.factoryOptions.filter((obj) =>
                      cncData.factory_selection.some(
                        (item) => item.label === obj.label
                      )
                    )}
                    components={{ Option }}
                    allowSelectAll={true}
                    menuPortalTarget={document.body}                                
                    styles={customStyles}
                    onChange={handleFactorySelection}
                    menuPlacement="auto"
                    menuPosition="fixed"
                  />
                </div>
              </Grid>
            )}

                
           
          </Grid>
        </div>
      )}
    </>
  );

}




MetaSelection.propTypes = {
    user: PropTypes.object,
    userData: PropTypes.object,
    cncData: PropTypes.object,
    updateCNCInputs: PropTypes.func,
    updatewholeCNCInputs: PropTypes.func,
    updateAlertCNC: PropTypes.func,
    updateCNCDatas:PropTypes.func,
  };
  
  const mapStateToProps = (state) => ({
    user: state.user,
    cncData: state.cncinputs,
    allData:state.data
  });
  
  const mapDispatchToProps = {
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateCNCDatas,
  };
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MetaSelection));