/*
Created on August 2022
Confidential and Proprietary of Reckit
Project: Demand Signal MVP
Component name : User Dialog
Component Description : To show dialog on some actions
@author: tailwyndz
*/

import { withStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Select from "react-select";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { config } from "../../../../config/config";
import { Grid } from "@material-ui/core";
import {
  updateAdminInput,
  updateAlertCNC,
  updateUserInfo,
} from "../../../../redux/actions";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

import { styled } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    maxHeight: "90%",
    fontSize: 12,
    fontFamily: "Hind Siliguri !important",
    zIndex: 9999,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "auto",
    maxHeight: "7vh",
  }),

  option: (provided, state) => ({
    ...provided,
    fontSize: 12,
    fontFamily: "Hind Siliguri !important",
  }),
};
const containedButton = {
  color: "white",
  backgroundColor: "#46596A",
  fontFamily: "Hind Siliguri",
  fontSize: "12px",
  fontWeight: "bold",
  marginLeft: "10px !important",
};
const outlinedButton = {
  borderColor: "#46596A",
  // background-color: #d7dadd,
  color: "#46596A",
  // width: '100%',
  fontFamily: "Hind Siliguri",
  fontSize: "12px",
  fontWeight: "bold",
  marginRight: "10px !important",
};
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {/* {onClose ? ( */}
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <i className="material-icons-outlined">close</i>
      </IconButton>
      {/* ) : null} */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: "600px",
    // width:1500
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function Usergroup(props) {
  let { open } = props.props;
  let api = config.api.api_url;
  let { admin_data, updateAdminInput, updateAlertCNC, user, updateUserInfo } =
    props;
  const [actionflag, setActionflag] = useState(false);
  let username = user.preferred_username;
  function initializeState() {
    updateAdminInput("activeData", []);
    let activeSelections = {
      indexSelection: [],
      commoditySelection: [],
      regionSelection: [],
      countrySelection: [],
      factorySelection: [],
    };
    updateAdminInput("activeSelections", activeSelections);
    updateAdminInput("selected_user", "");
  }
  const handleUserSelection = (e) => {
    // let user_list = admin_data.users_firestore
    let new_user = {
      name: e.label,
      email: e.email,
      preferred_username: e.email.split("@")[0],
      created_by: user.preferred_username,
      groups: ["cnc"],
    };
    // user_list.push(new_user)
    // setData(new_user)
    updateAdminInput("activeData", new_user);
    setActionflag(true);
    // updateAdminInput('users_firestore', user_list)
    updateAdminInput("selected_user", e.value);
  };
  const handleGroupselection = (e) => {
    let user_obj = [];
    let activeSelections = admin_data.activeSelections;
    updateAdminInput("indexOptions", []);
    updateAdminInput("commoditySelection", []);
    updateAdminInput("countryOptions", []);
    updateAdminInput("factoryOptions", []);
    admin_data.commodity_selection = [];
    activeSelections.factorySelection = [];
    activeSelections.commoditySelection = [];
    activeSelections.countrySelection = [];
    activeSelections.regionSelection = [];
    activeSelections.indexSelection = [];
    updateAdminInput("activeSelections", activeSelections);
    // user_obj = admin_data.users_firestore.filter((obj, id) => {
    //     index = id
    //     return (
    //         obj.preferred_username == admin_data.selected_user
    //     );
    // });
    user_obj = [admin_data.activeData];
    // let groups = [];
    // for (let i = 0; i < e.length; i++) {
    //     groups[i] = e[i].value
    // }
    user_obj[0].groups = [e.value];
    // setData(user_obj[0])
    // let user_list = admin_data.users_firestore
    // user_list[index] = user_obj[0]
    // data = user_obj[0]

    if (e.value !== "demand_planner" && e.value !== "commodity_manager") {
      updateAdminInput("activeData", user_obj[0]);
      setActionflag(true);
    } else {
      user_obj[0].demand_planner_info = {
        commodity:
          admin_data.activeData.demand_planner_info &&
          admin_data.activeData.demand_planner_info.commodity
            ? admin_data.activeData.demand_planner_info.commodity
            : [],
      };
      let planner_info = {
        commodity:
          admin_data.activeData.planner_info &&
          admin_data.activeData.planner_info.commodity
            ? admin_data.activeData.planner_info.commodity
            : [],
      };
      updateAdminInput("planner_info", planner_info);

      updateAdminInput("activeData", user_obj[0]);
      setActionflag(false);
    }
    // updateAdminInput('selected_user',e.value)
  };

  const userDetailsSave = () => {
    let reqOptions = {};
    let activedata = admin_data.activeData;

    if (props.props.type === "add") {
      reqOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([activedata]),
      };
    } else {
      reqOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([activedata]),
      };
    }
    fetch(api + "/user_details", reqOptions)
      .then((response) => response.json())
      .then((res) => {
        updateAdminInput("reload_flag", true);
        if (res.status.statusCode === "cnc-200") {
          //Update user groups
          fetch(api + "/userinfo?username=" + username)
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else if (response.status === 401) {
                let error = response.statusText;
                let errorCode = response.status;
                console.log("ERROR -> ", error);
                return {
                  error,
                  errorCode,
                  status: {},
                };
              }
            })
            .then((res) => {
              if (res.status.statusCode === "cnc-200") {
                updateUserInfo({
                  ...user,
                  groups: res.data.groups,
                  group_add_info: res.data.demand_planner_info,
                });
              } else {
                updateUserInfo({
                  ...user,
                  groups: [],
                  group_add_info: null,
                });
              }
            })
            .catch((error) => {
              updateUserInfo({
                ...user,
                groups: [],
                group_add_info: null,
              });
            });
          if (props.props.type === "add") {
            updateAlertCNC({
              open: true,
              severity: "info",
              message: "User Added Successfully",
              closeFlag: false,
            });
          } else {
            updateAlertCNC({
              open: true,
              severity: "info",
              message: "User Details Updated Successfully",
              closeFlag: false,
            });
          }
          initializeState();
        } else {
          updateAlertCNC({
            open: true,
            severity: "info",
            message: "Unable to add user.Check again later",
            closeFlag: false,
          });
          initializeState();
        }

        props.props.close();
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          severity: "info",
          message: "Unable to add user.Check again later",
          closeFlag: false,
        });
        initializeState();
        props.props.close();
      });
  };

  const handleIndexSelection = (e) => {
    let indexSelection = [];
    let activeSelections = admin_data.activeSelections;
    updateAdminInput("countryOptions", []);
    updateAdminInput("factoryOptions", []);
    activeSelections.factorySelection = [];
    activeSelections.countrySelection = [];
    activeSelections.regionSelection = [];
    activeSelections.indexSelection = [];
    updateAdminInput("activeSelections", activeSelections);
    let demand_planner_info = admin_data.planner_info;
    let planner_info_indices = [];
    for (let i = 0; i < e.length; i++) {
      let index_details = {
        index_commodity_id: e[i].value,
        index_name: e[i].label,
        source_weights: e[i].source_weights,
      };
      indexSelection.push(index_details);
      planner_info_indices.push(index_details);
    }
    activeSelections.indexSelection = indexSelection;

    let t1 = demand_planner_info ? demand_planner_info.commodity : [];
    let t2;
    t1.map((itms, idx) => {
      if (itms.commodity_name == currentcommodity.label) {
        t2 = demand_planner_info.commodity[idx];
      }
    });
    t2.indices = planner_info_indices;
    let activeData = admin_data.activeData;
    activeData.demand_planner_info = demand_planner_info;
    updateAdminInput("activeData", activeData);
    updateAdminInput("activeSelections", activeSelections);
    updateAdminInput("planner_info", demand_planner_info);
  };
  const currentCommoditySelect = (e) => {
    let activeData = admin_data.activeData;
    setCurrentcommodity(e);

    let activeSelections = admin_data.activeSelections;
    updateAdminInput("indexOptions", []);
    updateAdminInput("countryOptions", []);
    updateAdminInput("factoryOptions", []);

    updateAdminInput("planner_info", {});
    updateAdminInput("activeSelections", activeSelections);
    let indexOptions = [];

    let demand_planner_info = activeData.demand_planner_info;
    if (demand_planner_info && !demand_planner_info.commodity) {
      demand_planner_info.commodity = [];
    }
    // let index_options=[]
    let commodity_selection = [];
    commodity_selection.push(e.value);
    let commodity_details = {
      commodity_id: e.value,
      commodity_name: e.label,
      decision_months: e.decision_months,
      factory_id: [],
      indices: [],
      isIndexVisible: true,
      country: [],
    };
    let t1 =
      demand_planner_info && demand_planner_info.commodity
        ? demand_planner_info.commodity
        : [];
    let t2;

    t1.map((itms, idx) => {
      if (itms.commodity_name == e.label) {
        t2 = demand_planner_info.commodity[idx];
      }
    });

    !t2 && demand_planner_info.commodity.push(commodity_details);
    if (t2) {
      activeSelections.factorySelection = t2.factory_id;
      activeSelections.countrySelection = t2.country;
      activeSelections.regionSelection = t2.region;
      activeSelections.indexSelection = t2.indices;
    } else {
      activeSelections.factorySelection = [];
      activeSelections.countrySelection = [];
      activeSelections.regionSelection = [];
      activeSelections.indexSelection = [];
    }
    for (let i = 0; i < e.indices.length; i++) {
      indexOptions.push({
        label: e.indices[i].index_name,
        value: e.indices[i].index_commodity_id,
        source_weights: e.indices[i].source_weight,
      });
    }
    if (indexOptions.length === 1) {
      let index_details = {
        index_commodity_id: e.indices[0].index_commodity_id,
        index_name: e.indices[0].index_name,
        source_weights: e.indices[0].source_weight,
      };
      activeSelections.indexSelection.push(index_details);
      let t2 =
        demand_planner_info && demand_planner_info.commodity
          ? demand_planner_info.commodity
          : [];
      let t3;
      let t4;
      t2.map((itms, idx) => {
        if (itms.commodity_name == e.label) {
          t3 = demand_planner_info.commodity[idx];
          t4 = idx;
        }
      });
      let lemp = [];
      lemp.push(index_details);
      if (t3) {
        demand_planner_info.commodity[t4].indices = lemp;
      }
    }

    activeData.demand_planner_info = demand_planner_info;
    updateAdminInput("activeData", activeData);
    updateAdminInput("activeSelections", activeSelections);
    updateAdminInput("planner_info", demand_planner_info);
    updateAdminInput("indexOptions", indexOptions);
  };
  const handleCommoditySelection = (e) => {
    let commoditiesSelections = [];
    let activeSelections = admin_data.activeSelections;
    let demand_planner_info = admin_data.activeData.demand_planner_info;

    for (let i = 0; i < e.length; i++) {
      commoditiesSelections.push(e[i].value);
    }

    let commodity_selection = [];

    let chiptemp = [];
    e.map((itms, idx) => {
      commodity_selection.push(itms.value);

      let t1 = itms;
      t1.key = idx;
      chiptemp.push(t1);
    });
    if (e.length == 1) {
      let temp = e[0];
      temp.key = 0;
      currentCommoditySelect(temp);
    }
    if (e.length == 0) {
      demand_planner_info.commodity = [];
      let activeData = admin_data.activeData;
      activeData.demand_planner_info = demand_planner_info;
      updateAdminInput("activeData", activeData);
      updateAdminInput("planner_info", demand_planner_info);
    }
    setChipData(chiptemp);
    activeSelections.commoditySelection = commodity_selection;
    updateAdminInput("activeSelections", activeSelections);
  };
  const handleregionSelection = (e) => {
    let regionSelection = [];
    let activeSelections = admin_data.activeSelections;
    let demand_planner_info = admin_data.planner_info;
    let t1 =
      demand_planner_info && demand_planner_info.commodity
        ? demand_planner_info.commodity
        : [];
    let t2;

    t1.map((itms, idx) => {
      if (itms.commodity_name == currentcommodity.label) {
        t2 = idx;
      }
    });
    updateAdminInput("countryOptions", []);
    updateAdminInput("factoryOptions", []);
    activeSelections.factorySelection = [];
    activeSelections.countrySelection = [];
    activeSelections.regionSelection = [];
    updateAdminInput("activeSelections", activeSelections);

    let planner_info_indices = [];
    if (e.length > 0 && e !== null) {
      for (let i = 0; i < e.length; i++) {
        if (e.length > 1) {
          if (e[i].value !== "Global") {
            regionSelection.push(e[i].value);
            planner_info_indices.push(e[i].value);
          }
        } else {
          regionSelection.push(e[i].value);
          planner_info_indices.push(e[i].value);
        }
      }
    } else {
      regionSelection.push("Global");
      planner_info_indices.push("Global");
      demand_planner_info.commodity[t2].country = [];
      demand_planner_info.commodity[t2].factory_id = [];
    }
    activeSelections.regionSelection = regionSelection;
    demand_planner_info.commodity[t2].region = planner_info_indices;
    let activeData = admin_data.activeData;
    activeData.demand_planner_info = demand_planner_info;
    updateAdminInput("activeData", activeData);
    updateAdminInput("activeSelections", activeSelections);
    updateAdminInput("planner_info", demand_planner_info);
    if (activeData.groups.includes("commodity_manager")) {
      if (regionSelection.includes("Global")) {
        setActionflag(true);
      } else {
        setActionflag(false);
      }
    } else if (activeData.groups.includes("demand_planner")) {
      if (regionSelection.includes("Global")) {
        setActionflag(true);
      } else {
        setActionflag(false);
      }
    }
  };

  const handlecountrySelection = (e) => {
    let countrySelection = [];
    let activeSelections = admin_data.activeSelections;

    // updateAdminInput("countryOptions", [])
    updateAdminInput("factoryOptions", []);
    activeSelections.factorySelection = [];
    activeSelections.countrySelection = [];
    // activeSelections.regionSelection = []
    updateAdminInput("activeSelections", activeSelections);
    let demand_planner_info = admin_data.planner_info;
    let t1 =
      demand_planner_info && demand_planner_info.commodity
        ? demand_planner_info.commodity
        : [];
    let t2;

    t1.map((itms, idx) => {
      if (itms.commodity_name == currentcommodity.label) {
        t2 = idx;
      }
    });
    let planner_info_indices = [];
    for (let i = 0; i < e.length; i++) {
      countrySelection.push(e[i].value);
      planner_info_indices.push(e[i].value);
    }
    activeSelections.countrySelection = countrySelection;
    demand_planner_info.commodity[t2].country = planner_info_indices;
    let activeData = admin_data.activeData;
    activeData.demand_planner_info = demand_planner_info;
    updateAdminInput("activeData", activeData);
    updateAdminInput("activeSelections", activeSelections);
    updateAdminInput("planner_info", demand_planner_info);
  };

  const handlefactorySelection = (e) => {
    let factory_selection = [];
    let activeSelections = admin_data.activeSelections;
    // updateAdminInput("countryOptions", [])
    // updateAdminInput("factoryOptions", [])
    // activeSelections.factorySelection = []
    // activeSelections.factory_selection = []
    // activeSelections.regionSelection = []
    // updateAdminInput("activeSelections", activeSelections)
    let demand_planner_info = admin_data.planner_info;
    let t1 =
      demand_planner_info && demand_planner_info.commodity
        ? demand_planner_info.commodity
        : [];
    let t2;

    t1.map((itms, idx) => {
      if (itms.commodity_name == currentcommodity.label) {
        t2 = idx;
      }
    });
    let planner_info_indices = [];
    for (let i = 0; i < e.length; i++) {
      factory_selection.push(e[i].value);
      planner_info_indices.push(e[i].value);
    }
    activeSelections.factorySelection = factory_selection;
    demand_planner_info.commodity[t2].factory_id = planner_info_indices;
    let activeData = admin_data.activeData;
    activeData.demand_planner_info = demand_planner_info;
    updateAdminInput("activeData", activeData);
    updateAdminInput("activeSelections", activeSelections);
    updateAdminInput("planner_info", demand_planner_info);
    setActionflag(true);
  };
  //Fucntion to get Countries
  const getCountries = (regions) => {
    let countryOptions = [];

    let input = {
      level: "country",
      parent_id: regions,
    };

    //fetch call
    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };

    fetch(api + "/geography", reqOptions)
      .then((respone) => respone.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          for (let i = 0; i < response.data.length; i++) {
            countryOptions[i] = {
              value: response.data[i].geography_id,
              label: response.data[i].geography,
              geography_level: response.data[i].geography_level,
              parent_id: response.data[i].parent_id,
            };
          }
          updateAdminInput("countryOptions", countryOptions);
        } else {
          updateAdminInput("countryOptions", []);
        }
      })
      .catch((error) => {
        updateAdminInput("countryOptions", []);
      });
  };

  //Function to getFactories
  const getFactories = (countries) => {
    let factoryOptions = [];

    let input = {
      level: "factory",
      parent_id: countries,
    };

    //fetch call
    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };

    fetch(api + "/geography", reqOptions)
      .then((respone) => respone.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          for (let i = 0; i < response.data.length; i++) {
            factoryOptions[i] = {
              value: response.data[i].geography_id,
              label: response.data[i].geography,
              geography_level: response.data[i].geography_level,
              parent_id: response.data[i].parent_id,
            };
          }
          updateAdminInput("factoryOptions", factoryOptions);
        } else {
          updateAdminInput("factoryOptions", []);
        }
      })
      .catch((error) => {
        updateAdminInput("factoryOptions", []);
      });
  };
  const getIndexOptions = (commodity) => {
    if (commodity !== "" && commodity) {
      let e = admin_data.commodityOptions.filter(
        (obj) => commodity == obj.value
      )[0];
      let indexOptions = [];
      if (e && e.indices) {
        for (let i = 0; i < e.indices.length; i++) {
          indexOptions.push({
            label: e.indices[i].index_name,
            value: e.indices[i].index_commodity_id,
            source_weights: e.indices[i].source_weight,
          });
        }
        updateAdminInput("indexOptions", indexOptions);
      }
    }
  };
  useEffect(() => {
    let activeSelections = admin_data.activeSelections;
    let chiptemp = [];
    let temp2 = [];
    if (
      admin_data.activeData &&
      admin_data.activeData.demand_planner_info &&
      admin_data.activeData.demand_planner_info.commodity
    ) {
      chiptemp = admin_data.commodityOptions.filter((obj) =>
        admin_data.activeData.demand_planner_info.commodity.some(
          (item) => item.commodity_id === obj.value
        )
      );
    }

    if (chiptemp.length > 0) {
      chiptemp.map((itm, idx) => {
        chiptemp[idx].key = idx;
        temp2.push(itm.value);
      });
      activeSelections.commoditySelection = temp2;
      setChipData(chiptemp);
      setCurrentcommodity(chiptemp[0]);
      updateAdminInput("activeSelections", activeSelections);
    }
  }, []);
  useEffect(() => {
    // if (admin_data.activeSelections.regionSelection && admin_data.countryOptions) {
    getCountries(admin_data.activeSelections.regionSelection);
    // }
  }, [admin_data.activeSelections.regionSelection]);

  useEffect(() => {
    // if (admin_data.activeSelections.countrySelection && admin_data.factoryOptions) {
    getFactories(admin_data.activeSelections.countrySelection);
    // }
  }, [admin_data.activeSelections.countrySelection]);

  useEffect(() => {
    if (props.props.type === "edit") {
      getIndexOptions(admin_data.activeSelections.commoditySelection);
    }
  }, [admin_data.activeSelections.commoditySelection]);

  // function for multiselect inputs
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            id="productDimension"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            // style={{display:"flex",flexWrap:"wrap"}}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  //Mychanges
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  const [currentcommodity, setCurrentcommodity] = useState("");

  const [chipData, setChipData] = React.useState([]);

  const handleDelete = (chipToDelete) => () => {
    let activeData = admin_data.activeData;
    let activeSelections = admin_data.activeSelections;
    let l1 = [];

    let demand_planner_info = admin_data.activeData.demand_planner_info;
    let l2 = [];

    let l3 = [];
    chipData.map((chip) => {
      if (chip.label !== chipToDelete.label) {
        l3.push(chip);
      }
    });
    l3.length > 1
      ? currentCommoditySelect(l3[l3.length - 1])
      : l3.length == 0
      ? setCurrentcommodity("")
      : currentCommoditySelect(l3[0]);

    demand_planner_info.commodity.map((itm) => {
      if (itm.commodity_name !== chipToDelete.label) {
        l2.push(itm);
      }
    });
    activeSelections.commoditySelection.map((itm) => {
      if (itm !== chipToDelete.value) {
        l1.push(itm);
      }
    });
    activeSelections.commoditySelection = l1;
    updateAdminInput("activeSelections", activeSelections);
    activeData.demand_planner_info.commodity = l2;
    updateAdminInput("activeData", activeData);

    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };
  const [updateFlag1, setUpdateFlag1] = useState(true);
  useEffect(() => {
    let demand_planner_info = admin_data.planner_info;
    let t1 =
      demand_planner_info && demand_planner_info.commodity
        ? demand_planner_info.commodity
        : [];
    let t2;
    if (t1.length < 1 || chipData.length != t1.length) {
      setUpdateFlag1(false);
      return;
    }
    t1.map((itms, idx) => {
      if (
        !(
          (itms &&
            itms.region &&
            itms.region.length > 0 &&
            chipData.length == t1.length &&
            itms.region[0] == "Global") ||
          (itms.factory_id && itms.factory_id.length != 0)
        )
      ) {
        setUpdateFlag1(false);
        return;
      }
      setUpdateFlag1(true);
    });
  }, [admin_data, chipData]);

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle onClose={props.props.close}>{props.props.title}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} style={{ padding: "2px 15px 10px 15px" }}>
          {props.props.type === "add" && (
            <>
              <Grid item xs={4} sm={4}>
                <div className="">
                  <label>Select Users</label>
                  <p
                    style={{
                      display: "inline",
                      color: "red",
                      fontSize: 16,
                    }}
                  >
                    *
                  </p>
                </div>
              </Grid>
              <Grid item xs={8} sm={8}>
                <Select
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      maxHeight: "90%",
                      fontSize: 12,
                      fontFamily: "Hind Siliguri !important",
                      zIndex: 9999,
                    }),
                  }}
                  options={admin_data.userList.filter(
                    (obj) =>
                      !admin_data.users_firestore.some(
                        (item) => item.preferred_username === obj.value
                      )
                  )}
                  value={admin_data.userList.filter(
                    (obj) => obj.value === admin_data.selected_user
                  )}
                  placeholder="Select users to add"
                  onChange={(e) => {
                    handleUserSelection(e);
                  }}
                />
              </Grid>
            </>
          )}
          {admin_data.selected_user !== "" && (
            <>
              <Grid item xs={4} sm={4}>
                <div className="">
                  <label>User Groups</label>
                  <p
                    style={{
                      display: "inline",
                      color: "red",
                      fontSize: 16,
                    }}
                  >
                    *
                  </p>
                </div>
              </Grid>
              <Grid item xs={8} sm={8}>
                <Select
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      maxHeight: "90%",
                      fontSize: 12,
                      fontFamily: "Hind Siliguri !important",
                      zIndex: 9999,
                    }),
                  }}
                  options={admin_data.userGroupOptions}
                  value={
                    admin_data.activeData.groups &&
                    admin_data.userGroupOptions.filter((obj) =>
                      admin_data.activeData.groups.some(
                        (item) => item === obj.value
                      )
                    )
                  }
                  placeholder="Select User groups"
                  onChange={(e) => {
                    handleGroupselection(e);
                  }}
                />
              </Grid>
            </>
          )}
          {admin_data.activeData.groups &&
            (admin_data.activeData.groups.includes("demand_planner") ||
              admin_data.activeData.groups.includes("commodity_manager")) && (
              <>
                <Grid item xs={4} sm={4}>
                  <div className="">
                    <label>Select Commodity</label>
                    <p
                      style={{
                        display: "inline",
                        color: "red",
                        fontSize: 16,
                      }}
                    >
                      *
                    </p>
                  </div>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <div
                    className="Commodity-dropdown"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 5px",
                      borderColor: "hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyItems: "center",
                        listStyle: "none",
                        flexWrap: "wrap",
                      }}
                    >
                      {chipData.map((data) => {
                        let icon;
                        let demand_planner_info = admin_data.planner_info;
                        let t1 =
                          demand_planner_info && demand_planner_info.commodity
                            ? demand_planner_info.commodity
                            : [];
                        let t2;
                        let fl = false;

                        t1.map((itms, idx) => {
                          if (itms.commodity_name == data.label) {
                            t2 = idx;
                          }
                        });
                        if (
                          Number.isInteger(t2) &&
                          t1[t2] &&
                          t1[t2].region &&
                          t1[t2].region.length > 0 &&
                          (t1[t2].region[0] == "Global" ||
                            (t1[t2].factory_id &&
                              t1[t2].factory_id.length != 0))
                        ) {
                          icon = (
                            <i
                              className="material-icons-outlined "
                              style={{
                                background: "rgb(0 255 19 / 27%)",
                                color: "green",
                                borderRadius: "13%",
                              }}
                              onClick={() => {}}
                            >
                              done
                            </i>
                          );
                          fl = true;
                        } else {
                          icon = (
                            <i
                              className="material-icons-outlined "
                              onClick={() => {}}
                              style={{
                                background: "#ff000017",
                                color: "red",
                                borderRadius: "13%",
                              }}
                            >
                              close
                            </i>
                          );
                        }

                        return (
                          <ListItem
                            key={data.key}
                            style={{
                              listStyleType: "none",
                            }}
                          >
                            <Chip
                              icon={icon}
                              deleteIcon={
                                <i
                                  className="material-icons-outlined "
                                  style={{
                                    color: "rgba(32, 32, 32, 0.87)",
                                  }}
                                >
                                  delete
                                </i>
                              }
                              onDelete={handleDelete(data)}
                              label={data.label}
                              variant={
                                data.label != currentcommodity.label
                                  ? "outlined"
                                  : "default"
                              }
                              onClick={() => {
                                currentCommoditySelect(data);
                              }}
                            />
                          </ListItem>
                        );
                      })}
                    </div>
                    <div
                      className={
                        chipData.length < 1
                          ? "CommoditySlect low"
                          : "CommoditySlect"
                      }
                    >
                      <ReactSelect
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            maxHeight: "90%",
                            fontSize: 12,
                            fontFamily: "Hind Siliguri !important",
                            zIndex: 9999,
                          }),
                        }}
                        allowSelectAll={true}
                        hideSelectedOptions={false}
                        isMulti
                        options={admin_data.commodityOptions}
                        components={{ Option }}
                        value={
                          admin_data.activeSelections.commoditySelection &&
                          admin_data.commodityOptions.filter((obj) =>
                            admin_data.activeSelections.commoditySelection.some(
                              (item) => item === obj.value
                            )
                          )
                        }
                        // value={admin_data.activeSelections.commoditySelection && admin_data.activeSelections.commoditySelection[0]}
                        placeholder="Select Commodity"
                        onChange={(e) => {
                          handleCommoditySelection(e);
                        }}
                        maxMenuHeight={200}
                      ></ReactSelect>
                    </div>
                  </div>
                </Grid>

                {admin_data.activeSelections.commoditySelection &&
                  admin_data.planner_info.commodity &&
                  admin_data.planner_info.commodity.length > 0 &&
                  admin_data.activeSelections.commoditySelection.length > 0 && (
                    <>
                      <Grid item xs={4} sm={4}>
                        <div className="">
                          <label>Select Index</label>
                          <p
                            style={{
                              display: "inline",
                              color: "red",
                              fontSize: 16,
                            }}
                          >
                            *
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <ReactSelect
                          // styles={{
                          //     menuPortal: (base) => ({
                          //         ...base,
                          //         maxHeight: "90%",
                          //         fontSize: 12,
                          //         fontFamily: "Hind Siliguri !important",
                          //         zIndex: 9999,
                          //     }),
                          // }}
                          isMulti
                          options={admin_data.indexOptions}
                          value={
                            admin_data.activeSelections.indexSelection &&
                            admin_data.indexOptions.filter((obj) =>
                              admin_data.activeSelections.indexSelection.some(
                                (item) => item.index_commodity_id === obj.value
                              )
                            )
                          }
                          components={{ Option }}
                          allowSelectAll={true}
                          hideSelectedOptions={false}
                          styles={customStyles}
                          placeholder="Select Index"
                          onChange={(e) => {
                            handleIndexSelection(e);
                          }}
                        />
                      </Grid>
                    </>
                  )}
                {admin_data.planner_info.commodity &&
                  admin_data.planner_info.commodity.length > 0 &&
                  admin_data.activeSelections.indexSelection &&
                  admin_data.activeSelections.indexSelection.length > 0 && (
                    <>
                      <Grid item xs={4} sm={4}>
                        <div className="">
                          <label>Select Region</label>
                          <p
                            style={{
                              display: "inline",
                              color: "red",
                              fontSize: 16,
                            }}
                          >
                            *
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <ReactSelect
                          // styles={{
                          //     menuPortal: (base) => ({
                          //         ...base,
                          //         maxHeight: "90%",
                          //         fontSize: 12,
                          //         fontFamily: "Hind Siliguri !important",
                          //         zIndex: 9999,
                          //     }),
                          // }}
                          isMulti
                          options={admin_data.regionOptions}
                          components={{ Option }}
                          allowSelectAll={true}
                          hideSelectedOptions={false}
                          styles={customStyles}
                          value={
                            admin_data.activeSelections.regionSelection &&
                            admin_data.regionOptions.filter((obj) =>
                              admin_data.activeSelections.regionSelection.some(
                                (item) => item === obj.value
                              )
                            )
                          }
                          placeholder="Select Region"
                          onChange={(e) => {
                            handleregionSelection(e);
                          }}
                        />
                      </Grid>
                    </>
                  )}
                {admin_data.planner_info.commodity &&
                  admin_data.planner_info.commodity.length > 0 &&
                  admin_data.activeSelections.regionSelection &&
                  admin_data.activeSelections.regionSelection.length > 0 &&
                  !admin_data.activeSelections.regionSelection.includes(
                    "Global"
                  ) && (
                    <>
                      <Grid item xs={4} sm={4}>
                        <div className="">
                          <label>Select Country</label>
                          <p
                            style={{
                              display: "inline",
                              color: "red",
                              fontSize: 16,
                            }}
                          >
                            *
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <ReactSelect
                          // styles={{
                          //     menuPortal: (base) => ({
                          //         ...base,
                          //         maxHeight: "90%",
                          //         fontSize: 12,
                          //         fontFamily: "Hind Siliguri !important",
                          //         zIndex: 9999,
                          //     }),
                          // }}
                          isMulti
                          options={admin_data.countryOptions}
                          components={{ Option }}
                          allowSelectAll={true}
                          hideSelectedOptions={false}
                          styles={customStyles}
                          value={
                            admin_data.activeSelections.countrySelection &&
                            admin_data.countryOptions.filter((obj) =>
                              admin_data.activeSelections.countrySelection.some(
                                (item) => item === obj.value
                              )
                            )
                          }
                          placeholder="Select Country"
                          onChange={(e) => {
                            handlecountrySelection(e);
                          }}
                          maxMenuHeight={200}
                        />
                      </Grid>
                    </>
                  )}

                {admin_data.planner_info.commodity &&
                  admin_data.planner_info.commodity.length > 0 &&
                  admin_data.activeSelections.countrySelection &&
                  admin_data.activeSelections.countrySelection.length > 0 &&
                  !admin_data.activeSelections.regionSelection.includes(
                    "Global"
                  ) && (
                    <>
                      <Grid item xs={4} sm={4}>
                        <div className="">
                          <label>Select Factory for Demand planner</label>
                          <p
                            style={{
                              display: "inline",
                              color: "red",
                              fontSize: 16,
                            }}
                          >
                            *
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <ReactSelect
                          // styles={{
                          //     menuPortal: (base) => ({
                          //         ...base,
                          //         maxHeight: "90%",
                          //         fontSize: 12,
                          //         fontFamily: "Hind Siliguri !important",
                          //         zIndex: 9999,
                          //     }),
                          // }}
                          isMulti
                          options={admin_data.factoryOptions}
                          components={{ Option }}
                          allowSelectAll={true}
                          hideSelectedOptions={false}
                          styles={customStyles}
                          value={
                            admin_data.activeSelections.factorySelection &&
                            admin_data.factoryOptions.filter((obj) =>
                              admin_data.activeSelections.factorySelection.some(
                                (item) => item === obj.value
                              )
                            )
                          }
                          placeholder="Select Factory"
                          onChange={(e) => {
                            handlefactorySelection(e);
                          }}
                          maxMenuHeight={180}
                        />
                      </Grid>
                    </>
                  )}
              </>
            )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          style={actionflag && updateFlag1 ? containedButton : outlinedButton}
          onClick={userDetailsSave}
          disabled={!(actionflag && updateFlag1)}
        >
          {props.props.type === "add" ? "Save" : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Usergroup.propTypes = {
  updateAlertCNC: PropTypes.func,
  updateUserInfo: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  admin_data: state.admin_data,
});

const mapDispatchToProps = {
  updateAlertCNC,
  updateAdminInput,
  updateUserInfo,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Usergroup)
);
