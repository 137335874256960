// Import Required Libraries
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Import Pages
import Login from "../screens/Login/Login";
import Unauthorised from "../screens/Unauthorised/Unauthorised";
import PageNotFound from "../screens/PageNotFound/PageNotFound";
// import AIDEApp from "../screens/AIDEApp/AIDEApp";
import CNCApp from "../screens/CNCApp/CNCApp";
// Import Custom Components
import ProtectedRoute from "./ProtectedRoute";
import CNCRun from "../screens/CNCApp/CNCRun/CNCRun";
import CNCCommodity from "../screens/CNCApp/CNCCommodity/CNCCommodity";

function Routes() {
  return (
    <Switch>
      <Redirect from="/" to="/home" exact />
      <ProtectedRoute path="/home" component={CNCApp} exact />
      <ProtectedRoute path="/home/:tab" component={CNCApp} exact />
      <ProtectedRoute
        path="/home/myCommodityHedging/:commodityName"
        component={CNCCommodity}
        exact
      />
      <ProtectedRoute
        path="/home/viewRuns/:commodityRunName"
        component={CNCRun}
        exact
      />
      <Route path="/login" component={Login} exact />
      <Route path="/unauthorised" component={Unauthorised} exact />
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default Routes;
