import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";

//styles
import "./PopoverComponent.scss";


export default function PopoverComponent(props) {
  // console.log("props,bool",props,Boolean(props.anchorEl));

  // //function to open popover
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  //   setvalue(event.target.id);
  // };

  // //function to close popover
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <div>
      {
        props.component === "MyCommodity" ||
          props.component === "CommodityStarred" ||
          props.component === "MyCommodityRecent" ? (
          <Popover
            id="pop-over"
            open={Boolean(props.anchorEl)}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {
              <Menu
                id={props.id}
                anchorEl={props.anchorEl}
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
              >
                {props.menulist.map((item, idx) => {
                  // console.log(props.value);
                  return (
                    <MenuItem
                      key={idx}
                      className="MuiMenuItem-root"
                      onClick={() => {
                        {
                          item.func(props.value);
                        }
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  );

                })}
              </Menu>
            }
          </Popover>

        ) : (
          //shared-Commodity
          <Popover
            id="pop-over"
            open={Boolean(props.anchorEl)}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Menu
              id={props.id}
              anchorEl={props.anchorEl}
              open={Boolean(props.anchorEl)}
              onClose={props.handleClose}
            >
              {props.access === "edit&run" && (
                <MenuItem
                  onClick={() => {
                    props.handleEdit(props.value);
                  }}
                >
                  Edit
                </MenuItem>
              )}
              {(props.access === "view" || props.access === "view&run") && (
                <MenuItem
                  onClick={() => {
                    props.handleView(props.value);
                  }}
                >
                  View
                </MenuItem>
              )}
              {(props.access === "edit&run" || props.access === "view&run") && (
                <MenuItem
                  onClick={() => {
                    props.runCommodity(props.value);
                  }}
                >
                  Run
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  props.copyCommodity(props.value);
                }}
              >
                Copy
              </MenuItem>
              {/* {(props.access === "edit&run" || props.access === "view&run") && (
                <MenuItem
                  onClick={() => {
                    props.scheduleDialog(props.value);
                  }}
                >
                  Schedule Run
                </MenuItem>
              )} */}
            </Menu>
          </Popover>

        )
      }


    </div>
  );
}


