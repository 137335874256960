/*
Created on March 2022
Confidential and Proprietary of Reckitt
Project: CNC
Screen name : Commodity Starred
Screen Description : 
To View the Commodity Starred by Users 
Users can edit ,Run ,Delete also Unstar a Commodity from this Page
@author: Tailwyndz
*/

//Import Libraries
import React, { useEffect} from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from 'react';
import {config} from '../../../config/config';
import { withRouter } from "react-router";
import { updateCNCInputs,updateAlertCNC,updatewholeCNCInputs,updateUserInfo,updateCNCDatas } from '../../../redux/actions';
import Search from "../../../components/Search/Search";
import TableComponent from '../../../components/Table/table'
import { useHistory } from "react-router-dom";
// import forecast from "../../../assets/images/forecast.png";
import nocommodity from '../../../../assets/images/undraw_empty.svg'
import Loader from '../../../components/Loader/Loader';
import Dialogs from '../../../components/Dialog/Dialog';
//import styles
// import "../CNCView/cncview.scss";


function CommodityStarred(props) {
    let {
        user,
        allData,
        cncData,
        updateCNCInputs,
        updateCNCDatas,
        updatewholeCNCInputs,
        updateAlertCNC,
      } = props;
    let api = config.api.api_url;
    const [filteredData, setFilteredData] = useState([]);
    let username = user.preferred_username;
    let usermail = user.email;
    const [loading, setLoading] = useState(false);
    const [dialogData, setDialog] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setvalue] = React.useState(null);
    const [page, setPage] = React.useState(0);
    let rowsPerpage = config.table_rows.pagination_size;
    const history = useHistory();
    let today = new Date();
    let currentUTC =
    today.getUTCFullYear() +
    "-" +
    (today.getUTCMonth() < 9
      ? "0" + (today.getUTCMonth() + 1)
      : today.getUTCMonth() + 1) +
    "-" +
    (today.getUTCDate() < 10 ? "0" + today.getUTCDate() : today.getUTCDate());
   //Close alert snackbar
  const alertHandleClose = () => {
    if(user.alertInfo.closeFlag===true)
    updateAlertCNC({ ...user.alertInfo, open: false,closeFlag:true});
  };

    
  //function to get all the starred models created by the user
  function getStarredCommodity(delete_flag=false) {
    // setLoading(true)
    // initializeState();
    fetch(api + "/commodities/starred?user=" + username)
      .then((respone) => respone.json())
      .then((response) => {
        if(response.status.statusCode==="cnc-200"){
          updateCNCDatas("starred_commodity", response.data);
          if(cncData.searchValue!==undefined&& cncData.searchValue!=="" &&delete_flag){
            allData.starred_commodity=response.data;
            SearchVal(cncData.searchValue,true);
          }
          else{
            setFilteredData(response.data);
          } 
          setLoading(false);
        }

        else{
          updateCNCDatas("starred_commodity", []);
          setFilteredData([]);
          setLoading(false);
        }
          
      })
      .catch((error) => {
        updateCNCDatas("starred_commodity", []);
        setFilteredData([]);
        setLoading(false);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
  }
  useEffect(() => {
    // if (allData.starred_commodity === null) {
      setLoading(true);
      getStarredCommodity();
    // }
  }, [allData.starred_commodity=== null]);

    // search and filter x rows based on keyword
    function SearchVal(e,flag=false) {
      let value = flag ? e : e.target.value;
      updateCNCInputs("searchValue",value);
      // setVal(value);
      // if (value.length > 0) {
      //   settextClearVisible(true);
      // }
      // if (value == "" || value == "" || value.length == 0) {
      //   settextClearVisible(false);
      // }
      let result = [];
      result = allData.starred_commodity.filter((data) => {
        return (
          data.user_commodity_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          (data.commodity_created_dt!==undefined&& data.commodity_created_dt.includes(value)) ||
          data.description.toString().toLowerCase().includes(value.toLowerCase())
        );
      });
      setFilteredData(result);
      !flag && setPage(0);
    }

  useEffect(()=>{
    alertHandleClose();
    // if(userData.demand_planner){
    //   // updateAlertCNC({
    //   //   open: true,
    //   //   message: config.messages.access_restricted,
    //   //   severity: "info",
    //   //   closeFlag:false
    //   // });
    //   history.push({
    //     pathname: `/home/${match.params.function}/CNC/updateDemand/`,
    //   });
    // }
  },[])

  function initializeState(){

    let initialState = [];
    initialState.loading=false;
    initialState.mode="";
    initialState.cncDataGraph=[];
    initialState.tableHeader=[];
    initialState.futures_graph1=[];
    initialState.futures_graph2=[];
    initialState.futures_graph3=[];
    initialState.futures_graph4=[];
    initialState.weight_graph1=20;
    initialState.weight_graph2=20;
    initialState.weight_graph3=20;
    initialState.weight_graph4=20;
    initialState.weight_override=0;
    initialState.weight_default_total = 100;
    initialState.weight_graphs=[];
    initialState.weight_graphs_copy=[];
    initialState.weight_min=0;
    initialState.weight_max=100;
    initialState.importance_type="";
    initialState.override_values=[];
    initialState.demand_in_mt=[];
    initialState.override_forecast_header=[];
    initialState.demand_header=[];
    initialState.demand_treeData=[];
    initialState.commodityOptions=[];
    initialState.commodity_selection="";
    initialState.commodity_selection_value="";
    initialState.month_selection="";
    initialState.month_selection_value="";
    initialState.description="";
    initialState.month_options=[];
    initialState.region_selection=[];
    initialState.regionOptions=[];
    initialState.regionOptionsAll=[];
    initialState.region_selection_value=[{
      label:"Global",
      value:"Global",
      geography_level:"region",
      parent_id:"null",
    }];
    initialState.isIndexVisible=false;
    initialState.isCountryVisible=false;
    initialState.isDemandEditable=false;
    initialState.scenarioFlag=false;
    initialState.scenario_flag_checkbox=false;
    initialState.isExpandable=false;
    initialState.indexOptions=[];
    initialState.index_commodity_id="";
    initialState.countryOptions=[];
    initialState.countryOptionsAll=[];
    initialState.country_selection="";
    initialState.country_selection_value=[];
    initialState.factoryOptions=[];
    initialState.factoryOptionsAll=[];
    initialState.factory_selection=[];
    initialState.use_for_final_hedging=false;
    initialState.index_source_weights=[];
    initialState.demandDataEdited=false;
    initialState.searchValue="";
    updatewholeCNCInputs(initialState);
  }

     //function to Edit a particular Commodity
     const handleEdit = (item) => {
      initializeState();
     let data = filteredData.filter((obj) => item === obj.cnc_id);
     data = data[0];
     updateCNCInputs("mode", "edit");
     updateCNCDatas("activeCommodity", data);
     history.push({
       pathname: `/home/myCommodityHedging/${data.user_commodity_name}_${data.cnc_id}`,
     });
   };
 
   

  const unstarCommodity=(id)=>{
    let data = filteredData.filter((obj) => id === obj.cnc_id);
    const input = data[0];
    input.starred = 0;
    input.update = false;
    const reqOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    };
    fetch(api + "/userCommodities?cnc_id="+id, reqOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status.statusCode === "cnc-200") {
          updateAlertCNC({
            open: true,
            message: config.messages.commodity_unstarred,
            severity: "info",
            closeFlag:true
          });
          getStarredCommodity();
        }

        else{
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:true
          });
        }
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
    handleClose();
   
  }

  //close after shared or cancel sharing scenario
  const dialogClose = () => {
    setDialog({
      ...dialogData,
      open: false,
    });
  };

  const deleteDialog = (id) => {
    setDialog({
      open: true,
      delete: true,
      deleteRow: id,
      close: dialogClose,
      deleteFunction: deleteCommodity,
      name: "Commodity Hedging",
    });
  };

  //function to Delete a particular Commodity Hedging
  const deleteCommodity = (id) => {
    let data = filteredData.filter((obj) => id === obj.cnc_id);
    const input = data[0];
    if (input.starred === 1) {
      input.starred = 0;
    }
    if (input.shared === 1) {
      input.shared = 0;
      input.shared_with = [];
    }
    input.deleted = true;
    input.update = false;
    const reqOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    };
    setAnchorEl(null);

    fetch(api + "/userCommodities?cnc_id="+id, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        if(response.status.statusCode==="cnc-200"){
          updateAlertCNC({ open: true, message:config.messages.commodity_deleted,closeFlag:true});
          getStarredCommodity(true);
        }

        else{
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
          getStarredCommodity();
        }
          
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
        getStarredCommodity();
      });

    dialogClose();
  };

  const runCommodity = (id) => {
    let data = allData.saved_commodity.filter((obj) => id === obj.cnc_id);
    data = data[0];
    let commodity_created_dt=data.commodity_created_dt;
    let triggered_time =
      currentUTC +
      " " +
      (today.getUTCHours() < 10
        ? "0" + today.getUTCHours()
        : today.getUTCHours()) +
      ":" +
      (today.getUTCMinutes() < 10
        ? "0" + today.getUTCMinutes()
        : today.getUTCMinutes()) +
      ":" +
      (today.getUTCSeconds() < 10
        ? "0" + today.getUTCSeconds()
        : today.getUTCSeconds());

        const run = {
          run_id: data.cnc_id,
          cnc_id: data.cnc_id,
          user_commodity_name:data.user_commodity_name,
          use_for_final_hedging:data.use_for_final_hedging,
          created_by:username,
          user: data.user,
          created_on: commodity_created_dt,
          triggered_on: triggered_time,
          duration: "",
          flag: null,
          status: "",
          message: "",
          email:usermail,
          description:data.description,
          run_start_time:"",
          isDataLoaded:false,
        };

        const notification = {
          notification_id: "",
          username: data.user,
          triggered_on: triggered_time,
          cnc_id: data.cnc_id,
          user_commodity_name:data.user_commodity_name,
          notification_type: "",
          status: "Unread",
          message: "",
        };

    const input = {
      cnc_commodities: data,
      runs: run,
      notification:notification,
    };

    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };


    fetch(api + "/run", reqOptions)
    .then((response) => response.json())
    .then((response) => {
      if (response.status.statusCode === "cnc-200") {
        response.commodity_data.timestamp=response.run_data['triggered_on']
        reqOptions.body = JSON.stringify(response.commodity_data)
        // let run_id=response.data.run_id
        let run_data=response.run_data
        let cnc_commodities=response.commodity_data
        let notification=response.notification;
        // initializeState();
        let input={
          runs:run_data,
          cnc_commodities:cnc_commodities,
          notification:notification,
        }
          let req = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(input),
          };
          fetch(api+"/trigger_job",req)
          .then((res)=>res.json())
          .then((res)=>{

          })
          .catch((error) => {
            updateAlertCNC({
              open: true,
              message: config.messages.unexpected_error,
              severity: "info",
              closeFlag:false
            });
          });
        setTimeout(() => {
          fetch(api+"/db/hedging_forecast",reqOptions)
          .then((response) => response.json())
          .then((response) => {})
          .catch((error) => {
            updateAlertCNC({
              open: true,
              message: config.messages.unexpected_error,
              severity: "info",
              closeFlag:false
            });
          });  
          // history.push({
          //   pathname: `/home/${match.params.function}/CNC/myCommodityHedging`,
          // });
        }, 1000);
      }
      else{
        updateAlertCNC({
          open: true,
          message:
            response?.status?.statusMessage ,
          severity: "info",
          closeFlag:true
        });
      }
    })
    .catch((error) => {
      updateAlertCNC({
        open: true,
        message: config.messages.unexpected_error,
        severity: "info",
        closeFlag:false
      });
    });

    updateAlertCNC({
      open: true,
      message: config.messages.run_triggered,
      closeFlag: true,
    });
  };

  //function to open popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setvalue(event.target.id);
  };

  //function to close popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  //function to set page for changing table row
  const pageChange = (event, value) => {
    setPage(value);
  };
  
  let page_props = {
    total: filteredData.length,
    page: page,
    changePage: pageChange,
  };

  const menulist = [
    { label: "Edit", func: handleEdit },
    { label: "Run",  func: runCommodity },
    { label: "Delete", func: deleteDialog },
    { label: "Remove from Starred", func: unstarCommodity },

  ];



    return (
        <>
          {
            dialogData.open && <Dialogs props={dialogData}></Dialogs>
            // null
          }
          {loading ? (
            <Loader />
          ) : (
            <>
              {allData.starred_commodity !== null &&
              allData.starred_commodity.length !== 0 ? (
                <>
              <Search setFilteredData={setFilteredData} searchData={allData.starred_commodity} setPage={setPage}/>
              <div className="configs-container">
                <div className="apps-row">
                  {filteredData.length > 0 ? 
              
                    <TableComponent
                    component="CommodityStarred"
                    header={config.myCommodityHeader}
                    filteredData={filteredData}
                    page_props={page_props}
                    page={page}
                    rowsPerpage={rowsPerpage}
                    handleEdit={handleEdit}
                    handleClick={handleClick}
                    handleClose={handleClose}
                    anchorEl={anchorEl}
                    menulist={menulist}
                    value={value}
                  />
                  
                  :
                  <div className="no-commodity">
                <img src={nocommodity} alt="" className="cnc-img"/>
                <p>No Starred commodity available</p>
              </div>
                  }
                </div>
              </div>
              </>
              ) : (
              <div className="no-commodity">
                <img src={nocommodity} alt="" className="cnc-img"/>
                <p>No Starred Commodity available</p>
              </div>
            )}  
            </>
          )}
        </>
    );

}


CommodityStarred.propTypes = {
    user: PropTypes.object,
    cncData:PropTypes.object,
    updateCNCInputs: PropTypes.func,
    updatewholeCNCInputs: PropTypes.func,
    updateAlertCNC: PropTypes.func,
    updateCNCDatas: PropTypes.func,
};

const mapStateToProps = (state) => ({
    user: state.user,
    cncData:state.cncinputs,
    allData:state.data,
    defaultCommodity: state.cncinputs,
});

const mapDispatchToProps = {
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateUserInfo,
    updateCNCDatas,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommodityStarred));