/*
Created on March 2022
Confidential and Proprietary of Reckitt
Project: CNC
Screen name : AddCommodity
Screen Description : 
@author: Tailwyndz
*/

//Import Libraries
import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from 'react';
import { config } from '../../../config/config';
import { withRouter } from "react-router";
import { updateCNCInputs, updateAlertCNC, updatewholeCNCInputs, updateUserInfo, updateCNCDatas } from '../../../redux/actions';
import Search from "../../../components/Search/Search";
import { useHistory } from "react-router-dom";
import nocommodity from '../../../../assets/images/undraw_empty.svg'
import Dialogs from '../../../components/Dialog/Dialog';
import TableComponent from '../../../components/Table/table'
import Loader from "../../../components/Loader/Loader";

//import styles
// import "../CNCView/cncview.scss";


function SharedCommodity(props) {
  let {
    cncData,
    user,
    allData,
    updateCNCInputs,
    updateCNCDatas,
    updatewholeCNCInputs,
    updateAlertCNC,
  } = props;
  let api = config.api.api_url;
  const [filteredData, setFilteredData] = useState([]);
  let username = user.preferred_username;
  let usermail = user.email;
  const [loading, setLoading] = useState(false);
  const [dialogData, setDialog] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [access, setAccess] = useState(undefined);
  const [value, setvalue] = React.useState(null);
  const [page, setPage] = React.useState(0);
  let rowsPerpage = config.table_rows.pagination_size;
  const history = useHistory();
  let today = new Date();
  let currentUTC =
    today.getUTCFullYear() +
    "-" +
    (today.getUTCMonth() < 9
      ? "0" + (today.getUTCMonth() + 1)
      : today.getUTCMonth() + 1) +
    "-" +
    (today.getUTCDate() < 10 ? "0" + today.getUTCDate() : today.getUTCDate());
  let currentDate =
    today.getFullYear() +
    "-" +
    (today.getMonth() < 9
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

  function initializeState() {

    let initialState = [];
    initialState.loading = false;
    initialState.mode = "";
    initialState.cncDataGraph = [];
    initialState.tableHeader = [];
    initialState.futures_graph1 = [];
    initialState.futures_graph2 = [];
    initialState.futures_graph3 = [];
    initialState.futures_graph4 = [];
    initialState.weight_graph1 = 20;
    initialState.weight_graph2 = 20;
    initialState.weight_graph3 = 20;
    initialState.weight_graph4 = 20;
    initialState.weight_override = 0;
    initialState.weight_default_total = 100;
    initialState.weight_graphs = [];
    initialState.weight_graphs_copy = [];
    initialState.weight_min = 0;
    initialState.weight_max = 100;
    initialState.importance_type = "";
    initialState.override_values = [];
    initialState.demand_in_mt = [];
    initialState.override_forecast_header = [];
    initialState.demand_header = [];
    initialState.demand_treeData = [];
    initialState.commodityOptions = [];
    initialState.commodity_selection = "";
    initialState.commodity_selection_value = "";
    initialState.month_selection = "";
    initialState.month_selection_value = "";
    initialState.description = "";
    initialState.month_options = [];
    initialState.region_selection = [];
    initialState.regionOptions = [];
    initialState.regionOptionsAll = [];
    initialState.region_selection_value = [{
      label: "Global",
      value: "Global",
      geography_level: "region",
      parent_id: "null",
    }];
    initialState.isIndexVisible = false;
    initialState.isCountryVisible = false;
    initialState.isDemandEditable = false;
    initialState.scenarioFlag = false;
    initialState.scenario_flag_checkbox = false;
    initialState.isExpandable = false;
    initialState.indexOptions = [];
    initialState.index_commodity_id = "";
    initialState.countryOptions = [];
    initialState.countryOptionsAll = [];
    initialState.country_selection = "";
    initialState.country_selection_value = [];
    initialState.factoryOptions = [];
    initialState.factoryOptionsAll = [];
    initialState.factory_selection = [];
    initialState.use_for_final_hedging = false;
    initialState.index_source_weights = [];
    initialState.demandDataEdited = false;
    initialState.searchValue="";
    updatewholeCNCInputs(initialState);
  }

  // search and filter x rows based on keyword
  function SearchVal(e, flag = false) {
    let value = flag ? e : e.target.value;
    updateCNCInputs("searchValue", value);
    // setVal(value);
    // if (value.length > 0) {
    //   settextClearVisible(true);
    // }
    // if (value == "" || value == "" || value.length == 0) {
    //   settextClearVisible(false);
    // }
    let result = [];
    result = allData.shared_commodity.filter((data) => {
      return (
        data.user_commodity_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        (data.commodity_created_dt !== undefined && data.commodity_created_dt.includes(value)) ||
        data.description.toString().toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredData(result);
    !flag && setPage(0);
  }

  //Close alert snackbar
  const alertHandleClose = () => {
    if (user.alertInfo.closeFlag === true)
      updateAlertCNC({ ...user.alertInfo, open: false, closeFlag: true });
  };

  //function to get list of commodity hedging shared with the user
  function getCommodityData(delete_flag=false) {
    fetch(api + "/commodities/shared?user=" + username)
      .then((response) => response.json())
      .then((res) => {
        if(res.status.statusCode==="cnc-200"){
          updateCNCDatas("shared_commodity", res.data);
          if (cncData.searchValue !== undefined && cncData.searchValue !== "" &&delete_flag) {
            allData.shared_commodity = res.data;
            SearchVal(cncData.searchValue, true);
          }
          else {
            setFilteredData(res.data);
          }
          setLoading(false);
        }

        else{
          updateCNCDatas("shared_commodity", []);
          setFilteredData([]);
          setLoading(false);
        }
        
      })
      .catch((error) => {
        updateCNCDatas("shared_commodity", []);
        setFilteredData([]);
        setLoading(false);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
  }

  useEffect(() => {
    // initializeState();
    setLoading(true);
    getCommodityData();
    // eslint-disable-next-line
  }, [allData.shared_commodity === null]);

  useEffect(() => {
    alertHandleClose();
    // if(userData.demand_planner){
    //   // updateAlertCNC({
    //   //   open: true,
    //   //   message: config.messages.access_restricted,
    //   //   severity: "info",
    //   //   closeFlag:false
    //   // });
    //   history.push({
    //     pathname: `/home/${match.params.function}/CNC/updateDemand/`,
    //   });
    // }
  }, [])

  //function to Edit a particular Commodity
  const handleEdit = (item) => {
    initializeState();
    let data = allData.shared_commodity.filter((obj) => item === obj.cnc_id);
    data = data[0];
    updateCNCInputs("mode", "edit");
    updateCNCDatas("activeCommodity", data);
    history.push({
      pathname: `/home/myCommodityHedging/${data.user_commodity_name}_${data.cnc_id}`,
    });
  };

  // function to copy a particular Commodity
  const copyCommodity = (id) => {
    initializeState();
    let data = allData.shared_commodity.filter((obj) => id === obj.cnc_id);
    data = data[0];
    var input = { ...data };
    // var input = data
    input.cnc_id = "";
    input.run_id = "";
    input.user = username;
    input.user_commodity_name = input.user_commodity_name + " Copy";
    input.commodity_created_dt = currentDate;
    // input.scenario_model_output = [];
    // input.scenario_rescore_output = [];
    let triggered_time =
      currentUTC +
      " " +
      (today.getUTCHours() < 10
        ? "0" + today.getUTCHours()
        : today.getUTCHours()) +
      ":" +
      (today.getUTCMinutes() < 10
        ? "0" + today.getUTCMinutes()
        : today.getUTCMinutes()) +
      ":" +
      (today.getUTCSeconds() < 10
        ? "0" + today.getUTCSeconds()
        : today.getUTCSeconds());
    input.total_runs = 0;
    input.starred = 0;
    input.shared = 0;
    input.shared_with = [];
    input.timestamp = triggered_time
    setAnchorEl(null);
    const reqOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    };
    fetch(api + "/userCommodities", reqOptions)
      .then((response) => response.json())
      .then((res) => {
        let data = res.data;
        if (res.status.statusCode === "cnc-200") {
          // updateModelInput("model_id", "");
          updateCNCInputs("mode", "edit");
          data.copyFlag = true;
          updateCNCDatas("activeCommodity", data);
          updateAlertCNC({
            open: true,
            message: config.messages.commodity_copy,
            severity: "info",
            closeFlag: false
          });
          getCommodityData();
          reqOptions.body = JSON.stringify(res.data)
          setTimeout(() => {
            reqOptions.method = "POST"
            fetch(api + "/db/hedging_forecast", reqOptions)
              .then((response) => response.json())
              .then((response) => { })
              .catch((error) => {
                updateAlertCNC({
                  open: true,
                  message: config.messages.unexpected_error,
                  severity: "info",
                  closeFlag:false
                });
              });
          }, 1000);
          history.push({
            pathname: `/home/myCommodityHedging/${data.user_commodity_name}_${data.cnc_id}`,
          });
        }

        else{
          updateCNCDatas("activeCommodity", []);
          updateAlertCNC({
            open: true,
            message: config.messages.unexpected_error,
            severity: "info",
            closeFlag:false
          });
        }
      })
      .catch((error) => {
        updateCNCDatas("activeCommodity", []);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      });
  };

  //function to open popover to provide options for the commodity shared
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setvalue(event.target.id);
    let data = filteredData.filter(
      (obj) => event.target.id === obj.cnc_id
    );
    setAccess(data[0].shared_with[0].accessType);
  };

  //function to close the popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  //function to view a particular commodity
  const handleView = (item) => {
    initializeState();
    let data = allData.shared_commodity.filter((obj) => item === obj.cnc_id);
    data = data[0];
    updateCNCInputs("mode", "view-shared");
    updateCNCDatas("activeCommodity", data);
    history.push({
      pathname: `/home/myCommodityHedging/${data.user_commodity_name}_${data.cnc_id}`,
    });
  };


  //function to set page for changing table row
  const pageChange = (event, value) => {
    setPage(value);
  };

  let page_props = {
    total: filteredData.length,
    page: page,
    changePage: pageChange,
  };

  //close after shared or cancel sharing commodity hedging
  const dialogClose = () => {
    setDialog({
      ...dialogData,
      open: false,
    });
  };

  //function to handle run
  const runCommodity = (id) => {
    let data = allData.shared_commodity.filter((obj) => id === obj.cnc_id);
    data = data[0];
    let triggered_time =
      currentUTC +
      " " +
      (today.getUTCHours() < 10
        ? "0" + today.getUTCHours()
        : today.getUTCHours()) +
      ":" +
      (today.getUTCMinutes() < 10
        ? "0" + today.getUTCMinutes()
        : today.getUTCMinutes()) +
      ":" +
      (today.getUTCSeconds() < 10
        ? "0" + today.getUTCSeconds()
        : today.getUTCSeconds());

    const run = {
      run_id: data.cnc_id,
      cnc_id: data.cnc_id,
      user_commodity_name: data.user_commodity_name,
      use_for_final_hedging: data.use_for_final_hedging,
      created_by: username,
      user: data.user,
      created_on: currentDate,
      triggered_on: triggered_time,
      duration: "",
      flag: null,
      status: "",
      message: "",
      email: usermail,
      description: data.description,
      run_start_time: "",
      isDataLoaded: false,
    };

    const notification = {
      notification_id: "",
      username: data.user,
      triggered_on: triggered_time,
      cnc_id: data.cnc_id,
      user_commodity_name: data.user_commodity_name,
      notification_type: "",
      status: "Unread",
      message: "",
    };

    const input = {
      cnc_commodities: data,
      runs: run,
      notification: notification,
    };

    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };
    handleClose();


    fetch(api + "/run", reqOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          response.commodity_data.timestamp = response.run_data['triggered_on']
          reqOptions.body = JSON.stringify(response.commodity_data)
          // let run_id=response.data.run_id
          let run_data = response.run_data
          let cnc_commodities = response.commodity_data
          let notification = response.notification;
          // initializeState();
          let input = {
            runs: run_data,
            cnc_commodities: cnc_commodities,
            notification: notification,
          }
          let req = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(input),
          };
          fetch(api + "/trigger_job", req)
            .then((res) => res.json())
            .then((res) => {

            })
            .catch((error) => {
              updateAlertCNC({
                open: true,
                message: config.messages.unexpected_error,
                severity: "info",
                closeFlag:false
              });
            });
          setTimeout(() => {
            reqOptions.method = "POST"
            fetch(api + "/db/hedging_forecast", reqOptions)
              .then((response) => response.json())
              .then((response) => { })
              .catch((error) => {
                updateAlertCNC({
                  open: true,
                  message: config.messages.unexpected_error,
                  severity: "info",
                  closeFlag:false
                });
              }); 
          }, 1000);
        }
        else {
          updateAlertCNC({
            open: true,
            message:
              response.status.statusMessage,
            severity: "info",
            closeFlag: true
          });
        }
      })
      .catch((error) => {
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag:false
        });
      }); 

    updateAlertCNC({
      open: true,
      message: config.messages.run_triggered,
      closeFlag: true,
    });
  };
  return (
    <>

      {
        dialogData.open && <Dialogs props={dialogData}></Dialogs>
        // null
      }
      {loading ? (
        <Loader />
      ) : (
        <>

          {allData.shared_commodity !== null &&
            allData.shared_commodity.length !== 0 ? (
            <>
              <Search setFilteredData={setFilteredData} searchData={allData.shared_commodity} setPage={setPage} />
              <div className="configs-container">
                <div className="apps-row">
                  {filteredData.length > 0 ?

                    <TableComponent
                      component="SharedCommodity"
                      header={config.sharedCommodityHeader}
                      filteredData={filteredData}
                      page_props={page_props}
                      page={page}
                      rowsPerpage={rowsPerpage}
                      handleClick={handleClick}
                      handleClose={handleClose}
                      handleEdit={handleEdit}
                      handleView={handleView}
                      runCommodity={runCommodity}
                      copyCommodity={copyCommodity}
                      anchorEl={anchorEl}
                      value={value}
                      access={access}
                    />

                    :
                    <div className="no-commodity">
                      <img src={nocommodity} alt="" className="cnc-img" />
                      <p>No commodity available</p>
                    </div>
                  }
                </div>
              </div>
            </>
          ) : (
            <div className="no-scenario">
              <img src={nocommodity} alt="" className="cnc-img" />
              <p>No Shared Commodity Hedging available</p>
            </div>
          )}
        </>


      )
      }

    </>
  );
}


SharedCommodity.propTypes = {
  user: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  cncData: state.cncinputs,
  allData: state.data,
  defaultCommodity: state.cncinputs,
});

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateUserInfo,
  updateCNCDatas,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SharedCommodity));