import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useState } from "react";
import { config } from "../../../config/config";
import { withRouter } from "react-router";
// import Button from '@material-ui/core/Button';
import {
  updateCNCInputs,
  updateAlertCNC,
  updatewholeCNCInputs,
  updateCNCDatas,
  updateFlag,
} from "../../../redux/actions";
import Buttons from "../../../components/Button/Buttons";

import Loader from "../../../components/Loader/Loader";
import RecommendMetaSelection from "./components/RecommendMetaSelection";
import nocommodity from "../../../../assets/images/undraw_empty.svg";
import ExecutionTable from "./components/ExecutionTable";
import { v4 } from "uuid";
import CommentsDialog from "./components/CommentsDialog";
import Card from "@material-ui/core/Card";
import new_entry_2 from "../../../../assets/images/add_new_entry_2.svg";
import UnSavedConfirmationDialog from "../../../utils/UnSavedConfirmationDialog";
import DuplicateConfirmationDialog from "./components/DuplicateConfirmationDialog";
import { Grid } from "@material-ui/core";
import Select, { components } from "react-select";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import OnsaveDialog from "./OnsaveDialog";
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  container: {},
});

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    maxHeight: "80%",
    fontSize: 12,
    fontFamily: "Hind Siliguri !important",
    zIndex: 9999,
  }),
};
function Recommendation(props) {
  let {
    user,
    cncData,
    updateCNCInputs,
    updatewholeCNCInputs,
    updateAlertCNC,
    updateFlag,
    match,
    flags,
    allData,
  } = props;
  const [loading, setLoading] = useState(false);

  //My changes
  const [editedRows, setEditedRows] = useState([]);
  const [statuseditedRows, setStatusEditedRows] = useState([]);

  const [open, setOpen] = useState(false);

  const history = useHistory();
  let api = config.api.api_url;
  const [page, setPage] = React.useState(0);
  const [CommentsDialogData, setCommentsDialogData] = useState([]);
  const [DuplicateConfirmDialogData, setDuplicateConfirmDialogData] = useState(
    []
  );
  // let rowsPerpage = config.table_rows.pagination_size;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let today = new Date();
  let currentUTC =
    today.getUTCFullYear() +
    "-" +
    (today.getUTCMonth() < 9
      ? "0" + (today.getUTCMonth() + 1)
      : today.getUTCMonth() + 1) +
    "-" +
    (today.getUTCDate() < 10 ? "0" + today.getUTCDate() : today.getUTCDate());

  const handleTypeChange = (event, id) => {
    // let newFilteredData=[...cncData.filteredData];
    let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));

    newFilteredData.map((obj, idx) => {
      if (obj.id === id) {
        obj.type = event.value;
      }
    });

    updateCNCInputs("filteredData", newFilteredData);
    setCurrentData(newFilteredData);
  };

  const handleStatusChange = (event, id) => {
    // let newFilteredData=[...cncData.filteredData];
    let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));

    newFilteredData.map((obj, idx) => {
      if (obj.id === id) {
        obj.status = event.value;
      }
    });

    updateCNCInputs("filteredData", newFilteredData);
    setCurrentData(newFilteredData);
  };

  //to handle factory change in table
  const handleTableFactoryChange = (event, id) => {
    // let newFilteredData=[...cncData.filteredData];
    let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));

    newFilteredData.map((obj, idx) => {
      if (obj.id === id) {
        obj.geography_id = event.value;
      }
    });

    updateCNCInputs("filteredData", newFilteredData);
    setCurrentData(newFilteredData);
  };

  const checkforDuplicateRow = (row) => {
    let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));
    let row_date = new Date(row.eta_month);
    let row_date_utc = convertToUTC(row_date);
    let duplicate_flag = false;
    for (let i = 0; i < newFilteredData.length; i++) {
      if (row.id !== newFilteredData[i].id) {
        let filter_row_date = new Date(newFilteredData[i].eta_month);
        let filter_row_date_utc = convertToUTC(filter_row_date);
        if (
          newFilteredData[i].geography_id === row.geography_id &&
          newFilteredData[i].type === row.type &&
          row_date_utc.toString() === filter_row_date_utc.toString() &&
          newFilteredData[i].status === row.status
        ) {
          // updateAlertCNC({
          //   open: true,
          //   message: "Warning, There is already a duplicate row with current selection ",
          //   severity: "info",
          //   closeFlag: false,
          // });
          duplicate_flag = true;
          setDuplicateConfirmDialogData({
            open: true,
            row: row,
            handleDuplicateConfirmDialog: handleDuplicateConfirmDialog,
            close: handleDuplicateConfirmDialog,
          });
        }
      }
    }
    if (!duplicate_flag) return true;
  };
  //fucntion to handle duplicate confirmation
  const handleDuplicateConfirmDialog = (row, action, user_choice = true) => {
    if (action == "close") {
      //close the dialog
      setDuplicateConfirmDialogData({
        ...DuplicateConfirmDialogData,
        open: false,
      });

      let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));
      //if user wants to proceed with duplicates
      if (user_choice) {
        //change the edit flag only if the row is valid and non duplicate
        newFilteredData.map((obj) => {
          if (obj.id === row.id) {
            obj.edit_flag = false;
            obj.is_edited = true;
            let temp = editedRows;
            let temp2 = [];
            let temp3 = statuseditedRows;
            let temp4 = [];
            let oldStatus = obj.status;

            for (let i = 0; i < temp.length; i++) {
              if (obj.id !== temp[i].id) {
                temp2.push(temp[i]);
              }
            }

            for (let i = 0; i < temp3.length; i++) {
              if (obj.id !== temp3[i].id) {
                temp4.push(temp3[i]);
              }
            }
            oldStatus == "Filled" &&
              row.executed_user != "" &&
              row.timestamp != "" &&
              temp4.push(row);
            temp2.push(obj);

            setEditedRows(temp2);
            setStatusEditedRows(temp4);
          }
        });
        updateCNCInputs("filteredData", newFilteredData);
        setCurrentData(newFilteredData);

        //update the changed row in  filterDataCopy as well
        let index;
        // let filteredDataCopy=[...cncData.filteredDataCopy];
        let filteredDataCopy = JSON.parse(
          JSON.stringify(cncData.filteredDataCopy)
        );
        filteredDataCopy.map((obj, idx) => {
          if (obj.id === row.id) {
            index = idx;
          }
        });
        filteredDataCopy[index] = { ...newFilteredData[index] };
        updateCNCInputs("filteredDataCopy", filteredDataCopy);
        // return true
      }
      // else return false
    }
  };

  //to handle row edit,save,cancel
  const handleRowEdit = (id, type, index) => {
    if (!flags.unSavedChanges) updateFlag("unSavedChanges", true); //to notify some changes are being done in screen
    // let newFilteredData=[...cncData.filteredData];
    let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));

    if (type === "edit") {
      newFilteredData.map((obj, idx) => {
        if (obj.id === id) {
          obj.edit_flag = true;
        }
      });
      updateCNCInputs("filteredData", newFilteredData);
      setCurrentData(newFilteredData);
    } else if (type === "save") {
      let isValid = false;
      let notDuplicate = false; //used to check whether row is not duplicate
      let Row;
      newFilteredData.map((obj, idx) => {
        if (obj.id === id) {
          Row = obj;
          if (
            obj.geography_id === "" ||
            obj.status === "" ||
            obj.type === "" ||
            obj.eta_month === "" ||
            obj.executed_volume === null ||
            obj.executed_price === null ||
            obj.executed_volume === "" ||
            obj.executed_price === "" ||
            obj.executed_volume === undefined ||
            obj.executed_price === undefined
          ) {
            updateAlertCNC({
              open: true,
              message: "Fill all Mandatory Fields",
              severity: "info",
              closeFlag: false,
            });
          } else {
            // obj.edit_flag = false;
            isValid = true;
          }
        }
      });

      notDuplicate = checkforDuplicateRow(Row);

      if (isValid && notDuplicate) {
        //change the edit flag only if the row is valid and non duplicate
        newFilteredData.map((obj) => {
          if (obj.id === Row.id) {
            obj.edit_flag = false;
            //My changes
            obj.is_edited = true;
            let temp = editedRows;
            let temp2 = [];

            for (let i = 0; i < temp.length; i++) {
              if (obj.id !== temp[i].id) {
                temp2.push(temp[i]);
              }
            }

            temp2.push(obj);

            setEditedRows(temp2);
          }
        });
        updateCNCInputs("filteredData", newFilteredData);
        setCurrentData(newFilteredData);

        //update the changed row in  filterDataCopy as well
        let index;
        // let filteredDataCopy=[...cncData.filteredDataCopy];
        let filteredDataCopy = JSON.parse(
          JSON.stringify(cncData.filteredDataCopy)
        );
        filteredDataCopy.map((obj, idx) => {
          if (obj.id === id) {
            index = idx;
          }
        });
        filteredDataCopy[index] = { ...newFilteredData[index] };
        updateCNCInputs("filteredDataCopy", filteredDataCopy);
      } else {
        return;
      }
    } else {
      let isEmptyRow = false;
      let index;
      let filteredDataCopy = JSON.parse(
        JSON.stringify(cncData.filteredDataCopy)
      );
      newFilteredData.map((obj, idx) => {
        if (obj.id === id) {
          index = idx;
          if (
            obj.geography_id === "" &&
            obj.status === "" &&
            obj.type === "" &&
            obj.eta_month === "" &&
            ((obj.executed_volume === null && obj.executed_price === null) ||
              (obj.executed_volume === "" && obj.executed_price === ""))
          ) {
            isEmptyRow = true;
            return;
          } else {
            obj.edit_flag = false;
          }
        }
      });

      //check the copy arr for the empty row as well
      filteredDataCopy.map((obj, idx) => {
        if (obj.id === id) {
          index = idx;
          if (
            obj.geography_id === "" &&
            obj.status === "" &&
            obj.type === "" &&
            obj.eta_month === "" &&
            ((obj.executed_volume === null && obj.executed_price === null) ||
              (obj.executed_volume === "" && obj.executed_price === ""))
          ) {
            isEmptyRow = true;
            return;
          } else {
            obj.edit_flag = false;
          }
        }
      });
      //if all values of the row are empty and user click cancel remove the row else replace row from copy array
      if (isEmptyRow) {
        let newArr = newFilteredData.filter((obj, idx) => {
          return index !== idx;
        });
        updateCNCInputs("filteredData", newArr);
        setCurrentData(newArr);

        let newCopyArr = filteredDataCopy.filter((obj, idx) => {
          return index !== idx;
        });
        updateCNCInputs("filteredDataCopy", newCopyArr);
        setCurrentData(newCopyArr);
      } else {
        newFilteredData[index] = {
          ...cncData.filteredDataCopy[index],
          edit_flag: false,
        };

        updateCNCInputs("filteredData", newFilteredData);
        setCurrentData(newFilteredData);
      }
    }
  };

  const convertToUTC = (last_date) => {
    last_date = new Date(last_date);
    if (last_date.getMonth() === 1) {
      if (
        last_date.getFullYear() % 100 === 0
          ? last_date.getFullYear() % 400 === 0
          : last_date.getFullYear() % 4 === 0
      ) {
        if (last_date.getDate() === 29) {
          last_date.setDate(last_date.getDate() + 1);
          last_date.setHours(0, 0, 0, 0);
        }
      } else if (last_date.getDate() === 28) {
        last_date.setDate(last_date.getDate() + 1);

        last_date.setHours(0, 0, 0, 0);
      }
    } else if (
      last_date.getMonth() === 0 ||
      last_date.getMonth() === 2 ||
      last_date.getMonth() === 4 ||
      last_date.getMonth() === 6 ||
      last_date.getMonth() === 7 ||
      last_date.getMonth() === 9 ||
      last_date.getMonth() === 11
    ) {
      if (last_date.getDate() === 31) {
        last_date.setDate(last_date.getDate() + 1);
        last_date.setHours(0, 0, 0, 0);
      }
    } else {
      if (last_date.getDate() === 30) {
        last_date.setDate(last_date.getDate() + 1);
        last_date.setHours(0, 0, 0, 0);
      }
    }
    return last_date;
  };

  //to handle input changes for executed volume,exe price LCY &FCY
  const handleMonthChange = (event, id) => {
    // let newFilteredData=[...cncData.filteredData];
    let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));
    let name = event.target.name;
    let dt = event.target.value;
    let utc_dt = convertToUTC(dt);
    let future_dt = formattedDate(
      new Date(
        utc_dt.setMonth(utc_dt.getMonth() - cncData.eta_futures_month_lag)
      )
    );

    newFilteredData.map((obj, idx) => {
      if (obj.id === id) {
        obj[name] = "" + event.target.value + "-01";
        obj["contract_month"] = future_dt;
        // updateAlertCNC({
        //   open: true,
        //   message: "Date selection adjusted to first day of month",
        //   severity: "info",
        //   closeFlag:false
        // });
      }
    });

    updateCNCInputs("filteredData", newFilteredData);
    setCurrentData(newFilteredData);
  };

  //to handle input changes for executed volume,exe price LCY &FCY
  const handleInputchange = (event, id, name) => {
    // let newFilteredData=[...cncData.filteredData];
    let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));
    let flag = false;
    // let name=event.target.name
    newFilteredData.map((obj, idx) => {
      if (obj.id === id) {
        obj[name] = event.floatValue;
        flag = obj.edit_flag;
      }
    });

    if (flag) {
      updateCNCInputs("filteredData", newFilteredData);
      setCurrentData(newFilteredData);
    }
  };

  //function to set page for changing table row
  const pageChange = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const startPage = () => {
    setPage(0);
  };
  const [total, setTotal] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  let page_props = {
    total: currentData ? currentData.length : 0,
    page: page,
    changePage: pageChange,
    rowsPerPage: rowsPerPage,
    handleChangeRowsPerPage: handleChangeRowsPerPage,
    show_pageOptions: true,
  };

  const handleCommments = (user_comments, id) => {
    // let newFilteredData=[...cncData.filteredData];
    let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));
    newFilteredData.map((obj, idx) => {
      if (obj.id === id) {
        obj.user_comments = user_comments;
      }
    });

    updateCNCInputs("filteredData", newFilteredData);
    setCurrentData(newFilteredData);

    DialoghandleClose();
  };

  const handleDialog = (user_comments, id) => {
    setCommentsDialogData({
      open: true,
      user_comments: user_comments,
      id: id,
      handleCommments: handleCommments,
      close: DialoghandleClose,
    });
  };
  const DialoghandleClose = () => {
    setCommentsDialogData({
      ...CommentsDialogData,
      open: false,
    });
  };

  function initializeState() {
    let initialState = [];
    initialState.loading = false;
    initialState.mode = "";
    initialState.override_forecast_header = [];
    initialState.demand_header = [];
    initialState.execution_demand_month_options = [];
    initialState.demand_treeData = [];
    initialState.commodityOptions = [];
    initialState.commodity_selection = "";
    initialState.commodity_selection_value = "";
    initialState.month_selection = "";
    initialState.month_selection_value = "";
    initialState.description = "";
    initialState.month_options = [];
    initialState.region_selection = [];
    initialState.regionOptions = [];
    initialState.regionOptionsAll = [];
    initialState.region_selection_value = [];
    initialState.isIndexVisible = false;
    initialState.isCountryVisible = false;
    initialState.isDemandEditable = false;
    initialState.scenarioFlag = false;
    initialState.scenario_flag_checkbox = false;
    initialState.isExpandable = false;
    initialState.indexOptions = [];
    initialState.index_commodity_id = "";
    initialState.countryOptions = [];
    initialState.countryOptionsAll = [];
    initialState.country_selection = "";
    initialState.country_selection_value = [];
    initialState.factoryOptions = [];
    initialState.factoryOptionsTable = [];
    initialState.factoryOptionsAll = [];
    initialState.factory_selection = [];
    initialState.use_for_final_hedging = false;
    initialState.index_source_weights = [];
    initialState.demandDataEdited = false;
    initialState.factory_chosen = [];
    initialState.filteredData = [];
    initialState.filteredDataCopy = [];
    initialState.execution_type = [];
    initialState.execution_status = [];
    setEditedRows([]);
    setStatusEditedRows([]);
    updatewholeCNCInputs(initialState);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }
  function initializeState1(dat) {
    setCurrentData(dat);
    setTypeFilter({
      label: "",
      value: "",
    });
    setEtaFilter({
      label: "",
      value: "",
    });
    setFactoryFilter({
      geography_level: "",
      label: "",
      value: "",
    });
  }

  useEffect(() => {
    setLoading(true);
    initializeState();
    getCommodity();
    setDemandPlannerInfo();
    updateFlag("currentPageID", match.url.split("/")[2]);
  }, []);

  useEffect(() => {
    if (
      cncData.commodityOptionsAll !== undefined &&
      cncData.commodityOptionsAll.length > 0 &&
      cncData.commodity_selection_value !== ""
    ) {
      cncData.commodityOptionsAll.filter((obj) => {
        if (obj.value === cncData.commodity_selection_value) {
          updateCNCInputs("grade_commodity_flag", obj.grade_commodity_flag);
          updateCNCInputs("isCountryVisible", obj.isCountryVisible);
          updateCNCInputs("isDairy", obj.isDairy);
          updateCNCInputs("eta_futures_month_lag", obj.eta_futures_month_lag);
          updateCNCInputs("isIndexVisible", obj.isIndexVisible);
          updateCNCInputs("execution_type", obj.execution_type);
          updateCNCInputs("execution_status", obj.execution_status);
        }
      });
    }
  }, [cncData.commodityOptionsAll, cncData.commodity_selection_value]);

  function getNext_N_MonthNamesWithYear(maxMonth, date = "") {
    var names = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var now = new Date();
    var month;
    var year;
    if (arguments.length === 1) {
      // month=now.getMonth()-4;
      let before_three_month = new Date(now.setMonth(now.getMonth() - 3));
      month = before_three_month.getMonth();
      year = before_three_month.getFullYear();
      maxMonth = 13;
    } else {
      let month_in_text = date.split(" ")[0];
      month = names.indexOf(month_in_text);
      year = date.split(" ")[1];
    }

    let month_options = [];
    let override_forecast_header = [];
    let demand_header = [];
    for (var i = 0; i < maxMonth; ++i) {
      if (arguments.length === 1) {
        month_options[i] = {
          label: names[month] + " " + year,
          value: names[month] + " " + year,
        };
      } else if (arguments.length > 1) {
        override_forecast_header[i] = {
          label: names[month] + " " + year,
          value: "",
        };

        demand_header[i] = {
          label: names[month] + " " + year,
          value: "",
        };
      }

      if (++month === 12) {
        month = 0;
        ++year;
      }
    }
    if (arguments.length === 1) {
      updateCNCInputs("month_options", month_options);
    }
    if (arguments.length > 1) {
      updateCNCInputs("override_forecast_header", override_forecast_header);
      updateCNCInputs("demand_header", demand_header);
    }
  }

  const setDemandPlannerInfo = () => {
    if (user.group_add_info !== undefined && user.group_add_info !== null) {
      let commodity = user.group_add_info.commodity;
      let commodityOptions = [];
      for (let i = 0; i < commodity.length; i++) {
        commodityOptions[i] = {
          value: commodity[i].commodity_id,
          label: commodity[i].commodity_name,
          decision_months: commodity[i].decision_months,
          indices: commodity[i].indices,
          factory_id: commodity[i].factory_id,
          region: commodity[i].region,
          isIndexVisible: commodity[i].isIndexVisible,
        };
      }
      updateCNCInputs("commodityOptions", commodityOptions);
      if (commodityOptions.length === 1) {
        updateCNCInputs("commodity_selection", commodityOptions[0].label);
        updateCNCInputs("commodity_selection_value", commodityOptions[0].value);
        getNext_N_MonthNamesWithYear(commodityOptions[0].decision_months);
        let indexOptions = [];
        let e = commodityOptions[0];
        for (let i = 0; i < e.indices.length; i++) {
          indexOptions[i] = {
            label: e.indices[i].index_name,
            value: e.indices[i].index_commodity_id,
            source_weights: e.indices[i].source_weight,
          };
        }
        if (indexOptions.length === 1) {
          updateCNCInputs("index_commodity_id", indexOptions[0].value);
          updateCNCInputs(
            "index_source_weights",
            indexOptions[0].source_weights
          );
        }
        updateCNCInputs("indexOptions", indexOptions);
        let regionOptions = [];
        let region = e.region;
        // getTableFactories(e.region)//to get Factories for corresponding regions to show in Table
        for (let i = 0; i < region.length; i++) {
          regionOptions[i] = {
            value: region[i],
            label: region[i],
            geography_level: "region",
          };
        }
        if (regionOptions.length === 1) {
          updateCNCInputs("region_selection_value", [regionOptions[0]]);
        }
        updateCNCInputs("regionOptions", regionOptions);
        //set the factories to be shown in execution table
        let factoryOptions = [];
        let factory_id = e.factory_id;
        for (let i = 0; i < factory_id.length; i++) {
          factoryOptions[i] = {
            value: factory_id[i],
            label: factory_id[i],
            geography_level: "factory",
          };
        }

        updateCNCInputs("factoryOptionsTable", factoryOptions);
      }
    }
  };

  const validateRow = () => {
    if (cncData.filteredData !== undefined && cncData.filteredData.length > 0) {
      for (let i = 0; i < cncData.filteredData.length; i++) {
        let row = { ...cncData.filteredData[i] };
        if (cncData.isDairy) {
          if (
            row.geography_id === "" ||
            row.status === "" ||
            row.type === "" ||
            row.eta_month === "" ||
            row.executed_volume === null ||
            row.executed_volume === "" ||
            row.executed_price === null ||
            row.executed_price === "" ||
            row.executed_volume === undefined ||
            row.executed_price === undefined
          ) {
            return false;
          }
        } else {
          if (
            row.geography_id === "" ||
            row.status === "" ||
            row.type === "" ||
            row.eta_month === "" ||
            row.executed_volume === null ||
            row.executed_price === null ||
            row.executed_price_fcy === null ||
            row.executed_volume === "" ||
            row.executed_price === "" ||
            row.executed_price_fcy === "" ||
            row.executed_volume === undefined ||
            row.executed_price === undefined ||
            row.executed_price_fcy === undefined
          ) {
            return false;
          }
        }
      }

      return true;
    }
  };

  const addNewRow = (isEmpty = false) => {
    setPage(0);
    setTypeFilter({
      label: "",
      value: "",
    });
    setEtaFilter({
      label: "",
      value: "",
    });
    setFactoryFilter({
      geography_level: "",
      label: "",
      value: "",
    });
    console.log(cncData.isDairy, !cncData.isDairy ? 1 : "", isEmpty);
    if (!flags.unSavedChanges) updateFlag("unSavedChanges", true); //to notify some changes are being done in screen
    //isEmpty is used when no execution available & a new empty row needs to be added
    if (isEmpty) {
      let newRow = {
        id: v4(),
        type: "",
        geography_id: "",
        status: "",
        executed_volume: !cncData.isDairy ? 1 : "",
        executed_price_fcy: "",
        executed_price: "",
        user_comments: "",
        contract_month: "",
        eta_month: "",
        edit_flag: true,
        run_id: "",
        index_commodity_id: "",
        decision_month: "",
        executed_user: "",
        timestamp: "",
        existing_data: false,
      };

      // let newFiltereddata=[...cncData.filteredData];
      let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));
      newFilteredData.unshift(newRow);
      updateCNCInputs("filteredData", newFilteredData);
      setCurrentData(newFilteredData);

      //push the new row in filteredDataCopy as well
      // let newFiltereddatacopy=[...cncData.filteredDataCopy];
      let newFiltereddatacopy = JSON.parse(
        JSON.stringify(cncData.filteredDataCopy)
      );
      newFiltereddatacopy.unshift(newRow);
      updateCNCInputs("filteredDataCopy", newFiltereddatacopy);
    } else {
      // if(!validateRow()){
      //   updateAlertCNC({
      //     open: true,
      //     message: config.messages.fill_mandatory,
      //     severity: "info",
      //     closeFlag: false,
      //   });
      // }

      if (checkforeditrowsOnly()) {
        updateAlertCNC({
          open: true,
          message: "Save already editing rows before adding new row",
          severity: "info",
          closeFlag: false,
        });
      } else {
        let newRow = {
          id: v4(),
          type: "",
          geography_id: "",
          status: "",
          executed_volume: !cncData.isDairy ? 1 : "",
          executed_price_fcy: "",
          executed_price: "",
          user_comments: "",
          contract_month: "",
          eta_month: "",
          edit_flag: true,
          run_id: "",
          index_commodity_id: "",
          decision_month: "",
          executed_user: "",
          timestamp: "",
          existing_data: false,
        };

        // let newFiltereddata=[...cncData.filteredData];
        let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));
        //before adding a new row reset edit flag to false for other rows(if any)
        newFilteredData.map((obj) => {
          if (obj.edit_flag) {
            obj.edit_flag = false;
          }
        });
        newFilteredData.unshift(newRow);

        updateCNCInputs("filteredData", newFilteredData);
        setCurrentData(newFilteredData);

        //push the new row in filteredDataCopy as well
        // let newFiltereddatacopy=[...cncData.filteredDataCopy];
        let newFiltereddatacopy = JSON.parse(
          JSON.stringify(cncData.filteredDataCopy)
        );
        newFiltereddatacopy.unshift(newRow);
        updateCNCInputs("filteredDataCopy", newFiltereddatacopy);
      }
    }
  };

  //get Commodities
  const getCommodity = () => {
    let commodityOptions = [];
    fetch(api + "/commodities")
      .then((respone) => respone.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          for (let i = 0; i < response.data.length; i++) {
            commodityOptions[i] = {
              value: response.data[i].commodity_id,
              label: response.data[i].commodity_name,
              decision_months: response.data[i].decision_months,
              isIndexVisible: response.data[i].isIndexVisible,
              isCountryVisible: response.data[i].isCountryVisible,
              isDemandEditable: response.data[i].isDemandEditable,
              scenarioFlag: response.data[i].scenarioFlag,
              weight_graphs: response.data[i].weight_graphs,
              isExpandable: response.data[i].isExpandable,
              indices: response.data[i].indices,
              report_url: response.data[i].report_url,
              report_params: response.data[i].report_params,
              demand_difference_url: response.data[i].demand_difference_url,
              demand_difference_url_params:
                response.data[i].demand_difference_url_params,
              isDairy: response.data[i].isDairy,
              eta_futures_month_lag: response.data[i].eta_futures_month_lag,
              execution_type: response.data[i].execution_type,
              execution_status: response.data[i].execution_status,
            };
          }
          updateCNCInputs("commodityOptionsAll", commodityOptions);
        } else {
          updateCNCInputs("commodityOptionsAll", []);
        }
      })
      .catch((error) => {
        updateCNCInputs("commodityOptionsAll", []);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag: false,
        });
      });
  };

  function onCancel() {
    initializeState();
    getCommodity();
    setDemandPlannerInfo();
    updateFlag("unSavedChanges", false);
    history.push({
      pathname: `/home/execution`,
    });
  }

  //this function is used to check whether any rows is currently being edited
  const checkforeditrows = () => {
    let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));
    let hasEditRow = false;

    for (let i = 0; i < newFilteredData.length; i++) {
      if (newFilteredData[i].edit_flag) {
        hasEditRow = true;
        break;
      }
    }

    if (hasEditRow) {
      updateAlertCNC({
        open: true,
        message: "Cannot save when rows are being edited",
        severity: "info",
        closeFlag: false,
      });
    } else {
      //My changes
      setOpen(true);
      //updateTableExecutedVolume();
    }
  };

  //this function is used to check whether any rows is currently being edited (without calling update fn)
  const checkforeditrowsOnly = () => {
    let newFilteredData = JSON.parse(JSON.stringify(cncData.filteredData));
    let hasEditRow = false;

    for (let i = 0; i < newFilteredData.length; i++) {
      if (newFilteredData[i].edit_flag) {
        hasEditRow = true;
        break;
      }
    }

    if (hasEditRow) {
      updateAlertCNC({
        open: true,
        message: "Cannot save when rows are being edited",
        severity: "info",
        closeFlag: false,
      });
      return true;
    }

    return false;
  };

  const updateTableExecutedVolume = () => {
    updateCNCInputs("loading", true);
    updateCNCInputs("loading_message", "Execution Data Uploading...");
    let triggered_time =
      currentUTC +
      " " +
      (today.getUTCHours() < 10
        ? "0" + today.getUTCHours()
        : today.getUTCHours()) +
      ":" +
      (today.getUTCMinutes() < 10
        ? "0" + today.getUTCMinutes()
        : today.getUTCMinutes()) +
      ":" +
      (today.getUTCSeconds() < 10
        ? "0" + today.getUTCSeconds()
        : today.getUTCSeconds());

    let filteredData = JSON.parse(JSON.stringify(cncData.filteredData));
    //updating date format of contract month while sending to table
    filteredData.map((obj) => {
      if (obj.contract_month.includes("T")) {
        obj.contract_month = obj.contract_month.split("T")[0];
      } else {
        obj.contract_month = obj.contract_month + "-01";
      }
    });

    let input = {
      timestamp: triggered_time,
      commodity_selection_value: cncData.commodity_selection_value,
      index_commodity_id: cncData.index_commodity_id,
      user: user.preferred_username,
      filteredData: filteredData,
      editedRows: editedRows,
      statuseditedRows: statuseditedRows,
      decision_month: formattedDate(cncData.month_selection_value),
      recommended_run_id: cncData.recommended_run_id
        ? cncData.recommended_run_id
        : "",
    };

    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };
    updateAlertCNC({
      open: true,
      message: "Execution data will be uploaded shortly",
      severity: "info",
      closeFlag: true,
    });
    fetch(api + "/update_recommendation", reqOptions)
      .then((response) => response.json())
      .then((response) => {
        updateFlag("unSavedChanges", false);
        if (response.status.statusCode === "cnc-200") {
          fetch(
            api +
              "/sync_recommendation_data?commodity_selection_value=" +
              cncData.commodity_selection_value +
              "&index=" +
              cncData.index_commodity_id +
              "&decision_month=" +
              cncData.month_selection
          )
            .then((res) => res.json())
            .then((res) => {
              if (response.status.statusCode === "cnc-200") {
                updateCNCInputs("loading", false);
                updateAlertCNC({
                  open: true,
                  message: "Execution data uploaded successfully",
                  severity: "info",
                  closeFlag: true,
                });
                initializeState();
                getCommodity();
                setDemandPlannerInfo();
              } else {
                updateCNCInputs("loading", false);
                updateAlertCNC({
                  open: true,
                  message: response.status.statusMessage,
                  severity: "info",
                  closeFlag: false,
                });
                initializeState();
                getCommodity();
                setDemandPlannerInfo();
                history.push({
                  pathname: `/home/execution`,
                });
              }
            })
            .catch((error) => {
              updateCNCInputs("loading", false);
              updateAlertCNC({
                open: true,
                message: config.messages.unexpected_error,
                severity: "info",
                closeFlag: false,
              });
              initializeState();
              getCommodity();
              setDemandPlannerInfo();
              history.push({
                pathname: `/home/execution`,
              });
            });
        } else if (response.status.statusCode === "cnc-400") {
          updateCNCInputs("loading", false);
          updateAlertCNC({
            open: true,
            message: response.status.statusMessage,
            severity: "info",
            closeFlag: false,
          });
          updateCNCInputs("loading", false);
          initializeState();
          getCommodity();
          setDemandPlannerInfo();
          history.push({
            pathname: `/home/execution`,
          });
        }
      })
      .catch((error) => {
        updateCNCInputs("loading", false);
        updateFlag("unSavedChanges", false);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag: false,
        });
        initializeState();
        getCommodity();
        setDemandPlannerInfo();
        history.push({
          pathname: `/home/execution`,
        });
      });
  };

  function formattedDate(date) {
    let d = new Date(date);
    d = convertToUTC(d);
    let month = String(d.getMonth() + 1);
    let day = "01";
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "01";
    return `${year}-${month}`;
  }

  //Function to getFactories
  const getTableFactories = (geography) => {
    let factoryOptions = [];
    let input;
    if (geography[0] === "Global") {
      input = {
        level: "all",
        parent_id: "",
      };
    } else {
      input = {
        level: "region",
        parent_id: geography,
      };
    }

    //fetch call
    let reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };

    fetch(api + "/factories", reqOptions)
      .then((respone) => respone.json())
      .then((response) => {
        if (response.status.statusCode === "cnc-200") {
          for (let i = 0; i < response.data.length; i++) {
            factoryOptions[i] = {
              value: response.data[i].factory_id,
              label: response.data[i].factory_name,
            };
          }
          updateCNCInputs("factoryOptionsTable", factoryOptions);
        } else {
          updateCNCInputs("factoryOptionsTable", []);
        }
      })
      .catch((error) => {
        updateCNCInputs("factoryOptionsTable", []);
        updateAlertCNC({
          open: true,
          message: config.messages.unexpected_error,
          severity: "info",
          closeFlag: false,
        });
      });
  };
  useEffect(() => {
    setEditedRows([]);
  }, [cncData.month_selection_value, cncData.index_commodity_id]);

  const [factoryFilter, setFactoryFilter] = useState({
    geography_level: "",
    label: "",
    value: "",
  });
  const [typeFilter, setTypeFilter] = useState({
    label: "",
    value: "",
  });
  const [etaFilter, setEtaFilter] = useState({
    label: "",
    value: "",
  });
  const [etaOptions, setEtaoptions] = useState([]);
  const [done, setDone] = useState(true);
  useEffect(() => {
    if (cncData.filteredData && cncData.filteredData.length > 0) {
      let temp = new Set();
      let res = [];
      cncData.filteredData &&
        cncData.filteredData.map((itm) => {
          temp.add(itm.eta_month);
        });
      [...temp].map((itm) => {
        res.push({
          label: new Date(itm).toLocaleDateString("en-us", {
            year: "numeric",
            month: "long",
          }),
          value: itm,
        });
      });
      if (res[0] && res[0].label == "Invalid Date") {
        res = [
          {
            label: "No options",
            value: "",
          },
        ];
      }
      setEtaoptions(res);
      setCurrentData(cncData.filteredData);
      setDone(false);
    }
  }, [cncData.filteredData]);

  useEffect(() => {
    let temp = [];
    if (
      typeFilter.value != "" &&
      factoryFilter.value == "" &&
      etaFilter.value == ""
    ) {
      cncData.filteredData.map((obj) => {
        if (obj.status == typeFilter.value && obj.edit_flag == false) {
          temp.push(obj);
        }
      });
    }
    if (
      factoryFilter.value != "" &&
      typeFilter.value == "" &&
      etaFilter.value == ""
    ) {
      cncData.filteredData.map((obj) => {
        if (obj.geography_id == factoryFilter.value && obj.edit_flag == false) {
          temp.push(obj);
        }
      });
    }
    if (
      factoryFilter.value == "" &&
      typeFilter.value == "" &&
      etaFilter.value != ""
    ) {
      cncData.filteredData.map((obj) => {
        if (obj.eta_month == etaFilter.value && obj.edit_flag == false) {
          temp.push(obj);
        }
      });
    }
    if (
      factoryFilter.value != "" &&
      typeFilter.value != "" &&
      etaFilter.value == ""
    ) {
      cncData.filteredData.map((obj) => {
        if (
          obj.geography_id == factoryFilter.value &&
          obj.status == typeFilter.value &&
          obj.edit_flag == false
        ) {
          temp.push(obj);
        }
      });
    }
    if (
      factoryFilter.value == "" &&
      typeFilter.value != "" &&
      etaFilter.value != ""
    ) {
      cncData.filteredData.map((obj) => {
        if (
          obj.status == typeFilter.value &&
          obj.eta_month == etaFilter.value &&
          obj.edit_flag == false
        ) {
          temp.push(obj);
        }
      });
    }
    if (
      factoryFilter.value != "" &&
      typeFilter.value == "" &&
      etaFilter.value != ""
    ) {
      cncData.filteredData.map((obj) => {
        if (
          obj.geography_id == factoryFilter.value &&
          obj.eta_month == etaFilter.value &&
          obj.edit_flag == false
        ) {
          temp.push(obj);
        }
      });
    }
    if (
      factoryFilter.value != "" &&
      typeFilter.value != "" &&
      etaFilter.value != ""
    ) {
      cncData.filteredData.map((obj) => {
        if (
          obj.geography_id == factoryFilter.value &&
          obj.status == typeFilter.value &&
          obj.eta_month == etaFilter.value &&
          obj.edit_flag == false
        ) {
          temp.push(obj);
        }
      });
    }

    if (
      factoryFilter.value == "" &&
      typeFilter.value == "" &&
      etaFilter.value == ""
    ) {
      temp = cncData.filteredData;
    } else {
      cncData.filteredData.map((obj) => {
        if (obj.edit_flag) {
          temp.unshift(obj);
        }
      });
    }
    startPage();
    setCurrentData(temp);
  }, [factoryFilter, typeFilter, etaFilter]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <i
          aria-describedby={""}
          className="material-icons-outlined "
          value={""}
        >
          filter_alt
        </i>
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : user.group_add_info !== undefined && user.group_add_info !== null ? (
        <>
          <OnsaveDialog
            open={open}
            updateTableExecutedVolume={updateTableExecutedVolume}
            setOpen={setOpen}
            filteredData={editedRows}
            formattedDate={formattedDate}
            type_options={cncData.execution_type}
            status_options={cncData.execution_status}
            factory_options={cncData.factoryOptionsTable}
            header={
              cncData.isDairy
                ? config.ExecutionTableHeaderDairy
                : config.ExecutionTableHeader
            }
            isDairy={cncData.isDairy}
          />
          <RecommendMetaSelection
            disabled={false}
            setPage={setPage}
            convertToUTC={convertToUTC}
            getTableFactories={getTableFactories}
            initialState={initializeState1}
          />
          {cncData.month_selection_value !== "" &&
            cncData.index_commodity_id !== "" &&
            cncData.region_selection_value.length > 0 && (
              <>
                {cncData.loading ? (
                  <>
                    <Card
                      variant="outlined"
                      style={{
                        padding: 0,
                        maxHeight:
                          !cncData.loading && !cncData.expand ? "65vh" : "45vh",
                        height: cncData.loading && "290px",
                      }}
                    >
                      <Loader />
                      <p style={{ textAlign: "center" }}>
                        {cncData.loading_message &&
                        cncData.loading_message !== ""
                          ? cncData.loading_message
                          : "Fetching Execution data..."}
                      </p>
                    </Card>
                  </>
                ) : (
                  <>
                    {cncData.filteredData !== undefined &&
                    cncData.filteredData.length > 0 ? (
                      <>
                        <Grid
                          container
                          spacing={1}
                          style={{ padding: "5px", justifyContent: "right" }}
                        >
                          <Grid item xs={2} sm={2} style={{ zIndex: 999 }}>
                            <div className="">
                              <Select
                                type="month"
                                closeMenuOnSelect={false}
                                isClearable
                                components={{ DropdownIndicator }}
                                options={etaOptions ? etaOptions : []}
                                value={etaOptions.filter((obj) => {
                                  return obj.value === etaFilter.value;
                                })}
                                menuPlacement="auto"
                                style={customStyles}
                                placeholder={
                                  cncData.eta_futures_month_lag != 0
                                    ? "ETA Month"
                                    : "Futures Month"
                                }
                                menuPosition="fixed"
                                onChange={(e) => {
                                  if (e) {
                                    setEtaFilter(e);
                                  } else {
                                    setEtaFilter({
                                      label: "",
                                      value: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={2} sm={2} style={{ zIndex: 999 }}>
                            <div className="">
                              <Select
                                closeMenuOnSelect={false}
                                isClearable
                                components={{ DropdownIndicator }}
                                options={
                                  cncData.factoryOptionsTable
                                    ? cncData.factoryOptionsTable
                                    : []
                                }
                                value={cncData.factoryOptionsTable.filter(
                                  (obj) => {
                                    return obj.value === factoryFilter.value;
                                  }
                                )}
                                menuPlacement="auto"
                                style={customStyles}
                                placeholder={"Factory"}
                                menuPosition="fixed"
                                onChange={(e) => {
                                  if (e) {
                                    setFactoryFilter(e);
                                  } else {
                                    setFactoryFilter({
                                      geography_level: "",
                                      label: "",
                                      value: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={2} sm={2} style={{ zIndex: 999 }}>
                            <div className="">
                              <Select
                                closeMenuOnSelect={false}
                                isClearable
                                components={{ DropdownIndicator }}
                                options={
                                  cncData.execution_status
                                    ? cncData.execution_status
                                    : []
                                }
                                value={cncData.execution_status.filter(
                                  (obj) => {
                                    return obj.value === typeFilter.value;
                                  }
                                )}
                                menuPlacement="auto"
                                style={customStyles}
                                placeholder={"Status"}
                                menuPosition="fixed"
                                onChange={(e) => {
                                  if (e) {
                                    setTypeFilter(e);
                                  } else {
                                    setTypeFilter({
                                      label: "",
                                      value: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <ExecutionTable
                          component="Recommendation"
                          header={
                            cncData.isDairy
                              ? config.ExecutionTableHeaderDairy
                              : config.ExecutionTableHeader
                          }
                          filteredData={currentData}
                          setTotal={setTotal}
                          page_props={page_props}
                          page={page}
                          changePage={startPage}
                          rowsPerPage={rowsPerPage}
                          type_options={cncData.execution_type}
                          handleTypeChange={handleTypeChange}
                          handleStatusChange={handleStatusChange}
                          handleTableFactoryChange={handleTableFactoryChange}
                          status_options={cncData.execution_status}
                          factory_options={cncData.factoryOptionsTable}
                          handleDialog={handleDialog}
                          handleRowEdit={handleRowEdit}
                          handleInputchange={handleInputchange}
                          handleMonthChange={handleMonthChange}
                          formattedDate={formattedDate}
                        />

                        {
                          <div
                            // className="cnc-button-footer"
                            style={{
                              position: "absolute",
                              bottom: "30px",
                              right: "30px",
                            }}
                          >
                            <div className="jss546">
                              <Buttons
                                button={[
                                  {
                                    name: "Cancel",
                                    callback: onCancel,
                                    flag: false,
                                    style: "outlined",
                                  },
                                ]}
                              />
                              <Buttons
                                button={[
                                  {
                                    name: "Add New",
                                    callback: () => {
                                      addNewRow(false);
                                    },
                                    flag: false,
                                    style: "contained",
                                  },
                                ]}
                              />
                              <Buttons
                                button={[
                                  {
                                    name: "Save",
                                    callback: checkforeditrows,
                                    flag: editedRows.length > 0 ? false : true,
                                    style: "contained",
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        }
                      </>
                    ) : (
                      <>
                        <Card
                          variant="outlined"
                          style={{
                            maxHeight: "45vh",
                          }}
                        >
                          {cncData.fetch_status ? (
                            <div
                              className="no-commodity"
                              style={{ padding: "3%" }}
                            >
                              <img
                                src={new_entry_2}
                                alt=""
                                className="cnc-img"
                                style={{ height: "25vh", cursor: "pointer" }}
                                onClick={() => {
                                  addNewRow(true);
                                }}
                              />

                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "14px",
                                }}
                              >
                                No Execution data available for the selection,
                                Click add icon for creating new entries
                              </p>
                            </div>
                          ) : (
                            <div className="no-commodity">
                              <img
                                src={nocommodity}
                                alt=""
                                className="cnc-img"
                              />
                              <p
                                style={{
                                  marginLeft: "25px",
                                  width: "120%",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                {cncData.errorMessage}
                              </p>
                            </div>
                          )}
                        </Card>
                        <div
                          // className="cnc-button-footer"
                          style={{
                            position: "absolute",
                            bottom: "30px",
                            right: "30px",
                          }}
                        >
                          <div className="jss546">
                            <Buttons
                              button={[
                                {
                                  name: "Cancel",
                                  callback: onCancel,
                                  flag: false,
                                  style: "outlined",
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {CommentsDialogData.open && (
                  <CommentsDialog props={CommentsDialogData}></CommentsDialog>
                )}
                {DuplicateConfirmDialogData.open && (
                  <DuplicateConfirmationDialog
                    props={DuplicateConfirmDialogData}
                  ></DuplicateConfirmationDialog>
                )}
              </>
            )}
        </>
      ) : (
        <>
          <div className="no-commodity">
            <img src={nocommodity} alt="" className="cnc-img" />
            <p>No commodity configured for the user</p>
          </div>
        </>
      )}
      {allData.unSavedChangesDialogData.open && (
        <UnSavedConfirmationDialog
          props={{ savefunction: checkforeditrows, cancelfunction: onCancel }}
        ></UnSavedConfirmationDialog>
      )}
    </>
  );
}

Recommendation.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  cncData: PropTypes.object,
  updateCNCInputs: PropTypes.func,
  updatewholeCNCInputs: PropTypes.func,
  updateAlertCNC: PropTypes.func,
  updateCNCDatas: PropTypes.func,
  updateFlag: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  cncData: state.cncinputs,
  allData: state.data,
  flags: state.flags,
});

const mapDispatchToProps = {
  updateCNCInputs,
  updatewholeCNCInputs,
  updateAlertCNC,
  updateCNCDatas,
  updateFlag,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Recommendation)
);
